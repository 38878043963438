import {toast} from 'react-toastify';
import './Toaster.css';

export type IToastType = 'success' | 'warning' | 'error';

const Toast = (message: string, type: IToastType) => {
  const position = {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 3000,
  };

  switch (type) {
    case 'success':
      return toast.success(
        <div className='toastermgs'>
          <p>{message}</p>
        </div>,
        position,
      );
    case 'error':
      return toast.error(
        <div className='toastermgs'>
          <p>{message}</p>
        </div>,
        position,
      );
    case 'warning':
      return toast.warning(
        <div className='toastermgs'>
          <p>{message}</p>
        </div>,
        position,
      );
    default:
      return toast.warning(
        <div className='toastermgs'>
          <p>Toast not defined...</p>
        </div>,
        position,
      );
  }
};
export default Toast;
