import {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {Stack, ThemeProvider, Skeleton, Box} from '@mui/material';
import {useStoreSelector} from '../../../../store/configstore';
import {IErrorResponse} from '../../../../dto/Html.dto';
import RetryPage from '../../../../components/styles/RetryButton';
import {RequestTheme} from '../../../../components/layouts/Theme';
import {
  useRqViewTimedMessagesByRequestID,
  getViewTimedMessagesApiPath,
} from '../../../../react_query/messages/timed-messages/ViewTimedMsgDetails';
import TimedMsgRequestReview from '../request/review/TimedMsgRequestReview';
import {IStation} from '../../../../dto/Station.dto';
import {iTimedMessageMessageItem} from '../request/contents/TimedMsgRequestContentMessageCard';
import {ITimedMessageDayType} from '../../../../dto/timed-message/TimedMessageRequest.dto';
import {iTimedMsgTimesItem} from '../request/times/TimedMsgRequestTimesCard';
import {companyIdSelector} from '../../../../store/Slices/companySlice';
import {TimedSequenceToMessageFormat} from './TimedMsgFunctions';
import {reArrangeSequenceOrder} from '../request/contents/TimedMsgRequestSequenceBuilder';
import {iTimedMsgDateRange} from '../request/TimedMessageRequest';

const TimedMsgDetailed = ({id}: {id: number}) => {
  const [, setRetryTime] = useState(Date.now());

  const companyId = useStoreSelector(companyIdSelector);

  const {data, isFetching, isError, error} = useRqViewTimedMessagesByRequestID(
    Number(companyId),
    Number(id),
    Boolean(id),
  );

  const [selectedStations, setSelectedStations] = useState<IStation[]>([]);

  const [messages, setMessages] = useState<iTimedMessageMessageItem[]>([]);
  const [dayType, setDayType] = useState<ITimedMessageDayType>('one-day');
  const [dateRanges, setDateRanges] = useState<iTimedMsgDateRange[]>([]);
  const [daysTimes, setDaysTimes] = useState<iTimedMsgTimesItem[]>([]);
  const [messageName, setMessageName] = useState<string>('');

  useEffect(() => {
    if (data) {
      setSelectedStations(data.stations);
      setMessageName(data.name);
      setDayType(data.day_type);
      setDateRanges(
        data.date_ranges.map(o => ({
          id: o.id,
          start_date: dayjs(o.start_date),
          end_date: o.end_date ? dayjs(o.end_date) : null,
        })),
      );
      setDaysTimes(
        data.day_times.map(o => {
          return {
            id: o.id,
            selectedDays: o.week_days,
            timeRanges: o.start_times.map((timeString: string) => {
              const [h, m, s] = timeString.split(':');
              return {
                id: Date.now(),
                start_time: dayjs()
                  .set('hour', Number(h))
                  .set('minute', Number(m))
                  .set('second', Number(s)),
              };
            }),
          };
        }),
      );
      let timedSquenceformat = TimedSequenceToMessageFormat(
        data.timed_sequences,
      );
      setMessages(
        reArrangeSequenceOrder(timedSquenceformat, data.message_type),
      );
    }
  }, [data]);

  if (isError) {
    const errResp = error as IErrorResponse;
    return (
      <RetryPage
        pageTitle='Timed message details'
        errResp={errResp?.response}
        setRetryTime={setRetryTime}
        queryKey={[getViewTimedMessagesApiPath(Number(companyId), Number(id))]}
      />
    );
  }

  if (isFetching) {
    return <SkeletonLoader />;
  }

  return (
    <ThemeProvider theme={RequestTheme}>
      {data ? (
        <TimedMsgRequestReview
          stations={selectedStations}
          messageName={messageName}
          messages={messages}
          dayType={dayType}
          dateRanges={dateRanges}
          daysTimes={daysTimes}
          from='view'
        />
      ) : null}
    </ThemeProvider>
  );
};

export default TimedMsgDetailed;

const SkeletonLoader = () => {
  const skeletonElements = [];
  let i = 0;
  while (i < 5) {
    skeletonElements.push(
      <Box key={i}>
        <Skeleton height={40} width={200} />
        <Skeleton width={300} />
        <Skeleton width={300} />
      </Box>,
    );
    i++;
  }
  return <Stack spacing={2}>{skeletonElements}</Stack>;
};
