import {v4 as uuidv4} from 'uuid';
import {IScripts} from './EsmMessages';
import {IMediaTrack} from '../../../dto/MediaTrack.dto';
import {IMessageType} from '../../../dto/Message.dto';

type K = keyof IScripts;

export const updateMessages = (options: {
  messages: IScripts[];
  uuid: string;
  key: K;
  value: IScripts[K];
}) => {
  const {messages, uuid, key, value} = options;
  return messages.map(message => {
    if (message.uuid === uuid) {
      return {
        ...message,
        [key]: value,
      };
    }
    return message;
  });
};

export const addNewTrackToMessages = (options: {
  messages: IScripts[];
  tracks: IMediaTrack[];
  requestable_type: IMessageType;
}) => {
  const {messages, tracks, requestable_type} = options;

  const prevMessages = messages.filter(val => {
    return val.message.length !== 0 || val.name.length !== 0 || val.media_track;
  });
  const newTrackMessages: IScripts[] = [];
  for (const track of tracks) {
    if (!messages.some(val => tracks.some(t => t.id === val.media_track?.id))) {
      newTrackMessages.push({
        id: Date.now(),
        uuid: uuidv4(),
        message: track.track_name,
        name: '',
        requestable_type,
        media_track: track,
        events: [],
        seasons: [],
        isAllSeason: true,
      } as IScripts);
    }
  }
  return [...newTrackMessages, ...prevMessages];
};

export const updateTrackToMessage = (options: {
  messages: IScripts[];
  track: IMediaTrack;
}) => {
  const {messages, track} = options;
  return messages.map((o, i) => {
    if (i === 0) {
      return {...o, media_track: track};
    }
    return o;
  });
};
