import {Box, Dialog, DialogContent, Typography} from '@mui/material';
import IconTick from '../../asset/icons/icon_tick.svg';

const DialogSuccess = ({message}: {message: string}) => {
  return (
    <Dialog maxWidth='sm' open>
      <DialogContent
        className='w-430 h-255 flex flex-column justify-center'
        sx={{background: '#132921'}}>
        <Box
          className='w-61 h-61 flex flex-column justify-center rounded self-center'
          sx={{background: '#06A018'}}>
          <img
            src={IconTick}
            alt={'Asset is loading..'}
            className='w-22 h-15 self-center'
          />
        </Box>
        <Typography className='text-white text-16 self-center mt-12'>
          {message}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSuccess;
