import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {apiGet} from '../../axiosConfig';
import {Alert, AlertTitle, CircularProgress} from '@mui/material';
import {ISponsorByToken} from '../../dto/Sponsor.dto';

type Props = {
  setIsValidated: (selected: boolean) => void;
  setData: (seleted: ISponsorByToken) => void;
  setToken: (selected: string) => void;
};

const ValidateSponsorToken = ({setIsValidated, setData, setToken}: Props) => {
  const [apiState, setApiState] = useState({status: '', errorMsg: ''});
  const [searchParams] = useSearchParams();
  useEffect(() => {
    setApiState({status: 'pending', errorMsg: ''});
    const paramToken = searchParams.get('token');
    apiGet(`/v1/sponsor/${paramToken}/validate`)
      .then(response => {
        if (response.data.data.is_completed === 0) {
          setIsValidated(true);
          setData(response.data.data);
          setApiState({
            status: 'sucess',
            errorMsg: '',
          });
          setToken(String(paramToken));
        }
        setApiState({
          status: 'completed',
          errorMsg: '',
        });
      })
      .catch(error => {
        setIsValidated(false);
        setApiState({
          status: 'error',
          errorMsg: error?.response?.data.message || error?.message,
        });
      });
  }, [searchParams, setData, setIsValidated, setToken]);
  if (apiState.status === 'pending')
    return (
      <Alert icon={<CircularProgress size='1em' />} severity='info'>
        <AlertTitle>Validation token</AlertTitle>
        Please wait...
      </Alert>
    );
  if (apiState.status === 'completed') {
    return (
      <Alert severity='info'>
        <AlertTitle>Request Already Submitted!!</AlertTitle>
        {apiState.errorMsg}
      </Alert>
    );
  }
  if (apiState.status === 'error')
    return (
      <Alert severity='error'>
        <AlertTitle>Token validation failed!</AlertTitle>
        {apiState.errorMsg}
      </Alert>
    );
  return null;
};

export default ValidateSponsorToken;
