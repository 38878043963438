import {createSelector} from 'reselect';
import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {userLogout, ILoginResponse} from '../../Service/authService';
import {RootState} from '../configstore';
import {IRole} from '../../dto/Role.dto';
import {ICompanyDetails} from '../../dto/Company.dto';

const initialState = {
  id: 0,
  name: '',
  email: '',
  profile: '',
  isAdmin: false,
  isInternal: false,
  roles: [] as IRole[],
  scopes: [] as string[],
  companies: [] as ICompanyDetails[],
};
type IUserSelectedCompany = {
  scopes: string[];
  company: ICompanyDetails;
};
export const loginSuccess = createAction<ILoginResponse>('auth/loginSuccess');
export const selectedUserCompany = createAction<IUserSelectedCompany>(
  'selectedUserCompany',
);

export const logout = createAsyncThunk('logout', async (_AuthState, _) => {
  await userLogout();
  sessionStorage.removeItem('impersonated_company');
  return {
    displayName: null,
    email: null,
    authenticated: false,
    role: null,
    companyId: null,
    companies: [],
    isAdmin: false,
    loading: false,
  };
});

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loginSuccess, (state, {payload}) => {
      state.id = payload.user.id;
      state.name = payload.user.name;
      state.email = payload.user.email;
      state.profile = payload.user.profile;
      state.isAdmin = payload.user.user_type === 1; // 1 - admin
      state.isInternal =
        payload.user.user_type === 2 || payload.user.user_type === 1; // 2 - staff
      state.roles = payload.user.roles;
      state.scopes = payload.scopes;
      state.companies = payload.companies;
      if (!payload.isImpersonatedUser) {
        localStorage.setItem('access_token', payload.token);
        // @ts-ignore
        // @ts-ignore
        window.Tawk_API.onLoad = function () {
          // @ts-ignore
          window.Tawk_API.setAttributes(
            {
              id: state.id,
              name: state.name,
              email: state.email,
              hash: payload.user.tawk_hash,
            },
            () => {
              return;
            },
          );
        };
      }
    });
    builder.addCase(logout.fulfilled, () => {
      return initialState;
    });
    builder.addCase(selectedUserCompany, (state, {payload}) => {
      state.scopes = payload.scopes;
    });
  },
});

const authSelector = (state: RootState) => state.user;

export const userIdSelector = createSelector(authSelector, auth => auth.id);
export const userCompaniesSelector = createSelector(
  authSelector,
  auth => auth.companies,
);
export const profileUrlSelector = createSelector(
  authSelector,
  auth => auth.profile,
);
export const nameSelector = createSelector(authSelector, auth => auth.name);
export const emailSelector = createSelector(authSelector, auth => auth.email);
export const isAdminSelector = createSelector(
  authSelector,
  auth => auth.isAdmin,
);
export const isInternalSelector = createSelector(
  authSelector,
  auth => auth.isInternal,
);
export const rolesSelector = createSelector(authSelector, auth => auth.roles);
export const roleSlugsSelector = createSelector(rolesSelector, roles =>
  roles.map(role => role.slug),
);
export const roleTypeSelector = createSelector(rolesSelector, roles =>
  roles.map(role => role.role_type),
);
export const isAuthenticatedSelector = createSelector(
  authSelector,
  auth => auth.id > 1,
);
export const scopesSelector = createSelector(
  authSelector,
  state => state.scopes,
);
