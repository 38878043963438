import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Iconchecked from '../../../asset/icons/icon_checked.svg';
import IconUnchecked from '../../../asset/icons/icon_unchecked.svg';
import {OtherNamingCmpt} from './OtherNamingCmpt';
import {IEventGrouped} from '../../../react_query/event_types/List-EventByGroup';

export const EventSelection = ({
  sports,
  setSports,
  otherSport,
  setOtherSport,
}: {
  sports: IEventGrouped[];
  setSports: React.Dispatch<React.SetStateAction<IEventGrouped[]>>;
  otherSport: string;
  setOtherSport: (val: string) => void;
}) => {
  const firstColumnLimit = Math.floor(
    sports && sports.length ? sports.length / 3 : 0,
  );
  const secondColumnLimit = firstColumnLimit * 2;
  return (
    <Box>
      <Typography variant='h5' className='font-bold mb-4'>
        Select Sport
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Stack spacing={2}>
            {sports && sports.length > 0
              ? sports.map((sport, index) => {
                  if (index <= firstColumnLimit) {
                    return (
                      <CheckboxAccordion
                        key={sport.id}
                        sport={sport}
                        setSports={setSports}
                      />
                    );
                  }
                  return null;
                })
              : null}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={2}>
            {sports && sports.length > 0
              ? sports.map((sport, index) => {
                  if (index > firstColumnLimit && index <= secondColumnLimit) {
                    return (
                      <CheckboxAccordion
                        key={sport.id}
                        sport={sport}
                        setSports={setSports}
                      />
                    );
                  }
                  return null;
                })
              : null}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={2}>
            {sports && sports.length > 0
              ? sports.map((sport, index) => {
                  if (index > secondColumnLimit) {
                    return (
                      <CheckboxAccordion
                        key={sport.id}
                        sport={sport}
                        setSports={setSports}
                      />
                    );
                  }
                  return null;
                })
              : null}
          </Stack>
        </Grid>
      </Grid>
      <OtherNamingCmpt
        label='Any Other Sport'
        placeholder='Enter Sport Name'
        other={otherSport}
        setOther={setOtherSport}
      />
    </Box>
  );
};
const CheckboxAccordion = ({
  sport,
  setSports,
}: {
  sport: IEventGrouped;
  setSports: React.Dispatch<React.SetStateAction<IEventGrouped[]>>;
}) => {
  const handleChange = (type: string, id: number) => {
    setSports(prev => {
      const finalValue = prev.map(val => {
        if (val.id === id || val.sub_list.some(subVal => subVal.id === id)) {
          const newValue = {...val};
          if (type === 'parent') {
            if (newValue.selected) {
              newValue.selected = false;
              newValue.sub_list = newValue.sub_list.map(v => {
                const newSubVal = v;
                newSubVal.selected = false;
                return newSubVal;
              });
            } else {
              newValue.selected = true;
              newValue.sub_list = newValue.sub_list.map(v => {
                const newSubVal = {...v};
                newSubVal.selected = true;
                return newSubVal;
              });
            }
          } else {
            newValue.sub_list = newValue.sub_list.map(v => {
              const newSubVal = {...v};
              if (newSubVal.id === id) {
                newSubVal.selected = !newSubVal.selected;
              }
              return newSubVal;
            });

            if (newValue.sub_list.some(subVal => subVal.selected === true)) {
              newValue.selected = true;
            } else {
              newValue.selected = false;
            }
          }
          return newValue;
        }
        return val;
      });

      return finalValue;
    });
  };

  if (sport.sub_list.length > 0) {
    return (
      <Accordion
        className={`bg-blue-variant53 w-full radius-5 ${
          sport.selected ? 'border-green-variant18' : ''
        }`}>
        <AccordionSummary
          sx={{
            '.MuiAccordionSummary-content': {margin: '0 !important'},
            '&.MuiButtonBase-root': {
              minHeight: '40px !important',
            },
          }}
          expandIcon={<ArrowDropDownIcon />}
          aria-controls='panel-content'
          id='panel-header'>
          <FormControlLabel
            control={
              <Checkbox
                checked={sport.selected}
                onChange={() => handleChange('parent', sport.id)}
                icon={<img src={IconUnchecked} alt='' />}
                checkedIcon={<img src={Iconchecked} alt='' />}
              />
            }
            label={sport.name}
          />
        </AccordionSummary>
        <AccordionDetails className='pl-8'>
          {sport.sub_list.map(val => (
            <FormControlLabel
              key={val.id}
              className='m-0'
              control={
                <Checkbox
                  checked={val.selected}
                  onChange={() => handleChange('child', val.id)}
                  icon={<img src={IconUnchecked} alt='' />}
                  checkedIcon={<img src={Iconchecked} alt='' />}
                />
              }
              label={val.name}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    );
  } else {
    return (
      <AccordionSummary
        className={`bg-blue-variant53 w-full radius-5 ${
          sport.selected ? 'border-green-variant18' : ''
        }`}
        sx={{
          width: '100%',
          '.MuiAccordionSummary-content': {margin: '0 !important'},
          '&.MuiButtonBase-root': {
            minHeight: '40px !important',
          },
        }}
        expandIcon={<ArrowDropDownIcon sx={{opacity: '0.3'}} />}
        aria-controls='panel-content'
        id='panel-header'>
        <FormControlLabel
          control={
            <Checkbox
              checked={sport.selected}
              onChange={() => handleChange('parent', sport.id)}
              icon={<img src={IconUnchecked} alt='' />}
              checkedIcon={<img src={Iconchecked} alt='' />}
            />
          }
          label={sport.name}
        />
      </AccordionSummary>
    );
  }
};
