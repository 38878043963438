import {makeStyles} from '@mui/styles';

export const calendarStyles = makeStyles(theme => ({
  '@global': {
    '.calendar-header .MuiPickersCalendarHeader-root': {
      borderBottom: '1px dashed #70707088',
      paddingBottom: '60px',
      display: 'block',
      margin: '0',
    },
    '.calendar-header .MuiPickersArrowSwitcher-root': {
      width: '400px',
      justifyContent: ' space-around',
      margin: 'auto',
      position: 'relative',
      top: '-37px',
      textAlign: 'center',
    },
    '.calendar-header .MuiPickersCalendarHeader-labelContainer': {
      justifyContent: 'center',
      width: '400px',
      margin: 'auto',
      flexDirection: 'row',
      textAlign: 'center',
      paddingTop: '10px',
    },
    '.calendar-header .MuiPickersCalendarHeader-labelContainer .MuiPickersFadeTransitionGroup-root':
      {
        fontSize: '24px',
        fontWeight: 'bold',
      },
    '.calendar-header .MuiButtonBase-root.MuiIconButton-edgeEnd:before': {
      transform: 'rotate(180deg)',
    },
    '.calendar-header .MuiButtonBase-root.MuiIconButton-edgeStart:before': {
      transform: 'rotate(120deg) !important',
    },
    '.calendar-header .MuiButtonBase-root.MuiIconButton-edgeStart:before, .calendar-header .MuiButtonBase-root.MuiIconButton-edgeEnd:before':
      {
        content: '""',
        position: 'absolute',
        width: '0',
        height: '0',
        borderTop: '12px solid transparent',
        borderLeft: '22px solid #fff',
        borderBottom: '13px solid transparent',
        left: '5px',
      },
    '.calendar-header .MuiButtonBase-root.MuiIconButton-edgeStart svg, .calendar-header .MuiButtonBase-root.MuiIconButton-edgeEnd svg, button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.MuiPickersCalendarHeader-switchViewButton':
      {
        display: 'none !important',
      },
  },
}));
