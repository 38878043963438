import {ChangeEvent, useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import SchoolsDropdownGetSchools from '../../../../components/models/company-schools/SchoolsDropdownGetSchools';
import EventsDropdownGetEvents from '../../../../components/models/event/EventsDropdownGetEvents';
import StationsDropDown from '../../../../components/models/stations/StationDropDown';
import {ICompanySchool} from '../../../../dto/CompanySchool.dto';
import {IEventType} from '../../../../dto/EventType.dto';
import {IStation} from '../../../../dto/Station.dto';
import {cleanString} from '../../../../Service/CommonService';
import {
  companyIdSelector,
  isGTSelector,
  isNRorSZSelector,
} from '../../../../store/Slices/companySlice';
import CommonCusDropDown from '../../../messages/on-demand-msg/CommonCusDropDown';
import {useRqListSeasons} from '../../../../react_query/seasons/ListSeasons';
import {ISeasonMessage, seasonsDetails} from '../../../../dto/EditMessage.dto';
import BaseCreateSeasonDialog from './BaseCreateSeasonDialog';
type Props = {
  othersEventName: string;
  setOthersEventName: React.Dispatch<React.SetStateAction<string>>;
  events: IEventType[];
  setEvents: React.Dispatch<React.SetStateAction<IEventType[]>>;
  schools: ICompanySchool[];
  setSchools: React.Dispatch<React.SetStateAction<ICompanySchool[]>>;
  stations: IStation[];
  setStations: React.Dispatch<React.SetStateAction<IStation[]>>;
  seasons: ISeasonMessage;
  setSeasons: React.Dispatch<React.SetStateAction<ISeasonMessage>>;
};
const BaseMessageInfo = ({
  othersEventName,
  setOthersEventName,
  events,
  setEvents,
  schools,
  setSchools,
  stations,
  setStations,
  seasons,
  setSeasons,
}: Props) => {
  const isGT = useSelector(isGTSelector);
  const companyId = useSelector(companyIdSelector);
  const isNRSZ = useSelector(isNRorSZSelector);
  const [open, setOpen] = useState(false);
  const {data} = useRqListSeasons(
    Number(companyId),
    Boolean(companyId) && isNRSZ,
  );
  const handleOtherEvents = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {value} = e.target;
      setEvents(prev =>
        prev.map(o => {
          console.log('object', o);
          if (o.id === 0) {
            return {
              ...o, // spread the original object 'o' to keep its other properties
              event_name: value,
              event_short_name: cleanString(value).toUpperCase(),
            };
          }
          return o; // return the original object if 'o.id' is not 0
        }),
      );

      setOthersEventName(value);
    },
    [setOthersEventName, setEvents],
  );
  const handleYearAround = ({
    target: {checked: isChecked},
  }: ChangeEvent<HTMLInputElement>) => {
    setSeasons({
      seasons: [],
      isAllSeason: isChecked,
    });
  };
  const handleCancelSeason = (SeasonId: number) => {
    setSeasons(prev => ({
      ...prev,
      seasons: prev.seasons.filter(season => season.id !== SeasonId),
    }));
  };
  const deselectAllSeasons = () => {
    setSeasons(prev => ({
      ...prev,
      seasons: [],
    }));
  };
  const handleSeason = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Card>
      <CardHeader
        title='Select Options'
        action={
          isNRSZ && (
            <Button variant='outlined' className='mr-3' onClick={handleSeason}>
              Create New Season
            </Button>
          )
        }
      />
      <CardContent>
        {isGT && (
          <SchoolsDropdownGetSchools
            selected={schools}
            setSelected={setSchools}
          />
        )}
        <StationsDropDown
          stations={stations}
          setStations={setStations}
          fetchEnable={true}
        />
        {isGT ? (
          <EventsDropdownGetEvents
            selected={events}
            setSelected={setEvents}
            othersEventName={othersEventName}
            handleOtherEvents={handleOtherEvents}
          />
        ) : (
          <>
            <Stack direction='row' className='my-3'>
              <FormControlLabel
                className='w-200 ml-0 flex-nowrap'
                control={
                  <Checkbox
                    disableRipple
                    checked={seasons?.isAllSeason}
                    onChange={handleYearAround}
                  />
                }
                label='Year Around'
              />
              <Autocomplete
                multiple
                disableCloseOnSelect
                id='season'
                sx={{
                  width: 300,
                  '.MuiInputBase-root .MuiButtonBase-root': {
                    display: 'none',
                  },
                }}
                className='cusAutoSelect w-full max-300 overflow-hidden custDpIcon'
                onChange={(_, newSeasons) => {
                  setSeasons(prev => ({
                    ...prev, // Spread the previous state
                    seasons: newSeasons as seasonsDetails[], // Update only the `seasons` array
                  }));
                }}
                disabled={seasons.isAllSeason}
                value={seasons.seasons}
                options={data ?? []}
                getOptionLabel={option => option.season_name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={params => (
                  <TextField {...params} placeholder='Select seasons' />
                )}
                renderOption={(props, option, {selected}) => (
                  <li
                    {...props}
                    className='autoselect-option options-select flex items-center pl-6 cursor-pointer'>
                    <Typography component='h6' className='flex items-center'>
                      <Checkbox
                        checked={selected}
                        sx={{'& .MuiSvgIcon-root': {fontSize: 16}}}
                      />
                      {option.season_name}
                    </Typography>
                  </li>
                )}
              />
            </Stack>
            {seasons.seasons.length > 0 && (
              <CommonCusDropDown
                showData={seasons.seasons}
                cancelData={handleCancelSeason}
                deselectAll={deselectAllSeasons}
              />
            )}
          </>
        )}
        <BaseCreateSeasonDialog
          open={open}
          setOpen={setOpen}
          onClose={handleClose}
        />
      </CardContent>
    </Card>
  );
};

export default BaseMessageInfo;
