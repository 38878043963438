import {makeStyles} from '@mui/styles';

export const commonStyles = makeStyles(theme => ({
  '@global': {
    '*,h1,h2,h3,h4,h5,h6,a,button,input,span ul,li,div': {
      fontFamily: 'poppins !important',
    },
    '.hide-on-mobile': {display: 'inherit'},
    '@media screen and (max-width: 767px)': {
      '.hide-on-mobile': {display: 'none !important'},
      '.show-on-mobile': {display: 'inherit !important'},
      '.invisible-on-pc': {visibility: 'visible'},
    },
    '@media screen and (min-width: 1200px)': {
      '.hide-on-pc': {display: 'none !important'},
    },
    '@media screen and (max-width: 1200px)': {
      '.show-on-pc': {display: 'none !important'},
    },
    '.VictoryContainer svg': {height: '100% !important'},
    '.box-shadow-none': {boxShadow: 'none !important'},
    '.show-on-mobile': {display: 'none !important'},
    '.invisible-on-pc': {visibility: 'hidden'},
    '.cursor-pointer': {
      cursor: 'pointer',
    },
    '.cursor-move': {
      cursor: 'move',
    },
    '.cursor-auto': {cursor: 'auto !important'},
    '.avatar': {
      height: '45px !important',
      width: '45px !important',
    },

    '.opacity-70': {
      opacity: 0.7,
    },
    '.opacity-50': {
      opacity: 0.5,
    },
    '.overflow-auto': {overflow: 'auto'},
    'a.text-underline': {
      textDecoration: 'underline !important',
    },
    '.textDecoration-none': {textDecoration: 'none !important'},
    '.MuiPaper-root.MuiAutocomplete-paper': {
      backgroundColor: `var(--blue-variant17)`,
      borderRadius: '0',
      backgroundBlendMode: 'multiply',
    },

    '.tab-menu-icon': {
      height: '29px',
      width: '38px',
      objectFit: 'contain',
      objectPosition: 'left',
      marginRight: '18px',
      opacity: '0.5',
    },

    '.right-down-arrow': {
      bottom: '30px',
      padding: '11px',
      position: 'absolute',
      top: '42px',
      right: '0',
    },
    '.MuiIconButton-colorInherit:hover': {background: 'transparent !important'},
    '*::-webkit-scrollbar': {width: '0 !important'},
    '.shadow-gray': {
      boxShadow: `0 3px 6px var(--gray-opacity-variant1) !important`,
    },
    '.partner-list li': {
      marginBottom: '10px',
      borderRadius: '10px',
      backgroundColor: `var(--blue-variant8)`,
      height: '59px',
      '&:hover': {
        backgroundColor: `var(--green-variant8)`,
      },
    },
    '.partner-information': {
      position: 'absolute',
      top: '0',
      right: '0',
      background: `var(--blue-variant4)`,
    },
    '.partner-list .MuiTypography-body2': {
      fontSize: '16px',
      color: `var(--text-white)`,
    },
    '.tab-primary .MuiTabs-flexContainer button': {
      border: `1px solid var(--gray-variant3)`,
      borderRadius: '10px 10px 0 0',
      background: `var(--blue-variant9)`,
      color: `var(--text-white) !important`,
      height: '59px',
      borderBottom: 'none !important',
      '@media screen and (max-width: 1500px)': {
        minWidth: 'auto !important',
        flex: '1',
      },
    },
    '.tab-primary .MuiTabs-flexContainer button.active': {
      background: `var(--blue-variant12)`,
      border: `1px solid var(--blue-variant1)`,
      color: `var(--blue-variant1) !important`,
      height: '60px',
      paddingBottom: '14px',
    },
    '.tab-primary .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none !important',
    },
    '.tab-primary-content': {
      border: `1px solid var(--blue-variant1)`,
      background: `var(--blue-variant12)`,
      borderRadius: '0 10px 10px 10px',
      '@media screen and (max-width: 1500px)': {
        borderRadius: '0 0 10px 10px',
      },
    },
    '.tab-primary-content .MuiDataGrid-columnHeaders': {
      background: `var(--blue-variant13)`,
    },
    '.tab-primary-content .MuiDataGrid-cell': {lineBreak: 'anywhere'},
    '.tab-primary-content .MuiDataGrid-cell:focus,.tab-primary-content .MuiDataGrid-cell:visited':
      {
        outline: 'none !important',
      },
    '.tap-primary .MuiTabs-root': {position: 'relative', top: '1px !important'},
    '.tab-primary-content .MuiDataGrid-cell span,.tab-primary-content .MuiDataGrid-columnHeaderTitle,.tab-primary-content .MuiDataGrid-cell div, .tab-primary-content .MuiDataGrid-cell':
      {
        fontSize: '16px !important',
      },
    '.tab-primary-content .MuiDataGrid-columnHeaderTitle': {
      color: `var(--text-white-50)`,
    },
    '.tab-primary-content .MuiDataGrid-cell span, .tab-primary-content .MuiDataGrid-cell div':
      {
        color: `var(--text-white)`,
      },
    '.tab-primary-content .MuiDataGrid-withBorderColor': {
      borderBottomColor: `var(--gray-opacity-variant2) !important`,
      borderTop: 'none !important',
      paddingTop: '5px !important',
      paddingBottom: '5px !important',
    },
    '.icon-btn': {
      background: `var(--blue-variant4)`,
      border: `1px solid var(--gray-variant4) !important`,
      minWidth: 'auto !important',
    },
    '.partner-info-popup .MuiDialog-paper': {
      position: 'absolute',
      right: '0',
      height: 'calc(100% - 93px)',
      top: '93px',
      overflow: 'auto',
      width: 'calc((100% - 41.666667%) - 184px)',
      maxWidth: '100%',
      padding: '0',
      margin: '0',
      borderRadius: '0',
      boxShadow: 'none',
      '@media screen and (max-width: 1200px)': {
        width: '100% !important',
      },
    },
    '.partner-info-popup .MuiBackdrop-root': {
      background: 'transparent',
    },
    '.select-menuitem': {
      background: `var(--blue-variant17) !important`,
      border: `1px solid var(--gray-variant9)`,
      maxHeight: '222px !important',
      maxWidth: '322px !important',
      width: '100% !important',
      borderRadius: '0',
      marginTop: '10px',
      right: '10px',
      transformOrigin: 'right',
      color: `var(--text-white)`,
      fontSize: '16px',
      overflow: 'auto !important',
    },
    '.select-menuitem ul li,.select-menuitem ul a, .autoselect-option': {
      borderBottom: `1px solid var(--gray-opacity-variant2)`,
      fontSize: '16px',
      width: '100%',
      minHeight: '55px',
      maxHeight: '100px',
    },
    '.select-menuitem ul a': {
      background: `var(--blue-variant17) !important`,
    },
    '.select-menuitem ul li:first-child': {
      color: `var(--gray-variant1)`,
    },
    '.select-menuitem .Mui-disabled': {
      paddingTop: '20px !important',
      paddingBottom: '16px !important',
      color: `var(--gray-variant1) !important`,
      fontSize: '16px !important',
      opacity: '1 !important',
      background: `var(--blue-variant17) !important`,
      padding: 0,
      width: '100%',
      position: 'sticky',
      top: '0',
      zIndex: '99',
      paddingLeft: '16px',
      border: 'none',
    },
    '.select-menuitem::-webkit-scrollbar, .MuiAutocomplete-listbox::-webkit-scrollbar, .scrollbar::-webkit-scrollbar ':
      {
        width: '4px !important',
        height: '4px !important',
      },
    '.select-menuitem::-webkit-scrollbar-track, .MuiAutocomplete-listbox::-webkit-scrollbar-track, .scrollbar::-webkit-scrollbar-track':
      {
        boxShadow: `inset 0 0 5px var(--gray-variant10)`,
        borderRadius: '4px',
      },
    '.select-menuitem::-webkit-scrollbar-thumb, .MuiAutocomplete-listbox::-webkit-scrollbar-thumb,.scrollbar::-webkit-scrollbar-thumb':
      {
        background: `var(--blue-variant20)`,
        borderRadius: '4px',
      },
    '.select-menuitem::-webkit-scrollbar-thumb:hover, .MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover, .scrollbar::-webkit-scrollbar-thumb:hover':
      {
        background: `var(--gray-variant10)`,
      },

    '.select-menuitem-secondary': {
      background: `var(--blue-variant4)`,
      border: `1px solid var(--gray-variant9)`,
      maxHeight: '248px !important',
      maxWidth: '200px !important',
      width: '100% !important',
      borderRadius: '10px',
      marginTop: '5px',
      color: `var(--text-white)`,
      fontSize: '16px',
      overflow: 'auto !important',
    },
    '.select-menuitem-keyboard': {
      textAlign: 'center',
      width: '380px',
      padding: '10px !important',
      marginTop: '8px',
      background: 'var(--blue-variant4) !important',
    },
    '.select-menuitem-keyboard li': {
      display: 'inline-block',
      borderRadius: '5px',
      margin: '3px',
      width: '10px',
      height: '10px',
      textAlign: 'center',
      minWidth: '30px',
      background: '#202f58',
      padding: '6px !important',
      border: '1px solid #475781',
    },
    '.select-menuitem-keyboard li:last-child': {
      display: 'block !important',
      width: '138px',
      margin: '2px auto',
      padding: '4px !important',
    },
    '.select-menuitem::-webkit-scrollbar': {width: '4px !important'},

    '.MuiSelect-select': {
      paddingRight: '24px !important',
    },
    '.unauth-sec .MuiInputBase-root': {
      marginBottom: '33px',
      minHeight: '55px',
      borderRadius: '5px',
    },
    '.unauth-sec .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '60px',
      margin: '0',
    },
    '.MuiInputAdornment-root.MuiInputAdornment-positionStart': {
      marginRight: '30px',
    },
    '.Mui-focused.MuiInputBase-formControl .MuiOutlinedInput-notchedOutline': {
      borderWidth: ' 1px !important',
    },
    '.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline, .MuiInputBase-formControl .MuiOutlinedInput-notchedOutline:hover, .MuiFormLabel-filled + .MuiInputBase-root fieldset':
      {
        borderColor: `var(--blue-variant14) !important`,
      },
    'label[data-shrink=false]+.MuiInputBase-formControl:hover, .Mui-focused.MuiInputBase-formControl.MuiInputBase-sizeSmall, .MuiFormLabel-filled + .MuiSelect-select fieldset.MuiOutlinedInput-notchedOutline,.MuiFormLabel-filled + .MuiInputBase-root':
      {
        backgroundColor: `var(--blue-variant19) !important`,
      },
    '.task-toggle-close': {
      width: '56px',
      height: ' 58px',
      margin: '0 !important',
      backgroundColor: `var(--blue-variant15) !important`,
      borderRadius: '0 !important',
    },
    '.task-toggle-menu': {
      width: '56px',
      height: ' 58px',
      margin: '0 !important',
    },

    '.selected-filter-item': {
      border: `1px solid var(--blue-variant14)`,
      padding: '10px',
      paddingRight: '0',
      overflow: 'hidden',
      width: '100%',
      background: `var(--blue-variant19)`,
      whiteSpace: 'nowrap',
      borderRadius: '100px',
      textOverflow: 'ellipsis',
      margin: '0',
      maxWidth: 'fit-content',
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      marginTop: '5px',
      color: `var(--gray-variant1)`,
      '& span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '16px',
      },
    },

    '.selected-filter-item:first-child': {
      marginRight: '10px',
    },

    '.mytask-filter': {
      maxHeight: '158px',
      overflow: 'auto',
      paddingRight: '14px',
    },
    '.mytask-filter li:hover': {
      background: `var(--blue-variant16)`,
    },
    '.MuiPaper-root.MuiAccordion-root::before': {
      background: 'transparent !important',
    },
    '.MuiPaper-root.MuiMenu-paper': {
      background: `var(--blue-variant17)`,
    },
    '.usermenu-open .right-down-arrow': {
      background: `var(--blue-variant17)`,
      transform: 'rotate(180deg)',
    },
    'min-height-60': {minHeight: 'auto !important', height: '60px !important'},
    '.MuiTabs-scroller .Mui-selected': {
      fontWeight: 'bold !important',
      color: `var(--text-white) !important`,
    },
    '.MuiTabs-scroller .Mui-selected img': {opacity: '1 !important'},
    '.badge-input .MuiBadge-badge': {
      fontSize: '16px !important',
      position: 'absolute',
      right: '-12px',
    },
    '.MuiBadge-badge': {
      width: '29px !important',
      height: '29px !important',
      borderRadius: '100% !important',
    },
    '.leftbar .MuiTabs-flexContainer button': {
      minHeight: 'auto !important',
      maxWidth: '100% !important',
      height: '60px',
      textAlign: 'left',
      borderBottom: `1px solid var(--gray-opacity-variant2) !important`,
      color: `var(--text-white-50)`,
    },
    '.badge-count': {top: '8px'},
    '.badge-count span.MuiBadge-badge': {
      background: `var(--bg-danger)`,
      fontSize: '14px',
      left: '-3px',
      top: '-2px',
    },
    '.song-badge span.MuiBadge-badge': {
      left: '-30px !important',
      top: '6px !important',
      background: `var(--blue-variant1) !important`,
      width: '23px !important',
      height: '23px !important',
    },
    '.custom-data-grid .MuiDataGrid-cell.MuiDataGrid-cell--withRenderer': {
      outline: 'none !important',
      alignItems: 'center !important',
    },
    '.cmc_new_grid': {
      border: 'none',
      borderStyle: 'none !important',
      fontSize: '16px !important',
    },
    '.cmc_new_grid .MuiDataGrid-columnHeaders': {
      color: '#484a59',
    },
    'input.MuiOutlinedInput-input:-webkit-autofill': {
      backgroundClip: 'text',
      boxShadow: 'none',
    },
    'div:where(.swal2-container).swal2-backdrop-show, div:where(.swal2-container).swal2-noanimation':
      {
        background: '#000000E6 !important',
      },
    '.swal2-show': {
      background: `var(--blue-variant17)`,
    },
    '.swal2-container .swal2-cancel,.swal2-container .swal2-confirm': {
      minWidth: '150px',
    },
    '.swal2-container .swal2-cancel': {
      background: '#0F1526 !important',
      border: `1px solid var(--gray-variant15) !important`,
      color: `var(--gray-variant15) !important`,
    },
    '.swal2-container .swal2-confirm': {
      background: `var(--blue-variant1) !important`,
      border: `1px solid var(--blue-variant1) !important`,
    },
    '.partner-leftnav a': {
      height: 'auto',
      minHeight: '58px',
      maxHeight: '128px',
      padding: '6px 32px',
      fontSize: '16px',
      borderBottom: '1px solid rgb(112 112 112 / 20%)',
      transition: '0.3s linear',
    },
    '.partner-leftnav a:last-child': {
      border: 'none',
    },
    '.partner-leftnav a img': {
      width: '27px',
      height: '27px',
      marginRight: '25px',
    },
    '.partner-carousel .MuiIconButton-sizeSmall svg': {
      width: '8px !important',
      height: '8px !important',
    },
    '.hex-button': {
      width: '105px !important',
      height: '95px !important',
      position: 'relative',
      clipPath: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
    },
    '.list-bg-color-green': {
      background: `var(--green-variant10)`,
    },
    '.list-bg-color-green-selected,.list-bg-color-green:hover': {
      background: `var(--green-variant11)`,
    },
    '.list-bg-color-grey': {
      background: `var(--gray-variant11)`,
    },
    '.list-bg-color-grey-selected,.list-bg-color-grey:hover': {
      background: `var(--gray-variant12)`,
    },
    '.list-bg-color-red': {
      background: `var(--red-variant1)`,
    },
    '.list-bg-color-red-selected,.list-bg-color-red:hover': {
      background: `var(--red-variant2)`,
    },

    '.list-bg-color-orange': {
      background: `var(--orange-variant2)`,
    },
    '.list-bg-color-orange-selected,.list-bg-color-orange:hover': {
      background: `var(--orange-variant3)`,
    },
    '.MuiAutocomplete-root .MuiInputBase-root': {
      borderRadius: '100px !important',
      minHeight: '40px !important',
      padding: '0 10px !important',
    },
    '.partner-music-sec': {
      height: 'calc(100vh - 200px)',
      background: `radial-gradient(circle at top center, var(--blue_variant31) 30%, var(--blue_variant28) 78%)`,
    },
    '.partner-message-sec': {
      height: 'calc(100vh - 200px)',
      background: `radial-gradient(circle at top center, #2d447f2e 30%, var(--blue_variant28) 78%)`,
    },

    'a.btn-outlined-active button': {
      background: `var(--blue-variant1)`,
      color: `var(--text-white)`,
    },
    '.list-items-sec > li': {
      borderBottom: '1px dashed #70707088',
      color: `var(--text-white-50)`,
    },
    '.list-items-sec > li .MuiButtonBase-root.MuiListItemButton-root:hover': {
      color: '#fff',
    },
    '.list-items-sec > li .MuiButtonBase-root': {
      padding: '5px 36px',
    },

    '.list-items-sec > li .MuiButtonBase-root.Mui-selected': {
      color: `var(--blue_variant33) !important`,
      background: `var(--blue_variant32) !important`,
    },
    '.list-items-sec .active-list, .list-item-sec .Mui-selected': {
      background: `var(--blue_variant32)`,
      color: '#2DAEFF',
    },
    '.sport-list-heading span': {
      fontWeight: 'bolder',
    },
    '.text-red-1': {
      color: `var(--red-variant3)`,
    },
    '.progress-value ': {
      '& .MuiInputBase-input': {
        textAlign: 'center !important',
        height: '13px !important',
        padding: '6px 0px',
        maxWidth: '80px',
      },
      '& .MuiInputBase-root': {
        minHeight: 'auto !important',
      },
    },
    '.GridTableList': {
      background: `var(--blue_variant29)`,
      padding: '0px 66px',
    },
    '.gridNoDataContainer': {
      padding: '20px',
      height: '100%',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    '.gridNoDataContainer .noDataImg': {
      width: 100,
      height: 100,
      filter: 'brightness(0.4)',
    },
    '.gridNoDataContainer .NoDataBtn': {
      backgroundColor: `var(--blue-variant7)`,
      borderColor: '#555d71',
      boxShadow: '0px 0px 0px 1px #313341',
    },
    '.gridNoDataContainer .NoDataBtn:hover': {
      backgroundColor: `var(--blue-variant23)`,
      borderColor: '#838792',
    },
    '.customNewAddBtn': {
      borderColor: '#838792 !important',
      backgroundColor: `var(--blue-variant4) !important`,
    },
    '.customNewAddBtn:hover': {
      background: `var(--blue-variant23) !important`,
    },
    '.navSearchInput': {
      position: 'relative',
    },
    '.navSearchInput button': {
      position: 'absolute',
      right: '35px',
      top: '5px',
    },
    '.navSearchInput button svg': {
      fontSize: '38px',
      color: `var(--blue-variant1)`,
    },
    '.MuiDataGrid-root .MuiDataGrid-cell.MuiDataGrid-withBorderColor': {
      border: 'none !important',
      borderBottom: '1px dashed #70707088 !important',
    },
    '.MuiDataGrid-root, .MuiDataGrid-root .MuiDataGrid-withBorderColor': {
      border: 'none !important',
    },
    '.MuiDataGrid-columnHeaderTitle': {
      color: '#ffffff88',
      textTransform: 'capitalize',
    },
    '.MuiDataGrid-root .MuiDataGrid-cell, .MuiDataGrid-root .MuiDataGrid-row': {
      minHeight: '50px !important',
    },
    '.count-box': {
      padding: '4px',
    },
    '.count-box .MuiOutlinedInput-notchedOutline, .count-box .MuiOutlinedInput-root':
      {
        border: 'none !important',
        minHeight: '30px !important',
        backgroundColor: '#1b2338 !important',
      },
    '.count-box .MuiInputBase-input': {
      textAlign: 'center',
      padding: '0',
      background: 'transparent !important',
    },
    '.count-box .MuiFormControl-root': {
      margin: '0',
    },
    '.time-limit-select .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgb(126, 126, 126)',
      borderRadius: '5px',
    },
    '.time-limit-select .MuiSelect-select': {
      backgroundColor: 'rgb(27, 35, 56)',
    },
    '.custom-library-listing .Mui-selected': {
      background: `var(--blue_variant29) !important`,
      color: `var(--blue_variant33) `,
    },
    '.custom-library-listing li': {
      borderBottom: '1px solid #ffffff1f',
    },
    '.hotkeys-list .hotkeys:last-child button': {
      minWidth: '329px !important',
      width: '329px !important',
    },

    '.stepper-sec .Mui-select': {
      background: 'transparent',
    },
    '.MuiPickersPopper-paper': {
      background: `var(--blue-variant17) !important`,
      borderRadius: '10px !important',
      marginTop: '10px',
    },
    '.btn-delete,.btn-delete:hover': {
      background: `var(--red_variant1) !important`,
      border: `1px solid var(--red_variant4) !important`,
      color: `var(--text-white) !important`,
      opacity: '1',
    },
    '.Mui-disabled.btn-delete': {
      opacity: '0.5',
    },

    '.sponsor-card span': {
      color: 'var(--text-white-50)',
    },
    '.sponsor-card:hover img': {
      background: '#0087C4',
      borderColor: '#707070 !important',
    },
    '.sponsor-data .MuiPaper-root': {
      background: '#1E3262',
    },
    '.sponsor-info-sec': {
      width: '100%',
      maxWidth: '1200px',
      margin: '50px auto',
      border: '1px solid #80808033',
      padding: '40px',
      borderRadius: '10px',
      background: 'var(--blue_variant27)',
    },
    '.text-btn .MuiButton-endIcon': {
      margin: '0',

      '& svg': {
        fontSize: '14px',
        position: 'relative',
        top: '1px',
      },
    },

    '.msg_type_box': {
      color: '#03B0FF !important',
    },
    '.msg_type_box:hover': {
      background: '#03B0FF',
      border: '1px solid #03B0FF',
      color: `var(--text-white) !important`,
    },

    '.cmc_new_grid .MuiDataGrid-footerContainer': {
      bottom: '30px',
      position: 'relative',
      background: 'var(--blue_variant29)',
    },
    '.checklist .Mui-checked': {
      width: '0 !important',
      marginRight: '32px !important',
      marginLeft: '0 !important',
    },
    '.indReviewTable': {
      backgroundColor: 'transparent !important',
      '& thead th': {
        color: '#ffffff50',
        borderBottom: 'none',
        padding: '10px 5px 10px 0px',
        fontSize: '16px',
      },
      '& tbody td': {
        color: 'white',
        borderBottom: '1px dashed #70707088 !important',
        padding: '14px 5px 14px 0px',
        fontSize: '16px',
      },
    },
    '.focusedHeight .MuiFormControl-root': {
      height: '200px',
      borderRadius: '30px !important',
    },
    '.shadow-none': {
      boxShadow: 'none !important',
    },
    '.taglistview span': {
      background: '#272d3f',
      padding: '4px 8px',
      borderRadius: '100px',
      margin: ' 1px',
      fontSize: '12px',
    },
    '.radius-50': {
      borderRadius: '50px !important',
    },
    '.restore-btn': {
      background: `var(--red_variant1) !important`,
      border: `1px solid var(--red_variant2)  !important`,
      color: 'white !important',

      '&:hover': {
        background: `var(--red_variant2) !important`,
      },
    },
    '.deactivate-btn': {
      background: 'green',
      border: `1px solid green`,
    },
    '.popupDatePicker': {
      color: 'white !important',
    },
    '.popupDatePicker .MuiInputLabel-root': {
      color: 'white',
    },
    '.popupDatePicker .MuiOutlinedInput-root fieldset': {
      borderColor: `var(--gray-variant8) !important`,
    },
    '.popupDatePicker .MuiOutlinedInput-input': {
      color: 'white',
    },
    '.popupDatePicker .MuiPickersCalendarHeader-dayLabel': {
      color: 'white !important',
    },
    '.popupDatePicker .MuiPickersDay-day': {
      color: 'white !important',
    },
    '.popupDatePicker .Mui-selected': {
      color: 'white !important',
    },
    '.popupDatePicker .MuiPickersCalendarHeader-switchHeader, .popupDatePicker .MuiPickersCalendarHeader-daysHeader':
      {
        color: 'white !important',
      },
    '..Mui-selected': {
      backgroundColor: 'rgb(144 202 249 / 6%) !important',
    },
    '.MuiAutocomplete-inputRoot': {background: '#1B2338'},
    '.common-date-picker fieldset': {
      background: '#1B2338',
    },
    '.common-date-picker img, .common-date-picker svg': {
      zIndex: 1,
    },
    input: {
      zIndex: 2,
    },
    '.common-date-picker fieldset:hover': {
      background: `var(--blue-variant8) !important`,
    },

    '.MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight': {
      display: 'none !important',
    },
    '.MuiDataGrid-cell': {
      width: '-webkit-fill-available !important',
    },
    '.MuiDataGrid-row': {
      width: '-webkit-fill-available !important',
    },
    '.no-border li': {
      border: 'none',
    },
    '.bottomBorder': {
      borderBottom: `2px dashed var(--gray-variant2)`,
    },
    '.bottomBorderSolid': {
      borderBottom: `2px solid var(--gray-variant4)`,
    },
    '.onboarding textarea': {
      width: '500px !important',
      height: '100% !important',
    },
    '.onboarding .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-multiline':
      {
        height: '150px !important',
        width: '100%',
      },
    '.MuiDataGrid-overlayWrapper .MuiDataGrid-overlay': {
      background: 'var(--blue-variant19)',
    },
    '.MuiDataGrid-columnHeader:focus': {
      outline: 'none !important',
    },
    '.custDpIcon': {
      position: 'relative',
    },
    '.custDpIcon:before': {
      content: "''",
      position: 'absolute',
      right: '12px',
      top: '24px',
      zIndex: '1',
      borderStyle: 'solid',
      borderWidth: '8px 6px 0 6px',
      borderColor: 'white transparent transparent transparent',
    },
    '.custDpIcon.Mui-expanded:before': {
      transform: 'rotate(180deg)',
    },
    '.data-ellipsis th,.data-ellipsis td': {paddingRight: '15px'},
    '.data-ellipsis th,.data-ellipsis td p, .list-items-sec > li .MuiButtonBase-root .MuiTypography-root':
      {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0',
      },
    '.progressbar': {
      position: 'absolute',
      top: '20px',
      height: '5px',
      width: 'calc(100% - 40px)',
      left: '20px',
    },
    '.MuiAutocomplete-root.radius-0 .MuiInputBase-root': {
      borderRadius: '5px !important',
    },
    '.bg-blue-variant-47-rt-gradiant': {
      background: `linear-gradient(to right top, var(--black), var(--blue_variant51) 50%)`,
    },
    '.select-menuitem ul li,.select-menuitem ul a, .autoselect-option.options-select':
      {
        minHeight: '45px !important',
        maxHeight: '100px',
      },
    '.modal-deactive-btn button': {
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      width: '100%',
      top: '5px',
      left: '50%',
      transform: 'translate(-50%, -5px)',
      maxWidth: '120px',
      background: '#03061c',
    },
    '.base-view .MuiDialogContent-root': {
      height: '400px !important',
    },
    '.base-view .MuiPaper-root': {
      boxShadow: 'none !important',
    },
    '.calendar-picker .MuiButtonBase-root': {
      padding: '0',
    },
    '.calendar-picker .MuiOutlinedInput-root': {
      paddingRight: '10px !important',
    },
    '.stepper-inner-sec': {
      height: 'calc(100% - 80px)',
    },
    '.loading-alert': {
      background: 'transparent !important',
      color: '#ffffffa1 !important',
    },
    '.loading-error': {
      background: 'transparent !important',
      color: 'rgb(244, 199, 199) !important',
    },
    '.size-auto': {
      height: 'auto !important',
      width: 'auto !important',
    },
    '.radius-input .MuiInputBase-root': {
      borderRadius: '50px !important',
    },
  },
}));
