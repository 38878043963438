import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import {AxiosError} from 'axios';
import {Refresh} from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import {useEffect} from 'react';

type Props = {
  apiState: {
    status: string; // pending|success/failed;
    error: null | AxiosError;
  };
  pendingTitle: string;
  pendingSubTitle: string;
  successTitle: string;
  successSubTitle?: string;
  errorTitle: string;
  onRetry: () => void;
  onClose?: () => void;
};

const DialogApiStatus = ({
  apiState,
  pendingTitle,
  pendingSubTitle,
  successTitle,
  successSubTitle,
  errorTitle,
  onRetry,
  onClose,
}: Props) => {
  useEffect(() => {
    const handleClose = () => {
      if (onClose) {
        onClose();
      }
    };

    let timer: ReturnType<typeof setTimeout>;
    if (apiState.status === 'success') {
      timer = setTimeout(() => {
        handleClose();
      }, 1500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [apiState.status, onClose]);

  if (apiState.status === 'pending') {
    return (
      <Dialog maxWidth='sm' fullWidth open>
        <DialogContent sx={{padding: '0 !important'}}>
          <Stack spacing={1}>
            <Alert icon={<CircularProgress size='1em' />} severity='info'>
              <AlertTitle>{pendingTitle}</AlertTitle>
              {pendingSubTitle}
            </Alert>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  } else if (apiState.status === 'success') {
    return (
      <Dialog maxWidth='sm' fullWidth open onClose={onClose}>
        <DialogContent className='w-full text-center'>
          <DoneIcon
            className='mb-3 w-60 h-60 radius-100 p-3'
            sx={{
              background: '#06A018',
            }}
          />
          <Typography>{successTitle}</Typography>
          {successSubTitle}
        </DialogContent>
      </Dialog>
    );
  } else if (apiState.status === 'error') {
    return (
      <Dialog maxWidth='sm' fullWidth open>
        <DialogContent>
          <Alert severity='error'>
            <AlertTitle>{errorTitle}</AlertTitle>
            {apiState.error?.response?.data.message || apiState.error?.message}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<Refresh />}
            color='info'
            variant='contained'
            onClick={onRetry}>
            Retry
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return <p>Unknown api state: {apiState.status}</p>;
};

export default DialogApiStatus;
