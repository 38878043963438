import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../configstore';

interface MusicStationState {
  value: number;
}

const initialState: MusicStationState = {
  value: 0
};

const musicStationSlice = createSlice({
  name: 'station',
  initialState,
  reducers: {
    setStation: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
  },
});

const musicStationSelector = (state: RootState) => state.station;

export const musicStationIdSelector = createSelector(
  musicStationSelector,
  state => state.value,
);


export const { setStation } = musicStationSlice.actions;

export const selectStation = (state: RootState) => state.station.value;

export default musicStationSlice.reducer;
