import React from 'react';
import {Outlet} from 'react-router-dom';
import {Backdrop, CircularProgress, Container} from '@mui/material';

export default function GuestRoutes() {
  return (
    <>
      <BackDropCmpt loader={false} />
      <Container>
        <React.Suspense fallback={<BackDropCmpt />}>
          <Outlet />
        </React.Suspense>
      </Container>
    </>
  );
}

const BackDropCmpt = ({loader = true}) => {
  return (
    <Backdrop
      sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
      open={loader}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};
