import {List, ListItem, ListItemText} from '@mui/material';
import {TrendingFlat} from '@mui/icons-material';
import {iTimedMsgDateRange} from '../TimedMessageRequest';

type Props = {
  type: string;
  ranges: iTimedMsgDateRange[];
};

const TimedMsgRequestReviewDates = ({type, ranges}: Props) => {
  return (
    <List disablePadding>
      {ranges.map(range => (
        <ListItem dense key={range.id} className='p-0'>
          <ListItemText
            id='dateValue'
            primaryTypographyProps={{
              sx: {display: 'flex', alignItems: 'center'},
            }}>
            {range.start_date?.format('MM/DD/YYYY')}
            {type === 'multiple' ? (
              <>
                {' '}
                <TrendingFlat /> {range.end_date?.format('MM/DD/YYYY')}
              </>
            ) : (
              ''
            )}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};
export default TimedMsgRequestReviewDates;
