import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {apiGet} from '../../axiosConfig';
import {IApiDataResponse} from '../../dto/API.dto';
export interface ISeason {
  id: number;
  season_name: string;
  start_date: string;
  end_date: string;
}

export const getSeasonUrl = (companyId: number) => {
  return `v1/company/${companyId}/seasons`;
};

const getSeasons = async (companyId: number) => {
  return await apiGet(getSeasonUrl(companyId)).then(
    (response: AxiosResponse<IApiDataResponse<ISeason[]>>) => {
      return response.data.data;
    },
  );
};
export const useRqListSeasons = (
  company_id: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getSeasonUrl(company_id)],
    queryFn: () => getSeasons(company_id),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};
