import {makeStyles} from '@mui/styles';

export const positioningStyles = makeStyles(theme => ({
  '@global': {
    '.hidden': {display: 'none'},
    '.hidden-important': {display: 'none !important'},
    '.block': {display: 'block !important'},
    '.inline-block': {display: 'inline-block !important'},
    '.flex': {display: 'flex !important'},
    '.flex-column': {display: 'flex', flexDirection: 'column !important'},
    '.flex-row': {flexDirection: 'row !important'},
    '.flex-wrap': {flexWrap: 'wrap'},
    '.flex-nowrap': {flexWrap: 'nowrap !important'},
    '.justify-start': {justifyContent: 'flex-start !important'},
    '.justify-center': {justifyContent: 'center !important'},
    '.justify-end': {justifyContent: 'flex-end'},
    '.justify-between': {justifyContent: 'space-between !important'},
    '.justify-around': {justifyContent: 'space-around'},
    '.justify-evenly': {justifyContent: 'space-evenly'},
    '.self-center': {alignSelf: 'center'},
    '.self-bottom': {alignSelf: 'flex-end'},
    '.self-start': {alignSelf: 'flex-start'},
    '.items-center': {alignItems: 'center'},
    '.items-start': {alignItems: 'flex-start'},
    '.items-end': {alignItems: 'flex-end'},
    '.items-stretch': {alignItems: 'stretch'},
    '.flex-grow': {flexGrow: '1'},
    '.overflow-auto': {overflow: 'auto !important'},
    '.overflow-hidden': {overflow: 'hidden !important'},
    '.overflow-unset': {overflow: 'unset !important'},
    '.overflow-visible': {overflow: 'visible !important'},
    '.scroll-y': {overflowX: 'hidden', overflowY: 'auto'},
    '.relative': {position: 'relative'},
    '.absolute': {position: 'absolute !important'},
    '.sticky': {position: 'sticky !important'},
    '.position-bottom': {position: 'absolute', bottom: '0'},
    '.position-top-right': {
      position: 'absolute !important',
      top: '10px',
      right: '10px',
    },
    '.text-center': {textAlign: 'center'},
    '.align-middle': {verticalAlign: 'middle'},
    '.text-right': {textAlign: 'right'},
    '.text-left': {textAlign: 'left'},
    '.x-center': {left: '50%', transform: 'translateX(-50%)'},
    '.y-center': {top: '50%', transform: 'translateY(-50%)'},
    '.no-shrink': {flexShrink: 0},
    '.float-right': {float: 'right'},
    '.gap-1': {gap: '5px'},
    '.gap-2': {gap: '10px'},
    '.gap-3': {gap: '15px'},
    '.gap-4': {gap: '20px'},
  },
}));
