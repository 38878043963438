import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IApiDataResponse} from '../../dto/API.dto';
import axiosInstance from '../../axiosConfig';

export const getUsersByTaskApiPath = (taskId: number) => {
  return `v1/task/${taskId}/task-users`;
};

const getUsersByTaskList = (task_id: number) => {
  return axiosInstance
    .get(getUsersByTaskApiPath(task_id))
    .then(
      (
        response: AxiosResponse<IApiDataResponse<{id: number; name: string}[]>>,
      ) => {
        return response.data.data;
      },
    );
};

export const useRqUsersByTask = (task_id: number, enabled: boolean = true) => {
  return useQuery({
    queryKey: [getUsersByTaskApiPath(task_id)],
    queryFn: () => getUsersByTaskList(task_id),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(task_id) && enabled,
  });
};

// Task users by task slug.

export const getUsersByTaskSlugApiPath = (slug: string, companyId: number) => {
  return `v1/task/${slug}/company/${companyId}/task-users`;
};

const getUsersByTaskSlugList = (slug: string, companyId: number) => {
  return axiosInstance
    .get(getUsersByTaskSlugApiPath(slug, companyId))
    .then(
      (
        response: AxiosResponse<IApiDataResponse<{id: number; name: string}[]>>,
      ) => {
        return response.data.data;
      },
    );
};

export const useRqUsersByTaskSlug = (
  slug: string,
  companyId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getUsersByTaskSlugApiPath(slug, companyId)],
    queryFn: () => getUsersByTaskSlugList(slug, companyId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(companyId) && enabled,
  });
};
