import {
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {useMemo} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {useSelector} from 'react-redux';
import {Info} from '@mui/icons-material';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import IconViewButton from '../../../components/Button/IconViewButton';
import {useTimedMessageOnDate} from '../../../react_query/messages/timed-messages/TimedMsgOnDate';
import MusicPreviewBtn from '../../../components/music/MusicPreviewBtn';
import {
  ITimedMessageDetailedResponse,
  ITimedSequenceType,
} from '../../../react_query/messages/timed-messages/ViewTimedMsgDetails';
import {TimedSequenceToMessageFormat} from './TimedMsgDetails/TimedMsgFunctions';

const TimedMessageListOfDate = ({
  search,
  setViewId,
  selectedIds,
  selectedDate,
}: {
  search: string;
  setViewId: (val: number) => void;
  selectedIds: number[];
  selectedDate: Dayjs | null;
}) => {
  const companyId = useSelector(companyIdSelector);

  const {data, isFetching, isError} = useTimedMessageOnDate(companyId, {
    timedMessageIds: selectedIds,
    date: selectedDate?.format('YYYY-MM-DD') ?? '',
  });

  const listMessages = useMemo(() => {
    if (data && !isFetching && !isError) {
      const newData: (ITimedMessageDetailedResponse & {time: string})[] = [];
      data.forEach(val => {
        if (!search || val.name.toLowerCase().includes(search.toLowerCase())) {
          val.day_times.forEach(dayTimes => {
            dayTimes.start_times.forEach(time => {
              newData.push({...val, time});
            });
          });
        }
      });
      return newData.sort((a, b) => {
        return a.time.localeCompare(b.time);
      });
    }
    return [];
  }, [data, isError, isFetching, search]);
  return (
    <TableContainer className='w-full h-full max-h-550 overflow-auto scrollbar'>
      <Table aria-label='timed-by-date table'>
        <TableHead className='blue-variant29'>
          <TableRow>
            <TableCell>Message Name</TableCell>
            <TableCell>Play Times</TableCell>
            <TableCell>Preview</TableCell>
            <TableCell>View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetching ? (
            <TableRow>
              <TableCell colSpan={4}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          ) : listMessages.length ? (
            <>
              {listMessages.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {dayjs(row.time, 'HH:mm:ss').format('hh:mm A')}
                  </TableCell>
                  <TableCell>
                    {row.timed_sequences ? (
                      <TimedMusicPreview
                        sequence={row.timed_sequences}
                        index={index}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    <IconViewButton onClick={() => setViewId(row.id)} />
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={4}>No messages found</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TimedMessageListOfDate;

const TimedMusicPreview = ({
  sequence,
  index,
}: {
  sequence: ITimedSequenceType;
  index: number;
}) => {
  const formattedSequence = TimedSequenceToMessageFormat(sequence);
  if (formattedSequence[0]?.track?.url && formattedSequence[0]?.track?.id) {
    return <MusicPreviewBtn id={index} url={formattedSequence[0].track.url} />;
  }

  return (
    <IconButton title='In-progress'>
      <Info />
    </IconButton>
  );
};
