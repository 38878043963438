import React, {createContext, useContext, useState} from 'react';
import {IStation} from '../../../dto/Station.dto';

interface IStationContext {
  stations: IStation[];
  setStations: React.Dispatch<React.SetStateAction<IStation[]>>;
}

const StationContext = createContext<IStationContext | undefined>(undefined);

export const StationProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const [stations, setStations] = useState<IStation[]>([]);

  return (
    <StationContext.Provider value={{stations, setStations}}>
      {children}
    </StationContext.Provider>
  );
};

export const useStationContext = (): IStationContext => {
  const context = useContext(StationContext);
  if (!context) {
    throw new Error('useStationContext must be used within a StationProvider');
  }
  return context;
};
