import {v4 as uuidv4} from 'uuid';
import {useSelector} from 'react-redux';
import {Box, Typography} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  StepperLayout,
  useStepperState,
} from '../../../components/layouts/stepper/StepperLayout';
import {
  companyIdSelector,
  isGTSelector,
  isNRorSZSelector,
} from '../../../store/Slices/companySlice';
import {apiPost} from '../../../axiosConfig';
import {IEventType} from '../../../dto/EventType.dto';
import {ICompanySchool} from '../../../dto/CompanySchool.dto';
import StationsDropDown from '../../../components/models/stations/StationDropDown';
import {IStation} from '../../../dto/Station.dto';
import DialogApiRequestStatus from '../../../components/dialogs/DialogApiRequestStatus';
import SchoolsDropdownGetSchools from '../../../components/models/company-schools/SchoolsDropdownGetSchools';
import EventsDropdownGetEvents from '../../../components/models/event/EventsDropdownGetEvents';
import {IMediaTrack} from '../../../dto/MediaTrack.dto';
import ExistingCampaignChooserBtn from '../../sponsor/ExistingCampaignChooserBtn';
import {checkMessagesValid} from '../../../Service/CommonService';
import StingerReview from './StingerReview';
import {EsmMessages, IScripts} from '../event-specific/EsmMessages';
import {
  addNewTrackToMessages,
  updateTrackToMessage,
} from '../event-specific/MessagesStateFunctionality';
import AutoCompleteGetSeasons from '../../../components/models/seasons/AutoCompleteGetSeasons';
import {ISeason} from '../../../react_query/seasons/ListSeasons';
import {useRqViewStingerListMessagesByRequestID} from '../../../react_query/messages/message-requests/StingerMessageList';
import {isInternalSelector} from '../../../store/Slices/userSlice';

const defaultApiState = {
  status: '',
  error: null,
  successMsg: '',
};

const StingerStepper = ({
  stingerId,
  handleClose,
}: {
  stingerId?: number;
  handleClose: () => void;
}) => {
  const isStaff = useSelector(isInternalSelector);
  const {activeStepIndex, onNextStep, onPreviousStep, onStepClick} =
    useStepperState(0);
  const queryClient = useQueryClient();
  const isGT = useSelector(isGTSelector);
  const isNRSZ = useSelector(isNRorSZSelector);
  const companyId = useSelector(companyIdSelector);
  const [apiState, setApiState] = useState(defaultApiState);
  const [events, setEvents] = useState<IEventType[]>([]);
  const [schools, setSchools] = useState<ICompanySchool[]>([]);
  const [stations, setStations] = useState<IStation[]>([]);
  const [othersEventName, setOthersEventName] = useState('');
  const [seasons, setSeasons] = useState<ISeason[]>([]);
  const [isAllSeason, setIsAllSeason] = useState(false);
  const [messages, setMessages] = useState<IScripts[]>([
    {
      id: Date.now(),
      uuid: uuidv4(),
      message: '',
      name: '',
      requestable_type: 'stinger-message',
    },
  ]);
  const [selectedTracks, setSelectedTracks] = useState<IMediaTrack[]>([]);
  const [allEvents, setAllEvents] = useState<IEventType[]>([]);

  const {data, isFetched} = useRqViewStingerListMessagesByRequestID(
    companyId,
    stingerId ? stingerId : 0,
    Boolean(stingerId),
  );

  const messageStatus = useCallback(() => {
    if (stingerId && data && isFetched) {
      // Some migrated data have improper data which can be editable
      return !data.message?.media_track ? 'In Progress' : data?.status;
    }
    return null;
  }, [data, isFetched, stingerId]);

  const isDuplicateOtherEvent = useMemo(() => {
    if (othersEventName.length > 0 && Array.isArray(allEvents)) {
      return allEvents
        .filter(x => x.event_name !== null)
        .some(o => {
          return o.event_name.toLowerCase() === othersEventName.toLowerCase();
        });
    }
    return false;
  }, [allEvents, othersEventName]);

  const isEventSportsAvail = useMemo(() => {
    if (events.some(obj => obj.id === 0) && !othersEventName) {
      return false;
    }
    return events.length;
  }, [events, othersEventName]);

  const isMessagesValid = useMemo(() => {
    if (
      stingerId &&
      (messageStatus() === 'Active' || messageStatus() === 'Inactive')
    ) {
      return messages[0]?.name && messages[0]?.media_track;
    }
    return checkMessagesValid(messages);
  }, [messageStatus, messages, stingerId]);

  const isStep1InValid = useMemo(() => {
    if (!stations.length) return true;
    if (isGT) {
      return !isEventSportsAvail || isDuplicateOtherEvent || !schools.length;
    } else if (isNRSZ) {
      return !isAllSeason && !seasons.length;
    }
    return false;
  }, [
    isAllSeason,
    isDuplicateOtherEvent,
    isEventSportsAvail,
    isGT,
    isNRSZ,
    schools.length,
    seasons.length,
    stations.length,
  ]);

  const steps = useMemo(() => {
    const stepList = [
      {
        label: 'Select Options',
        disabled: false,
      },
      {
        label: 'Message details',
        disabled: isStep1InValid,
      },
      {
        label: 'Review',
        disabled: !isMessagesValid,
      },
    ];
    return stepList;
  }, [isStep1InValid, isMessagesValid]);

  const handleSubmit = useCallback(() => {
    const url = stingerId
      ? `/v1/company/${companyId}/message/stinger-message/${stingerId}?_method=PUT`
      : `/v1/company/${companyId}/message/stinger-message`;
    const msgs = messages
      .filter(
        ({message, name, media_track}) =>
          (message || media_track) && name !== '',
      )
      .map(({message, name, media_track}) => ({
        message,
        name,
        media_track_id: media_track?.id ?? null,
      }));
    const stationIds = stations.map(v => v.id);
    const schoolIds = schools.map(v => v.id);
    const sportIds = events.map(v => v.id).filter(id => id !== 0);
    const payload = {
      station_ids: stationIds,
      school_ids: schoolIds,
      event_type_ids: sportIds,
      season_ids: seasons.map(o => o.id),
      messages: msgs,
      isAllSeason,
    };

    if (othersEventName) {
      Object.assign(payload, {others_event_name: othersEventName});
    }

    apiPost(url, payload)
      .then(res => {
        setApiState({
          status: 'success',
          error: null,
          successMsg: res.data.message,
        });
        queryClient.invalidateQueries({
          queryKey: [`v1/company/${companyId}/message/stinger-message`],
          refetchType: 'active',
        });
      })
      .catch(error => {
        setApiState({status: 'error', error, successMsg: ''});
      });
  }, [
    companyId,
    messages,
    stations,
    schools,
    events,
    isAllSeason,
    othersEventName,
    seasons,
    queryClient,
    stingerId,
  ]);

  const handleOtherEvents = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setOthersEventName(e.target.value);
    },
    [],
  );

  const handleSeason = useCallback(
    (_e: React.SyntheticEvent<Element, Event>, seasons: ISeason[]) => {
      setSeasons(seasons);
    },
    [],
  );

  const removeSeason = useCallback((seasonId: number) => {
    setSeasons(prev => prev.filter(o => o.id !== seasonId));
  }, []);

  const handleYearAround = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSeasons([]);
      setIsAllSeason(e.target.checked);
    },
    [],
  );

  const removeAllSeasons = useCallback(() => {
    setSeasons([]);
  }, []);

  useEffect(() => {
    if (selectedTracks.length) {
      if (stingerId) {
        setMessages(messages =>
          updateTrackToMessage({
            messages,
            track: selectedTracks[0] as IMediaTrack,
          }),
        );
      } else {
        setMessages(messages =>
          addNewTrackToMessages({
            messages,
            requestable_type: 'stinger-message',
            tracks: selectedTracks,
          }),
        );
      }
    }
  }, [selectedTracks, stingerId]);

  useEffect(() => {
    if (isFetched && data) {
      setEvents(data.events ?? []);
      setSeasons((data.seasons as ISeason[]) ?? []);
      setSchools(data.schools ?? []);
      setIsAllSeason(Boolean(data.is_all_seasons));
      setStations(data.stations ?? []);
      setMessages([
        {
          id: Date.now(),
          uuid: uuidv4(),
          message: data.message?.message ?? '',
          name: data.name,
          requestable_type: 'stinger-message',
          media_track: data.message?.media_track,
        },
      ]);
    }
  }, [data, isFetched]);

  return (
    <StepperLayout
      steps={steps}
      activeStepIndex={activeStepIndex}
      onBack={onPreviousStep}
      onNext={onNextStep}
      onCancel={handleClose}
      onSubmit={handleSubmit}
      onStepClick={onStepClick}
      isSubmitted={apiState.status !== ''}
      title={`${stingerId ? 'Edit' : 'Create'} Stinger Message`}>
      {(() => {
        const label = steps?.[activeStepIndex]?.label ?? '';
        switch (label) {
          case 'Select Options':
            return (
              <Box>
                <Typography
                  variant='h5'
                  className='text-white font-bold mb-6 mt-2'>
                  Select Options
                </Typography>
                <Box>
                  {isGT ? (
                    <SchoolsDropdownGetSchools
                      selected={schools}
                      setSelected={setSchools}
                    />
                  ) : null}
                  <StationsDropDown
                    stations={stations}
                    setStations={setStations}
                    fetchEnable={true}
                  />
                  {isGT ? (
                    <EventsDropdownGetEvents
                      selected={events}
                      setSelected={setEvents}
                      setEvents={setAllEvents}
                      othersEventName={othersEventName}
                      handleOtherEvents={handleOtherEvents}
                      isDuplicateOtherEvent={isDuplicateOtherEvent}
                    />
                  ) : (
                    <AutoCompleteGetSeasons
                      seasons={seasons}
                      isAllSeason={isAllSeason}
                      onChange={handleSeason}
                      removeSeason={removeSeason}
                      handleYearAround={handleYearAround}
                      removeAllSeasons={removeAllSeasons}
                    />
                  )}
                </Box>
              </Box>
            );
          case 'Message details':
            return (
              <>
                {isStaff &&
                (!stingerId || messageStatus() === 'In Progress') ? (
                  <Box textAlign='end'>
                    <ExistingCampaignChooserBtn
                      label='Choose Stinger Message'
                      type='stinger-message'
                      max={stingerId ? 1 : 50}
                      tracks={selectedTracks}
                      onChange={setSelectedTracks}
                      companyId={companyId}
                    />
                  </Box>
                ) : null}
                <EsmMessages
                  messages={messages}
                  setMessages={setMessages}
                  setSelectedTracks={setSelectedTracks}
                  requestableType='stinger-message'
                  isUpdate={Boolean(stingerId)}
                  messageStatus={messageStatus() ?? null}
                />
              </>
            );
          case 'Review':
            return (
              <StingerReview
                other_event_name={othersEventName}
                schools={schools}
                events={events}
                stations={stations}
                messages={messages}
                seasons={seasons}
                isAllSeason={isAllSeason}
              />
            );
          default:
            return null;
        }
      })()}
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          onEdit={() => setApiState(defaultApiState)}
          onRetry={handleSubmit}
          onClose={handleClose}
          apiState={apiState}
        />
      )}
    </StepperLayout>
  );
};

export default StingerStepper;
