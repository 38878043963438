import {useCallback} from 'react';
import {UnfoldMore} from '@mui/icons-material';
import {Box, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Draggable} from 'react-beautiful-dnd';
import {iTimedMessageMessageItem} from './TimedMsgRequestContentMessageCard';
import IconDeleteButton from '../../../../../components/Button/IconDeletebutton';

type SequenceItemProps = {
  index: number;
  item: iTimedMessageMessageItem;
  onRemove: (id: number) => void;
  disableDelete: boolean;
  selectedItem: number | undefined;
  setSelectedItem: ((val: number) => void) | undefined;
  label?: string;
};

const useStyles = makeStyles({
  listItem: {
    marginBottom: 4,
    backgroundColor: '#1e2c39',
  },
  dragging: {
    backgroundColor: '#2c4053',
  },
});
const TimedMsgRequestSequenceBuilderListItem = ({
  index,
  item,
  onRemove,
  disableDelete,
  selectedItem,
  setSelectedItem,
  label,
}: SequenceItemProps) => {
  const classes = useStyles();
  const handleRemove = useCallback(() => {
    onRemove(item.id);
  }, [item.id, onRemove]);
  return (
    <Draggable draggableId={item.id.toString()} index={index}>
      {(provided, snapshot) => (
        <ListItem
          id={`${label}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          autoFocus
          className={`bg-transparent p-0 ${classes.listItem} ${
            snapshot.isDragging && classes.dragging
          }`}
          sx={provided.draggableProps.style}
          onClick={() => {
            setSelectedItem?.(item.id);
          }}>
          <Box
            className='w-178 flex items-center black-variant1 p-1 radius-5 mr-2'
            style={{background: selectedItem === item.id ? 'dimgrey' : ''}}>
            <ListItemIcon sx={{minWidth: '30px'}}>
              <UnfoldMore />
            </ListItemIcon>
            <ListItemText primary={label} />
          </Box>
          <IconDeleteButton disabled={disableDelete} onClick={handleRemove} />
        </ListItem>
      )}
    </Draggable>
  );
};

export default TimedMsgRequestSequenceBuilderListItem;
