import {
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {Dayjs} from 'dayjs';
import {useCallback} from 'react';
import {CalendarIconDatePicker} from './CalendarIconDatePicker';

export const NeptuneRadioEventOptions = ({
  selectedSeasonYear,
  setSelectedSeasonYear,
  selectedDate,
  setSelectedDate,
  totalDays,
  setTotalDays,
  totalYears,
  setTotalYears,
}: {
  selectedSeasonYear: string;
  setSelectedSeasonYear: (val: string) => void;
  selectedDate: Dayjs | null;
  setSelectedDate: (val: Dayjs | null) => void;
  totalDays: number;
  setTotalDays: (val: number) => void;
  totalYears: number;
  setTotalYears: (val: number) => void;
}) => {
  const currentYear = new Date().getFullYear();
  const handleSingleDateChange = useCallback(
    (date: Dayjs | null) => {
      if (date) {
        setSelectedDate(date);
      }
    },
    [setSelectedDate],
  );

  const handleLabelChange = useCallback(
    (type: string, value: string) => {
      if (type === 'totalDays' && Number(value) > 0) {
        setTotalDays(Number(value));
      } else if (type === 'totalYears' && Number(value) > 0) {
        setTotalYears(Number(value));
      }
    },
    [setTotalDays, setTotalYears],
  );

  return (
    <>
      <Typography className='font-bold text-16 mb-2'>
        Are you open all Year or Seasonally?
      </Typography>
      <Stack direction={'row'} className='mb-5'>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedSeasonYear === 'All Year'}
              onClick={() => setSelectedSeasonYear('All Year')}
            />
          }
          label={<Typography>All Year</Typography>}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedSeasonYear === 'Seasonally'}
              onClick={() => setSelectedSeasonYear('Seasonally')}
            />
          }
          label={<Typography>Seasonally</Typography>}
        />
      </Stack>
      {selectedSeasonYear === 'Seasonally' ? (
        <>
          <Typography className='font-bold text-16 mb-2'>
            {`What is the OPENING DATE for your ${currentYear} season?`}
          </Typography>
          <CalendarIconDatePicker
            selectedDate={selectedDate}
            handleSingleDateChange={handleSingleDateChange}
          />
        </>
      ) : null}
      <Typography className='font-bold text-16 mt-3 mb-2'>
        How many TOTAL days are you open annually?
      </Typography>
      <TextField
        placeholder='Enter number'
        type='number'
        size='small'
        value={totalDays}
        onChange={e => handleLabelChange('totalDays', e.target.value)}
      />
      <Typography className='font-bold text-16 mt-3 mb-2'>
        What is your estimated total yearly attendance?
      </Typography>
      <TextField
        placeholder='Enter number'
        type='number'
        size='small'
        value={totalYears}
        onChange={e => handleLabelChange('totalYears', e.target.value)}
      />
    </>
  );
};
