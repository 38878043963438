import {Dayjs} from 'dayjs';
import {Typography} from '@mui/material';
import {IScripts} from '../event-specific/EventSpecificStepper';
import {
  MessageReviewTable,
  MessageSingleReviewLine,
} from '../MessageReviewLine';
import {commonDateFormat} from '../../../Service/CommonService';
import {IOnDemandStation} from './OndemandStepper';
import {OnDemandStationsList} from './OnDemandStationLibrarySelection';

type Props = {
  stations: IOnDemandStation[];
  is_expire_date_set: boolean;
  expire_date: Dayjs | null;
  messages: IScripts[];
  from?: string;
};

export const OndemandReview = ({
  stations,
  is_expire_date_set,
  expire_date,
  messages,
  from = '',
}: Props) => {
  return (
    <>
      {from !== 'view' && (
        <Typography variant='h5' className='mb-5'>
          Review
        </Typography>
      )}
      <OnDemandStationsList stationsList={stations} />
      <MessageSingleReviewLine
        sectionTitle='End Date'
        sectionContent={
          is_expire_date_set && expire_date
            ? commonDateFormat(expire_date.format('MM-DD-YYYY'))
            : 'NA'
        }
      />
      <MessageReviewTable sectionTitle='Message Details' messages={messages} />
    </>
  );
};
