import React, {SetStateAction} from 'react';
import {useSelector} from 'react-redux';
import {Box, FormControl, Grid} from '@mui/material';
import ActiveDropdown from '../dropdowns/ActiveDropdown';
import {UserCanAny} from '../UserCan';
import InputSearch from '../styles/InputSearch';
import {
  BaseMessageSeason,
  BaseMessageVoice,
  EventsFilter,
  SeasonsFilter,
} from '../../pages/contacts/messages/base/BaseMsgDataGrid';
import {
  companyIdSelector,
  isGTSelector,
  isNRorSZSelector,
} from '../../store/Slices/companySlice';
import {ISeason} from '../../pages/contacts/messages/base/BaseMessagesReducer';
import {IEventType} from '../../dto/EventType.dto';
import CreateButton from '../Button/CreateButton';

type IEsmStatus = 'Active' | 'Inactive' | 'In Progress';

export interface HeaderProps {
  handleActionButtonClick: () => void;
  handleSearch: (value: string) => void;
  handleStatus: (newValue: IEsmStatus) => void;
  status: IEsmStatus;
  IconAdd: string;
  query: {
    search?: string;
  };
  selectedEvent?: IEventType;
  handleEventChange?: (id: IEventType) => void;
  selectedSeason?: ISeason;
  handleSeasonChange?: (val: ISeason) => void;
  eventName?: string;
  bmvOpen?: Boolean;
  setBmvOpen?: React.Dispatch<SetStateAction<boolean>>;
  bmsOpen?: Boolean;
  setBmsOpen?: React.Dispatch<SetStateAction<boolean>>;
}

const GridFilterHeader: React.FC<HeaderProps> = ({
  handleActionButtonClick,
  handleSearch,
  handleStatus,
  status,
  IconAdd,
  query,
  selectedEvent,
  handleEventChange,
  selectedSeason,
  handleSeasonChange,
  eventName = 'event-message',
  bmvOpen,
  setBmvOpen,
  bmsOpen,
  setBmsOpen,
}) => {
  const isGT = useSelector(isGTSelector);
  const isNRSZ = useSelector(isNRorSZSelector);
  const companyId = useSelector(companyIdSelector);
  return (
    <Grid container direction='row' className='h-85 items-center pt-5'>
      <Grid item xs={6} className='flex items-center'>
        <FormControl sx={{width: 200}} className='mr-2'>
          <ActiveDropdown value={status} onChange={handleStatus} />
        </FormControl>
        {handleEventChange && isGT && (
          <FormControl sx={{width: 200}} className='mr-2'>
            <EventsFilter
              selectedEvent={selectedEvent as IEventType}
              setSelectedEvent={handleEventChange}
              company_id={companyId}
            />
          </FormControl>
        )}
        {isNRSZ && handleSeasonChange && (
          <FormControl sx={{width: 200}} className='mr-2'>
            <SeasonsFilter
              selectedSeason={selectedSeason as ISeason}
              setSelectedSeason={handleSeasonChange}
            />
          </FormControl>
        )}
      </Grid>
      <Grid item xs={6}>
        <Box className='flex items-center justify-end' style={{gap: '16px'}}>
          {eventName && eventName === 'base-message' && isNRSZ && (
            <UserCanAny scope='is-admin,partner,base-message:create'>
              <>
                {isNRSZ ? (
                  <>
                    <BaseMessageVoice
                      open={bmvOpen as boolean}
                      setOpen={
                        setBmvOpen as React.Dispatch<
                          React.SetStateAction<boolean>
                        >
                      }
                    />
                    <BaseMessageSeason
                      open={bmsOpen as boolean}
                      setOpen={
                        setBmsOpen as React.Dispatch<
                          React.SetStateAction<boolean>
                        >
                      }
                    />
                  </>
                ) : null}
              </>
            </UserCanAny>
          )}
          <InputSearch
            className='max-w-218'
            value={query.search || ''}
            placeholder='Search'
            onChange={handleSearch}
          />
          <UserCanAny scope={`is-admin,partner,${eventName}:create`}>
            <CreateButton
              icon={IconAdd}
              className='create_btn_message'
              onClick={handleActionButtonClick}
            />
          </UserCanAny>
        </Box>
      </Grid>
    </Grid>
  );
};

export default GridFilterHeader;
