import {useCallback, useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import {Box} from '@mui/system';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import {useSelector} from 'react-redux';
import {useRqParentGenres} from '../../../../Service/musicReviewService';
import {IMusicGenre} from '../../../../dto/MusicGenre.dto';
import {IGenreActionProp} from '../PartnerCustomGenre';
import {productSelector} from '../../../../store/Slices/companySlice';
import {useRqVoicePersonnelMappedWithProduct} from '../../../../react_query/partner-custom-genres/PartnerCustomGenres';

type ISuperGenreProp = IMusicGenre & {
  selected: boolean;
};
const ListSuperGenreCmpt = ({
  selectedSuperGenres,
}: {
  selectedSuperGenres: number[];
}) => {
  const {data, isFetching, isError} = useRqParentGenres();
  const [superGenres, setSuperGenres] = useState<ISuperGenreProp[]>([]);
  useEffect(() => {
    if (data && !isFetching && !isError) {
      const selectedValues: ISuperGenreProp[] = [];
      const unSelectedValues: ISuperGenreProp[] = [];
      data.forEach(value => {
        if (selectedSuperGenres.includes(value.id)) {
          selectedValues.push({...value, selected: true});
        } else {
          unSelectedValues.push({...value, selected: false});
        }
      });
      setSuperGenres([...selectedValues, ...unSelectedValues]);
    }
  }, [data, isError, isFetching, selectedSuperGenres]);
  return (
    <Box
      bgcolor='#afaaaa24'
      pb={1}
      borderRadius={2}
      maxHeight='calc(100vh - 250px)'
      overflow={'auto'}
      position={'relative'}>
      <Typography variant='h6' textAlign='center' mb={1}>
        Super Genres
      </Typography>
      <Grid container spacing={1}>
        {superGenres &&
          superGenres.map(superGenre => {
            return (
              <Grid item xs={4} textAlign='center' key={superGenre.id}>
                <HexButton
                  name={superGenre.name}
                  selected={superGenre.selected}
                />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export default ListSuperGenreCmpt;

const HexButton = ({name, selected}: {name: string; selected: boolean}) => {
  const color = selected ? 'green !important' : 'red !important';
  return (
    <Button
      variant='contained'
      className={`hex-button`}
      sx={{background: color}}>
      {name}
    </Button>
  );
};

// Genre show and hide action components =================>

export const GenreActionCompt = ({
  showList,
  setShowList,
  hideList,
  setHideList,
  showSelectedId,
  setShowSelectedId,
  hideSelectedId,
  setHideSelectedId,
}: {
  showList: IGenreActionProp[];
  setShowList: React.Dispatch<React.SetStateAction<IGenreActionProp[]>>;
  hideList: IGenreActionProp[];
  setHideList: React.Dispatch<React.SetStateAction<IGenreActionProp[]>>;
  showSelectedId: number[];
  setShowSelectedId: React.Dispatch<React.SetStateAction<number[]>>;
  hideSelectedId: number[];
  setHideSelectedId: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const updateSameComponentDraggable = (
    dataList: IGenreActionProp[],
    setDataList: React.Dispatch<React.SetStateAction<IGenreActionProp[]>>,
    sourceIndex: number,
    DestinationIndex: number,
  ) => {
    const updatedTracks = Array.from(dataList);
    const [movedTrack] = updatedTracks.splice(sourceIndex, 1);
    updatedTracks.splice(DestinationIndex, 0, movedTrack!);

    setDataList(updatedTracks);
  };

  const onDragEnd = (result: DropResult) => {
    const {source, destination} = result;
    if (!destination) return; // Dragged outside the list

    if (destination) {
      if (source.droppableId === destination.droppableId) {
        if (source.droppableId === 'type-1') {
          updateSameComponentDraggable(
            showList,
            setShowList,
            source.index,
            destination?.index,
          );
        } else {
          updateSameComponentDraggable(
            hideList,
            setHideList,
            source.index,
            destination?.index,
          );
        }
      } else {
        if (
          source.droppableId === 'type-1' &&
          destination.droppableId === 'type-2'
        ) {
          const show = Array.from(showList);
          const [removedData] = show.splice(source.index, 1);
          setShowList(show);

          if (removedData?.id && removedData.name) {
            const hide = Array.from(hideList);
            hide.splice(
              destination.index,
              0,
              {...removedData, colorClassName: 'list-bg-color-red'}!,
            );
            setHideList(hide);
          }
        } else {
          const hide = Array.from(hideList);
          const [removedData] = hide.splice(source.index, 1);
          setHideList(hide);

          if (removedData?.id && removedData.name) {
            const show = Array.from(showList);
            show.splice(
              destination.index,
              0,
              {...removedData, colorClassName: 'list-bg-color-orange'}!,
            );
            setShowList(show);
          }
        }
      }
    }
  };

  // previous codes
  const handleToggle = (value: number, type: string) => () => {
    if (type === 'show') {
      const currentIndex = showSelectedId.indexOf(value);
      const newChecked = [...showSelectedId];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setShowSelectedId(newChecked);
    } else {
      const currentIndex = hideSelectedId.indexOf(value);
      const newChecked = [...hideSelectedId];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setHideSelectedId(newChecked);
    }
  };

  const handleHideAction = useCallback(() => {
    const removedShowData: IGenreActionProp[] = [];
    const addedShowData: IGenreActionProp[] = [];
    showList.forEach(value => {
      if (showSelectedId.includes(value.id)) {
        removedShowData.push({...value, colorClassName: 'list-bg-color-red'});
      } else {
        addedShowData.push(value);
      }
    });
    setShowList(addedShowData);
    setHideList(prev => {
      return [...prev, ...removedShowData];
    });
    setShowSelectedId([]);
  }, [setHideList, setShowList, setShowSelectedId, showList, showSelectedId]);

  const handleShowAction = useCallback(() => {
    const removedHideData: IGenreActionProp[] = [];
    const addedHideData: IGenreActionProp[] = [];
    hideList.forEach(value => {
      if (hideSelectedId.includes(value.id)) {
        removedHideData.push({
          ...value,
          colorClassName: 'list-bg-color-orange',
        });
      } else {
        addedHideData.push(value);
      }
    });
    setHideList(addedHideData);
    setShowList(prev => {
      return [...prev, ...removedHideData];
    });
    setHideSelectedId([]);
  }, [hideList, hideSelectedId, setHideList, setHideSelectedId, setShowList]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <Droppable droppableId='Categories' type='droppableItem'>
          {provided => (
            <div ref={provided.innerRef}>
              <Grid
                container
                spacing={2}
                height={'100%'}
                alignContent='center'
                pt={2}>
                <Grid
                  item
                  xs={5}
                  maxHeight='calc(100vh - 316px)'
                  overflow={'auto'}
                  position={'relative'}
                  className='pt-0'>
                  <Draggable
                    draggableId={`category-1`}
                    key={`category-1`}
                    index={1}>
                    {parentProvider => (
                      <div
                        className='w-full'
                        ref={parentProvider.innerRef}
                        {...parentProvider.draggableProps}>
                        <Droppable droppableId={`type-1`}>
                          {provided => (
                            <List
                              sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                              }}
                              ref={provided.innerRef}>
                              <Typography
                                variant='h6'
                                component='div'
                                textAlign='center'
                                bgcolor={'green'}
                                sx={{
                                  position: 'sticky',
                                  top: 0,
                                  display: 'block',
                                  zIndex: '1',
                                }}
                                {...parentProvider.dragHandleProps}>
                                Show
                              </Typography>
                              {showList.map((item, index) => (
                                <Draggable
                                  draggableId={`type-1 ${item.id}`}
                                  key={item.id}
                                  index={index}>
                                  {provided => (
                                    <ListItemButton
                                      divider
                                      sx={{p: 0}}
                                      onClick={handleToggle(item.id, 'show')}
                                      dense>
                                      <ListItem
                                        className={
                                          showSelectedId.indexOf(item.id) !== -1
                                            ? `${item.colorClassName}-selected text-black`
                                            : `${item.colorClassName}  text-black`
                                        }
                                        sx={{mx: 0}}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                        <ListItemText
                                          id={`checkbox-list-label-${item.id}`}
                                          primary={item.name}
                                        />
                                      </ListItem>
                                    </ListItemButton>
                                  )}
                                </Draggable>
                              ))}
                            </List>
                          )}
                        </Droppable>
                      </div>
                    )}
                  </Draggable>
                </Grid>
                <Grid item xs={2}>
                  <Grid
                    container
                    direction='column'
                    alignContent='center'
                    justifyContent='center'
                    height={'100%'}>
                    <IconButton
                      sx={{
                        color:
                          showSelectedId.length !== 0 ? 'red !important' : '',
                      }}
                      disabled={showSelectedId.length === 0}
                      onClick={handleHideAction}>
                      <ArrowForwardTwoToneIcon />
                    </IconButton>
                    <IconButton
                      sx={{
                        mt: 5,
                        color:
                          hideSelectedId.length !== 0 ? 'green !important' : '',
                      }}
                      disabled={hideSelectedId.length === 0}
                      onClick={handleShowAction}>
                      <ArrowBackTwoToneIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={5}
                  maxHeight='calc(100vh - 316px)'
                  overflow={'auto'}
                  position={'relative'}
                  className='pt-0'>
                  <Draggable
                    draggableId={`category-2`}
                    key={`category-2`}
                    index={2}>
                    {parentProvider => (
                      <div
                        className='w-full'
                        ref={parentProvider.innerRef}
                        {...parentProvider.draggableProps}>
                        <Droppable droppableId={`type-2`}>
                          {provided => (
                            <List
                              sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                              }}
                              ref={provided.innerRef}>
                              <Typography
                                variant='h6'
                                component='div'
                                textAlign='center'
                                bgcolor={'blue'}
                                sx={{
                                  position: 'sticky',
                                  top: 0,
                                  display: 'block',
                                  zIndex: '1',
                                }}
                                {...parentProvider.dragHandleProps}>
                                Hide
                              </Typography>
                              {hideList.map((item, index) => (
                                <Draggable
                                  draggableId={`type-1 ${item.id}`}
                                  key={item.id}
                                  index={index}>
                                  {provided => (
                                    <ListItemButton
                                      divider
                                      sx={{p: 0}}
                                      onClick={handleToggle(item.id, 'hide')}
                                      dense>
                                      <ListItem
                                        className={
                                          hideSelectedId.indexOf(item.id) !== -1
                                            ? `${item.colorClassName}-selected text-black`
                                            : `${item.colorClassName}  text-black`
                                        }
                                        sx={{mx: 0}}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                        <ListItemText
                                          id={`checkbox-list-label-${item.id}`}
                                          primary={item.name}
                                        />
                                      </ListItem>
                                    </ListItemButton>
                                  )}
                                </Draggable>
                              ))}
                            </List>
                          )}
                        </Droppable>
                      </div>
                    )}
                  </Draggable>
                </Grid>
              </Grid>

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

// Reset button component
export const GenreResetButton = ({
  handleReset,
}: {
  handleReset: () => number;
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickReset = () => {
    handleReset();
    setOpen(false);
  };
  return (
    <>
      <Button variant='contained' color='success' onClick={handleClickOpen}>
        Reset
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure to reset the Genres to defaults?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClickReset} autoFocus>
            Reset
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

type IProductVoicePersonnel = {
  stinger: string[];
  dj: string[];
  live: string[];
  voice: string[];
};
export const VoiceArtistRoleWiseUsers = () => {
  const [users, setUsers] = useState<IProductVoicePersonnel>({
    stinger: [],
    dj: [],
    live: [],
    voice: [],
  });
  const productData = useSelector(productSelector);
  const {data, isFetching, isError} = useRqVoicePersonnelMappedWithProduct(
    productData.id,
  );

  useEffect(() => {
    if (data && !isFetching && !isError) {
      data.forEach(value => {
        if (value.djvp_status) {
          setUsers(prev => {
            return {...prev, dj: [...prev.dj, value.user.name]};
          });
        }
        if (value.lmvp_status) {
          setUsers(prev => {
            return {...prev, live: [...prev.live, value.user.name]};
          });
        }
        if (value.svp_status) {
          setUsers(prev => {
            return {...prev, stinger: [...prev.stinger, value.user.name]};
          });
        }
        if (value.voice_over_status) {
          setUsers(prev => {
            return {...prev, voice: [...prev.voice, value.user.name]};
          });
        }
      });
    }
  }, [data, isError, isFetching]);

  return (
    <Table>
      <TableHead sx={{color: 'white', backgroundColor: 'blue'}}>
        <TableRow>
          <TableCell>Channel</TableCell>
          <TableCell>Voice Personnel(s)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Stinger</TableCell>
          <TableCell>
            {users.stinger.length > 0 ? users.stinger.join(', ') : 'Unassigned'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>DJ</TableCell>
          <TableCell>
            {users.dj.length > 0 ? users.dj.join(', ') : 'Unassigned'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Live Message</TableCell>
          <TableCell>
            {users.live.length > 0 ? users.live.join(', ') : 'Unassigned'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Voice Over</TableCell>
          <TableCell>
            {users.voice.length > 0 ? users.voice.join(', ') : 'Unassigned'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
