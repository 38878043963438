import {Button, ButtonProps} from '@mui/material';

type iconAddProps = ButtonProps & {
  icon?: string;
  title?: string;
};

const CreateButton: React.FC<iconAddProps> = ({
  title,
  icon,
  ...buttonProps
}) => {
  return (
    <Button variant='outlined' size='small' color='secondary' {...buttonProps}>
      {icon && <img src={icon} width={18} alt='Create New' className='mr-2' />}
      {title ? title : 'Create New'}
    </Button>
  );
};

export default CreateButton;
