import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Allroutes from '../src/routes';
import {loginSuccess} from './store/Slices/userSlice';
import {RootState} from './store/configstore';
import Toast from './package/Toaster';
import {toast_msg} from './store/Slices/commonSlice';
import DialogApiStatus from './components/dialogs/DialogApiStatus';
import {apiGet} from './axiosConfig';
import {MusicPlayAssignCtx} from './pages/music-section/MusicSectionContexts';

const PreLoadUser = ({onComplete}: {onComplete: (val: boolean) => void}) => {
  const dispatch = useDispatch();
  const [apiState, setApiState] = useState({status: '', error: null});
  const [isCompleted, setIsCompleted] = useState(false);
  const navigate = useNavigate();

  const fetchCurrentUser = useCallback(() => {
    setApiState({status: 'pending', error: null});
    if (!isCompleted) {
      const impersonatedUserToken =
        sessionStorage.getItem('access_token') || '';
      //impersonated_user
      apiGet('/v1/user/me')
        .then(res => {
          if (impersonatedUserToken) {
            dispatch(
              loginSuccess(Object.assign(res.data, {isImpersonatedUser: true})),
            );
          } else {
            dispatch(
              loginSuccess(
                Object.assign(res.data, {
                  token: localStorage.getItem('access_token'),
                }),
              ),
            );
            // To keep the impersonated company
            const companyId = parseInt(
              sessionStorage.getItem('impersonated_company') || '',
            );
            if (companyId > 0) {
              let url = `/partner-impersonate/${companyId}`;
              if (
                !window.location.pathname.startsWith('/partner-impersonate')
              ) {
                url += `?redirectTo=${window.location.pathname}`;
              }
              setIsCompleted(true);
              navigate(url);
            }
          }
          setApiState({status: '', error: null});
          setTimeout(onComplete, 10, true);
        })
        .catch(error => {
          setApiState({status: 'error', error});
        });
    }
  }, [dispatch, isCompleted, navigate, onComplete]);

  useEffect(() => {
    if (
      (sessionStorage.hasOwnProperty('access_token') &&
        sessionStorage.hasOwnProperty('impersonated_user')) ||
      localStorage.hasOwnProperty('access_token')
    ) {
      fetchCurrentUser();
    } else {
      onComplete(true);
    }
  }, [onComplete, fetchCurrentUser, isCompleted]);
  if (apiState.status === '') return null;
  return (
    <DialogApiStatus
      apiState={apiState}
      pendingTitle='Fetching user details'
      pendingSubTitle='Please wait...'
      successTitle=''
      errorTitle='Fetching user details is failed!'
      onRetry={fetchCurrentUser}
    />
  );
};

function App() {
  const dispatch = useDispatch();
  const [isInitialised, setInitialised] = useState(false);
  const [trackId, setTrackId] = useState<string | number | null>(null);

  const {toastMsg, toastType} = useSelector((state: RootState) => state.common);
  React.useEffect(() => {
    if (toastMsg !== '') {
      Toast(toastMsg, toastType);
    }
    dispatch(toast_msg({toastMsg: '', toastType: 'error'}));
  }, [toastMsg, toastType, dispatch]);
  return isInitialised ? (
    <MusicPlayAssignCtx.Provider
      value={{
        trackId,
        setTrackId,
      }}>
      <Allroutes />
    </MusicPlayAssignCtx.Provider>
  ) : (
    <PreLoadUser onComplete={setInitialised} />
  );
}

export default App;
