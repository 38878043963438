import React from 'react';
import {Box, Paper, Popper, Typography} from '@mui/material';

interface GridCellExpandProps {
  value: string;
  width?: number;
  isReview?: boolean;
}

function isOverflown(element: Element): boolean {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

export const GridCellExpand = React.memo(function GridCellExpand(
  props: GridCellExpandProps,
) {
  const {value, isReview} = props;
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    if (isReview) {
      setShowPopper(true);
      setAnchorEl(null);
    } else {
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
    }
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
      }}>
      <Box
        ref={cellDiv}
        sx={{
          height: '100%',
          width: '400px',
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        className='overflow-hidden whitespace-no-wrap'
        ref={cellValue}
        sx={{textOverflow: 'ellipsis'}}>
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{width: '400px', marginLeft: -17}}>
          <Paper
            elevation={1}
            style={{minHeight: wrapper.current!.offsetHeight - 3}}>
            <Typography
              variant='body2'
              style={{padding: 8, whiteSpace: 'pre-wrap'}}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});
