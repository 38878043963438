import {useCallback, useState} from 'react';
import {Button, ButtonProps, IconButton} from '@mui/material';
import {apiPost} from '../../../axiosConfig';
import DialogApiRequestStatus from '../../dialogs/DialogApiRequestStatus';

const SubmitButton = ({
  title = 'Submit',
  url,
  payload,
  successAction,
  buttonProps = {variant: 'contained'},
  icon,
}: {
  title: string;
  url: string;
  payload: object;
  successAction?: () => void;
  buttonProps?: ButtonProps;
  icon?: JSX.Element;
}) => {
  const [apiState, setApiState] = useState({
    status: '',
    error: null,
  });

  const closeDialog = useCallback(() => {
    setApiState({
      status: '',
      error: null,
    });
  }, []);

  const handleSubmit = useCallback(() => {
    setApiState({status: 'pending', error: null});

    apiPost(url, payload)
      .then(() => {
        successAction?.();
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({
          status: 'error',
          error,
        });
      });
  }, [payload, successAction, url]);

  return (
    <>
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={handleSubmit}
          onEdit={closeDialog}
          onClose={closeDialog}
        />
      )}
      {icon ? (
        <IconButton title={title} {...buttonProps} onClick={handleSubmit}>
          {icon}
        </IconButton>
      ) : (
        <Button {...buttonProps} onClick={handleSubmit}>
          {title}
        </Button>
      )}
    </>
  );
};

export default SubmitButton;
