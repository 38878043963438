import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

export const useStylesed = makeStyles((theme: Theme) => ({
  tableTitle: {
    shadows: 1,
  },
  icon: () => ({
    fontSize: '90px',
    color: theme.palette.mode === 'dark' ? '#fff' : '#0d3654',
  }),

  activeCard: {
    backgroundColor: '#0187c4!important',
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0px 2px 30px 6px #0187c4'
        : '0px 2px 30px 6px rgba(92,91,91,.5)',
  },
  trackCard: {
    color: theme.palette.primary.contrastText,
    backgroundColor: '#90CAF9',
    '&:hover': {backgroundColor: '#3498db'},
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiTypography-root.MuiTypography-noWrap': {
      width: '10vw',
    },
  },
  trackActiveCard: {
    backgroundColor: '#3498db',
  },

  card_nohover: {
    backgroundColor: theme.palette.mode === 'dark' ? '#373a3e' : '#f2f2f2',
    borderRadius: '20px',
    cursor: 'pointer',
  },
  card: {
    backgroundColor: theme.palette.mode === 'dark' ? '#373a3e' : '#f2f2f2',
    borderRadius: '20px',
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? '#0187c4 !important'
          : 'rgba(148,149,153,255) !important',
      shadows: 4,
      boxShadow:
        theme.palette.mode === 'dark'
          ? '0px 2px 30px 6px rgba(1,135,196,255)'
          : '0px 2px 30px 6px rgba(92, 91, 91, 0.5)',
      '& .MuiSvgIcon-root': {
        color: theme.palette.mode === 'dark' ? '#000' : '#fff',
      },
      '& $cardTitle': {
        color: theme.palette.mode === 'dark' ? '#000' : '#fff',
      },
      '& $help': {
        color: '#fff !important',
      },
    },
  },
  card_flex: {
    backgroundColor: theme.palette.mode === 'dark' ? '#373a3e' : '#f2f2f2',
    borderRadius: '20px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? '#0187c4 !important'
          : 'rgba(148,149,153,255) !important',
      shadows: 4,
      boxShadow:
        theme.palette.mode === 'dark'
          ? '0px 2px 30px 6px rgba(1,135,196,255)'
          : '0px 2px 30px 6px rgba(92, 91, 91, 0.5)',
      '& .MuiSvgIcon-root': {
        color: theme.palette.mode === 'dark' ? '#000' : '#fff',
      },
      '& $cardTitle': {
        color: theme.palette.mode === 'dark' ? '#000' : '#fff',
      },
      '& $help': {
        color: '#fff !important',
      },
    },
  },
  cardTitle: {
    fontWeight: 600,
    color: theme.palette.mode === 'dark' ? '#fff' : '#0d3654',
  },
  cardBody: {
    textAlign: 'center',
    padding: '1rem',
  },
  help: () => ({
    fontSize: '30px',
    padding: '5px',
    color: theme.palette.mode === 'dark' ? '#fff' : '#8f9091',
  }),
  /** Modal popup layout */
  ContainerPopupModal: {
    marginTop: '10px',
    minHeight: '600px',
  },
  modelCard: {
    borderRadius: '30px',
    padding: '20px',
    width: '100%',
    '& .modal-dialog .modal-content': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgb(10, 25, 41) !important'
          : 'rgb(210,211,213) !important',
      color: theme.palette.mode === 'dark' ? '#fff' : '#0d3654',
    },
  },
  modelIcon: {
    fontSize: '50px',
    cursor: 'pointer',
    padding: '5px',
    color: theme.palette.mode === 'dark' ? '#fff' : '#0d3654',
  },
  /************ */
  cardBadge: {
    backgroundColor: '#74b267',
    fontSize: '13px',
    width: '100px',
    padding: '5px',
    color: '#fff',
    textAlign: 'center',
    fontWeight: 600,
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    float: 'right',
    marginRight: '15px',
  },
  musicIcon: {
    fontSize: '5em',
    color: theme.palette.mode === 'dark' ? '#fff' : '#0d3654',
  },
  Themetypography: {
    textTransform: 'uppercase',
    color: theme.palette.mode === 'dark' ? '#fff' : '#0d3654',
  },
  ThemetypographyWithoutCase: {
    color: theme.palette.mode === 'dark' ? '#fff' : '#0d3654',
  },
  tabs: {
    '& li button': {
      color:
        theme.palette.mode === 'dark'
          ? '#fff !important'
          : '#0d3654 !important',
    },
  },
  reviewExtraNotes: {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? '#333336  !important'
        : 'rgba(148,149,153,255) !important',
  },
  DataTableCss: {
    '& .rdt_TableHead': {
      color:
        theme.palette.mode === 'dark'
          ? '#fff !important'
          : '#0d3654 !important',
    },
    '& .rdt_TableBody .rdt_TableRow': {
      color:
        theme.palette.mode === 'dark'
          ? '#fff !important'
          : '#0d3654 !important',
      '&:hover': {
        color: '#fff !important',
      },
    },
    '& .rdt_TableBody .invalidSong': {
      color: '#858383 !important',
    },
  },
  searchDropDownBg: {
    '& div': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? '#0187c4 !important'
          : 'rgba(148,149,153,255) !important',
    },
  },
  SongTabList: {
    backgroundColor: 'unset',
    color: theme.palette.mode === 'dark' ? '#fff' : '#0d3654',
    border: 'unset',
    marginLeft: '10px',
    fontSize: '0.875rem',
    cursor: 'pointer',
  },
  r_center: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  r_start: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  r_end: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  r_between: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  r_around: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  r_even: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  c_center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  c_start: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  c_end: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  c_between: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  c_around: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  c_even: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  c_flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  blue_checkbox: {
    width: '17px',
    height: '17px',
    borderRadius: '4px',
    appearance: 'none',
    background: 'gray',
    cursor: 'pointer',
    '&:checked': {
      background: '#0d6efd',
      boxShadow: '0px 0px 4px 2px rgb(1 135 196)',
    },
  },
  gn_textArea: {
    width: '100%',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
  },
  overflowY_byVh: {
    height: '48vh',
    overflowY: 'auto',
  },
  cls_inactive: {
    background: '#4e4f5082',
    color: '#FFFFFF',
    minHeight: '45px !important',
  },
  cls_active: {
    minHeight: '45px !important',
  },
}));
