import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import {apiGet} from '../../axiosConfig';
import {IApiDataResponse, IApiPaginationResponse} from '../../dto/API.dto';
import {IMessage1} from '../../dto/Message.dto';

export type IListQueryParams = {
  generic_type?: number;
};

export interface IGenericBaseRequest {
  id: number;
  name: string;
  generic_base_message_type_id: number;
  message: IMessage1;
  created_at: string;
}

export interface IGenericBaseType {
  id: number;
  name: string;
  product_id?: number;
}

export const queryKeyGenericBasetList = (type: number) => {
  return `v1/global-content/generic-base/type/${type}`;
};

export const useRqGetGenericBaseList = (type: number) => {
  return useQuery({
    queryKey: [queryKeyGenericBasetList(type)],
    queryFn: () => getBaseList(type),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: true,
  });
};

const getBaseList = (type: number) => {
  return apiGet(queryKeyGenericBasetList(type)).then(
    (
      response: AxiosResponse<IApiPaginationResponse<IGenericBaseRequest[]>>,
    ) => {
      return response.data;
    },
  );
};

export interface IGenericBase {
  id: number;
  name: string;
  generic_base_message_type_id: number;
  message: IMessage1;
}

export const apiGetGenericBaseUsingID = (id: number, type: number) => {
  return apiGet(
    `/v1/global-content/generic-base/type/${type}/message/${id}`,
  ).then((res: AxiosResponse<IApiDataResponse<IGenericBase>>) => res.data.data);
};

export interface IExceptionCompanies {
  id: number;
  name: string;
  requestable_id: number;
}

export const queryExceptionCompaniesList = (id: number, type: string) => {
  return type === 'generic-stinger'
    ? `v1/global-content/stingers/${id}/exception`
    : `v1/global-content/generic-base/type/${id}/exception`;
};

const getExcepCompanies = (id: number, type: string) => {
  return apiGet(queryExceptionCompaniesList(id, type)).then(
    (response: AxiosResponse<IApiDataResponse<IExceptionCompanies[]>>) => {
      return response.data.data;
    },
  );
};
export const useRqListExceptionCompanies = (id: number, type: string) => {
  return useQuery({
    queryKey: [queryExceptionCompaniesList(id, type)],
    queryFn: () => getExcepCompanies(id, type),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: true,
  });
};

export const apiGetGenericBaseTypes = () => {
  return apiGet(`/v1/global-content/generic-base/type`).then(
    (response: AxiosResponse<IApiDataResponse<IGenericBaseType[]>>) =>
      response.data.data,
  );
};
