import {AxiosResponse} from 'axios';
import {useQuery} from '@tanstack/react-query';
import axiosInstance, {apiGet} from '../../../axiosConfig';
import {IApiDataResponse, IApiPaginationResponse} from '../../../dto/API.dto';
import {IDataGridSearchSort} from '../../../dto/Html.dto';
import {IMessage} from '../../../dto/Message.dto';
import {IEventType} from '../../../dto/EventType.dto';
import {ISeason} from '../../../pages/contacts/messages/base/BaseMessagesReducer';
import {ICompanySchool} from '../../../dto/CompanySchool.dto';
import {IStation} from '../../../dto/Station.dto';
import {IUserBase} from '../../../dto/User.dto';
import {ICompany} from '../../../dto/Company.dto';

export const useRqStingerMessageList = (
  company_id: number,
  parms: IStingerMsgQueryParams,
) => {
  const params = {...parms, page: parms.page + 1};
  return useQuery({
    queryKey: [`v1/company/${company_id}/message/stinger-message`, params],
    queryFn: () => getStingerMgsList(company_id, params),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export type IStingerMsgQueryParams = IDataGridSearchSort & {
  event_id?: number;
  season_id?: number;
};

const getStingerMgsList = (
  company_id: number,
  params: IStingerMsgQueryParams,
) => {
  return axiosInstance
    .get(`v1/company/${company_id}/message/stinger-message`, {
      params,
    })
    .then((response: AxiosResponse<IApiPaginationResponse<IMessage[]>>) => {
      return response.data;
    });
};

// show stinger.
export type IGetStingerMessageRequestResponse = {
  company: ICompany;
  expire_date: string;
  name: string;
  id: number;
  events?: IEventType[];
  is_all_seasons: number;
  seasons?: ISeason[];
  is_expire_date_set: number;
  message?: IMessage;
  schools?: ICompanySchool[];
  stations?: IStation[];
  created_at: string;
  created_by: IUserBase;
  status: string;
};

export const viewStingerMessagesApi = (
  companyId: number,
  requestId: number,
) => {
  return `/v1/company/${companyId}/message/stinger-message/${requestId}`;
};

const getViewStingerMessages = (companyId: number, requestId: number) => {
  return apiGet(viewStingerMessagesApi(companyId, requestId)).then(
    (
      response: AxiosResponse<
        IApiDataResponse<IGetStingerMessageRequestResponse>
      >,
    ) => {
      return response.data.data;
    },
  );
};
export const useRqViewStingerListMessagesByRequestID = (
  companyId: number,
  requestId: number,
  enabled: boolean = true,
) => {
  const enableRender = companyId > 0 && requestId > 0 ? enabled : false;
  return useQuery({
    queryKey: [viewStingerMessagesApi(companyId, requestId)],
    queryFn: () => getViewStingerMessages(companyId, requestId),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: enableRender,
  });
};
