import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IApiDataResponse} from '../../dto/API.dto';
import axiosInstance from '../../axiosConfig';
import {IMusicGenre} from '../../dto/MusicGenre.dto';
import {IUser, IUserBlackListProp} from '../../dto/User.dto';

// Get Default custom-genres list.
export const getProductDefaultGenreApiPath = (product: number) => {
  return `v1/product/${product}/genres`;
};

const getProductDefaultGenreList = (product: number) => {
  return axiosInstance
    .get(getProductDefaultGenreApiPath(product))
    .then((response: AxiosResponse<IApiDataResponse<IMusicGenre[]>>) => {
      return response.data.data;
    });
};

export const useRqProductDefaultGenre = (
  product: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getProductDefaultGenreApiPath(product)],
    queryFn: () => getProductDefaultGenreList(product),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(product) && enabled,
  });
};

// Partner Custom Genre list.
export type IShowHideGenreProp = {
  id: number;
  super_genre_id: number;
  name: string;
  pivot: {genre_id: number; is_visible: number; order: number};
};
export type IPartnerGenreDetailsProp = {
  id: number;
  genres?: IShowHideGenreProp[];
  updated_by: IUser;
  update_at: string;
};
export const getPartnerGenreApiPath = (companyId: number) => {
  return `v1/company/${companyId}/genre-management`;
};

const getPartnerGenreList = (companyId: number) => {
  return axiosInstance
    .get(getPartnerGenreApiPath(companyId))
    .then(
      (response: AxiosResponse<IApiDataResponse<IPartnerGenreDetailsProp>>) => {
        return response.data.data;
      },
    );
};

export const useRqPartnerGenreDetails = (
  companyId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getPartnerGenreApiPath(companyId)],
    queryFn: () => getPartnerGenreList(companyId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(companyId) && enabled,
  });
};

// Get partner selected genre list or default genre list based on the product.
export const getPartnerOrDefaultGenresApiPath = (companyId: number) => {
  return `v1/company/${companyId}/genres`;
};

const getPartnerOrDefaultGenresList = (companyId: number) => {
  return axiosInstance
    .get(getPartnerOrDefaultGenresApiPath(companyId))
    .then((response: AxiosResponse<IApiDataResponse<IMusicGenre[]>>) => {
      return response.data.data;
    });
};

export const useRqPartnerOrDefaultGenres = (
  companyId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getPartnerOrDefaultGenresApiPath(companyId)],
    queryFn: () => getPartnerOrDefaultGenresList(companyId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(companyId) && enabled,
  });
};

// Get voice personnel list based on the product.
export type IProductMappedVoicePersonnel = {
  id: number;
  svp_status: boolean;
  djvp_status: boolean;
  lmvp_status: boolean;
  voice_over_status: boolean;
  user: IUserBlackListProp;
};
export const getVoicePersonnelMappedWithProductApiPath = (
  productId: number,
) => {
  return `v1/global-content/product/${productId}/voice-personnel`;
};

const getVoicePersonnelMappedWithProductList = (productId: number) => {
  return axiosInstance
    .get(getVoicePersonnelMappedWithProductApiPath(productId))
    .then(
      (
        response: AxiosResponse<
          IApiDataResponse<IProductMappedVoicePersonnel[]>
        >,
      ) => {
        return response.data.data;
      },
    );
};

export const useRqVoicePersonnelMappedWithProduct = (
  productId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getVoicePersonnelMappedWithProductApiPath(productId)],
    queryFn: () => getVoicePersonnelMappedWithProductList(productId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(productId) && enabled,
  });
};
