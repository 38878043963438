import dayjs, {Dayjs} from 'dayjs';
import {DateRange} from '@mui/lab';
import {Add} from '@mui/icons-material';
import {useSelector} from 'react-redux';
import {useCallback, useMemo, useState} from 'react';
import {IconButton, ListItem} from '@mui/material';
import {DatePicker, DateRangePicker} from '@mui/x-date-pickers-pro';
import CustomCalIcon from '../../../../../asset/icons/icon_calendar_gray.svg';
import {isInternalSelector} from '../../../../../store/Slices/userSlice';
import {iTimedMsgDateRange} from '../TimedMessageRequest';

const TimedMsgDateRange = ({
  isOneDay,
  dates,
  onChangeDates,
}: {
  isOneDay: boolean;
  dates: iTimedMsgDateRange[];
  onChangeDates: (value: React.SetStateAction<iTimedMsgDateRange[]>) => void;
}) => {
  const isStaff = useSelector(isInternalSelector);
  const minDate = isStaff ? dayjs() : dayjs().add(1, 'day');

  const [datesState, setDatesState] = useState<DateRange<Dayjs | null>>([
    null,
    null,
  ]);

  const handleOnChange = useCallback((dates: DateRange<Dayjs | null>) => {
    setDatesState(dates);
  }, []);

  const handleSingleDate = useCallback((val: Dayjs | null) => {
    setDatesState([val, val]);
  }, []);

  const handleAdd = () => {
    onChangeDates(prevDateRanges => {
      if (!prevDateRanges[0]?.start_date && !prevDateRanges[0]?.end_date) {
        return [
          {id: Date.now(), start_date: datesState[0], end_date: datesState[1]},
        ];
      }
      return [
        {id: Date.now(), start_date: datesState[0], end_date: datesState[1]},
        ...prevDateRanges,
      ];
    });
    setDatesState([null, null]);
  };
  function MuiIcon() {
    return (
      <img src={CustomCalIcon} alt='Date picker opening icon' width={23} />
    );
  }

  const disableAdd = useMemo(() => {
    if (datesState.some(d => !d)) {
      return true;
    } else if (
      dates.every(o => o.start_date && o.end_date) &&
      dates.some(
        o =>
          (o.start_date &&
            o.start_date.isBetween(datesState[0], datesState[1], 'd', '[]')) ||
          (o.end_date &&
            o.end_date.isBetween(datesState[0], datesState[1], 'd', '[]')) ||
          datesState[0]?.isBetween(o.start_date, o.end_date, 'd', '[]') ||
          datesState[1]?.isBetween(o.start_date, o.end_date, 'd', '[]'),
      )
    ) {
      return true;
    }
    return false;
  }, [dates, datesState]);

  return (
    <ListItem disableGutters>
      {isOneDay ? (
        <DatePicker
          className='w-175'
          disablePast
          minDate={minDate}
          label='Date'
          value={datesState[0]}
          slots={{openPickerIcon: MuiIcon}}
          onChange={handleSingleDate}
          slotProps={{
            textField: {size: 'small'},
            calendarHeader: {className: 'month-year'},
          }}
        />
      ) : (
        <DateRangePicker
          className='max-w-320'
          disablePast
          localeText={{start: 'Start Date', end: 'End Date'}}
          value={datesState}
          onChange={handleOnChange}
        />
      )}

      <IconButton
        className='ml-4 w-40 h-40 font-bold radius-5'
        sx={{
          background: `var(--blue-variant10)`,
          border: `1px solid var(--blue_variant40)`,
        }}
        aria-label='add-time'
        disabled={disableAdd}
        onClick={handleAdd}>
        <Add />
      </IconButton>
    </ListItem>
  );
};

export default TimedMsgDateRange;
