import * as React from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {useSelector} from 'react-redux';
import updateLocale from 'dayjs/plugin/updateLocale';
import {PickersDayProps} from '@mui/x-date-pickers/PickersDay';
import {DateCalendar} from '@mui/x-date-pickers/DateCalendar';
import {Box, Card, CardContent, Typography} from '@mui/material';
import {DayCalendarSlotsComponentsProps} from '@mui/x-date-pickers/internals';
import CalenderSkeleton from '../../../components/skeleton/CalenderSkeleton';
import {useStylesed} from '../../../components/styles/commonStyled';
import {useTimedMessageCalendar} from '../../../react_query/messages/timed-messages/TimedMsgCalendar';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import {ITimedMessageRequest} from '../../../dto/timed-message/TimedMessageRequest.dto';
import {ITimedRequestDateRange} from '../../../dto/timed-message/TimedRequestDateRange.dto';
import {ITimedRequestDayTime} from '../../../dto/timed-message/TimedRequestDayTime.dto';
import {GetDatesArrayBetweenDates} from '../../../components/utlis/date-time/GetDatesArrayBetweenDates';

dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  weekStart: 1,
  weekdaysMin: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
});
const today = dayjs();

type ServerDayProps = PickersDayProps<Dayjs> & {
  dates?: iTimedMonthType[];
  selectedDate?: Dayjs;
  setSelectedDate?: (date: Dayjs | null) => void;
};

type ICardStyles = {
  m: number;
  width: number;
  height: number;
  cursor?: string;
  background?: string;
  border?: string;
  color?: string;
};

const styles: ICardStyles = {
  m: 1,
  width: 127,
  height: 80,
  border: `1px solid var(--gray-varaint19)`,
  cursor: 'pointer',
};

function ServerDay(props: ServerDayProps) {
  const classes = useStylesed();
  const {day, dates, selectedDate, setSelectedDate, outsideCurrentMonth} =
    props;

  const currentDateMessage = React.useMemo(
    () => dates?.find(o => day.isSame(o?.date, 'day')),
    [day, dates],
  );

  const messagesLength = Number(currentDateMessage?.timed_messages?.length);

  const handleSchedule = React.useCallback(() => {
    setSelectedDate?.(day);
  }, [day, setSelectedDate]);

  if (selectedDate?.isSame(day, 'day')) {
    styles.background = `var(--blue-variant1)`;
    styles.color = `var(--text-white)`;
  } else if (
    messagesLength &&
    (day.isAfter(today) || day.isSame(today, 'day'))
  ) {
    styles.color = `var(--text-white)`;
    styles.background = `#1e3262`;
  } else {
    styles.color = `var(--text-white-50)`;
    styles.background = `var(--gray-varaint18)`;
  }

  if (outsideCurrentMonth) {
    return (
      <Card
        sx={{
          ...styles,
          background: `var(--gray-varaint18)`,
        }}
      />
    );
  }

  return (
    <Card
      sx={styles}
      elevation={0}
      className='flex items-center justify-center'
      onClick={handleSchedule}>
      <CardContent className={`items-center p-1`}>
        <Box className={`${classes.c_center}`}>
          <Typography variant='h5' className='mb-2'>
            {day.format('DD')}
          </Typography>
          {messagesLength ? (
            <Typography
              noWrap
              variant='caption'
              className='max-w-180 text-center text-12 items-center'>
              Timed Message ({messagesLength})
            </Typography>
          ) : (
            <Typography
              noWrap
              variant='caption'
              className='max-w-180 text-center text-12 items-center'>
              Timed Message (0)
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

type ITimedMsgCalendarResponse = ITimedMessageRequest & {
  date_ranges: ITimedRequestDateRange[];
  day_times: ITimedRequestDayTime[];
};

const getDayOfWeek = (date: Dayjs) => dayjs(date).format('dddd').toLowerCase();

const getFilteredDates = (
  input: ITimedMsgCalendarResponse,
  end_date: Dayjs,
) => {
  let weekDays: string[] = [];
  for (const weeks of input.day_times) {
    weekDays = [...weekDays, ...weeks.week_days];
  }

  const dateRanges = input.date_ranges;
  let dates: string[] = [];

  dateRanges.forEach(range => {
    let currentDate = dayjs(range.start_date);
    const endDate = dayjs(range.end_date).isAfter(end_date)
      ? end_date
      : dayjs(range.end_date);

    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
      const dayOfWeek = getDayOfWeek(currentDate);
      if (weekDays.includes(dayOfWeek)) {
        dates.push(currentDate.format('YYYY-MM-DD'));
      }
      currentDate = currentDate.add(1, 'day');
    }
  });

  const uniqueDates = new Set(dates);

  return [...uniqueDates];
};

type iTimedMonthType = {
  date: dayjs.Dayjs;
  timed_messages: number[];
};

export default function TimedMsgCalender({
  selectedDate,
  setSelectedDate,
  setSelectedIds,
}: {
  selectedDate: Dayjs;
  setSelectedDate: (val: Dayjs) => void;
  setSelectedIds: (val: number[]) => void;
}) {
  const companyId = useSelector(companyIdSelector);
  const [calendarWidth, setCalendarWidth] = React.useState(0);
  const calendarRef = React.useRef<HTMLDivElement>(null);

  const handleChange = React.useCallback(
    (date: Dayjs | null) => {
      if (date) {
        setSelectedDate(date);
        setSelectedIds([]);
      }
    },
    [setSelectedDate, setSelectedIds],
  );

  const {data, isFetching} = useTimedMessageCalendar(companyId, {
    from: selectedDate.startOf('month').format('YYYY-MM-DD'),
    to: selectedDate.endOf('month').format('YYYY-MM-DD'),
  });

  const dates = React.useMemo(() => {
    const monthDates = GetDatesArrayBetweenDates(
      selectedDate.startOf('month'),
      selectedDate.endOf('month'),
    );
    let dateRange: iTimedMonthType[] = monthDates.map(date => ({
      date,
      timed_messages: [],
    }));

    if (data && !isFetching) {
      data.forEach(timedMessage => {
        const availableDates = getFilteredDates(
          timedMessage,
          selectedDate.endOf('month'),
        );
        dateRange = [
          ...dateRange.map(o => {
            if (availableDates.includes(o.date.format('YYYY-MM-DD'))) {
              return {
                ...o,
                timed_messages: [...o.timed_messages, timedMessage.id],
              };
            }
            return o;
          }),
        ];
      });
    }
    return dateRange;
  }, [selectedDate, data, isFetching]);

  React.useEffect(() => {
    if (calendarRef.current) {
      setCalendarWidth(calendarRef.current.offsetWidth);
    }
  }, []);

  React.useEffect(() => {
    if (data && !isFetching) {
      const pickedDate = dates.find(o => o.date.isSame(selectedDate, 'd'));
      if (pickedDate) {
        setSelectedIds(pickedDate.timed_messages);
      }
    }
  }, [data, dates, isFetching, selectedDate, setSelectedIds]);

  return (
    <div ref={calendarRef} id='date-value'>
      <DateCalendar
        className='calendar-header'
        sx={{
          '&.MuiDateCalendar-root': {
            width: '100%',
            height: 'auto',
            maxHeight: '100%',
          },
          '& .MuiDayCalendar-header': {
            justifyContent: 'space-around',
          },
          '& .MuiPickersSlideTransition-root': {
            height: '532px',
          },
          '& .MuiPaper-root': {
            width: '100% !important',
            height: '72px',
          },
        }}
        maxDate={dayjs('2100-1-1')}
        minDate={dayjs('2023-1-1')}
        onChange={handleChange}
        onMonthChange={handleChange}
        value={selectedDate}
        dayOfWeekFormatter={day => {
          return day;
        }}
        loading={isFetching}
        renderLoading={() => <CalenderSkeleton width={calendarWidth} />}
        slots={{
          day: ServerDay,
        }}
        slotProps={
          {
            day: {
              dates,
              selectedDate,
              setSelectedDate,
              setSelectedIds,
            },
          } as DayCalendarSlotsComponentsProps<Dayjs>
        }
      />
    </div>
  );
}
