import {IconButton} from '@mui/material';
import IconAdd from '../../../asset/icons/icon_plus_blue.svg';

const MessageAddButton = ({
  isValid,
  onClick,
}: {
  isValid: boolean;
  onClick: () => void;
}) => {
  return (
    <IconButton
      onClick={onClick}
      id='add-button'
      color='primary'
      size='large'
      disabled={!isValid}
      disableFocusRipple>
      <img src={IconAdd} width='20' height='20' alt='Add-event-message' />
    </IconButton>
  );
};

export default MessageAddButton;
