import {makeStyles} from '@mui/styles';

export const spacingStyles = makeStyles(theme => ({
  '@global': {
    '.mainsec': {
      height: 'calc(100vh - 92px)',
    },
    '.leftbar': {
      maxWidth: '315px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '115px',
      },
    },
    '@media screen and (max-height: 605px)': {
      '.staffnav-height': {
        maxHeight: '250px',
      },
    },
    '.tasklist': {
      height: 'calc(100% - 75px) !important',
    },
    '.rightbar': {
      width: 'calc(100% - 315px)',
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 115px)',
      },
    },
    '.partner-list': {
      height: 'calc(100% - 300px)',
    },
    '.navbar-brand': {
      maxWidth: '170px',
    },
    '.m-auto': {margin: 'auto !important'},
    '.ml-auto': {marginLeft: 'auto !important'},
    '.mx-auto': {
      marginLeft: 'auto !important',
      marginRight: 'auto !important',
    },

    '.h-full-lg': {
      height: '100%',
      [theme.breakpoints.down('lg')]: {
        height: '50% !important',
      },
    },
    '.w-full': {width: '100%'},
    '.w-p-25': {width: '25%'},
    '.w-p-50': {width: '50%'},
    '.w-p-75': {width: '75%'},
    '.max-w-full': {maxWidth: '100% !important'},
    '.w-auto': {width: 'auto !important'},
    '.min-w-full': {minWidth: '100% !important'},
    '.min-w-auto': {minWidth: 'auto !important'},
    '.min-w-full-screen': {minWidth: '100vw !important'},
    '.w-full-screen': {width: '100vw'},
    '.h-full': {height: '100% !important'},
    '.min-h-full': {minHeight: '100% !important'},
    '.h-auto': {height: 'auto !important'},
    '.h-full-screen': {height: '100vh'},
    '.min-h-full-screen': {minHeight: '100vh !important'},
    '.w-150': {width: '150px !important'},
    '.h-150px': {height: '150px !important'},
    '.h-200px': {height: '200px !important'},
    '.h-40px': {height: '40px !important'},
    '.max-h-300px': {maxHeight: '300px !important'},
    '.max-w-150': {maxWidth: '150px'},
    '.h-174': {height: '174px !important'},
    '.size-36': {height: '36px !important', width: '36px !important'},
    '.size-24': {height: '24px !important', width: '24px !important'},
    '.max-w-140': {maxWidth: '140px !important'},
    '.top-0': {top: '0'},
    '.right-0': {right: '0'},

    ...generateMarginPadding(0, 25, 0.25, 'rem'),
    ...generateMarginPadding(1, 16, 1, 'px'),
    ...generateMarginPadding(-25, -1, 1, 'px'),
    ...generateMarginPadding(-16, -1, 1, 'px'),
    ...generateHeightWidh(1, 1000, 1, 'px'),
  },
}));

const generateMarginPadding = (
  start = 0,
  end = 25,
  increament = 0.25,
  unit = 'rem',
) => {
  let classList = {};

  for (let i = start; i <= end; i++) {
    classList[`.m-${i}${unit === 'px' ? 'px' : ''}`] = {
      margin: `${i * increament}${unit} !important`,
    };
    classList[`.mt-${i}${unit === 'px' ? 'px' : ''}`] = {
      marginTop: `${i * increament}${unit} !important`,
    };
    classList[`.mb-${i}${unit === 'px' ? 'px' : ''}`] = {
      marginBottom: `${i * increament}${unit} !important`,
    };
    classList[`.mr-${i}${unit === 'px' ? 'px' : ''}`] = {
      marginRight: `${i * increament}${unit} !important`,
    };
    classList[`.ml-${i}${unit === 'px' ? 'px' : ''}`] = {
      marginLeft: `${i * increament}${unit} !important`,
    };
    classList[`.mx-${i}${unit === 'px' ? 'px' : ''}`] = {
      marginLeft: `${i * increament}${unit} !important`,
      marginRight: `${i * increament}${unit} !important`,
    };
    classList[`.my-${i}${unit === 'px' ? 'px' : ''}`] = {
      marginTop: `${i * increament}${unit} !important`,
      marginBottom: `${i * increament}${unit} !important`,
    };

    classList[`.p-${i}${unit === 'px' ? 'px' : ''}`] = {
      padding: `${i * increament}${unit} !important`,
    };
    classList[`.pt-${i}${unit === 'px' ? 'px' : ''}`] = {
      paddingTop: `${i * increament}${unit} !important`,
    };
    classList[`.pb-${i}${unit === 'px' ? 'px' : ''}`] = {
      paddingBottom: `${i * increament}${unit} !important`,
    };
    classList[`.pr-${i}${unit === 'px' ? 'px' : ''}`] = {
      paddingRight: `${i * increament}${unit} !important`,
    };
    classList[`.pl-${i}${unit === 'px' ? 'px' : ''}`] = {
      paddingLeft: `${i * increament}${unit} !important`,
    };
    classList[`.px-${i}${unit === 'px' ? 'px' : ''}`] = {
      paddingLeft: `${i * increament}${unit} !important`,
      paddingRight: `${i * increament}${unit} !important`,
    };
    classList[`.py-${i}${unit === 'px' ? 'px' : ''}`] = {
      paddingTop: `${i * increament}${unit} !important`,
      paddingBottom: `${i * increament}${unit} !important`,
    };
  }

  return classList;
};

const generateHeightWidh = (
  start = 0,
  end = 400,
  increament = 4,
  unit = 'px',
) => {
  let classList = {};

  for (let i = start; i <= end; i += increament) {
    classList[`.w-${i}`] = {
      width: `${i}${unit} !important`,
    };
    classList[`.min-w-${i}`] = {
      minWidth: `${i}${unit} !important`,
    };
    classList[`.max-w-${i}`] = {
      maxWidth: `${i}${unit} !important`,
    };
    classList[`.h-${i}`] = {
      height: `${i}${unit} !important`,
    };
    classList[`.min-h-${i}`] = {
      minHeight: `${i}${unit} !important`,
    };
    classList[`.max-h-${i}`] = {
      maxHeight: `${i}${unit} !important`,
    };
    classList[`.calcheight-${i}`] = {
      height: `calc(100% - ${i}${unit}) !important`,
    };
  }

  return classList;
};
