import {useSelector} from 'react-redux';
import {Box, Typography} from '@mui/material';
import {IMediaTrack} from '../../../../dto/MediaTrack.dto';
import {isInternalSelector} from '../../../../store/Slices/userSlice';
import {companyIdSelector} from '../../../../store/Slices/companySlice';
import ExistingCampaignChooserBtn from '../../../sponsor/ExistingCampaignChooserBtn';
import {useStoreSelector} from '../../../../store/configstore';

const BaseSeasonAndTracksAdd = ({
  title,
  selectedTracks,
  setSelectedTracks,
  trackLimit,
}: {
  trackLimit: number;
  title: string;
  selectedTracks?: IMediaTrack[];
  setSelectedTracks?: React.Dispatch<React.SetStateAction<IMediaTrack[]>>;
}) => {
  const isStaff = useSelector(isInternalSelector);
  const companyId = useStoreSelector(companyIdSelector);

  return (
    <Box className='w-full flex flex-row justify-between mb-2'>
      <Typography className='text-white text-18'>{title}</Typography>
      <Box className='flex'>
        {isStaff && selectedTracks && setSelectedTracks ? (
          <ExistingCampaignChooserBtn
            tracks={selectedTracks}
            onChange={setSelectedTracks}
            type='base-message'
            label='Choose Base Message'
            max={trackLimit}
            companyId={companyId}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default BaseSeasonAndTracksAdd;
