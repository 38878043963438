import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, {useCallback, useState} from 'react';
import {ICompanyDetails} from '../../../../dto/Company.dto';
import {IStationType} from '../../../../dto/Station.dto';
import {IService} from '../../../../dto/Service.dto';
import IconAddButton from '../../../../components/Button/IconAddButton';
import {
  ILevelStationDetails,
  IStationWithIsDelete,
  removeUnwantedCharacters,
} from './AddOnCmpt';
import {DialogClose} from '../../../../components/dialogs/DialogFrame/DialogClose';

export const AddNewStationDialog = ({
  company,
  isAddDisabled,
  stationLevels,
  levelStationDetails,
  setLevelStationDetails,
}: {
  company: ICompanyDetails;
  isAddDisabled: boolean;
  stationLevels: IService[];
  levelStationDetails: ILevelStationDetails;
  setLevelStationDetails: React.Dispatch<
    React.SetStateAction<ILevelStationDetails>
  >;
}) => {
  const [newStation, setNewStation] = useState<IStationWithIsDelete>({
    id: -1 * new Date().getTime(),
    parent_id: null,
    company_id: 67491,
    player_update_disabled_at: null,
    name: `${removeUnwantedCharacters(company?.name)} ${
      levelStationDetails.stations ? levelStationDetails.stations.length + 1 : 2
    }`,
    label: '',
    type: '',
    is_deleted: false,
    deleted_at: '',
    previous_type: '',
  } as IStationWithIsDelete);
  const [addOnDialog, setAddOnDialog] = useState(false);

  const onClose = useCallback(() => {
    setAddOnDialog(false);
  }, [setAddOnDialog]);

  const handleAddStation = useCallback(() => {
    setLevelStationDetails(prev => {
      const newStations = prev.stations;
      newStations.push(newStation);
      return {...prev, stations: newStations};
    });
    onClose();
  }, [setLevelStationDetails, onClose, newStation]);

  const handleLabelChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setNewStation(prev => {
        return {...prev, label: value.trimLeft()};
      });
    },
    [setNewStation],
  );

  const handleStationLevelChecked = useCallback(
    (value: IStationType) => {
      setNewStation(prev => {
        return {...prev, type: value};
      });
    },
    [setNewStation],
  );

  const handleDialogOpen = useCallback(() => {
    setAddOnDialog(true);
    setNewStation({
      id: -1 * new Date().getTime(),
      parent_id: null,
      company_id: 67491,
      player_update_disabled_at: null,
      name: `${removeUnwantedCharacters(company?.name)} ${
        levelStationDetails.stations
          ? levelStationDetails.stations.length + 1
          : 2
      }`,
      label: '',
      type: company.product.cmc_product === 'SchoolZone' ? 'full_station' : '',
      is_deleted: false,
      deleted_at: '',
      previous_type:
        company.product.cmc_product === 'SchoolZone' ? 'full_station' : '',
    } as IStationWithIsDelete);
  }, [
    setAddOnDialog,
    company?.name,
    company.product.cmc_product,
    levelStationDetails.stations,
  ]);

  return (
    <>
      <IconAddButton onClick={handleDialogOpen} disabled={isAddDisabled} />
      <Dialog
        maxWidth='sm'
        open={addOnDialog}
        aria-labelledby='custom-modal-title'
        aria-describedby='custom-modal-description'
        fullWidth>
        <DialogTitle>
          <Typography>Add a new station</Typography>
          <DialogClose onClose={onClose} />
        </DialogTitle>

        <Divider />
        <DialogContent sx={{boxShadow: 'none'}}>
          <Box id='custom-modal-description' mt={2}>
            <Typography>Station Name</Typography>
            <TextField
              id='demo-helper-text-aligned-no-helper'
              size='small'
              fullWidth
              value={newStation.name}
              disabled
            />
            <Typography>Label Name</Typography>
            <TextField
              id='demo-helper-text-aligned-no-helper'
              size='small'
              onChange={handleLabelChange}
              fullWidth
              value={newStation.label}
            />
            <Stack direction={'row'} justifyContent={'center'} mt={2}>
              {stationLevels.map(val => {
                if (
                  (company.product.cmc_product === 'SchoolZone' &&
                    val.name === 'full_station') ||
                  company.product.cmc_product !== 'SchoolZone'
                ) {
                  return (
                    <FormControlLabel
                      key={val.id}
                      control={
                        <Checkbox
                          checked={newStation.type === val.name}
                          onChange={() =>
                            handleStationLevelChecked(
                              (val.name as IStationType) ?? '',
                            )
                          }
                        />
                      }
                      label={
                        <Typography>
                          {val.name === 'full_station'
                            ? 'FULL'
                            : val.name === 'odo_station'
                            ? 'ODO'
                            : 'MIRROR'}
                        </Typography>
                      }
                    />
                  );
                }
                return null;
              })}
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions className='flex justify-center mb-5'>
          <Button variant='outlined' onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={handleAddStation}
            disabled={newStation.type.length === 0}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
