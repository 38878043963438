import {ChangeEvent, Dispatch, SetStateAction, useCallback} from 'react';
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MusicPreviewBtn from '../../../../components/music/MusicPreviewBtn';
import {useRqVoiceArtists} from '../../../../react_query/users/CompanyContacts';
import {useStoreSelector} from '../../../../store/configstore';
import {
  companyIdSelector,
  productLevelSelector,
} from '../../../../store/Slices/companySlice';
import {useSelector} from 'react-redux';
import {CompanyDetailsList} from '../../../../components/onboarding/CompanyDetailsList';
type Props = {
  voice: string[];
  setVoice: Dispatch<SetStateAction<string[]>>;
  setVoiceName: Dispatch<SetStateAction<string[]>>;
  taskCompanyId?: number;
  singleArtist?: boolean;
};
const BaseVoiceArtistSelection = ({
  voice,
  setVoice,
  setVoiceName,
  taskCompanyId,
  singleArtist,
}: Props) => {
  const partnerId = useStoreSelector(companyIdSelector);
  const companyId =
    taskCompanyId && taskCompanyId !== 0 ? taskCompanyId : partnerId;
  const productLevel = useSelector(productLevelSelector);
  const isLevel1 =
    singleArtist && singleArtist === true
      ? singleArtist
      : productLevel.includes('Level 1');
  const {data} = useRqVoiceArtists(companyId, {type: 'djvp'});

  const handleVoice = useCallback(
    ({
      target: {value: id, checked: isChecked},
    }: ChangeEvent<HTMLInputElement>) => {
      setVoice(prevIds => {
        if (prevIds.some(prevId => prevId === id)) {
          return prevIds.filter(prevId => prevId !== id);
        }
        if (isLevel1 && prevIds.length < 1) {
          return [...prevIds, id];
        } else if (!isLevel1 && prevIds.length < 3) {
          return [...prevIds, id];
        }

        return prevIds;
      });
      const matchingData = data?.find(item => item.id === Number(id));
      if (matchingData) {
        setVoiceName(prevNames =>
          isChecked
            ? [...prevNames, matchingData.name]
            : prevNames.filter(prevName => prevName !== matchingData.name),
        );
      }
    },
    [setVoice, data, isLevel1, setVoiceName],
  );

  return (
    <Box className='w-full h-full flex flex-column justify-start'>
      {taskCompanyId ? (
        <CompanyDetailsList
          company_id={taskCompanyId}
          taskSlug='create_dj_stinger_message'
          className='max-h-184 overflow-auto scrollbar'
        />
      ) : null}
      <Typography className='text-white text-18'>
        Select Voice Artist
      </Typography>
      <Box className='w-full overflow-auto mt-5'>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Voice Artist</TableCell>
                <TableCell align='center'>Play</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map(val => (
                <TableRow key={val.id}>
                  <TableCell>
                    <Checkbox
                      tabIndex={-1}
                      onChange={handleVoice}
                      value={val.id}
                      checked={voice.some(
                        selectedItem => selectedItem === String(val.id),
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography className='text-16 text-white'>
                      {val.name}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <MusicPreviewBtn
                      id={val.id}
                      url={val.sample_url}></MusicPreviewBtn>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
export default BaseVoiceArtistSelection;
