import {Dispatch, SetStateAction, useCallback, useMemo} from 'react';
import {Grid, List, Stack, Typography} from '@mui/material';
import {
  DragDropContext,
  DropResult,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import TimedMsgRequestContentMessageCard, {
  iTimedMessageMessageItem,
} from './TimedMsgRequestContentMessageCard';
import TimedMsgRequestSequenceBuilderListItem from './TimedMsgRequestSequenceBuilderListItem';
import CreateButton from '../../../../../components/Button/CreateButton';
import MsgAddIcon from '../../../../../asset/icons/addmsg.svg';
import MusicAddIcon from '../../../../../asset/icons/icon_addsongs.svg';
import TimedMsgRequestContentSongCard from './TimedMsgRequestContentSongCard';

type Props = {
  selectedItem?: number;
  setSelectedItem?: (val: number) => void;
  items: iTimedMessageMessageItem[];
  onChange: Dispatch<SetStateAction<iTimedMessageMessageItem[]>>;
  type?: string;
  handleChange: (newMessage: iTimedMessageMessageItem) => void;
};
const getListStyle = (isDraggingOver: boolean) => ({
  borderRadius: '4px',
  background: `rgba(255,255,255, ${isDraggingOver ? '.1' : '.05'})`,
  padding: 8,
  height: 300,
  overflow: 'auto',
});
const MAX_SEQUENCE_ITEMS = 20;
export const reArrangeSequenceOrder = (
  items: iTimedMessageMessageItem[],
  message_type?: string,
) => {
  let messageIndex = 0;
  let songIndex = 0;
  return items.map(item => {
    let label;
    if (item.type === 'message') {
      messageIndex++;
      label = `Message ${message_type === 'sequence' ? messageIndex : ''}`;
    } else if (item.type === 'song') {
      songIndex++;
      label = `Song ${message_type === 'sequence' ? songIndex : ''}`;
    }
    return {
      ...item,
      label,
    };
  });
};
const TimedMsgRequestSequenceBuilder = ({
  items,
  onChange,
  selectedItem,
  setSelectedItem,
  type,
  handleChange,
}: Props) => {
  const handleAddMessage = useCallback(() => {
    onChange(prevItems => {
      const messageIndex =
        prevItems.filter(item => item.type === 'message').length + 1;
      return [
        ...prevItems,
        {
          id: -1 * Date.now(),
          isNew: true,
          type: 'message',
          message: '',
          message_name: '',
          artist: '',
          title: '',
          label: `Message ${messageIndex}`,
        },
      ];
    });
  }, [onChange]);

  const handleAddSong = useCallback(() => {
    onChange(prevItems => {
      const songIndex =
        prevItems.filter(item => item.type === 'song').length + 1;
      return [
        ...prevItems,
        {
          id: -1 * Date.now(),
          isNew: true,
          type: 'song',
          message: '',
          message_name: '',
          artist: '',
          title: '',
          label: `Song ${songIndex}`,
        },
      ];
    });
  }, [onChange]);
  const handleRemove = useCallback(
    (id: number) => {
      onChange(prevItems => {
        const newItems = [...prevItems];
        const index = newItems.findIndex(o => o.id === id);
        if (index !== -1) {
          newItems.splice(index, 1);
        }
        return reArrangeSequenceOrder(newItems);
      });
    },
    [onChange],
  );
  const onDragEnd = useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (result.destination) {
        onChange(prevItems => {
          const newItems = Array.from(prevItems);
          const [removed] = newItems.splice(result.source.index, 1);
          if (removed && result.destination) {
            newItems.splice(result.destination.index, 0, removed);
          }
          return reArrangeSequenceOrder(newItems);
        });
      }
    },
    [onChange],
  );
  const content = useMemo(() => {
    if (type === 'sequence') {
      return items.filter(o => o.id === selectedItem);
    }
    return items;
  }, [items, selectedItem, type]);

  let messageIndex = 0;
  let songIndex = 0;

  const labeledItems = items.map(item => {
    let label;
    if (item.type === 'message') {
      messageIndex++;
      label = `Message ${messageIndex}`;
    } else if (item.type === 'song') {
      songIndex++;
      label = `Song ${songIndex}`;
    }
    return {...item, label};
  });

  return (
    <Grid container className='w-full'>
      <Grid item xs={12}>
        <Stack spacing={2} direction='row' className='flex py-5'>
          <CreateButton
            title='Add Message'
            onClick={handleAddMessage}
            icon={MsgAddIcon}
            disabled={items.length === MAX_SEQUENCE_ITEMS}
          />

          <CreateButton
            title='Add Song'
            onClick={handleAddSong}
            icon={MusicAddIcon}
            disabled={items.length === MAX_SEQUENCE_ITEMS}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption' component='p' className='pb-2'>
          Please use drag & drop to change the order
        </Typography>
        <Grid container>
          <Grid item xs={3}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable'>
                {(
                  provided: DroppableProvided,
                  snapshot: DroppableStateSnapshot,
                ) => (
                  <List
                    className='bg-transparent p-0 mr-5'
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    dense
                    id='ordered-list'
                    style={getListStyle(snapshot.isDraggingOver)}>
                    {labeledItems.map((item, index) => (
                      <TimedMsgRequestSequenceBuilderListItem
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        key={item.id}
                        item={item}
                        onRemove={handleRemove}
                        disableDelete={items.length === 1}
                        index={index}
                        label={item.label}
                      />
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
          <Grid item xs={9}>
            {content.map(message => {
              let clickedData = labeledItems.find(x => x.id === message.id);
              if (clickedData) {
                if (clickedData.type === 'message') {
                  return (
                    <TimedMsgRequestContentMessageCard
                      key={message.id}
                      message={message}
                      setMessages={onChange}
                      onChange={handleChange}
                      type={type}
                      label={clickedData.label}
                    />
                  );
                } else {
                  return (
                    <TimedMsgRequestContentSongCard
                      key={message.id}
                      message={message}
                      onChange={handleChange}
                      type={type}
                      label={clickedData.label}
                    />
                  );
                }
              }
              return null;
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TimedMsgRequestSequenceBuilder;
