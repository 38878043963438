import React, {useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {Badge, Grid, IconButton, Tab, Tabs, Typography} from '@mui/material';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  IMessagesCountData,
  useRqListMessages,
  useRqMessagesCount,
} from '../../react_query/PSM/Psm';
import {IMessageType} from '../../dto/Message.dto';
import {useNavigate} from 'react-router-dom';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface MessagesRequestProps {
  id: number;
  company_id: number;
  company_name: string;
  request_type: string;
  status: string;
  request_status: string;
  event_date_type: number;
  is_all_day: number;
  start_date: Date;
  end_date: Date;
  start_time: string;
  end_time: string;
  created_by: string;
  created_at: Date;
  task_id: number;
}
interface TableObjectProps {
  id: number;
  companyname: string;
  date: string;
  created_at: string;
  taskId: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Typography sx={{padding: 3}}>{children}</Typography>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
type Props = {
  rowsdata: TableObjectProps[];
  columsdata: GridColDef[];
};
const GridTable = ({rowsdata, columsdata}: Props) => {
  return (
    <DataGrid
      rows={rowsdata}
      columns={columsdata}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
      disableColumnFilter
      disableColumnSelector
    />
  );
};
const PsmHomePage = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [type, setType] = React.useState<IMessageType>('event-message');
  const {data, isFetched, isError} = useRqListMessages(type);
  const {
    data: cdata,
    isFetched: cisFetched,
    isError: cisError,
  } = useRqMessagesCount();
  const [content, setContent] = React.useState<TableObjectProps[]>([]);
  const [countData, setCountData] = useState<IMessagesCountData>();

  React.useEffect(() => {
    if (isFetched && !isError) {
      const details: TableObjectProps[] = [];
      data?.data.forEach((values: MessagesRequestProps) => {
        let date;
        if (values.event_date_type === 1) {
          date =
            values.start_date + ' ' + values.start_time + '-' + values.end_time;
        } else if (values.event_date_type === 2) {
          date =
            values.start_date +
            ' ' +
            values.start_time +
            '-' +
            values.end_date +
            ' ' +
            values.end_time;
        } else {
          date = values.start_date + ' ' + values.end_date;
        }
        if (values.task_id) {
          sessionStorage.setItem('taskId', String(values.task_id));
        }
        details.push({
          id: values.id,
          companyname: values.company_name,
          date,
          created_at: dayjs(values.created_at).format('mm-DD-YYYY hh:mm A'),
          taskId: values.task_id,
        });
      });

      setContent(details);
    }
  }, [data, isError, isFetched]);
  React.useEffect(() => {
    sessionStorage.setItem('type', 'event-message');
  }, []);
  React.useEffect(() => {
    if (cisFetched && !cisError) {
      setCountData(cdata?.data);
    }
  }, [cdata, cisError, cisFetched]);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    let type: IMessageType = 'event-message';
    if (newValue === 0) {
      type = 'event-message';
    } else if (newValue === 2) {
      type = 'ondemand-message';
    } else if (newValue === 4) {
      type = 'base-message';
    } else if (newValue === 6) {
      type = 'timed-message';
    }
    setType(type);
    sessionStorage.setItem('type', type);
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        width: 90,
      },

      {
        field: 'companyname',
        headerName: 'Partner Name',
        width: 250,
        editable: true,
      },
      {
        field: 'created_at',
        headerName: 'Submitted At',
        width: 250,
        editable: true,
      },
      {
        field: 'actions',
        headerName: 'Action',
        width: 200,
        renderCell: params => (
          <div>
            <IconButton color='primary'>
              <VisibilityIcon
                onClick={() => {
                  navigate(`/psm/${params.row.id}`);
                }}
              />
            </IconButton>
          </div>
        ),
      },
    ],
    [navigate],
  );
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Tabs value={value} onChange={handleChange} aria-label='tabs' centered>
          <Tab label='Event Specific Message' {...a11yProps(0)} />
          <Badge
            badgeContent={
              countData?.event_specific_message
                ? countData?.event_specific_message
                : '0'
            }
            color='error'
            style={{marginTop: '22px'}}></Badge>
          <Tab label='On-Demand Message' {...a11yProps(1)} />
          <Badge
            badgeContent={
              countData?.ondemand_message ? countData?.ondemand_message : '0'
            }
            color='error'
            style={{marginTop: '22px'}}></Badge>
          <Tab label='Base Message' {...a11yProps(2)} />
          <Badge
            badgeContent={
              countData?.base_message ? countData?.base_message : '0'
            }
            color='error'
            style={{marginTop: '22px'}}></Badge>
          <Tab label='Timed Message' {...a11yProps(3)} />
          <Badge
            badgeContent={
              countData?.timed_message ? countData?.timed_message : '0'
            }
            color='error'
            style={{marginTop: '22px'}}></Badge>
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <CustomTabPanel value={value} index={0}>
          <GridTable rowsdata={content} columsdata={columns} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <GridTable rowsdata={content} columsdata={columns} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <GridTable rowsdata={content} columsdata={columns} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <GridTable rowsdata={content} columsdata={columns} />
        </CustomTabPanel>
      </Grid>
    </Grid>
  );
};
export default PsmHomePage;
