import {Grid, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
  companyIdSelector,
  companyNameSelector,
} from '../../store/Slices/companySlice';
import {isInternalSelector} from '../../store/Slices/userSlice';
import IconBack from '../../asset/icons/icon_back.svg';

interface HeaderProps {
  IconMessage: string;
  Title: string;
  imgClassName?: string;
}

const Header: React.FC<HeaderProps> = ({IconMessage, Title, imgClassName}) => {
  const companyId = useSelector(companyIdSelector);
  const companyName = useSelector(companyNameSelector);
  const isStaff = useSelector(isInternalSelector);
  return (
    <Grid container direction='row' className='h-116 items-center'>
      <Grid item xs={4}>
        <Link to='/' className='flex'>
          <img src={IconBack} className='mr-3' alt='' />
          <Typography component='h6' className='text-white-50 font-bold'>
            Back to Dashboard
          </Typography>
        </Link>
      </Grid>
      <Grid
        item
        xs={4}
        className='text-center flex items-center justify-center'>
        <img
          src={IconMessage}
          className={imgClassName ? imgClassName : 'w-43 h-43 mr-4'}
          alt=''
        />
        <Typography component='h4' className='font-bold text-white'>
          {Title}
        </Typography>
      </Grid>
      <Grid item xs={4} className='text-right'>
        <Typography>
          {companyName}
          {isStaff ? ` (${companyId})` : ''}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Header;
