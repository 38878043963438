import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IEventType} from '../../dto/EventType.dto';
import axiosInstance from '../../axiosConfig';

interface IEventTypeResponse {
  data: IEventType[];
}

export interface IEventsPayload {
  event_search?: string | null;
}

export const getAllSportsEventsApiPath = (company_id?: number) => {
  return company_id ? `/v1/company/${company_id}/event/list` : '/v1/events';
};

const getAllSports = (company_id?: number) => {
  return axiosInstance
    .get(getAllSportsEventsApiPath(company_id))
    .then((response: AxiosResponse<IEventTypeResponse>) => {
      return response.data?.data;
    });
};

export const useRqAllSports = (
  enabled: boolean = true,
  company_id?: number,
) => {
  return useQuery({
    queryKey: [getAllSportsEventsApiPath(company_id)],
    queryFn: () => getAllSports(company_id),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};
