import {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import dayjs, {Dayjs} from 'dayjs';
import {useNavigate} from 'react-router-dom';
import {useQueryClient} from '@tanstack/react-query';
import {
  StepperLayout,
  useStepperState,
} from '../../components/layouts/stepper/StepperLayout';
import {companyIdSelector} from '../../store/Slices/companySlice';
import DialogApiRequestStatus from '../../components/dialogs/DialogApiRequestStatus';
import TnnLogo from '../../asset/images/tnn_logo.png';
import {
  IEventGrouped,
  useRqGroupedEvents,
} from '../../react_query/event_types/List-EventByGroup';
import {
  IParticipationForms,
  IRestrictedSponsors,
  getParticipationFormIdApiPath,
  useRqRestrictedOptions,
} from '../../react_query/tnn-participation-form/ParticipationForm';
import {apiPost} from '../../axiosConfig';
import {RestrictedSponsorshipSelectCmpt} from './ParticipationFormCmpt/RestrictedSponsorshipSelectCmpt';
import {SponsorShipInformationCmpt} from './ParticipationFormCmpt/SponsorShipInformationCmpt';
import {SportsInformationCmpt} from './ParticipationFormCmpt/SportsInformationCmpt';
import {NeptuneRadioEventOptions} from './ParticipationFormCmpt/NeptuneRadioEventOptions';
import {EventSelection} from './ParticipationFormCmpt/EventSelection';

export type ISportInformationProp = {
  id: number;
  name: string;
  no_of_events: number;
  attendence_per_event: number;
};

export type ISponsorShipInformationProp = {
  uuid: string;
  business_name: string;
  contact_name: string;
};
const ParticipationFormStepper = ({
  handleCancel,
  checkedId,
  participationFormDetails,
  setParticipationFormDetails,
  productType,
  isAdminPageFormEdit = false,
}: {
  handleCancel: () => void;
  checkedId: number;
  participationFormDetails: IParticipationForms;
  setParticipationFormDetails?: React.Dispatch<
    React.SetStateAction<IParticipationForms>
  >;
  productType: string;
  isAdminPageFormEdit?: boolean;
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {activeStepIndex, onNextStep, onPreviousStep, onStepClick} =
    useStepperState(isAdminPageFormEdit ? 1 : 0);
  const [apiState, setApiState] = useState({
    status: '',
    error: null,
    message: '',
  });
  let companyId = useSelector(companyIdSelector);
  companyId =
    participationFormDetails && participationFormDetails.company_id
      ? participationFormDetails.company_id
      : companyId;
  const [sports, setSports] = useState<IEventGrouped[]>([]);
  const [restrictedOptions, setRestrictedOptions] = useState<
    IRestrictedSponsors[]
  >([]);
  const [sportInformation, setSportInformation] = useState<
    ISportInformationProp[]
  >([]);
  const [sporsorShipInformation, setSporsorShipInformation] = useState<
    ISponsorShipInformationProp[]
  >([]);
  const [otherCategory, setOtherCategory] = useState('');
  const [otherSport, setOtherSport] = useState('');
  const {data, isFetching, isError} = useRqGroupedEvents(companyId);
  const {
    data: restrictedOptionData,
    isFetching: restrictedOptionIsFetching,
    isError: restrictedOptionIsError,
  } = useRqRestrictedOptions();
  const [selectedSeasonYear, setSelectedSeasonYear] = useState('');
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [totalDays, setTotalDays] = useState<number>(1);
  const [totalYears, setTotalYears] = useState<number>(1);

  const eventSelected = useCallback(() => {
    return sports.some(
      val =>
        val.selected || (val.sub_list && val.sub_list.some(v => v.selected)),
    );
  }, [sports]);

  const eventSeasonDetailsCompleted = useCallback(() => {
    return (
      selectedSeasonYear.length > 0 &&
      totalDays &&
      totalDays > 0 &&
      totalYears &&
      totalYears > 0 &&
      ((selectedSeasonYear === 'Seasonally' && selectedDate) ||
        selectedSeasonYear === 'All Year')
    );
  }, [selectedDate, selectedSeasonYear, totalDays, totalYears]);

  const steps = useMemo(() => {
    const gameTimeStepper = [
      {
        label: 'Create Message',
        disabled: false,
        isReviewDisabled: false,
        virtual: true,
      },
      {
        label: 'Select Sport',
        disabled: false,
        isReviewDisabled: false,
      },
      {
        label: 'Enter Event Information',
        disabled: !eventSelected(),
        isReviewDisabled: false,
      },
      {
        label: 'Enter Sponsorship Details',
        disabled: false,
        isReviewDisabled: false,
      },
      {
        label: 'Restricted sponsorship categories',
        disabled: false,
        isReviewDisabled: false,
      },
    ];
    const neptuneRadioStepper = [
      {
        label: 'Create Message',
        disabled: false,
        isReviewDisabled: false,
        virtual: true,
      },
      {
        label: 'Select Event Options',
        disabled: false,
        isReviewDisabled: false,
      },
      {
        label: 'Restricted sponsorship categories',
        disabled: !eventSeasonDetailsCompleted(),
        isReviewDisabled: false,
      },
    ];

    if (
      Boolean(participationFormDetails.sponsor_option_id) &&
      !isAdminPageFormEdit
    ) {
      gameTimeStepper.push({
        label: 'Info',
        disabled: false,
        isReviewDisabled: false,
      });
      neptuneRadioStepper.push({
        label: 'Info',
        disabled: false,
        isReviewDisabled: false,
      });
    }

    const stepList =
      productType === 'GameTime' ? gameTimeStepper : neptuneRadioStepper;
    return stepList;
  }, [
    eventSelected,
    productType,
    eventSeasonDetailsCompleted,
    participationFormDetails,
    isAdminPageFormEdit,
  ]);

  const handleSubmit = useCallback(() => {
    if (setParticipationFormDetails) {
      setParticipationFormDetails(prev => {
        let finalValue = {...prev};
        finalValue.other_event = otherSport;
        finalValue.other_restricted_sponsorship = otherCategory;
        finalValue.sponsorship_details = sporsorShipInformation.map(
          (val, key) => {
            return {
              id: key,
              contact_name: val.contact_name,
              business_name: val.business_name,
            };
          },
        );
        finalValue.events = sportInformation.map(val => {
          return {
            id: val.id,
            event_name: val.name,
            pivot: {
              no_of_events: val.no_of_events,
              attendence_per_event: val.attendence_per_event,
            },
          };
        });
        if (restrictedOptions) {
          finalValue.restricted_sponsorships = restrictedOptions
            ?.filter(val => val.selected)
            .map(val => {
              return {id: val.id, categories: val.categories};
            });
        }
        if (productType === 'Neptune Radio') {
          finalValue.season_type = selectedSeasonYear;
          if (totalDays) {
            finalValue.total_days = totalDays;
          }
          if (totalYears) {
            finalValue.total_years = totalYears;
          }
          if (selectedDate) {
            finalValue.opening_date = selectedDate.toDate();
          }
        }

        return finalValue;
      });
      handleCancel();
    } else {
      const submitUrl =
        participationFormDetails && participationFormDetails.id
          ? `v1/company/${companyId}/participation-form/${participationFormDetails.id}?_method=PUT`
          : `v1/company/${companyId}/participation-form`;
      setApiState({status: 'pending', error: null, message: ''});
      const fd = new FormData();
      fd.append('sponsor_option_id', checkedId.toString());
      if (otherCategory && otherCategory.length > 0) {
        fd.append('other_restricted_sponsorship', otherCategory.trim());
      }

      if (restrictedOptions && restrictedOptions.length > 0) {
        restrictedOptions.map((val, index) => {
          if (val.selected) {
            fd.append(
              `restricted_sponsorship_ids[${index}]`,
              val.id.toString(),
            );
          }
        });
      }

      if (productType === 'Neptune Radio') {
        fd.append(`season_type`, selectedSeasonYear);
        if (selectedSeasonYear === 'Seasonally') {
          fd.append(`opening_date`, dayjs(selectedDate).format('YYYY-MM-DD'));
        }
        fd.append(`total_days`, totalDays?.toString() ?? '0');
        fd.append(`total_years`, totalYears?.toString() ?? '0');
      }

      if (productType === 'GameTime') {
        if (otherSport && otherSport.length > 0) {
          fd.append('other_event', otherSport.trim());
        }
        if (sportInformation && sportInformation.length > 0) {
          sportInformation.map((val, index) => {
            fd.append(`events[${index}][event_id]`, val.id.toString());
            fd.append(
              `events[${index}][no_of_events]`,
              val.no_of_events.toString(),
            );
            fd.append(
              `events[${index}][attendence_per_event]`,
              val.attendence_per_event.toString(),
            );
          });
        }

        if (sporsorShipInformation && sporsorShipInformation.length > 0) {
          sporsorShipInformation.map((val, index) => {
            fd.append(
              `sponsorship_details[${index}][business_name]`,
              val.business_name.trim(),
            );
            fd.append(
              `sponsorship_details[${index}][contact_name]`,
              val.contact_name.trim(),
            );
          });
        }
      }

      apiPost(submitUrl, fd)
        .then(() => {
          const message =
            participationFormDetails && participationFormDetails.id
              ? 'TNN Participation form updated'
              : 'Thank you for partnering with TNN';
          setApiState({
            status: 'success',
            error: null,
            message: message,
          });
          if (isAdminPageFormEdit) {
            queryClient.invalidateQueries({
              queryKey: [getParticipationFormIdApiPath(companyId)],
              refetchType: 'active',
            });
          }
        })
        .catch(error => {
          setApiState({status: 'error', error, message: ''});
        });
    }
  }, [
    checkedId,
    companyId,
    otherCategory,
    otherSport,
    participationFormDetails,
    productType,
    restrictedOptions,
    selectedDate,
    selectedSeasonYear,
    sporsorShipInformation,
    sportInformation,
    totalDays,
    totalYears,
    queryClient,
    isAdminPageFormEdit,
    setParticipationFormDetails,
    handleCancel,
  ]);

  const handleEdit = useCallback(() => {
    setApiState({
      status: '',
      error: null,
      message: '',
    });
  }, []);

  useEffect(() => {
    if (data && !isFetching && !isError) {
      if (participationFormDetails && participationFormDetails.id) {
        const previousSportIds = participationFormDetails.events.map(
          val => val.id,
        );
        setSports(
          data.map(val => {
            const sport = {
              ...val,
              selected: previousSportIds.includes(val.id),
              sub_list:
                val.sub_list.length > 0
                  ? val.sub_list.map(subVal => {
                      return {
                        ...subVal,
                        selected: previousSportIds.includes(subVal.id),
                      };
                    })
                  : [],
            };
            return sport;
          }),
        );
      } else {
        setSports(
          data.map(val => {
            const sport = {
              ...val,
              selected: false,
              sub_list:
                val.sub_list.length > 0
                  ? val.sub_list.map(subVal => {
                      return {...subVal, selected: false};
                    })
                  : [],
            };
            return sport;
          }),
        );
      }
    }
  }, [data, isError, isFetching, participationFormDetails]);

  useEffect(() => {
    setSportInformation(prev => {
      const existIds = [...prev.map(o => o.id)];
      let selectedSports = [...prev];
      sports.forEach(val => {
        if (
          val.sub_list.length === 0 &&
          val.selected === true &&
          !existIds.includes(val.id)
        ) {
          selectedSports.push({
            id: val.id,
            name: val.name,
            no_of_events: 1,
            attendence_per_event: 1,
          });
        } else if (val.sub_list.length > 0) {
          val.sub_list.forEach(v => {
            if (v.selected === true && !existIds.includes(v.id)) {
              selectedSports.push({
                id: v.id,
                name: v.name,
                no_of_events: 1,
                attendence_per_event: 1,
              });
            } else if (v.selected === false) {
              selectedSports = selectedSports.filter(o => o.id !== v.id);
            }
          });
        } else if (val.sub_list.length === 0 && val.selected === false) {
          selectedSports = selectedSports.filter(o => o.id !== val.id);
        }
      });
      return selectedSports;
    });
  }, [sports]);

  useEffect(() => {
    if (
      restrictedOptionData &&
      !restrictedOptionIsFetching &&
      !restrictedOptionIsError
    ) {
      if (participationFormDetails && participationFormDetails.id) {
        const participationFormIds =
          participationFormDetails.restricted_sponsorships.map(val => val.id);
        const restrictedOptionDetails = restrictedOptionData.map(value => {
          return {
            ...value,
            selected: participationFormIds.includes(value.id),
          };
        });
        setRestrictedOptions(restrictedOptionDetails);
      } else {
        setRestrictedOptions(restrictedOptionData);
      }
    }
  }, [
    restrictedOptionData,
    restrictedOptionIsError,
    restrictedOptionIsFetching,
    participationFormDetails,
  ]);

  const handleClose = useCallback(() => {
    if (isAdminPageFormEdit) {
      handleCancel();
    } else {
      navigate('/dashboard');
    }
  }, [handleCancel, isAdminPageFormEdit, navigate]);

  useEffect(() => {
    if (participationFormDetails && participationFormDetails.id) {
      setOtherSport(participationFormDetails.other_event);
      setOtherCategory(participationFormDetails.other_restricted_sponsorship);
      setSporsorShipInformation(
        participationFormDetails.sponsorship_details.map(val => {
          return {
            uuid: uuidv4(),
            business_name: val.business_name,
            contact_name: val.contact_name,
          };
        }),
      );
      setSportInformation(
        participationFormDetails.events.map(val => {
          return {
            id: val.id,
            name: val.event_name,
            no_of_events: val.pivot.no_of_events,
            attendence_per_event: val.pivot.attendence_per_event,
          };
        }),
      );
      setSelectedSeasonYear(participationFormDetails.season_type ?? '');
      if (participationFormDetails.total_days) {
        setTotalDays(participationFormDetails.total_days);
      }
      if (participationFormDetails.total_years) {
        setTotalYears(participationFormDetails.total_years);
      }
      if (participationFormDetails.opening_date) {
        setSelectedDate(dayjs(participationFormDetails.opening_date));
      }
    }
  }, [participationFormDetails]);

  return (
    <StepperLayout
      defaultFirstStep={1}
      steps={steps}
      activeStepIndex={activeStepIndex}
      onBack={onPreviousStep}
      onNext={onNextStep}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      onStepClick={onStepClick}
      isSubmitted={apiState.status !== ''}
      isLastHide={false}
      title='Participation form'>
      <Box className='forminput-dark'>
        {activeStepIndex === 0 && (
          <Box>
            <img src={TnnLogo} className='mr-3' alt='' />
            {productType === 'GameTime' ? (
              <>
                <Typography className='text-18 mt-4'>
                  Thank you for participating in The Neptune Network. In order
                  to sell ads on your GameTime station(s), there is some
                  information about where and how often you currently use or
                  plan to use GameTIme at your sporting events.
                </Typography>
                <Typography className='text-18 my-4'>
                  This will take about 10-15 minutes to complete, base on the
                  number of sporting events you typically host at your school.
                  If necessary, you can always save your responses and come back
                  to finish them later.
                </Typography>
                <Typography className='text-18'>
                  However, please note that we cannot begin selling ads for your
                  station until we have this information. The sooner we get this
                  information, the sooner we can start making money for you!
                </Typography>
              </>
            ) : (
              <Typography className='text-18 mt-4'>
                Thank you for participating in The Neptune Network. We need to
                collect some information about your facility or venue in order
                to sell ads on your Neptune Radio station(s).
              </Typography>
            )}
          </Box>
        )}
        {activeStepIndex === 1 && productType === 'GameTime' && (
          <EventSelection
            sports={sports}
            setSports={setSports}
            otherSport={otherSport}
            setOtherSport={setOtherSport}
          />
        )}
        {activeStepIndex === 2 && productType === 'GameTime' && (
          <SportsInformationCmpt
            sportInformation={sportInformation}
            setSportInformation={setSportInformation}
          />
        )}
        {activeStepIndex === 3 && productType === 'GameTime' && (
          <SponsorShipInformationCmpt
            sporsorShipInformation={sporsorShipInformation}
            setSporsorShipInformation={setSporsorShipInformation}
          />
        )}
        {activeStepIndex === 1 && productType === 'Neptune Radio' && (
          <NeptuneRadioEventOptions
            selectedSeasonYear={selectedSeasonYear}
            setSelectedSeasonYear={setSelectedSeasonYear}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            totalDays={totalDays}
            setTotalDays={setTotalDays}
            totalYears={totalYears}
            setTotalYears={setTotalYears}
          />
        )}
        {((activeStepIndex === 4 && productType === 'GameTime') ||
          (productType === 'Neptune Radio' && activeStepIndex === 2)) && (
          <RestrictedSponsorshipSelectCmpt
            restrictedOptions={restrictedOptions}
            setRestrictedOptions={setRestrictedOptions}
            otherCategory={otherCategory}
            setOtherCategory={setOtherCategory}
          />
        )}
        {((activeStepIndex === 5 && productType === 'GameTime') ||
          (productType === 'Neptune Radio' && activeStepIndex === 3)) && (
          <>
            <Typography mb={3}>
              Thank you for opting back in to participate in{' '}
              <b>The Neptune Network</b>. Below is the information originally
              provided for your school or business.
            </Typography>
            <Typography mb={3}>
              Please take a moment to review it and make any necessary edits. If
              nothing has changed, simply hit the “submit” button.
            </Typography>
            <Typography>
              Thank you again for participating in <b>The Neptune Network!</b>
            </Typography>
          </>
        )}
        {apiState.status !== '' && (
          <DialogApiRequestStatus
            apiState={apiState}
            onRetry={handleSubmit}
            onEdit={handleEdit}
            onClose={handleClose}
          />
        )}
      </Box>
    </StepperLayout>
  );
};

export default ParticipationFormStepper;
