import {useSelector} from 'react-redux';
import {
  Box,
  Grid,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import {makeStyles} from '@mui/styles';
import {isGTSelector} from '../../../store/Slices/companySlice';
import {IMsgReqEventDateType} from '../../../dto/MessageRequest.dto';
import {ICompanySchool} from '../../../dto/CompanySchool.dto';
import {IStation} from '../../../dto/Station.dto';
import {IEventType} from '../../../dto/EventType.dto';
import {IStartEndDateTime} from '../../../dto/DateTime.dto';
import {CombinedMessageProps} from './EventSpecificViewMessage';
import MusicPreviewBtn from '../../../components/music/MusicPreviewBtn';
import {commonDateFormat} from '../../../Service/CommonService';

type Props = {
  schools: ICompanySchool[];
  stations: IStation[];
  events: IEventType[];
  event_date_type?: IMsgReqEventDateType;
  multiple_dates: IStartEndDateTime[];
  messages: CombinedMessageProps[];
  other_event_name?: string;
  from: string;
  created_date?: string;
  created_by?: string;
  days: string[];
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    overflowX: 'auto',
  },
});

const EsmReview = ({
  schools,
  stations,
  events,
  multiple_dates,
  messages,
  from,
  created_date,
  created_by,
  other_event_name,
  days,
}: Props) => {
  const isGT = useSelector(isGTSelector);
  const classes = useStyles();
  return (
    <>
      {from && from === 'form' && (
        <Grid item xs={12}>
          <Box>
            <Typography variant='h5' className='text-white font-bold mt-2 mb-9'>
              Review
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        {created_date && created_by && (
          <Box className='flex justify-between mb-5 border-bottom-2'>
            <Box>
              <Typography
                variant='h6'
                gutterBottom
                className='mb-0 text-white-50'>
                Created Date
              </Typography>
              <h5 className='text-white text-16'>
                {dayjs(created_date).format('MM/DD/YYYY hh:mm:ss A')}
              </h5>
            </Box>
            <Box className='text-right'>
              <Typography
                variant='h6'
                gutterBottom
                className='mb-0 text-white-50'>
                Created by
              </Typography>
              <h5 className='text-white text-16'>{created_by}</h5>
            </Box>
          </Box>
        )}
        {isGT && (
          <Box className={`${classes.root} mb-4`}>
            <Box className='w-82'>
              <Typography
                variant='h6'
                gutterBottom
                className='mb-0 text-white-50'>
                Schools
              </Typography>
            </Box>
            <Box>
              <Typography variant='h6' className='text-white'>
                <Box
                  className={`${classes.root} scrollbar flex flex-wrap max-h-84 max-w-650 overflow-auto`}
                  style={{gap: '2px'}}>
                  {schools.map((item, index: number) => {
                    if (item.id !== 0) {
                      return (
                        <Box key={item.id}>
                          <span id='school-name'>{item.name}</span>
                          {index !== schools.length - 1 && <span>, </span>}
                        </Box>
                      );
                    }
                    return null;
                  })}
                </Box>
              </Typography>
            </Box>
          </Box>
        )}
        <Box className={classes.root} style={{marginBottom: '16px'}}>
          <Box className='w-82'>
            <Typography
              variant='h6'
              gutterBottom
              className='mb-0 text-white-50'>
              Stations
            </Typography>
          </Box>
          <Box>
            <Typography variant='h6' className='text-white'>
              <Box
                className={`${classes.root} scrollbar flex flex-wrap max-h-84 max-w-650 overflow-auto`}
                style={{gap: '2px'}}>
                {stations.map((item, index: number) => {
                  if (item.id !== 0) {
                    return (
                      <Box key={item.id}>
                        <span id='Stations-name'>{item.name}</span>
                        {index !== stations.length - 1 && <span>, </span>}
                      </Box>
                    );
                  }
                  return null;
                })}
              </Box>
            </Typography>
          </Box>
        </Box>
        {isGT && (
          <Box className={classes.root} style={{marginBottom: '16px'}}>
            <Box className='w-82'>
              <Typography
                variant='h6'
                gutterBottom
                className='mb-0 text-white-50'>
                Sports
              </Typography>
            </Box>
            <Box>
              <Typography variant='h6' className='text-white'>
                <Box
                  className={`${classes.root} scrollbar flex flex-wrap max-h-84 max-w-650 overflow-auto`}
                  style={{gap: '2px'}}>
                  {events.map((item, index: number) => {
                    if (item.id !== 0) {
                      return (
                        <Box key={item.id}>
                          <span id='Sports-name' style={{whiteSpace: 'nowrap'}}>
                            {item.event_name}
                          </span>
                          {index !== stations.length - 1 && <span>, </span>}
                        </Box>
                      );
                    }
                    return null;
                  })}
                  {other_event_name &&
                    events.find(x => x.id === 0) &&
                    (events.length > 1
                      ? `, ${other_event_name}`
                      : other_event_name)}
                </Box>
              </Typography>
            </Box>
          </Box>
        )}
        <Box>
          <Typography variant='h6' className='mt-5 text-white'>
            Event Date
          </Typography>
          <TableContainer
            component={Paper}
            sx={{backgroundColor: 'transparent'}}
            className='indReviewTable pb-11'
            elevation={0}>
            <Table>
              <TableHead>
                <TableRow key={-1}>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {multiple_dates.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell id={`start-date-${index + 1}`}>
                      {commonDateFormat(row.start_date)}
                    </TableCell>
                    <TableCell
                      id={`end-date-${index + 1}`}
                      className='text-white'>
                      {commonDateFormat(row.end_date)}
                    </TableCell>
                    <TableCell
                      id={`start-time-${index + 1}`}
                      className='text-white'>
                      {row.start_time?.format('hh:mm A')}
                    </TableCell>
                    <TableCell
                      id={`end-time-${index + 1}`}
                      className='text-white'>
                      {row.end_time?.format('hh:mm A')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box mb={2}>
          <Typography variant='h6' className='text-white'>
            Selected days :{' '}
            {days
              .map(d => d.charAt(0).toUpperCase() + d.slice(1).toLowerCase())
              .join(', ')}
          </Typography>
        </Box>
        <Box>
          <Typography variant='h6' className='text-white'>
            Message Details
          </Typography>
          <TableContainer
            component={Paper}
            className='indReviewTable'
            elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className='w-50'>Play</TableCell>
                  <TableCell className='min-w-160 text-white-50'>
                    Message Name
                  </TableCell>
                  <TableCell className='text-white-50'>Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messages.map(
                  (row, index) =>
                    row.message !== '' &&
                    row.name !== '' && (
                      <TableRow key={row.uuid}>
                        <TableCell align='center'>
                          {row.media_track ? (
                            <MusicPreviewBtn
                              id={row.uuid}
                              url={row.media_track.url}
                            />
                          ) : null}
                        </TableCell>
                        <TableCell
                          id={`message-name-${index + 1}`}
                          className='text-white'>
                          {row.name}
                        </TableCell>
                        <TableCell
                          id={`message-description-${index + 1}`}
                          className='text-white'>
                          <pre>{row.message}</pre>
                        </TableCell>
                      </TableRow>
                    ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </>
  );
};

export default EsmReview;

export const EsmDateTime = ({
  event_date_type,
  multiple_dates,
}: {
  event_date_type: IMsgReqEventDateType;
  multiple_dates: IStartEndDateTime[];
}) => {
  return (
    <>
      <Typography>
        {event_date_type === 1 ? 'Single Day' : 'Multiple Days'}
      </Typography>
      {event_date_type === 1 ? (
        <Typography>
          {multiple_dates.map(date => (
            <Typography key={date.id}>
              {date.start_date?.format('MM-DD-YYYY')} (
              {date.is_whole_day ? (
                'Whole day'
              ) : (
                <>
                  {date.start_time?.format('hh:mm A')} -{' '}
                  {date.end_time?.format('hh:mm A')}
                </>
              )}
              )
            </Typography>
          ))}
        </Typography>
      ) : (
        <List>
          <Table aria-label='simple table indReviewTable'>
            <TableHead>
              <TableRow>
                <TableCell>START DATE</TableCell>
                <TableCell>END DATE</TableCell>
                <TableCell>TIME</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {multiple_dates.map(date => (
                <TableRow key={date.id}>
                  <TableCell>{date.start_date?.format('MM-DD-YYYY')}</TableCell>
                  <TableCell>{date.end_date?.format('MM-DD-YYYY')}</TableCell>
                  <TableCell>
                    {date.is_whole_day ? (
                      'Whole day'
                    ) : (
                      <>
                        {date.start_time?.format('hh:mm A')} -{' '}
                        {date.end_time?.format('hh:mm A')}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </List>
      )}
    </>
  );
};
