import {Alert, AlertTitle, Button, Container} from '@mui/material';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {
  isGTSelector,
  isNRSelector,
  isSZSelector,
  isTrampolineSelector,
  productLevelSelector,
} from '../store/Slices/companySlice';

const ErrorMessage = ({productType}: {productType: string}) => {
  return (
    <Container>
      <Alert
        variant='outlined'
        severity='error'
        sx={{my: 2}}
        action={
          <Button variant='contained' color='info' component={Link} to='/'>
            GO TO HOMEPAGE
          </Button>
        }>
        <AlertTitle>ACCESS DENIED</AlertTitle>
        Sorry, this page is accessible for the {productType} partners.
      </Alert>
    </Container>
  );
};

type Props = {
  children: JSX.Element;
  isRoute?: boolean;
};

export const ProductGtOnly = ({children, isRoute}: Props) => {
  const isGT = useSelector(isGTSelector);
  if (isGT) return children;
  if (isRoute) return <ErrorMessage productType='GameTime' />;
  return null;
};

export const ProductLevelGTZeroOnly = ({children, isRoute}: Props) => {
  const isGT = useSelector(isGTSelector);
  const productLevel = useSelector(productLevelSelector);

  if (isGT && productLevel !== 'Level 0 (ODO Only)') return children;
  if (isRoute) return <ErrorMessage productType='GameTime' />;
  return null;
};

export const ProductNrOnly = ({children, isRoute}: Props) => {
  const isNR = useSelector(isNRSelector);
  if (isNR) return children;
  if (isRoute) return <ErrorMessage productType='Neptune Radio' />;
  return null;
};

export const ProductSzOnly = ({children, isRoute}: Props) => {
  const isSZ = useSelector(isSZSelector);
  if (isSZ) return children;
  if (isRoute) return <ErrorMessage productType='SchoolZone' />;
  return null;
};

export const ProductGtNrOnly = ({children, isRoute}: Props) => {
  const isGT = useSelector(isGTSelector);
  const isNR = useSelector(isNRSelector);
  if (isGT || isNR) return children;
  if (isRoute) return <ErrorMessage productType='GameTime, Neptune Radio' />;
  return null;
};

export const ProductGtSzOnly = ({children, isRoute}: Props) => {
  const isGT = useSelector(isGTSelector);
  const isSZ = useSelector(isSZSelector);
  if (isGT || isSZ) return children;
  if (isRoute) return <ErrorMessage productType='GameTime, SchoolZone' />;
  return null;
};

export const ProductNrSzOnly = ({children, isRoute}: Props) => {
  const isNR = useSelector(isNRSelector);
  const isSZ = useSelector(isSZSelector);
  if (isNR || isSZ) return children;
  if (isRoute) return <ErrorMessage productType='Neptune Radio, SchoolZone' />;
  return null;
};

export const ProductTrampolineOnly = ({children, isRoute}: Props) => {
  const isTrampoline = useSelector(isTrampolineSelector);
  if (isTrampoline) return children;
  if (isRoute) return <ErrorMessage productType='Trampoline' />;
  return null;
};
