import dayjs, {Dayjs} from 'dayjs';
import {useSelector} from 'react-redux';
import {Box, Stack, Typography} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  StepperLayout,
  useStepperState,
} from '../../../components/layouts/stepper/StepperLayout';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import {apiPost} from '../../../axiosConfig';
import {IScripts} from '../event-specific/EventSpecificStepper';
import {IStation} from '../../../dto/Station.dto';
import {ICustomLibrary} from '../../../dto/Message.dto';
import DialogApiRequestStatus from '../../../components/dialogs/DialogApiRequestStatus';
import OndemandExpiryDate from './OndemandExpiryDate';
import MessageTrackUploadButton from '../MessageTrackSyncCmpt';
import {IMediaTrack} from '../../../dto/MediaTrack.dto';
import {isInternalSelector} from '../../../store/Slices/userSlice';
import {EsmMessages} from '../event-specific/EsmMessages';
import {StepperItemProps} from '../../../components/layouts/stepper/StepperLeftNavigation';
import {
  addNewTrackToMessages,
  updateTrackToMessage,
} from '../event-specific/MessagesStateFunctionality';
import {onDemandGridApiPath} from '../../../react_query/messages/message-requests/OndemandRequestList';
import {checkMessagesValid} from '../../../Service/CommonService';
import ProcessedFileUploadButton from '../timed-message/request/contents/UploadProcessedFileDialog';
import OnDemandStationLibrarySelection from './OnDemandStationLibrarySelection';
import {OndemandReview} from './OndemandReview';

const defaultApiState = {
  status: '',
  error: null,
};

export type IOnDemandStation = IStation & {custom_libraries: IOnDemCustLib[]};

export type IOnDemCustLib = Pick<ICustomLibrary, 'id' | 'name'> & {
  station_name: string;
  station_id: number;
  new: boolean;
};

const OndemandStepper = ({
  handleClose,
  defaultStationsList,
  defaultMessages,
  defaultExpireDate,
  defaultIsExpireDateSet,
  messageStatus,
  defaultTracks,
  update_id,
}: {
  handleClose: () => void;
  defaultStationsList: IOnDemandStation[];
  defaultMessages: IScripts[];
  defaultExpireDate: Dayjs | null;
  defaultIsExpireDateSet: boolean;
  messageStatus: string | null;
  defaultTracks: IMediaTrack[];
  update_id?: number;
}) => {
  const queryClient = useQueryClient();
  const {activeStepIndex, onNextStep, onPreviousStep, onStepClick} =
    useStepperState(0);
  const companyId = useSelector(companyIdSelector);
  const isStaff = useSelector(isInternalSelector);
  const [isExpireDateSet, setIsExpireDateSet] = useState(
    defaultIsExpireDateSet,
  );
  const [expireDate, setExpireDate] = useState(defaultExpireDate);
  const [messages, setMessages] = useState(defaultMessages);
  const [selectedTracks, setSelectedTracks] = useState(defaultTracks);
  const [stationsList, setStationsList] = useState(defaultStationsList);
  const [apiState, setApiState] = useState(defaultApiState);

  const isEmptyMsgAvail = useMemo(() => {
    return checkMessagesValid(messages);
  }, [messages]);

  const isExpireDateNotValid = useMemo(() => {
    if (isExpireDateSet) {
      const today = dayjs().startOf('day');
      const endDate = dayjs(expireDate).startOf('day');
      return expireDate === null || !endDate.isAfter(today);
    }
    return false;
  }, [isExpireDateSet, expireDate]);

  const steps: StepperItemProps[] = useMemo(() => {
    const stepList: StepperItemProps[] = [
      {
        label: 'Select Options',
        disabled: false,
      },
      {
        label: 'Message Details',
        disabled:
          !stationsList.length ||
          stationsList.some(o => !o.custom_libraries.length) ||
          isExpireDateNotValid,
      },
      {
        label: 'Review',
        disabled: !isEmptyMsgAvail,
      },
    ];

    return stepList;
  }, [stationsList, isExpireDateNotValid, isEmptyMsgAvail]);

  const handleSubmit = useCallback(() => {
    const msgs = messages
      ? messages
          .filter(({message, name}) => message !== '' && name !== '')
          .map(({message, name, media_track}) => ({
            message,
            name,
            media_track_id: media_track?.id ?? null,
          }))
      : [];
    const stations = stationsList.map(o => ({
      id: o.id,
      custom_library_ids: o.custom_libraries.filter(o => !o.new).map(o => o.id),
      custom_library_names: o.custom_libraries
        .filter(o => o.new)
        .map(o => o.name),
    }));
    const end_date = expireDate ? dayjs(expireDate).format('YYYY-MM-DD') : '';
    const payload = {
      company_id: companyId,
      stations,
      messages: msgs,
      is_expire_date_set: isExpireDateSet,
      expire_date: end_date,
    };
    const url = update_id
      ? `/v1/company/${companyId}/message/ondemand-message/${update_id}?_method=PUT`
      : `/v1/company/${companyId}/message/ondemand-message`;
    apiPost(url, payload)
      .then(_res => {
        queryClient.invalidateQueries({
          queryKey: [onDemandGridApiPath(companyId)],
          refetchType: 'active',
        });
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });
  }, [
    messages,
    stationsList,
    expireDate,
    companyId,
    isExpireDateSet,
    update_id,
    queryClient,
  ]);

  useEffect(() => {
    if (selectedTracks.length) {
      if (update_id) {
        setMessages(messages =>
          updateTrackToMessage({
            messages,
            track: selectedTracks[0] as IMediaTrack,
          }),
        );
      } else {
        setMessages(messages =>
          addNewTrackToMessages({
            messages,
            requestable_type: 'ondemand-message',
            tracks: selectedTracks,
          }),
        );
      }
    }
  }, [selectedTracks, update_id]);

  return (
    <StepperLayout
      steps={steps}
      activeStepIndex={activeStepIndex}
      onBack={onPreviousStep}
      onNext={onNextStep}
      onCancel={handleClose}
      onSubmit={handleSubmit}
      onStepClick={onStepClick}
      isSubmitted={apiState.status !== ''}
      title={update_id ? 'Update Message' : 'Create New Message'}>
      {(() => {
        const label = steps?.[activeStepIndex]?.label ?? '';
        switch (label) {
          case 'Select Options':
            return (
              <Box>
                <Typography variant='h5' className='mb-5'>
                  Select Options
                </Typography>
                <OnDemandStationLibrarySelection
                  stationsList={stationsList}
                  setStationsList={setStationsList}
                />
                <div
                  style={{
                    display: 'block',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: '25px',
                  }}>
                  <OndemandExpiryDate
                    is_expire_date_set={isExpireDateSet}
                    expire_date={expireDate}
                    setIsExpireDateSet={setIsExpireDateSet}
                    setExpireDate={setExpireDate}
                    isToday={isExpireDateNotValid}
                  />
                </div>
              </Box>
            );
          case 'Message Details':
            return (
              <>
                {isStaff &&
                (!messageStatus || messageStatus === 'In Progress') ? (
                  <Stack direction='row' justifyContent='flex-end'>
                    <MessageTrackUploadButton
                      type={'Choose OD Message'}
                      isSingleSongSelection={Boolean(update_id)}
                      selectedTracks={selectedTracks}
                      setSelecetedTracks={setSelectedTracks}
                    />
                    <ProcessedFileUploadButton
                      type={'ondemand-message'}
                      companyId={companyId}
                      onClose={(track?: IMediaTrack) => {
                        if (track) {
                          if (update_id) {
                            setSelectedTracks([track]);
                          } else {
                            setSelectedTracks(prev => [...prev, track]);
                          }
                        }
                      }}
                    />
                  </Stack>
                ) : null}

                <EsmMessages
                  messages={messages}
                  setMessages={setMessages}
                  setSelectedTracks={setSelectedTracks}
                  requestableType='ondemand-message'
                  isUpdate={Boolean(update_id)}
                  messageStatus={messageStatus}
                />
              </>
            );
          case 'Review':
            return (
              <OndemandReview
                stations={stationsList}
                is_expire_date_set={isExpireDateSet}
                expire_date={expireDate}
                messages={messages}
              />
            );
          default:
            return null;
        }
      })()}
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          onEdit={() => setApiState(defaultApiState)}
          onRetry={handleSubmit}
          onClose={handleClose}
          apiState={apiState}
        />
      )}
    </StepperLayout>
  );
};

export default OndemandStepper;
