import {AxiosError, AxiosResponse} from 'axios';
import {useQuery} from '@tanstack/react-query';
import axiosInstance from '../../axiosConfig';
import {IDataProps, IMessageType} from '../../dto/Message.dto';
import {AxiosErrorToast} from '../general/ReusableFunctions';
import {IApiDataResponse, IApiSuccessMsgResponse} from '../../dto/API.dto';
export interface IPssAssignData {
  assigned_to: number;
}
interface MessagesRequestProps {
  id: number;
  company_id: number;
  company_name: string;
  request_type: string;
  status: string;
  request_status: string;
  event_date_type: number;
  is_all_day: number;
  start_date: Date;
  end_date: Date;
  start_time: string;
  end_time: string;
  created_by: string;
  created_at: Date;
  task_id: number;
}
interface IPssDataReceived {
  email: string;
  id: number;
  name: string;
}

export interface IMessagesCountData {
  base_message: number;
  event_specific_message: number;
  onboarding_message: number;
  ondemand_message: number;
  timed_message: number;
  wristband_message: number;
}
const getRequestList = (message_type: IMessageType) => {
  return axiosInstance
    .get(`/v1/message-request/${message_type}/list`)
    .then(
      (response: AxiosResponse<IApiDataResponse<MessagesRequestProps[]>>) => {
        return response.data;
      },
    )
    .catch((error: AxiosError) => {
      AxiosErrorToast(error, 'Error in fetching an requests');
      throw error;
    });
};

export const useRqListMessages = (
  message_type: IMessageType,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: ['api/v1/message-request/', message_type, 'list'],
    queryFn: () => getRequestList(message_type),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};

const viewRequestById = (
  companyId: number,
  message_type: IMessageType,
  id: number,
) => {
  return axiosInstance
    .get(`/v1/company/${companyId}/message/${message_type}/${id}`)
    .then((response: AxiosResponse<IApiDataResponse<IDataProps>>) => {
      return response.data.data;
    })
    .catch((error: AxiosError) => {
      AxiosErrorToast(error, 'Error in fetching an requests');
      throw error;
    });
};

export const useRqViewMessages = (
  companyId: number,
  message_type: IMessageType,
  id: number,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: [`/v1/company/${companyId}/message/${message_type}/${id}`],
    queryFn: () => viewRequestById(companyId, message_type, id),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};

const getPssList = async () => {
  return await axiosInstance
    .get(`/v1/user/pss`)
    .then((response: AxiosResponse<IPssDataReceived[]>) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      AxiosErrorToast(error, 'Error in fetching an requests');
      throw error;
    });
};

export const useRqPssList = (enabled: boolean = true) => {
  return useQuery({
    queryKey: ['api/v1/user/pss'],
    queryFn: () => getPssList(),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};

export const assignPssToRequest = async (payload: IPssAssignData) => {
  return axiosInstance
    .patch(`/v1/task/assign`, payload)
    .then((response: AxiosResponse<IApiSuccessMsgResponse>) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      AxiosErrorToast(error, 'Error in saving');
      throw error;
    });
};

export const updateRequestApprovalStatus = async (
  taskId: number,
  payload: FormData,
) => {
  return axiosInstance
    .post(`/v1/task/${taskId}/recording/verification`, payload)
    .then((response: AxiosResponse<IApiSuccessMsgResponse>) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      AxiosErrorToast(error, 'Error in saving');
      throw error;
    });
};

const getMessagesCount = () => {
  return axiosInstance
    .get(`/v1/message-request/count`)
    .then((response: AxiosResponse<IApiDataResponse<IMessagesCountData>>) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      AxiosErrorToast(error, 'Error in fetching an requests');
      throw error;
    });
};

export const useRqMessagesCount = (enabled: boolean = true) => {
  return useQuery({
    queryKey: ['api/v1/message-request/count'],
    queryFn: () => getMessagesCount(),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};
