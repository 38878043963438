import {makeStyles} from '@mui/styles';

export const dataGridStyles = makeStyles(theme => ({
  '@global': {
    '.MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
    '.MuiDataGrid-toolbarContainer .MuiInputBase-root': {
      borderRadius: '100px !important',
      background: 'var(--blue-variant6)',
      border: '1px solid var(--gray-variant8)',
      padding: '3px 4px 2px 12px',
      boxShadow: 'inset 0 3px 6px var(--gray-opacity-variant1)',
      marginTop: '8px',
    },
    '.MuiDataGrid-toolbarContainer .MuiInputBase-root:before, .MuiDataGrid-toolbarContainer .MuiInputBase-root:after, .MuiDataGrid-toolbarContainer .MuiButtonBase-root':
      {
        display: 'none !important',
      },
    '.MuiDataGrid-toolbarContainer .MuiInputBase-root svg': {
      color: '#0087C4',
      fontSize: '30px',
      fontWeight: '100',
    },
  },
}));
