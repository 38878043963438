import React, {useCallback} from 'react';
import {Box, Grid, Typography} from '@mui/material';
import FrontFlipCardContent, {
  FlipCard,
  SubCategoryBox,
} from '../../../components/styles/flipcard/FlipCard';
import '../../../components/styles/flipcard/FlipCard.css';
import {
  IMusicGenreCust,
  IMusicGenreGrouped,
  useRqGroupedGenres,
} from '../../../react_query/music_genres/ListGenresGrouped';
import RetryPage from '../../styles/RetryButton';
import {IErrorResponse} from '../../../dto/Html.dto';
import {IMusicGenre} from '../../../dto/MusicGenre.dto';
import {useStoreSelector} from '../../../store/configstore';
import {companyIdSelector} from '../../../store/Slices/companySlice';

type CardParms = {
  setGenresId: React.Dispatch<React.SetStateAction<number[]>>;
  genres: IMusicGenreGrouped[];
  setGenres: React.Dispatch<React.SetStateAction<IMusicGenreGrouped[]>>;
  handleGenreSelect: (genreId: number, subGenreId?: number) => void;
};

const SubGenreCard = ({
  parentGenreId,
  genre,
  onSelect,
}: {
  parentGenreId: number;
  genre: IMusicGenreCust;
  onSelect: (genreId: number, subGenreId?: number) => void;
}) => {
  const handleGenreSelect = useCallback(() => {
    onSelect(parentGenreId, genre.id);
  }, [parentGenreId, genre.id, onSelect]);
  return (
    <SubCategoryBox
      className={genre.selected ? 'active' : ''}
      onClick={handleGenreSelect}>
      {genre.name}
    </SubCategoryBox>
  );
};

const GenreFlipCard = ({
  genre,
  onSelect,
}: {
  genre: IMusicGenreGrouped;
  onSelect: (genreId: number, subGenreId?: number) => void;
}) => {
  const handleGenreSelect = useCallback(() => {
    onSelect(genre.id);
  }, [genre.id, onSelect]);
  return (
    <Grid item xs={6} md={4} lg={3} key={genre.id}>
      {genre?.sub_genres.length > 0 ? (
        <FlipCard className={`flipCard ${genre.selected ? 'active' : ''}`}>
          <div className='flipCardInner'>
            <div className='flipCardFront'>
              <FrontFlipCardContent name={genre.name} />
            </div>
            <div
              className='flipCardBack'
              style={{overflowY: 'auto', scrollbarWidth: 'none'}}>
              <Box>
                <Typography align='center' fontWeight={600} mt={1}>
                  {genre.name}
                </Typography>
                {genre.sub_genres.map(subGenre => (
                  <SubGenreCard
                    key={subGenre.id}
                    parentGenreId={genre.id}
                    genre={subGenre}
                    onSelect={onSelect}
                  />
                ))}
              </Box>
            </div>
          </div>
        </FlipCard>
      ) : (
        <FlipCard
          className={`flipCard ${genre.selected ? 'active' : ''}`}
          onClick={handleGenreSelect}>
          <div className='onlyFrontCard'>
            <div className='flipCardFront'>
              <FrontFlipCardContent name={genre.name} />
            </div>
          </div>
        </FlipCard>
      )}
    </Grid>
  );
};

export const GenresFlipCard = ({
  setGenresId,
  genres,
  setGenres,
  handleGenreSelect,
}: CardParms) => {
  const [, setRetryTime] = React.useState(Date.now());
  const companyId = useStoreSelector(companyIdSelector);
  const {data, isFetching, isError, error} = useRqGroupedGenres(companyId);

  React.useEffect(() => {
    if (data && !isFetching && !isError) {
      setGenresId(selectedGenreIds => {
        const newGenres = data.map(genre => {
          let isSubGenreSelected = false;
          const subGenres = genre.sub_genres.map((subGenre: IMusicGenre) => {
            const selected = selectedGenreIds.includes(subGenre.id);
            isSubGenreSelected = isSubGenreSelected || selected;
            return {
              status: '',
              percentage: 0,
              selected,
              ...subGenre,
            };
          });
          return {
            status: '',
            percentage: 0,
            selected: isSubGenreSelected || selectedGenreIds.includes(genre.id),
            sub_genres: subGenres,
            ...(genre as IMusicGenre),
          };
        });
        setGenres(newGenres);
        return selectedGenreIds;
      });
    }
  }, [data, isError, isFetching, setGenres, setGenresId]);

  if (isError) {
    const errResp = error as IErrorResponse;
    return (
      <RetryPage
        pageTitle='List genres'
        errResp={errResp?.response}
        setRetryTime={setRetryTime}
        queryKey={['api/v1/genre-group']}
      />
    );
  }

  return (
    <Grid container spacing={4}>
      {isFetching ? <>Loading</> : null}
      {genres?.map(genre => (
        <GenreFlipCard
          genre={genre}
          key={genre.id}
          onSelect={handleGenreSelect}
        />
      ))}
    </Grid>
  );
};
