import {Button, styled} from '@mui/material';
import IconAddSong from '../../asset/icons/icon_addsong.svg';
import {useCallback} from 'react';

const UploadButton = ({
  label = 'Upload File',
  handleChange,
  disabled = false,
}: {
  label?: string;
  handleChange: (value: string | File | undefined, field: 'files') => void;
  disabled?: boolean;
}) => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files && files.length > 0) {
        const file = files[0];
        handleChange(file, 'files');
      }
    },
    [handleChange],
  );

  return (
    <Button
      variant='contained'
      color='primary'
      disabled={disabled}
      component='label'
      startIcon={<img src={IconAddSong} alt='Create Task Icon' />}>
      {label}
      <VisuallyHiddenInput
        type='file'
        accept='audio/*'
        onChange={handleFileChange}
      />
    </Button>
  );
};

export default UploadButton;
