import {Button, ButtonProps} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

type iconAddProps = ButtonProps & {
  icon?: string;
  title?: string;
};

const IconAddButton: React.FC<iconAddProps> = ({
  icon,
  title = 'Add',
  ...buttonProps
}) => {
  return (
    <Button
      {...buttonProps}
      variant='outlined'
      size='small'
      startIcon={icon ? <img src={icon} alt='' /> : <AddIcon />}>
      {title}
    </Button>
  );
};

export default IconAddButton;
