import dayjs from 'dayjs';
import {useCallback} from 'react';
import {v4 as uuidv4} from 'uuid';
import {useSelector} from 'react-redux';
import {useRqGetEventMessageByRequestId} from '../../../react_query/messages/messages-details/ViewEventMessages';
import EventSpecificStepper from './EventSpecificStepper';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import {IScripts} from './EsmMessages';
import {daysOfWeek} from '../../../dto/DateTime.dto';
import {newStartEndDateTime} from './EsmcDatesTimes';

const EventMessageCreate = ({
  onClose,
  update_id,
}: {
  onClose: () => void;
  update_id?: number;
}) => {
  const companyId = useSelector(companyIdSelector);

  const {data, isFetching} = useRqGetEventMessageByRequestId(
    companyId,
    Number(update_id),
    Boolean(update_id),
  );

  const days = useCallback(() => {
    if (data && !isFetching) {
      return daysOfWeek.filter(day =>
        data.week_days.map(d => d.toLowerCase()).includes(day.toLowerCase()),
      );
    }
    return [];
  }, [data, isFetching]);

  const dates = useCallback(() => {
    if (data && !isFetching) {
      return data.multiple_dates.map((o, i) => ({
        id: i,
        start_date: dayjs(o.start_date),
        end_date: dayjs(o.end_date),
        start_time: dayjs(o.start_time, 'HH:mm:ss'),
        end_time: dayjs(o.end_time, 'HH:mm:ss'),
        is_whole_day: o.is_whole_day,
      }));
    }
    return [{...newStartEndDateTime, id: Date.now()}];
  }, [data, isFetching]);

  const messages = useCallback(() => {
    if (!isFetching && data) {
      return data.messages.map(o => ({
        id: o.id,
        uuid: uuidv4(),
        message: o.message,
        name: data.name || '',
        requestable_type: 'event-message',
        media_track: o.media_track,
      }));
    }
    return [
      {
        id: Date.now(),
        uuid: uuidv4(),
        message: '',
        name: '',
        requestable_type: 'event-message',
      },
    ];
  }, [data, isFetching]);

  const messageStatus = useCallback(() => {
    if (update_id && data && !isFetching) {
      // Some migrated data have improper data which can be editable
      return !data.messages[0]?.media_track ? 'In Progress' : data?.status;
    }
    return null;
  }, [data, isFetching, update_id]);

  return !isFetching ? (
    <EventSpecificStepper
      handleClose={onClose}
      defaultEventDateType={data?.event_date_type ?? 1}
      defaultEvents={data?.event_types ?? []}
      defaultDays={days()}
      defaultMultipleDates={dates()}
      defaultSchools={data?.schools ?? []}
      defaultStations={data?.stations ?? []}
      defaultMessages={messages() as unknown as IScripts[]}
      update_id={update_id}
      messageStatus={messageStatus() ?? null}
    />
  ) : null;
};

export default EventMessageCreate;
