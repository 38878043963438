import {Button, ButtonProps} from '@mui/material';

const DeactivateButton: React.FC<ButtonProps> = ({...buttonProps}) => {
  return (
    <Button {...buttonProps} variant='outlined' color='error' size='small'>
      Deactivate
    </Button>
  );
};

export default DeactivateButton;
