import {Tooltip, Typography} from '@mui/material';

export const TooltipText = ({
  text,
  maxWidth = 100,
}: {
  text: string;
  maxWidth?: number;
}) => {
  return (
    <Tooltip title={text}>
      <Typography noWrap maxWidth={maxWidth}>
        <pre>{text}</pre>
      </Typography>
    </Tooltip>
  );
};
