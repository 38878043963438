import {
  Box,
  Grid,
  Paper,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Alert,
} from '@mui/material';
import {v4 as uuidv4} from 'uuid';
import {useSelector} from 'react-redux';
import {useCallback, useMemo} from 'react';
import {IMediaTrack} from '../../../dto/MediaTrack.dto';
import {IMessage, IMessageType} from '../../../dto/Message.dto';
import IconDeleteButton from '../../../components/Button/IconDeletebutton';
import {updateMessages} from './MessagesStateFunctionality';
import MusicPreviewBtn from '../../../components/music/MusicPreviewBtn';
import {isInternalSelector} from '../../../store/Slices/userSlice';
import MessageAddButton from '../../../components/styles/buttons/MessageAddButton';
import IconEditButton from '../../../components/Button/IconEditButton';
import {CustomTextarea} from '../../../components/styles/CustomTextarea';

type EsmMessagesProps = {
  setMessages: React.Dispatch<React.SetStateAction<IScripts[]>>;
  setSelectedTracks: React.Dispatch<React.SetStateAction<IMediaTrack[]>>;
};

type MessageFormProps = {
  setMessages: React.Dispatch<React.SetStateAction<IScripts[]>>;
  message: IScripts;
  requestableType: IMessageType;
  isUpdate: boolean;
  messageStatus: string | null;
};

type MessageListProps = {
  messages: IScripts[];
  setMessages: React.Dispatch<React.SetStateAction<IScripts[]>>;
  deleteMultiSelectData: (msg: IScripts) => void;
  setSelectedTracks: React.Dispatch<React.SetStateAction<IMediaTrack[]>>;
};

export type IScripts = Pick<IMessage, 'id' | 'requestable_type' | 'message'> & {
  uuid: string;
  name: string;
  media_track?: IMediaTrack;
};

export const EsmMessages = ({
  messages,
  setMessages,
  setSelectedTracks,
  requestableType,
  isUpdate,
  messageStatus,
}: EsmMessagesProps & {
  messages: IScripts[];
  requestableType: IMessageType;
  isUpdate: boolean;
  messageStatus: string | null;
}) => {
  const deleteMultiSelectData = (msg: IScripts) => {
    setMessages(prev => prev.filter(v => v.uuid !== msg.uuid));
    if (msg.media_track) {
      setSelectedTracks(prev => {
        const removedSongs = prev.filter(val => val.id !== msg.media_track?.id);
        return removedSongs;
      });
    }
  };

  return (
    <Stack className='calcheight-40 w-full'>
      <Typography variant='h5' className='text-white font-bold mt-2 mb-2'>
        Message Details
      </Typography>
      <MessageForm
        message={messages[0] as IScripts}
        setMessages={setMessages}
        requestableType={requestableType}
        isUpdate={isUpdate}
        messageStatus={messageStatus}
      />
      <MessageList
        messages={messages}
        setMessages={setMessages}
        setSelectedTracks={setSelectedTracks}
        deleteMultiSelectData={deleteMultiSelectData}
      />
    </Stack>
  );
};

const MessageForm = ({
  message,
  setMessages,
  requestableType,
  isUpdate,
  messageStatus,
}: MessageFormProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessages(messages =>
      updateMessages({
        messages,
        uuid: message.uuid,
        key: 'message',
        value: e.target.value,
      }),
    );
  };

  const handleMsgName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessages(messages =>
      updateMessages({
        messages,
        uuid: message.uuid,
        key: 'name',
        value: e.target.value,
      }),
    );
  };

  const isValidMessage = useMemo(
    () =>
      message.name.length > 0 &&
      message.name.length <= 50 &&
      message.message.length > 10,
    [message.message, message.name],
  );

  const addNewMessage = useCallback(() => {
    setMessages(prev => [
      {
        id: Date.now(),
        uuid: uuidv4(),
        message: '',
        name: '',
        requestable_type: requestableType,
      },
      ...prev,
    ]);
  }, [setMessages, requestableType]);

  return (
    <Stack>
      <MessageAlert name={message.name} message={message.message} />
      <Box m={0} className='mb-5'>
        <TextField
          id='message-name'
          className='w-398 text-white radius-5 p-0 min-h-40 h-40'
          variant='outlined'
          value={message.name}
          InputProps={{
            style: {
              background: '#000000E6',
            },
            placeholder: 'Message Name *',
          }}
          onChange={handleMsgName}
        />
      </Box>
      <Stack direction='row' className='flex items-end' spacing={1}>
        <CustomTextarea
          id='message-description'
          placeholder='Enter Message here'
          onChange={handleChange}
          value={message.message}
          disabled={messageStatus === 'Active' || messageStatus === 'Inactive'}
        />
        {message.media_track ? (
          <MusicPreviewBtn
            id={message.media_track.id}
            url={message.media_track.url}
          />
        ) : null}
        {isUpdate ? null : (
          <MessageAddButton isValid={isValidMessage} onClick={addNewMessage} />
        )}
      </Stack>
    </Stack>
  );
};

export const MessageAlert = ({
  name,
  message,
}: {
  name: string;
  message: string;
}) => {
  if (name && name.trim().length > 50) {
    return (
      <Alert severity='error' sx={{mb: 1}}>
        Name must below 50 characters
      </Alert>
    );
  }
  if (message && message.length <= 10) {
    return (
      <Alert severity='error' sx={{mb: 1}}>
        Message must greater than 10 characters
      </Alert>
    );
  }
  return null;
};

const MessageList: React.FC<MessageListProps> = ({
  messages,
  setMessages,
  deleteMultiSelectData,
  setSelectedTracks,
}) => {
  const isStaff = useSelector(isInternalSelector);

  const handleEdit = useCallback(
    (uuid: string) => {
      setMessages(prev => {
        const messages = [...prev.filter(o => o.name || o.message)];
        const index = messages.findIndex(message => message.uuid === uuid);
        if (index !== -1) {
          const splicedMessage = messages.splice(index, 1)[0] as IScripts; // Remove the message from its current position
          if (splicedMessage.media_track) {
            setSelectedTracks([splicedMessage.media_track]);
          } else {
            setSelectedTracks([]);
          }
          messages.unshift(splicedMessage); // Move the message to the first position
        }
        return [...messages];
      });
    },
    [setMessages, setSelectedTracks],
  );

  return (
    <Grid item xs={12} className='calcheight-210 mt-5'>
      {messages && messages.length > 0 && (
        <TableContainer
          component={Paper}
          className='indReviewTable h-full overflow-auto scrollbar'>
          <Table size='small'>
            <TableHead sx={{backgroundColor: `var(--blue-variant17)`}}>
              <TableRow>
                <TableCell>Play</TableCell>
                <TableCell>Message Name</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {messages.map((row, index) => {
                if (index !== 0) {
                  return (
                    <TableRow key={row.uuid}>
                      {isStaff ? (
                        <TableCell>
                          {row.media_track ? (
                            <MusicPreviewBtn
                              id={row.uuid}
                              url={row.media_track.url}
                            />
                          ) : null}
                        </TableCell>
                      ) : null}
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        <pre>{row.message}</pre>
                      </TableCell>
                      <TableCell>
                        <IconEditButton
                          aria-label='edit'
                          className='mr-3'
                          color='primary'
                          onClick={() => handleEdit(row.uuid)}
                        />
                        <IconDeleteButton
                          onClick={() => deleteMultiSelectData(row)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};
