import {MenuItem, Select} from '@mui/material';
import {IEsmStatus} from '../../dto/EventSpecificMessageRequest.dto';
import React from 'react';

interface DropdownSelectProps {
  value: IEsmStatus;
  onChange: (newValue: IEsmStatus) => void;
}

const ActiveDropdown: React.FC<DropdownSelectProps> = ({value, onChange}) => {
  return (
    <Select
      value={value}
      onChange={event => onChange(event.target.value as IEsmStatus)}
      displayEmpty
      className='customSelect w-full min-h-40 max-w-204 border radius-50'
      inputProps={{'aria-label': 'Without label'}}
      sx={{
        backgroundColor: `var(--blue-variant4)`,
        borderRadius: '50px !important',
      }}>
      <MenuItem value='Active'>Active</MenuItem>
      <MenuItem value='Inactive'>Inactive</MenuItem>
    </Select>
  );
};

export default ActiveDropdown;
