import {AxiosResponse} from 'axios';
import {useQuery} from '@tanstack/react-query';
import {apiGet} from '../../../axiosConfig';
import {IApiDataResponse} from '../../../dto/API.dto';
import {ITimedMessageRequest} from '../../../dto/timed-message/TimedMessageRequest.dto';
import {ITimedRequestDateRange} from '../../../dto/timed-message/TimedRequestDateRange.dto';
import {ITimedRequestDayTime} from '../../../dto/timed-message/TimedRequestDayTime.dto';

export const getTimedMsgCalendarApiPath = (company_id: number) => {
  return `v1/company/${company_id}/message/timed-message/calendar/month`;
};

type ITimedMsgCalendarResponse = ITimedMessageRequest & {
  date_ranges: ITimedRequestDateRange[];
  day_times: ITimedRequestDayTime[];
};

const getTimedMsgCalendar = (
  company_id: number,
  params: {from: string; to: string},
) => {
  return apiGet(getTimedMsgCalendarApiPath(company_id), {params}).then(
    (
      response: AxiosResponse<IApiDataResponse<ITimedMsgCalendarResponse[]>>,
    ) => {
      return response.data.data;
    },
  );
};

export const useTimedMessageCalendar = (
  company_id: number,
  params: {from: string; to: string},
  enabled: boolean = true,
) => {
  const enableRender = company_id > 0 ? enabled : false;
  return useQuery({
    queryKey: [getTimedMsgCalendarApiPath(company_id), params],
    queryFn: () => getTimedMsgCalendar(company_id, params),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enableRender,
  });
};
