import {AxiosResponse} from 'axios';
import {useQuery} from '@tanstack/react-query';
import {apiGet} from '../../axiosConfig';
import {IApiDataResponse} from '../../dto/API.dto';
import {ISponsor} from '../../dto/Sponsor.dto';

// Get Sponsor Details By Id.
export const queryKeySponsorRequestDetails = (sponsorRequestId: number) => {
  return `v1/sponsor/${sponsorRequestId}`;
};

const getSponsorRequestDetails = async (sponsorRequestId: number) => {
  return await apiGet(queryKeySponsorRequestDetails(sponsorRequestId)).then(
    (response: AxiosResponse<IApiDataResponse<ISponsor>>) => {
      return response.data.data;
    },
  );
};
export const useRqSponsorRequestDetails = (
  sponsorRequestId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [queryKeySponsorRequestDetails(sponsorRequestId)],
    queryFn: () => getSponsorRequestDetails(sponsorRequestId),
    enabled: enabled,
    refetchOnWindowFocus: false,
  });
};

// Get Sponsor Details By token.
export const queryKeySponsorRequestDetailsByToken = (sponsorToken: string) => {
  return `v1/sponsor/${sponsorToken}/details`;
};
const getSponsorRequestDetailsByToken = async (sponsorToken: string) => {
  return await apiGet(queryKeySponsorRequestDetailsByToken(sponsorToken)).then(
    (response: AxiosResponse<IApiDataResponse<ISponsor>>) => {
      return response.data.data;
    },
  );
};
export const useRqSponsorRequestDetailsByToken = (
  sponsorToken: string,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [queryKeySponsorRequestDetailsByToken(sponsorToken)],
    queryFn: () => getSponsorRequestDetailsByToken(sponsorToken),
    enabled: enabled,
    refetchOnWindowFocus: false,
  });
};
