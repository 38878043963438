import {Box, CircularProgress} from '@mui/material';
import {useStylesed} from './commonStyled';

export const Loader = () => {
  const classes = useStylesed();
  return (
    <Box className={classes.r_center}>
      <CircularProgress color='inherit' />
    </Box>
  );
};
