import {ListItem, Skeleton} from '@mui/material';

const SkeletonListItem = () => {
  return (
    <ListItem sx={{py: 2}} divider>
      <Skeleton variant='rectangular' width={'100%'} height={'24px'} />
    </ListItem>
  );
};

export default SkeletonListItem;
