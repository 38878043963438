import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  styled,
  Typography,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useSelector, useDispatch} from 'react-redux';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {ChangeEvent, useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import {AxiosError, AxiosResponse} from 'axios';
import {useQueryClient} from '@tanstack/react-query';
import {RootState} from '../../../../store/configstore';
import MusicPreviewBtn from '../../../../components/music/MusicPreviewBtn';
import {loading} from '../../../../store/Slices/commonSlice';
import {apiPost} from '../../../../axiosConfig';
import {AxiosErrorToast} from '../../../../react_query/general/ReusableFunctions';

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const initialErrorValue = {
  file_name: {
    error: false,
    helperText: '',
  },
  name: {
    error: false,
    helperText: '',
  },
  script: {
    error: false,
    helperText: '',
  },
};
type GenericStingerTracksPageProps = {
  isTrackOpen: boolean;
  handleOpen: () => void;
};
const GenericStingersAddTracksModal = ({
  isTrackOpen,
  handleOpen,
}: GenericStingerTracksPageProps) => {
  const gloablDispatch = useDispatch();

  const {stinger_id} = useParams();
  const {loader} = useSelector((state: RootState) => state.common);
  const queryClient = useQueryClient();
  // Form Fields Config
  const [file, setFile] = useState<File | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [formFields, setFormFields] = useState({
    file_name: '',
    name: '',
    script: '',
  });
  const [errors, setErrors] = useState(initialErrorValue);
  const [isAudioFile, setIsAudioFile] = useState(false);
  const closeModal = useCallback(() => {
    handleOpen();
    Resetdata();
  }, [handleOpen]);
  // Form Actions
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormFields(prevVal => ({
      ...prevVal,
      [event.target.name]: event.target.value,
    }));
    if (event.target.name === 'name') {
      setErrors(prevVal => {
        prevVal.name.error = !event.target.value;
        prevVal.name.helperText = event.target.value ? '' : '*Required';
        return prevVal;
      });
    } else {
      setErrors(prevVal => {
        prevVal.script.error = !event.target.value;
        prevVal.script.helperText = event.target.value ? '' : '*Required';
        return prevVal;
      });
    }
  };
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0];
    setIsAudioFile(false);
    if (fileObj) {
      if (fileObj.type.startsWith('audio')) {
        setFile(fileObj);
        const url = URL.createObjectURL(fileObj);
        setAudioUrl(url);
        setFormFields(prevVal => ({
          ...prevVal,
          file_name: fileObj.name,
        }));
      } else {
        setFormFields(prevVal => ({
          ...prevVal,
          file_name: '',
        }));
        setAudioUrl(null);
        setFile(null);
        setIsAudioFile(true);
      }
      setErrors(prevVal => {
        prevVal.file_name.error = fileObj.name ? false : true;
        prevVal.file_name.helperText = fileObj.name ? '' : '*Required';
        return prevVal;
      });
    }
  };
  const Resetdata = () => {
    setFile(null);
    setAudioUrl(null);
    setFormFields({
      file_name: '',
      name: '',
      script: '',
    });
    setErrors(initialErrorValue);
  };
  // Form Submit Operations
  const ValidationProcess = useCallback(() => {
    let errorValues = {
      ...initialErrorValue,
    };
    let isError = false;
    if (!formFields.file_name) {
      errorValues.file_name.error = true;
      errorValues.file_name.helperText = '*Required';
      isError = true;
    }
    if (!formFields.name) {
      errorValues.name.error = true;
      errorValues.name.helperText = '*Required';
      isError = true;
    }
    if (!formFields.script) {
      errorValues.script.error = true;
      errorValues.script.helperText = '*Required';
      isError = true;
    }
    return {isError, errorValues};
  }, [formFields]);

  const AddStingerTracks = useCallback(() => {
    if (stinger_id) {
      const {isError, errorValues} = ValidationProcess();
      if (isError) {
        setErrors(errorValues);
      } else {
        const ApiData = new FormData();
        ApiData.append('name', formFields.name);
        ApiData.append('script', formFields.script);
        ApiData.append('track_song', file as Blob);
        gloablDispatch(loading(true));
        apiPost(
          `v1/global-content/stingers/${parseInt(stinger_id)}/add-track`,
          ApiData,
          {
            headers: {'Content-Type': 'multipart/form-data'},
          },
        )
          .then((response: AxiosResponse) => {
            if (response.status === 200) {
              gloablDispatch(loading(false));
              let queryValApi = [
                `v1/global-content/stingers/${parseInt(
                  stinger_id,
                )}/list-tracks`,
                {
                  stinger_id: parseInt(stinger_id),
                  per_page: 25,
                  page: 1,
                },
              ];
              queryClient.invalidateQueries({
                queryKey: queryValApi,
                refetchType: 'active',
              });
              closeModal();
            }
          })
          .catch((error: AxiosError) => {
            AxiosErrorToast(error, 'Error in uploading files');
            gloablDispatch(loading(false));
            closeModal();
          });
      }
    }
  }, [
    ValidationProcess,
    closeModal,
    file,
    formFields,
    gloablDispatch,
    queryClient,
    stinger_id,
  ]);
  return (
    <Dialog
      fullWidth
      open={isTrackOpen}
      onClose={() => {
        closeModal();
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>
        <Typography>Add Generic Stinger Tracks</Typography>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => {
          closeModal();
        }}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <TextField
          name='name'
          value={formFields.name}
          label='Track Name'
          fullWidth
          onChange={handleChange}
          error={errors.name.error}
          helperText={errors.name.helperText}
        />
        <TextField
          name='script'
          value={formFields.script}
          multiline
          label='Script'
          placeholder='Enter Your script'
          fullWidth
          onChange={handleChange}
          error={errors.script.error}
          helperText={errors.script.helperText}
        />
        <Stack direction='row' className='mt-3' spacing={1}>
          <Button
            component='label'
            variant='outlined'
            className='h-30'
            size='medium'
            startIcon={<CloudUploadIcon />}>
            Choose
            <VisuallyHiddenInput
              type='file'
              accept='audio/*'
              onChange={handleFileUpload}
            />
          </Button>
          {audioUrl && (
            <>
              <Typography>{formFields.file_name}</Typography>
              <MusicPreviewBtn id={audioUrl} url={audioUrl} />
            </>
          )}
        </Stack>
        {isAudioFile && (
          <Alert severity='error' sx={{mt: 1}}>
            Please upload a valid .mp3 file.
          </Alert>
        )}
      </DialogContent>
      <Stack
        spacing={2}
        direction={'row'}
        justifyContent={'center'}
        paddingBottom={'20px'}>
        {loader ? (
          <CircularProgress color='inherit' />
        ) : (
          <Button
            disabled={audioUrl === null}
            variant='outlined'
            onClick={() => {
              AddStingerTracks();
            }}>
            Submit
          </Button>
        )}
        <Button
          variant='outlined'
          onClick={() => {
            closeModal();
          }}>
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
};

export default GenericStingersAddTracksModal;
