import {Button, Dialog, DialogContent, Stack, Typography} from '@mui/material';
import {useCallback} from 'react';

const BackNavigationConfirmDialog = (props: {
  setDialog: (val: boolean) => void;
  closeStepper: () => void;
}) => {
  const handleClose = useCallback(() => {
    props.setDialog(false);
  }, [props]);

  return (
    <Dialog
      open
      maxWidth='xs'
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogContent className='px-10 py-8 text-center'>
        <Typography className='font-bold text-24 mb-3'>WARNING!!</Typography>
        <Typography className='mb-1 text-white-50'>
          If you close this window now you will lose all of your current work.
        </Typography>
        <Typography className='text-white-50'>
          Click Agree to close out or cancel to continue with this request
        </Typography>
        <Stack
          className='d-flex align-center justify-center pt-5 mt-3'
          direction='row'
          spacing={3}>
          <Button variant='contained' onClick={props.closeStepper}>
            Agree
          </Button>
          <Button variant='outlined' onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default BackNavigationConfirmDialog;
