import {Box, Card, Typography, styled} from '@mui/material';
import Rock from '../../../asset/icons/genres/Rock.png';
import Country from '../../../asset/icons/genres/Country.png';
import HIP_POP_RIP from '../../../asset/icons/genres/HIP-POP-RIP.png';
import Blues from '../../../asset/icons/genres/Blues.png';
import Stadium from '../../../asset/icons/genres/Stadium.png';
import Christian from '../../../asset/icons/genres/Christian.png';
import Edm from '../../../asset/icons/genres/Edm.png';
import {useStylesed} from '../../../components/styles/commonStyled';
interface GenreIcons {
  [genre: string]: string;
}

const genersIcons: GenreIcons = {
  ROCK: Rock,
  POP: Rock,
  COUNTRY: Country,
  'R&B/HIP HOP': HIP_POP_RIP,
  EDM: Edm,
  Blues: Blues,
  CHRISTIAN: Christian,
  'Stadium Hits': Stadium,
};

const FrontFlipCardContent = ({name}: {name: string}) => {
  const classes = useStylesed();
  return (
    <>
      <Box className={`${classes.c_center}`} sx={{flex: 1}}>
        {genersIcons[name] ? <img src={genersIcons[name]} alt='' /> : null}
      </Box>
      <Typography variant='h5' fontWeight={600} align='center'>
        {name}
      </Typography>
    </>
  );
};

export default FrontFlipCardContent;
export const FlipCard = styled(Card)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#373a3e' : '#f2f2f2',
  height: 180,
  padding: 8,
  borderRadius: '20px',
  cursor: 'pointer',
  '&.active': {
    backgroundColor: '#0187c4',
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0px 2px 30px 6px #0187c4'
        : '0px 2px 30px 6px rgba(92,91,91,.5)',
  },
  '&.active:hover': {
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0px 2px 30px 6px #0187c4'
        : '0px 2px 30px 6px rgba(92,91,91,.5)',
  },
}));

export const SubCategoryBox = styled(Box)(({theme}) => ({
  background: theme.palette.mode === 'dark' ? 'gray' : '#e1dede',
  borderRadius: 7,
  padding: 7,
  margin: 5,
  '&.active': {
    backgroundColor: theme.palette.mode === 'dark' ? '#136991' : '#66c2ed',
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0px 0px 4px 1px #0187c4'
        : '0px 0px 4px 1px rgba(92,91,91,.5)',
  },
}));
