import {ChangeEvent} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from '@mui/material';
type Props = {
  open: boolean;
  note: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (type: string) => void;
  handleClose: () => void;
};
const DialogNotes = ({
  open,
  note,
  handleChange,
  handleSubmit,
  handleClose,
}: Props) => {
  return (
    <Dialog maxWidth='sm' fullWidth={true} open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>Notes</DialogContentText>
        <TextField
          fullWidth
          multiline
          rows={4}
          id='message-note-field'
          value={note}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={note.trim() === ''}
          onClick={() => handleSubmit('reassign')}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogNotes;
