import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import {useEffect} from 'react';
import {AxiosError} from 'axios';
import {ErrorResponse, useNavigate} from 'react-router-dom';
import {Edit, Refresh} from '@mui/icons-material';
import IconTick from '../../asset/icons/icon_tick.svg';

type Props = {
  apiState: {
    status: string; // pending|success/failed;
    error: null | AxiosError;
    message?: string;
  };
  onRetry: () => void;
  onEdit: () => void;
  onClose?: () => void;
};

const DialogApiRequestStatus = ({
  apiState,
  onRetry,
  onEdit,
  onClose,
}: Props) => {
  const navigate = useNavigate();
  const className =
    apiState.message && apiState.message === 'tnn-alter-message'
      ? 'w-500 h-300 flex flex-column justify-center'
      : 'w-430 h-255 flex flex-column justify-center';

  useEffect(() => {
    const handleClose = () => {
      if (onClose) {
        onClose();
      }
    };

    let timer: ReturnType<typeof setTimeout>;
    if (apiState.status === 'success') {
      timer = setTimeout(() => {
        handleClose();
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [apiState.status, navigate, onClose]);

  if (apiState.status === 'pending') {
    return (
      <Dialog maxWidth='sm' fullWidth open>
        <DialogContent sx={{padding: '0 !important'}}>
          <Stack spacing={1}>
            <Alert
              icon={<CircularProgress size='1em' />}
              severity='info'
              className='loading-alert'>
              <AlertTitle>Submitting your request</AlertTitle>
              Do Not Close This Window (Or Click The Back Button)
            </Alert>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  } else if (apiState.status === 'success') {
    return (
      <Dialog maxWidth='sm' open>
        <DialogContent className={className} sx={{background: '#132921'}}>
          <Box
            className='w-61 h-61 flex flex-column justify-center rounded self-center'
            sx={{background: '#06A018'}}>
            <img
              src={IconTick}
              alt={'Asset is loading..'}
              className='w-22 h-15 self-center'
            />
          </Box>
          {apiState.message && apiState.message === 'tnn-alter-message' ? (
            <Typography
              aria-label='success-message'
              className='text-white text-16 self-center mt-12'>
              Thanks for joining The Neptune Network!
              <br />
              <br />
              A TNN team member will reach out to you very soon to collect
              important information about attendance at your sporting events.
              This phone call will only take 5 minutes, but it will give us the
              information we need to help secure sponsorships on your GameTime
              station.
              <br />
              <br />
              Once again -- welcome to The Neptune Network!
              <br />
              <br />
            </Typography>
          ) : (
            <Typography
              aria-label='success-message'
              className='text-white text-16 self-center mt-12'>
              {apiState.message
                ? apiState.message
                : 'Your request submitted successfully!'}
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    );
  } else if (apiState.status === 'error') {
    const responseStatus = apiState.error?.response?.status;

    const errorMessage =
      apiState.error?.response?.data.message || apiState.error?.message;

    // Create a generic error alert
    const commonErrorAlert = <Typography>{errorMessage}</Typography>;
    const individualErrorAlerts: React.ReactNode[] = [];

    if (responseStatus === 422) {
      // If status is 422, extract validation errors
      const errors: ErrorResponse = apiState.error?.response?.data?.errors;
      // Loop through validation errors and display individual toasts
      Object.entries(errors).forEach(([field, msgObj]) => {
        if (msgObj.length) {
          // Display individual error messages
          const individualError = (
            <Typography>
              {field}:{msgObj[0]}
            </Typography>
          );
          individualErrorAlerts.push(individualError);
        }
      });
    }

    return (
      <Dialog maxWidth='sm' fullWidth open>
        <DialogContent>
          <Alert severity='error' className='loading-error'>
            <AlertTitle>Your request failed!</AlertTitle>
            {responseStatus === 422 ? individualErrorAlerts : commonErrorAlert}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<Edit />} variant='contained' onClick={onEdit}>
            Edit
          </Button>
          <Button
            startIcon={<Refresh />}
            color='info'
            variant='contained'
            onClick={onRetry}>
            Retry
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return <p>Unknown api state: {apiState.status}</p>;
};

export default DialogApiRequestStatus;
