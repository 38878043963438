import {Typography, IconButton, Grid, Box, Button, Alert} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import {Add} from '@mui/icons-material';
import {TimePicker} from '@mui/x-date-pickers';
import {useCallback, useMemo, useState} from 'react';
import TimedMsgRequestWeekDayForm from './TimedMsgRequestWeekDayForm';
import TimedMsgRequestTimeRangePicker, {
  iTimedMsgTimeRange,
} from './TimedMsgRequestTimeRangePicker';
import {ITimedDuplicateTimes} from '../TimedMessageRequest';

export interface iTimedMsgTimesItem {
  id: number;
  selectedDays: string[];
  timeRanges: iTimedMsgTimeRange[];
}

type Props = {
  title: string;
  item: iTimedMsgTimesItem;
  selectedWeekdays: string[];
  enableWeekdays: boolean;
  removeBtnDisabled: boolean;
  onRemove: (id: number) => void;
  setDaysTimes: React.Dispatch<React.SetStateAction<iTimedMsgTimesItem[]>>;
  availableDays?: string[];
  duplicateTimes?: ITimedDuplicateTimes[];
};

const MAX_ITEMS = 30;
const TimedMsgRequestTimesCard = ({
  title,
  item,
  selectedWeekdays,
  enableWeekdays,
  removeBtnDisabled,
  onRemove,
  setDaysTimes,
  availableDays,
  duplicateTimes,
}: Props) => {
  const [time, setTime] = useState<Dayjs | null>(null);

  const handleAdd = useCallback(() => {
    if (time && time.isValid()) {
      setDaysTimes(prevItems => {
        const index = prevItems.findIndex(o => o.id === item.id);
        if (index === -1) return prevItems;
        const newItems = [...prevItems];
        const newItem = {
          ...newItems[index],
        } as iTimedMsgTimesItem;
        if (newItem.timeRanges[0]?.start_time !== null) {
          newItem.timeRanges = [
            ...newItem.timeRanges,
            {id: Date.now(), start_time: time},
          ];
        } else {
          newItem.timeRanges = [{id: Date.now(), start_time: time}];
        }

        newItems.splice(index, 1, newItem);
        return newItems;
      });
      setTime(null);
    }
  }, [item.id, setDaysTimes, time]);

  const handleRemove = () => {
    onRemove(item.id);
  };

  const isReachedMaxItems = useMemo(
    () => item.timeRanges.length === MAX_ITEMS,
    [item.timeRanges.length],
  );

  const handleTimeChange = useCallback((val: Dayjs | null) => {
    setTime(val);
  }, []);

  const timeExistenceText = useMemo(() => {
    if (duplicateTimes) {
      for (const dayTimes of duplicateTimes) {
        const existedDays = dayTimes.days
          .map(d => d.charAt(0).toUpperCase() + d.slice(1).toLowerCase())
          .filter(d => item.selectedDays.includes(d));
        const existedTimes = dayTimes.times
          .map(t => dayjs(t, 'HH:mm:ss'))
          .filter(t =>
            item.timeRanges.some(
              range => range.start_time?.isSame(t, 'second'),
            ),
          );
        if (existedDays.length && existedTimes.length) {
          return `${existedDays.join(' / ')} - ${existedTimes
            .map(t => t.format('hh:mm A'))
            .join(' / ')}`;
        }
      }
    }
    return '';
  }, [duplicateTimes, item.selectedDays, item.timeRanges]);

  return (
    <Grid container className='mb-2 radius-5'>
      <Grid
        item
        xs={12}
        sx={{
          background: `var(--blue_variant41)`,
          borderRadius: '7px',
        }}
        className='p-5'>
        <Box className='flex justify-between items-center '>
          <Typography className='text-white-50'>{title}</Typography>
          {enableWeekdays && (
            <Button
              variant='text'
              aria-label='delete'
              onClick={handleRemove}
              disabled={removeBtnDisabled}
              color='error'>
              Delete Slot
            </Button>
          )}
        </Box>

        {timeExistenceText ? (
          <Alert severity='error'>Time conflict: {timeExistenceText}</Alert>
        ) : null}

        {enableWeekdays && (
          <TimedMsgRequestWeekDayForm
            itemId={item.id}
            selected={item.selectedDays}
            selectedWeekdays={selectedWeekdays}
            setDaysTimes={setDaysTimes}
            availableDays={availableDays ?? []}
          />
        )}
        <Box className='flex items-end'>
          <TimePicker
            className='mt-5 mb-0 mr-6 max-w-170'
            label='Select Time'
            value={time}
            onChange={handleTimeChange}
            timeSteps={{hours: 1, minutes: 1, seconds: 60}}
            slotProps={{textField: {size: 'small'}}}
          />

          <IconButton
            className='w-40 h-40 font-bold radius-5'
            sx={{
              background: `var(--blue-variant10)`,
              border: `1px solid var(--blue_variant40)`,
            }}
            aria-label='add-time'
            onClick={handleAdd}
            disabled={isReachedMaxItems}>
            <Add />
          </IconButton>
        </Box>

        <Box className='h-110 overflow-auto scrollbar mt-3'>
          {item.timeRanges.map(timeRange => (
            <TimedMsgRequestTimeRangePicker
              key={timeRange.id}
              itemId={item.id}
              timeRange={timeRange}
              setDaysTimes={setDaysTimes}
              disableDelete={item.timeRanges.length === 1}
            />
          ))}
        </Box>
        {isReachedMaxItems && (
          <Typography variant='caption'>
            Maximum of {MAX_ITEMS} start time can be added for each request
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default TimedMsgRequestTimesCard;
