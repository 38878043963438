import {Grid, Skeleton} from '@mui/material';

const CalenderSkeleton = ({width}: {width: number}) => {
  const renderSkeletons = () => {
    const skeletons = [];
    for (let i = 0; i < 35; i++) {
      skeletons.push(
        <Grid item xs key={i}>
          <Skeleton sx={{width: width / 7.5}} height={90} />
        </Grid>,
      );
    }
    return skeletons;
  };

  return (
    <Grid container className='h-full mb-20'>
      {renderSkeletons()}
    </Grid>
  );
};

export default CalenderSkeleton;
