import {ButtonProps, Drawer, IconButton} from '@mui/material';
import {styled} from '@mui/material/styles';
import HeaderAdminMenu from '../layouts/HeaderAdminMenu';
import {useState} from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

type iconMenuProps = ButtonProps & {
  MenuIcon?: React.ReactNode;
  className?: string;
};

const IconMenuButton: React.FC<iconMenuProps> = ({
  MenuIcon,
  ...buttonProps
}) => {
  const DrawerStyle = styled(Drawer)`
    & .MuiDrawer-paper {
      position: absolute !important;
      left: 0 !important;
      top: 93px !important;
      height: calc(100% - 92px);
      width: 442px;
      borderradius: 0;
      background: var(--blue_variant28);
    }
    & .MuiBackdrop-root {
      background: transparent !important;
    }
  `;
  const [menuOpen, setMenuOpen] = useState(false);
  const handleClick = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <>
      <IconButton
        size='large'
        color='primary'
        {...buttonProps}
        sx={{
          background: 'var(--blue-variant7) !important',
        }}
        onClick={handleClick}>
        {menuOpen ? (
          <CloseOutlinedIcon className='w-25 h-25' />
        ) : (
          <MenuOutlinedIcon className='w-30 h-30' />
        )}
      </IconButton>
      <DrawerStyle open={menuOpen} onClose={handleClick} className='admin-menu'>
        <HeaderAdminMenu handleClick={handleClick} />
      </DrawerStyle>
    </>
  );
};

export default IconMenuButton;
