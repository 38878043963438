import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import {useSelector} from 'react-redux';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useLocation, useNavigate} from 'react-router-dom';
import {apiPut} from '../../../axiosConfig';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import EsmcDatesTimes, {newStartEndDateTime} from './EsmcDatesTimes';
import DialogApiRequestStatus from '../../../components/dialogs/DialogApiRequestStatus';
import {IMsgReqEventDateType} from '../../../dto/MessageRequest.dto';
import {IEsmStatus} from '../../../dto/EventSpecificMessageRequest.dto';
import {daysOfWeek, IStartEndDateTime} from '../../../dto/DateTime.dto';
import {DialogClose} from '../../../components/dialogs/DialogFrame/DialogClose';

type Props = {
  status: IEsmStatus;
  handleCancel: () => void;
  messageRequestId: number;
};

const defaultApiState = {
  status: '',
  error: null,
};

const EventDialog = ({status, messageRequestId, handleCancel}: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const companyId = useSelector(companyIdSelector);
  const [apiState, setApiState] = useState(defaultApiState);
  const [eventDateType, setEventDateType] = useState<IMsgReqEventDateType>(1);
  const [multipleDates, setMultipleDates] = useState<IStartEndDateTime[]>([
    {...newStartEndDateTime, id: Date.now()},
  ]);
  const [days, setDays] = useState<string[]>([]);
  const [savedOldDays, setSavedOldDays] = useState<string[]>([]);

  const isEmptyDate = useMemo(() => {
    return multipleDates.some(
      date =>
        !date.start_date ||
        !date.start_date.isValid() ||
        !date.end_date ||
        !date.end_date.isValid() ||
        !date.start_time ||
        !date.end_time ||
        !date.start_time.isValid() ||
        !date.end_time.isValid() ||
        (date.start_time &&
          date.end_time &&
          date.start_time.isSame(date.end_time)),
    );
  }, [multipleDates]);

  const isInvalidDate = useMemo(() => {
    let invalidDate = false;
    multipleDates.forEach((dates1, index1) => {
      const sDate1 = dates1.start_date;
      const eDate1 = dates1.end_date;
      const sTime1 = dates1.start_time;
      const eTime1 = dates1.end_time;
      if (sDate1 && eDate1 && sTime1 && eTime1) {
        multipleDates.forEach((dates2, index2) => {
          const sDate2 = dates2.start_date;
          const eDate2 = dates2.end_date;
          const sTime2 = dates2.start_time;
          const eTime2 = dates2.end_time;
          if (
            index1 !== index2 &&
            sDate2 &&
            eDate2 &&
            sTime2 &&
            eTime2 &&
            (sDate2.isBetween(sDate1, eDate1, 'day', '[]') ||
              eDate2.isBetween(sDate1, eDate1, 'day', '[]')) &&
            (sTime2.isBetween(sTime1, eTime1, 'minute', '[]') ||
              eTime2.isBetween(sTime1, eTime1, 'minute', '[]'))
          ) {
            invalidDate = true;
          }
        });
      }
    });
    return invalidDate;
  }, [multipleDates]);

  const handleSubmit = useCallback(() => {
    setApiState({
      status: 'pending',
      error: null,
    });
    const url =
      status === 'Active'
        ? `/v1/company/${companyId}/message/event-message/${messageRequestId}/inactive`
        : `/v1/company/${companyId}/message/event-message/${messageRequestId}/active`;
    const multiple_dates = multipleDates.map(o => ({
      start_date: dayjs(o.start_date).format('YYYY-MM-DD'),
      end_date: dayjs(o.end_date).format('YYYY-MM-DD'),
      start_time: dayjs(o.start_time).format('HH:mm'),
      end_time: dayjs(o.end_time).format('HH:mm'),
    }));
    const payload = {
      event_date_type: eventDateType,
      multiple_dates,
      week_days: daysOfWeek.filter(day => days.includes(day)),
    };
    apiPut(url, payload)
      .then(_res => {
        queryClient.invalidateQueries({
          queryKey: [`v1/company/${companyId}/message/event-message`],
          refetchType: 'active',
        });
        if (location.pathname.includes('/view')) {
          navigate('/messages/event-message');
        }
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({
          status: 'error',
          error,
        });
      });
  }, [
    companyId,
    days,
    eventDateType,
    location.pathname,
    messageRequestId,
    multipleDates,
    navigate,
    queryClient,
    status,
  ]);

  useEffect(() => {
    if (eventDateType === 2) {
      setSavedOldDays(days);
    }
  }, [days, eventDateType]);

  if (apiState.status !== '') {
    return (
      <DialogApiRequestStatus
        onEdit={() => setApiState(defaultApiState)}
        onRetry={handleSubmit}
        onClose={handleCancel}
        apiState={apiState}
      />
    );
  }

  return (
    <Dialog
      open
      onClose={handleCancel}
      maxWidth='md'
      fullWidth
      oaria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle>
        <Typography className='alignCenter'>Change Request Status</Typography>
        <DialogClose onClose={handleCancel} />
      </DialogTitle>
      <DialogContent className='flex items-center justify-center'>
        {status === 'Inactive' && (
          <EsmcDatesTimes
            invalidDate={isInvalidDate}
            event_date_type={eventDateType}
            setEventDateType={setEventDateType}
            multiple_dates={multipleDates}
            setMultipleDates={setMultipleDates}
            days={days}
            setDays={setDays}
            savedOldDays={savedOldDays}
          />
        )}
        {status === 'Active' && (
          <>
            <h5 className='text-white text-center'>
              Are you sure to deactivate this message?
            </h5>
          </>
        )}
      </DialogContent>
      <DialogActions className='flex items-center justify-center'>
        {status === 'Inactive' ? (
          <Button
            className='restore-btn cursor-pointer'
            variant='contained'
            onClick={handleSubmit}
            disabled={isInvalidDate || isEmptyDate}>
            Restore
          </Button>
        ) : (
          <Button
            variant='contained'
            className='deactivate-btn'
            color='error'
            onClick={handleSubmit}
            sx={{
              background: `var(--red_variant1)`,
              border: `1px solid var(--red_variant2)`,
            }}>
            Deactivate
          </Button>
        )}
        <Button
          variant='contained'
          onClick={handleCancel}
          sx={{border: `1px solid #1976d2`}}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventDialog;
