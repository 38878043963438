import {ReactNode, lazy} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {Grid, Stack} from '@mui/material';

import {CanRouteAny} from '../components/UserCan';
import BaseMsgDataGrid from '../pages/contacts/messages/base/BaseMsgDataGrid';
import EventMessageDataGrid from '../pages/messages/event-specific/EventMessageDataGrid';
import OndemandMsgDataGrid from '../pages/messages/on-demand-msg/OndemandMsgDataGrid';
import StingersDataGrid from '../pages/messages/stingers/StingersDataGrid';
import MessagesTabNavigate from '../pages/messages/MessagesTabNavigate';
import IconMessage from '../asset/icons/icon_messaging.svg';
import {
  ProductNrSzOnly,
  ProductTrampolineOnly,
} from '../components/ProductOnlyRoute';
import Header from '../components/header/Header';
import ViewOnDemandMgsList from '../pages/contacts/messages/on-demand/ViewOnDemandMgsList';
import TimedMessageHome from '../pages/messages/timed-message/TimedMessageHome';

const MessageSelection = lazy(
  () => import('../pages/contacts/messages/MessageSelection'),
);

const WristbandRoutes = lazy(
  () => import('./message/wristband-message/WristbandRoutes'),
);
const BaseMsgRoutes = lazy(
  () => import('./message/base-message/BaseMessageRoutes'),
);

const CreateBaseMsg = lazy(
  () => import('../pages/contacts/messages/base/CreateBaseMsg'),
);

const BaseMessageApprovalProcess = lazy(
  () =>
    import(
      '../pages/contacts/edit_messages/base_message/BaseMessageApprovalProcess'
    ),
);
const BaseMgsMultipleApprovalProcess = lazy(
  () =>
    import(
      '../pages/contacts/edit_messages/base_message/BaseMgsMultipleApprovalProcess'
    ),
);

type MessageLayoutProps = {
  children: ReactNode; // or specify a more specific type for children if needed
};
const messageRouteLabel = [
  {text: 'Event Specific', route: 'event-message'},
  {text: 'On Demand', route: 'ondemand-message'},
  {text: 'Base', route: 'base-message'},
  {text: 'Timed', route: 'timed-message'},
  {text: 'Wristband', route: 'wristband-message'},
  {text: 'Stinger', route: 'stinger-message'},
];
const MessageLayout = ({children}: MessageLayoutProps) => {
  const location = useLocation();
  const currentPath = location.pathname.split('/');
  const currentRoute = currentPath[2];
  const currentLabel = messageRouteLabel.find(
    label => label.route === currentRoute,
  )?.text;

  return (
    <Stack direction='column' className='px-11'>
      <Header IconMessage={IconMessage} Title='Messaging' />
      <Grid
        container
        className='flex justify-between items-center px-5 min-h-65 blue-variant17 radius-10-0'>
        <Grid item xl={3} lg={3} md={3} sm={2}>
          <h4 className='px-12 font-bolder m-0 text-white-50'>
            {currentLabel}
          </h4>
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={10} className='flex justify-end'>
          <MessagesTabNavigate />
        </Grid>
      </Grid>
      {children}
    </Stack>
  );
};

const MessagesRoutes = () => {
  return (
    <Routes>
      <Route index element={<MessageSelection />} />
      <Route
        path='wristband-message/*'
        element={
          <ProductTrampolineOnly isRoute>
            <MessageLayout>
              <WristbandRoutes />
            </MessageLayout>
          </ProductTrampolineOnly>
        }
      />
      <Route
        path='ondemand-message/*'
        element={
          <CanRouteAny scope='is-admin,partner,on-demand-message:list,on-demand-message:create,on-demand-message:update-status,on-demand-message:view,on-demand-message:delete,update-on-demand-message'>
            <MessageLayout>
              <OndemandRoutes />
            </MessageLayout>
          </CanRouteAny>
        }
      />
      <Route
        path='event-message/*'
        element={
          <CanRouteAny scope='is-admin,partner,event-message:list,event-message:create,event-message:view,event-message:update,event-message:update-status,event-message:delete'>
            <MessageLayout>
              <EventMsgRoutes />
            </MessageLayout>
          </CanRouteAny>
        }
      />
      <Route
        path='timed-message/*'
        element={
          <ProductNrSzOnly isRoute>
            <CanRouteAny scope='is-admin,partner,timed-message:list,timed-message:create,timed-message:view,timed-message:delete'>
              <MessageLayout>
                <TimedMsgRoute />
              </MessageLayout>
            </CanRouteAny>
          </ProductNrSzOnly>
        }
      />
      <Route
        path='base-message/*'
        element={
          <CanRouteAny scope='is-admin,partner,base-message:list,base-message:create,base-message:view,base-message:update,base-message:delete,base-message:convert-base-message'>
            <MessageLayout>
              <BaseMsgRoute />
            </MessageLayout>
          </CanRouteAny>
        }
      />
      <Route
        path='stinger-message'
        element={
          <CanRouteAny scope='is-admin,partner,stinger-message:list,stinger-message:create,stinger-message:update-status,stinger-message:delete'>
            <MessageLayout>
              <StingersDataGrid />
            </MessageLayout>
          </CanRouteAny>
        }
      />
    </Routes>
  );
};

export default MessagesRoutes;

const OndemandRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <CanRouteAny scope='is-admin,partner,on-demand-message:list,on-demand-message:create,on-demand-message:update-status,on-demand-message:view,on-demand-message:delete,update-on-demand-message'>
            <OndemandMsgDataGrid />
          </CanRouteAny>
        }
      />
      <Route
        path=':id/view'
        element={
          <CanRouteAny scope='is-admin,partner,on-demand-message:update-status,on-demand-message:view'>
            <ViewOnDemandMgsList />
          </CanRouteAny>
        }
      />
    </Routes>
  );
};

const EventMsgRoutes = () => {
  return (
    <Routes>
      <Route index element={<EventMessageDataGrid />} />
    </Routes>
  );
};

const TimedMsgRoute = () => {
  return (
    <Routes>
      <Route index element={<TimedMessageHome />} />
    </Routes>
  );
};

const BaseMsgRoute = () => {
  return (
    <Routes>
      <Route index element={<BaseMsgDataGrid />} />
      <Route path='*' element={<BaseMsgRoutes />} />
      <Route path='add' element={<CreateBaseMsg />} />
      <Route
        path=':company_id/:edit_message_request_id/pending'
        element={<BaseMessageApprovalProcess />}
      />
      <Route
        path=':company_id/:edit_message_request_id/revision'
        element={<BaseMgsMultipleApprovalProcess />}
      />
    </Routes>
  );
};
