import { Dispatch, useCallback, SetStateAction } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ISponsorReactiveProp } from '../../dto/Sponsor.dto';
import OutlinedRadioControlLabel from '../../components/OutlinedRadioControlLabel';
import UploadButton from '../../components/styles/UploadButton';
type Props = {
  sponsorReactiveType: ISponsorReactiveProp;
  setSponsorReactiveType: Dispatch<SetStateAction<ISponsorReactiveProp>>;
};

const SponsorReactiveType = ({
  sponsorReactiveType,
  setSponsorReactiveType,
}: Props) => {
  const handleChange = useCallback(
    (value: string | File | undefined, field: 'reactive_type' | 'files') => {
      setSponsorReactiveType(prevInfo => ({ ...prevInfo, [field]: value }));
    },
    [setSponsorReactiveType],
  );
  const handleRemoveFile = useCallback(() => {
    setSponsorReactiveType(prevInfo => ({ ...prevInfo, files: undefined }));
  }, [setSponsorReactiveType]);
  return (
    <>
      <Typography variant='h5' className='text-white font-bold mb-8'>
        Reactivate Sponsor
      </Typography>
      <RadioGroup
        name='is_reactive_type_radio'
        onChange={event => handleChange(event.target.value, 'reactive_type')}>
        <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
          <Grid item xs>
            <OutlinedRadioControlLabel
              value='0'
              label='Use last year commercial'
            />
          </Grid>
          <Grid item xs>
            <OutlinedRadioControlLabel
              value='1'
              label='Attach new commercial'
            />
          </Grid>
          <Grid item xs>
            <OutlinedRadioControlLabel
              value='2'
              label='Fill Commercial form'
            />
          </Grid>
        </Grid>
      </RadioGroup>
      {sponsorReactiveType.reactive_type === '1' && (
        <>
          <Typography variant='subtitle1'>
            Attach your new commercial file
          </Typography>

          <UploadButton handleChange={handleChange} />

        </>
      )}
      {sponsorReactiveType.reactive_type === '1' &&
        sponsorReactiveType.files &&
        sponsorReactiveType.files && (
          <List sx={{ mt: 2 }}>
            <ListItem sx={{ p: 2, background: '#0d1525' }}>
              <ListItemText primary={sponsorReactiveType.files?.name} />
              <ListItemIcon onClick={handleRemoveFile}>
                <DeleteOutlineIcon />
              </ListItemIcon>
            </ListItem>
          </List>
        )}
    </>
  );
};

export default SponsorReactiveType;
