import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IApiPaginationResponse2} from '../../dto/API.dto';
import {IMediaTrack, ITrackCategory} from '../../dto/MediaTrack.dto';
import {apiGet} from '../../axiosConfig';
import {
  IConditionalOperators,
  IDataGridProSearchSort,
} from '../../dto/Html.dto';

export const getMediaTracksProPaginateApiPath = (companyId: Number) => {
  return `/v1/music-selection/company/${companyId}/media-track/pro`;
};
export const getMediaTracksWithoutCompanyProPaginateApiPath = () => {
  return `/v1/music-selection/media-track/pro`;
};

const getMediaTracks = (params: IMediaTracksQueryParams, companyId: number) => {
  return apiGet(getMediaTracksProPaginateApiPath(companyId), {params}).then(
    (response: AxiosResponse<IApiPaginationResponse2<IMediaTrack>>) => {
      return response.data;
    },
  );
};

const getMediaTracksWithoutCompany = (params: IMediaTracksQueryParams) => {
  return apiGet(getMediaTracksWithoutCompanyProPaginateApiPath(), {
    params,
  }).then((response: AxiosResponse<IApiPaginationResponse2<IMediaTrack>>) => {
    return response.data;
  });
};

export type IMediaTracksQueryParams = IDataGridProSearchSort & {
  starts_with?: string;
  track_category?: ITrackCategory;
  genres?: number[];
  mediaTags?: number[];
  trackIds?: number[];
  type?: string;
  event_id?: number[];
  school_id?: number[];
  station_id?: number[];
} & (
    | {
        track_length: number;
        track_length_condition: IConditionalOperators;
      }
    | {track_length?: undefined; track_length_condition?: undefined}
  );

export const useRqMediaTracksPaginate = (
  query: IMediaTracksQueryParams = {page: 1, pageSize: 10},
  companyId: number,
  enabled: boolean = true,
) => {
  const params = {...query, page: query.page + 1};
  return useQuery({
    queryKey: [getMediaTracksProPaginateApiPath(companyId), params],
    queryFn: () => getMediaTracks(params, companyId),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(companyId) && enabled,
  });
};

export const useRqMediaTracksWithoutCompanyPaginate = (
  query: IMediaTracksQueryParams = {page: 1, pageSize: 10},
  enabled: boolean = true,
) => {
  const params = {...query, page: query.page + 1};
  return useQuery({
    queryKey: [getMediaTracksWithoutCompanyProPaginateApiPath(), params],
    queryFn: () => getMediaTracksWithoutCompany(params),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};
