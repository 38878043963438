import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import {useSelector} from 'react-redux';
import {Box, Grid, Stack, TextField, Typography} from '@mui/material';
import {IScripts} from './BaseMessagesReducer';
import {isGTSelector} from '../../../../store/Slices/companySlice';
import {
  addNewMessageToBaseMessages,
  invalidBaseMessage,
  invalidBaseMessageName,
  updateBaseMessages,
} from './BaseMessageFunctionalities';
import MessageAddButton from '../../../../components/styles/buttons/MessageAddButton';
import {MessageAlert} from '../../../messages/event-specific/EsmMessages';
import MusicPreviewBtn from '../../../../components/music/MusicPreviewBtn';
import {CustomTextarea} from '../../../../components/styles/CustomTextarea';

const BaseMessageForm = ({
  message,
  setMessages,
  isNewBaseMessage,
  formStatus = null,
}: {
  message: IScripts;
  setMessages: Dispatch<SetStateAction<IScripts[]>>;
  isNewBaseMessage: boolean;
  formStatus: string | null;
}) => {
  const isGT = useSelector(isGTSelector);
  const handleName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMessages(prev =>
        updateBaseMessages({
          messages: prev,
          uuid: message.uuid,
          value: {...message, name: event.target.value},
        }),
      );
    },
    [message, setMessages],
  );
  const handleMessage = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMessages(prev =>
        updateBaseMessages({
          messages: prev,
          uuid: message.uuid,
          value: {...message, message: event.target.value},
        }),
      );
    },
    [message, setMessages],
  );

  const handleAddMessageDetails = useCallback(() => {
    setMessages(prev =>
      addNewMessageToBaseMessages({
        messages: prev,
      }),
    );
  }, [setMessages]);

  const inValidForm = useMemo(() => {
    if (
      invalidBaseMessageName(`${message?.name}`) ||
      invalidBaseMessage(message)
    ) {
      return true;
    }

    return false;
  }, [message]);

  return (
    <>
      <MessageAlert name={`${message?.name}`} message={message?.message} />
      <Box>
        <Grid container alignItems='center'>
          <Grid item md={6}>
            <TextField
              id='message-name-text'
              placeholder='Message Name*'
              variant='outlined'
              value={message?.name}
              onChange={handleName}
              className='m-h-40 h-40 w-full max-w-400 p-0 my-3'
              InputProps={{
                style: {
                  minHeight: '40px',
                },
              }}
            />
          </Grid>
        </Grid>

        <Stack
          spacing={1}
          direction='row'
          alignItems='end'
          className='textarea_newmsg mb-5'>
          <CustomTextarea
            placeholder='Enter Message here'
            onChange={handleMessage}
            value={message?.message}
            disabled={formStatus === 'Active' || formStatus === 'Inactive'}
          />
          <Stack spacing={2}>
            {message?.media_track ? (
              <MusicPreviewBtn
                id={message.uuid}
                url={message.media_track.url}
              />
            ) : null}
            {!formStatus ? (
              <MessageAddButton
                isValid={!inValidForm}
                onClick={handleAddMessageDetails}
              />
            ) : null}
          </Stack>
        </Stack>
      </Box>
      {isNewBaseMessage ? (
        <Typography className='text-13 text-white opacity-70'>
          Note: You will need a minimum of {isGT ? 'five' : 'six'} base messages
          before you will be able to submit your request.
        </Typography>
      ) : null}
    </>
  );
};

export default BaseMessageForm;
