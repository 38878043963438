import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IApiDataResponse} from '../../dto/API.dto';
import axiosInstance from '../../axiosConfig';
import {IUserBase} from '../../dto/User.dto';

// Get base messages count
type IBaseMessageCount = {
  count: number;
};
export const getBaseMessageCountApiPath = (companyId: number) =>
  `v1/company/${companyId}/message/base-message/count`;

const getBaseMessagesCount = (companyId: number) => {
  return axiosInstance
    .get(getBaseMessageCountApiPath(companyId))
    .then((response: AxiosResponse<IApiDataResponse<IBaseMessageCount>>) => {
      return response.data.data;
    });
};

export const useRqBaseMessagesCount = (
  companyId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getBaseMessageCountApiPath(companyId)],
    queryFn: () => getBaseMessagesCount(companyId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled && Boolean(companyId),
  });
};

// Get base messages count
export const getBaseMessageVoiceArtistApiPath = (companyId: number) =>
  `v1/company/${companyId}/message/base-message/voice-artist`;

const getBaseMessageVoiceArtist = (companyId: number) => {
  return axiosInstance
    .get(getBaseMessageVoiceArtistApiPath(companyId))
    .then((response: AxiosResponse<IApiDataResponse<IUserBase[]>>) => {
      return response.data.data;
    });
};

export const useRqBaseMessageVoiceArtist = (
  companyId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getBaseMessageVoiceArtistApiPath(companyId)],
    queryFn: () => getBaseMessageVoiceArtist(companyId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled && Boolean(companyId),
  });
};
