import {Edit} from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@mui/material';
import {ReactNode, useCallback} from 'react';

type StepperWrapProps = {
  children: ReactNode;
  title?: string;
};
const StepperCard: React.FC<StepperWrapProps> = ({children, title}) => {
  return (
    <>
      {title && (
        <Typography variant='h5' className='font-bold text-white mb-4'>
          {title}
        </Typography>
      )}
      {children}
    </>
  );
};

export default StepperCard;

export const StepperReviewCard = ({
  title,
  navigate_step,
  children,
  onStepClick,
}: {
  title: string;
  navigate_step: number;
  children: JSX.Element;
  onStepClick: (index: number) => void;
}) => {
  const handlleClick = useCallback(() => {
    onStepClick(navigate_step);
  }, [onStepClick, navigate_step]);

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <IconButton onClick={handlleClick}>
            <Edit />
          </IconButton>
        }
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};
