import {useCallback, useEffect, useState} from 'react';
import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {IRestrictedSponsors} from '../../../react_query/tnn-participation-form/ParticipationForm';
import InputSearch from '../../../components/styles/InputSearch';
import {OtherNamingCmpt} from './OtherNamingCmpt';
import Iconchecked from '../../../asset/icons/icon_checked.svg';
import IconUnchecked from '../../../asset/icons/icon_unchecked.svg';

export const RestrictedSponsorshipSelectCmpt = ({
  restrictedOptions,
  setRestrictedOptions,
  otherCategory,
  setOtherCategory,
}: {
  restrictedOptions: IRestrictedSponsors[];
  setRestrictedOptions: React.Dispatch<
    React.SetStateAction<IRestrictedSponsors[]>
  >;
  otherCategory: string;
  setOtherCategory: (val: string) => void;
}) => {
  const [restrictedOptionList, setRestrictedOptionList] =
    useState<IRestrictedSponsors[]>(restrictedOptions);
  const [search, setSearch] = useState('');

  const handleChange = useCallback(
    (id: number) => {
      setRestrictedOptions(prev => {
        const newData = prev.map(value => {
          if (value.id === id) {
            return {...value, selected: !value.selected};
          }
          return value;
        });
        return newData;
      });
    },
    [setRestrictedOptions],
  );

  useEffect(() => {
    if (search.trim().length !== 0) {
      setRestrictedOptionList(
        restrictedOptions.filter(val =>
          val.categories.toLowerCase().includes(search.toLowerCase()),
        ),
      );
    } else {
      setRestrictedOptionList(restrictedOptions);
    }
  }, [restrictedOptions, search]);

  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Typography variant='h5' className='font-bold'>
          Select Restricted sponsorships
        </Typography>
        <Box className='input-search flex w-340 search-bg'>
          <InputSearch
            placeholder='Search'
            value={search}
            onChange={e => setSearch(e)}
          />
        </Box>
      </Stack>
      <Typography className='text-white-50 py-2 my-2'>
        Finally, which - if any - category(s) are off-limits due to an exclusive
        agreement with a sponsor? (Example: Banks, Car Dealerships, Hospitals,
        etc.)
      </Typography>
      <Grid container spacing={2}>
        {restrictedOptionList.map(val => (
          <Grid item xs={6} key={val.id}>
            <FormControlLabel
              className={`ml-3 bg-blue-variant53 w-full radius-5 ${
                val.selected ? 'border-green-variant18' : ''
              }`}
              control={
                <Checkbox
                  checked={val.selected}
                  onChange={() => handleChange(val.id)}
                  icon={<img src={IconUnchecked} alt='' />}
                  checkedIcon={<img src={Iconchecked} alt='' />}
                />
              }
              label={val.categories}
            />
          </Grid>
        ))}
      </Grid>
      <OtherNamingCmpt
        label='All Other'
        placeholder='Enter Other Category'
        other={otherCategory}
        setOther={setOtherCategory}
      />
    </>
  );
};

export const IconCheckedBox = ({
  CustomCheckboxProps,
}: {
  CustomCheckboxProps: CheckboxProps;
}) => {
  return (
    <Checkbox
      {...CustomCheckboxProps}
      icon={<img src={IconUnchecked} alt='' />}
      checkedIcon={<img src={Iconchecked} alt='' />}
    />
  );
};
