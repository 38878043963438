import AppBar from '@mui/material/AppBar';
import {Autocomplete, Box, Button, Grid, TextField} from '@mui/material';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import gt_logo from '../../asset/images/gt-dark-logo.png';
import sz_logo from '../../asset/images/sz-dark-logo.png';
import nr_logo from '../../asset/images/nr-dark-logo.png';
import logo from '../../asset/images/neptune-dark-logo.png';
import {
  companyIdSelector,
  companyIsImpersonateSelector,
  isGTSelector,
  isNRSelector,
  isSZSelector,
} from '../../store/Slices/companySlice';
import HeaderProfileButton from './HeaderProfileButton';
import {useStoreSelector} from '../../store/configstore';
import {ICompany} from '../../dto/Company.dto';
import {apiGet} from '../../axiosConfig';
import {
  isInternalSelector,
  selectedUserCompany,
  userCompaniesSelector,
} from '../../store/Slices/userSlice';

import IconMenuButton from '../Button/IconMenuButton';

const Header = () => {
  const companyIsImpersonate = useSelector(companyIsImpersonateSelector);
  const isInternal = useSelector(isInternalSelector);
  const isGT = useSelector(isGTSelector);
  const isNR = useSelector(isNRSelector);
  const isSz = useSelector(isSZSelector);
  const userImpersonateToken = sessionStorage.getItem('access_token');
  const userImpersonateUserId = sessionStorage.getItem('impersonated_user');

  return (
    <AppBar
      elevation={0}
      position='fixed'
      className='blue-variant4 border-bottom-1'>
      <Grid container className='h-92'>
        <Grid
          className='flex leftbar border-right-1 w-full items-center pl-10'
          item>
          {isInternal && <IconMenuButton className='mr-8' />}
          <Link to='/dashboard' className='flex h-full items-center'>
            <img
              className='w-full navbar-brand'
              src={isGT ? gt_logo : isNR ? nr_logo : isSz ? sz_logo : logo}
              alt='logo'
            />
          </Link>
        </Grid>
        <Grid className='rightbar flex pr-10' item>
          <Box sx={{flexGrow: 1}} className='flex items-center justify-center'>
            <UserCompanyDropDown />
            {(companyIsImpersonate ||
              (userImpersonateToken && userImpersonateUserId)) && (
              <Button variant='outlined' color='warning' onClick={window.close}>
                End Session
              </Button>
            )}
          </Box>
          <HeaderProfileButton />
        </Grid>
      </Grid>
    </AppBar>
  );
};
export default Header;

const UserCompanyDropDown = () => {
  const companyId = useStoreSelector(companyIdSelector);
  const companies = useSelector(userCompaniesSelector);
  const isPartner = !useSelector(isInternalSelector);
  const [selectedCompany, setselectedCompany] = useState<ICompany>(
    {} as ICompany,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (companies) {
      companies.forEach(val => {
        if (val.id === companyId) {
          setselectedCompany(val);
        }
      });
    }
  }, [companies, companyId]);

  const handleOnChange = (value: ICompany | null) => {
    if (value) {
      apiGet(`/v1/user/change/company/${value.id}`)
        .then(res => {
          dispatch(selectedUserCompany(res.data.data));
          window.location.reload();
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return (
    <>
      {isPartner && companies && companies.length > 1 ? (
        <Autocomplete
          sx={{width: '500px'}}
          id='tags-outlined'
          onChange={(_, value) => handleOnChange(value)}
          value={selectedCompany}
          options={companies ?? []}
          getOptionLabel={(option: ICompany) => {
            return option.name;
          }}
          renderInput={params => <TextField {...params} label='Companies' />}
        />
      ) : null}
    </>
  );
};
