import {useSelector} from 'react-redux';
import {scopesSelector} from '../store/Slices/userSlice';

export const useUserCan = (scope = '') => {
  const scopes = useSelector(scopesSelector);
  const scopesToCheck = scope.split(',');
  const havePermission = scopesToCheck.every(s => scopes.includes(s));
  return havePermission;
};

export const useUserCanAny = (scope = '') => {
  const scopes = useSelector(scopesSelector);
  const scopesToCheck = scope.split(',');
  const havePermission = scopesToCheck.some(s => scopes.includes(s));
  return havePermission;
};
