import {styled} from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import {FormControlLabel} from '@mui/material';

export const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName='.Mui-focusVisible' {...props} />
))(() => ({
  width: 45,
  height: 24,
  padding: 0,
  opacity: 1,
  '& .MuiSwitch-switchBase': {
    transitionDuration: '300ms',
    padding: '3px',
    '&.hover': {background: 'transparent'},
    '&.Mui-checked': {
      transform: 'translateX(21px)',
      color: `var(--text-white)`,
      '& + .MuiSwitch-track': {
        backgroundColor: `var(--green-variant9)`,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 18,
    height: 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: '100px',
    border: `1px solid var(--text-white)`,
    backgroundColor: `var(--orange-variant1)`,
    opacity: '1 !important',
  },
}));

export default function CustomizedSwitches({
  isActive,
  onChange,
  disabled,
}: {
  disabled?: boolean;
  isActive: boolean;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean,
  ) => void;
}) {
  return (
    <FormGroup>
      <FormControlLabel
        disabled={disabled ?? false}
        className={`mr-2 ${disabled ? 'opacity-50' : ''}`}
        label=''
        control={<IOSSwitch defaultChecked />}
        checked={Boolean(isActive)}
        onChange={onChange}
      />
    </FormGroup>
  );
}
