import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

type Props = {
  name: string | React.ReactNode; // Name of the resource that going to be save
  onSave: () => void;
  onCancel: () => void;
};

const DialogConfirmSave = ({name, onSave, onCancel}: Props) => {
  return (
    <Dialog maxWidth='sm' fullWidth open>
      <DialogTitle>
        <Typography>Confirmation</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant='body2'
          className='text-center text-white-50'
          marginBottom={1}>
          {name}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button color='primary' variant='contained' onClick={onSave}>
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmSave;
