import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IApiDataResponse} from '../../../dto/API.dto';
import axiosInstance from '../../../axiosConfig';

type ITaskPreviewProp = {
  is_preview: boolean;
};

export const useRqGetTaskIsPreview = (
  taskId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [`v1/task/${taskId}/preview`],
    queryFn: () => getTaskIsPreview(taskId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};

const getTaskIsPreview = (taskId: number) => {
  return axiosInstance
    .get(`v1/task/${taskId}/preview`)
    .then((response: AxiosResponse<IApiDataResponse<ITaskPreviewProp>>) => {
      return response.data.data;
    });
};

// check user is assigned to task.
type ITaskAssignedProp = {
  is_assigned: boolean;
};

export const queryKeyTaskIsAssigned = (taskId: number) => {
  return `v1/task/${taskId}/is-assigned`;
};

export const useRqGetTaskIsAssigned = (
  taskId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [queryKeyTaskIsAssigned(taskId)],
    queryFn: () => getTaskIsAssigned(taskId),
    refetchOnWindowFocus: true,
    retry: false,
    enabled: enabled,
  });
};

const getTaskIsAssigned = (taskId: number) => {
  return axiosInstance
    .get(queryKeyTaskIsAssigned(taskId))
    .then((response: AxiosResponse<IApiDataResponse<ITaskAssignedProp>>) => {
      return response.data.data;
    });
};
