import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IApiDataResponse} from '../../dto/API.dto';
import axiosInstance from '../../axiosConfig';
import {IMusicGenre} from '../../dto/MusicGenre.dto';

const getGenres = () => {
  return axiosInstance
    .get(`/v1/music-selection/genre`)
    .then((response: AxiosResponse<IApiDataResponse<IMusicGenre[]>>) => {
      return response.data?.data;
    });
};

export const useRqGenres = (enabled: boolean = true) => {
  return useQuery({
    queryKey: ['api/v1/music-selection/genre'],
    queryFn: () => getGenres(),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
    staleTime: 60000, //verify.
  });
};
