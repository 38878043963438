import {Info} from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import {useRqNoPlayByMediaTrack} from '../../../react_query/no-play/NoPlayList';
import {DialogClose} from '../../../components/dialogs/DialogFrame/DialogClose';
import {useState} from 'react';
import {Loader} from '../../../components/styles/MuiComponenets';

export const NoPlayInfoButton = ({id}: {id: number}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton
        title='The Reasons why the song is unavailable'
        onClick={() => setIsOpen(true)}>
        <Info />
      </IconButton>
      <NoPlayInfoDialog id={id} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

const NoPlayInfoDialog = ({
  id,
  isOpen,
  setIsOpen,
}: {
  id: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {data, isFetching} = useRqNoPlayByMediaTrack(id);

  return (
    <Dialog open={isOpen} maxWidth='lg' onClose={() => setIsOpen(false)}>
      <DialogTitle>
        <Typography>Reasons for Song Rejection</Typography>
        <DialogClose onClose={() => setIsOpen(false)} />
      </DialogTitle>
      <DialogContent>
        {isFetching ? (
          <Loader />
        ) : data ? (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h4'>
                  {data.song_name} by {data.artist_name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>Details: {data.details}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h5'>Song Ratings:</Typography>
                <List>
                  {data.no_play_list_ratings.map(rating => (
                    <ListItem key={rating.id}>
                      <ListItemText
                        primary={`${rating.id}: ${rating.description}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};
