import {ButtonProps, IconButton} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const IconPlusButton: React.FC<ButtonProps> = ({...buttonProps}) => {
  return (
    <IconButton
      {...buttonProps}
      className='blue-variant4 radius-5 text-gray-variant1 text-16 w-40 h-40 p-0'
      sx={{
        color: `var(--blue_variant52)`,
        border: `1px solid var(--blue_variant52)`,
        '&:hover': {
          background: `var(--blue_variant52) !important`,
        },
      }}>
      <AddIcon className='self-center' />
    </IconButton>
  );
};

export default IconPlusButton;
