import {useNavigate} from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {Box, Grid, Typography} from '@mui/material';
import {useStylesed} from './commonStyled';
import {apiPost} from '../../axiosConfig';

type IBackBtn = {
  title: string | null;
  storeType: 'set' | 'remove' | null;
  storeName: string;
  storeData: unknown;
  redirectTo?: string | number;
  taskId?: number | null;
  storeCustomData?: () => void;
};

const BackCloseStoreBtn = ({
  title = null,
  storeType = null,
  storeName = 'none',
  storeData = null,
  redirectTo = -1,
  taskId = null,
  storeCustomData,
}: IBackBtn) => {
  const navigate = useNavigate();
  const classes = useStylesed();

  const handleStorage = () => {
    if (storeType === 'set' && storeName !== 'none') {
      sessionStorage.setItem(storeName, JSON.stringify(storeData));
    } else if (sessionStorage.hasOwnProperty(storeName)) {
      sessionStorage.removeItem(storeName);
    }
  };

  const handleClose = () => {
    if (taskId) {
      apiPost(`/v1/task/${taskId}/unassign?_method=PATCH`).then(() => {
        navigate('/dashboard');
      });
    }
    navigate('/dashboard');
  };

  return (
    <>
      <Grid container item>
        <Grid item xs={6} sm={1}>
          <Box
            onClick={() => {
              handleStorage();
              if (typeof redirectTo === 'string') {
                navigate(redirectTo);
              } else {
                navigate(-1);
              }
              if (typeof storeCustomData === 'function') {
                storeCustomData();
              }
            }}>
            <ArrowCircleLeftRoundedIcon className={classes.modelIcon} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          display='flex'
          justifyContent='center'
          order={{xs: 2, sm: 1}}>
          <Typography
            align='center'
            sx={{
              backgroundColor: 'unset',
              fontSize: '30px',
              fontWeight: 600,
              borderBottom: '3px solid #0187c4',
            }}>
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={1}
          display='flex'
          justifyContent='flex-end'
          order={{xs: 1}}>
          <Box onClick={handleClose}>
            <HighlightOffIcon className={classes.modelIcon} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default BackCloseStoreBtn;
