import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  OutlinedInput,
  Typography,
} from '@mui/material';
import {DialogClose} from './DialogFrame/DialogClose';

type Props = {
  name: string | React.ReactNode; // Name of the resource that going to be delete
  isPermanent?: boolean;
  onDelete: () => void;
  onCancel: () => void;
};

const DialogConfirmDeletion = ({
  name,
  isPermanent,
  onDelete,
  onCancel,
}: Props) => {
  const [text, setText] = useState('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };
  return (
    <Dialog maxWidth='sm' fullWidth open>
      <DialogTitle>
        <Typography>Delete</Typography>
        <DialogClose onClose={onCancel} />
      </DialogTitle>
      <DialogContent>
        {isPermanent ? (
          <Typography marginBottom={3}>
            Permanently delete {name}. You can't undo this action.
          </Typography>
        ) : (
          <Typography marginBottom={3}>Delete {name}</Typography>
        )}
        {isPermanent && (
          <Typography paragraph sx={{opacity: 0.85}}>
            To avoid accidental deletion provide additional written consent.
          </Typography>
        )}
        <Typography variant='body2' marginBottom={1} sx={{opacity: 0.7}}>
          To confirm deletion, type{' '}
          <strong>
            <i>delete</i>
          </strong>{' '}
          into the field.
        </Typography>
        <OutlinedInput
          autoFocus
          fullWidth
          value={text}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          variant='contained'
          disabled={text !== 'delete'}
          onClick={onDelete}>
          Delete
        </Button>
        <Button color='primary' variant='contained' onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmDeletion;
