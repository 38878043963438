import {AxiosResponse} from 'axios';
import {useQuery} from '@tanstack/react-query';
import {apiGet} from '../../../axiosConfig';
import {IApiDataResponse} from '../../../dto/API.dto';
import {ITimedMessageDetailedResponse} from './ViewTimedMsgDetails';

export const getTimedMsgOnDateApiPath = (company_id: number) => {
  return `v1/company/${company_id}/message/timed-message/calendar/message-list`;
};

const getTimedMsgOnDate = (
  company_id: number,
  params: {timedMessageIds: number[]; date: string},
) => {
  return apiGet(getTimedMsgOnDateApiPath(company_id), {params}).then(
    (
      response: AxiosResponse<
        IApiDataResponse<ITimedMessageDetailedResponse[]>
      >,
    ) => {
      return response.data.data;
    },
  );
};

export const useTimedMessageOnDate = (
  company_id: number,
  params: {timedMessageIds: number[]; date: string},
  enabled: boolean = true,
) => {
  const enableRender =
    company_id > 0 &&
    params.date &&
    params.timedMessageIds &&
    params.timedMessageIds.length
      ? enabled
      : false;
  return useQuery({
    queryKey: [getTimedMsgOnDateApiPath(company_id), params],
    queryFn: () => getTimedMsgOnDate(company_id, params),
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000, //1hr
    retry: false,
    enabled: enableRender,
  });
};
