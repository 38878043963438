import {makeStyles} from '@mui/styles';

export const borderStyles = makeStyles(theme => ({
  '@global': {
    '.border-none': {border: 'none !important'},
    '.radius-10-0': {borderRadius: '10px 10px 0 0'},
    '.rounded': {
      borderRadius: '300px !important',
      overflow: 'hidden !important',
    },
    '.radius-right-10': {borderRadius: '0 10px 10px 0 !important'},
    '.border-width-3': {borderLeft: '3px solid !important'},
    ...generateBorderRadius(),
  },
}));

const generateBorderRadius = (
  start = 0,
  end = 100,
  increament = 1,
  unit = 'px',
) => {
  let classList = {};

  for (let i = start; i <= end; i += increament) {
    classList[`.radius-${i}`] = {
      borderRadius: `${i}${unit} !important`,
    };
  }

  return classList;
};
