import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IService} from '../../dto/Service.dto';
import {apiGet} from '../../axiosConfig';
import {IApiDataResponse} from '../../dto/API.dto';

const getPartnerService = (product_type: string) => {
  return apiGet(`/v1/service-price/${product_type}`).then(
    (response: AxiosResponse<IApiDataResponse<IService[]>>) => {
      return response.data.data;
    },
  );
};

export const useRqServiceList = (
  product_type: string,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: ['api/vi/service-price', product_type],
    queryFn: () => getPartnerService(product_type),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
    initialData: [],
  });
};
