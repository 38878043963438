import {AxiosResponse} from 'axios';
import {useQuery} from '@tanstack/react-query';
import {apiGet} from '../../axiosConfig';
import {IApiDataResponse, IApiPaginationResponse} from '../../dto/API.dto';
import NoPlayListItem, {
  INoPlayListSpotifyApiResult,
} from '../../dto/NoPlayList.dto';
import {IDataGridSearchSort} from '../../dto/Html.dto';

export const getNoPlayListIndexApiPath = `/v1/no-play-list`;
export const getNoPlayListSpotifyMusicSearchPath =
  'v1/no-play-list/spotify/music-search';
export const getNoPlayListInfoByMediaTrack = (id: number) =>
  `v1/no-play-list/media-track/${id}`;

const getNoPlayListIndex = (params: IDataGridSearchSort) => {
  return apiGet<IApiPaginationResponse<NoPlayListItem[]>>(
    getNoPlayListIndexApiPath,
    {params},
  ).then(
    (response: AxiosResponse<IApiPaginationResponse<NoPlayListItem[]>>) => {
      return response.data;
    },
  );
};

export const useRqNoPlayListIndex = (query: IDataGridSearchSort) => {
  const params = {...query, page: query.page + 1};
  return useQuery({
    queryKey: [getNoPlayListIndexApiPath, params],
    queryFn: () => getNoPlayListIndex(params),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: true,
  });
};

export const getNoPlayMusicSearch = (params: {query: string}) => {
  return apiGet<IApiDataResponse<INoPlayListSpotifyApiResult[]>>(
    getNoPlayListSpotifyMusicSearchPath,
    {params},
  ).then(
    (
      response: AxiosResponse<IApiDataResponse<INoPlayListSpotifyApiResult[]>>,
    ) => {
      return response.data;
    },
  );
};

export const useRqNoPlayMusicSearch = (params: {query: string}) => {
  return useQuery({
    queryKey: [getNoPlayListSpotifyMusicSearchPath, params],
    queryFn: () => getNoPlayMusicSearch(params),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: true,
  });
};

export const getNoPlayListByMediaTrack = (id: number) => {
  return apiGet<IApiDataResponse<NoPlayListItem>>(
    getNoPlayListInfoByMediaTrack(id),
    {},
  ).then((response: AxiosResponse<IApiDataResponse<NoPlayListItem>>) => {
    return response.data.data;
  });
};

export const useRqNoPlayByMediaTrack = (id: number) => {
  return useQuery({
    queryKey: [getNoPlayListByMediaTrack, id],
    queryFn: () => getNoPlayListByMediaTrack(id),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: true,
  });
};
