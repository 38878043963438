import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IApiDataResponse} from '../../dto/API.dto';
import {apiGet} from '../../axiosConfig';
import {IEventBlackListProp} from '../../dto/EventType.dto';
import {ICompany1} from '../../dto/Company.dto';
import {IUser} from '../../dto/User.dto';

export const getParticipationFormIdApiPath = (companyId: number) =>
  `/v1/company/${companyId}/participation-form`;

type participationEventWithPivot = IEventBlackListProp & {
  pivot: {
    no_of_events: number;
    attendence_per_event: number;
  };
};

export interface IParticipationForms {
  id: number;
  company_id?: number;
  company?: ICompany1;
  sponsor_option_id: number;
  other_event: string;
  other_restricted_sponsorship: string;
  restricted_sponsorships: {
    id: number;
    categories: string;
  }[];
  sponsorship_details: {
    id: number;
    business_name: string;
    contact_name: string;
  }[];
  season_type: string;
  opening_date: Date;
  total_days: number;
  total_years: number;
  events: participationEventWithPivot[];
  created_by: IUser | null;
  form_submitted_by: IUser | null;
  updated_at: string;
}

const getParticipationFormId = (companyId: number) => {
  return apiGet(getParticipationFormIdApiPath(companyId)).then(
    (response: AxiosResponse<IApiDataResponse<IParticipationForms>>) => {
      return response.data.data;
    },
  );
};

export const useRqParticipationFormId = (
  companyId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getParticipationFormIdApiPath(companyId)],
    queryFn: () => getParticipationFormId(companyId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};

// Restricted sponsorship options list.
export const getRestrictedOptionsApiPath = () => `/v1/restricted-sponsorship`;

export interface IRestrictedSponsors {
  id: number;
  categories: string;
  selected: boolean;
}

const getRestrictedOptions = () => {
  return apiGet(getRestrictedOptionsApiPath()).then(
    (response: AxiosResponse<IApiDataResponse<IRestrictedSponsors[]>>) => {
      return response.data?.data;
    },
  );
};

export const useRqRestrictedOptions = (enabled: boolean = true) => {
  return useQuery({
    queryKey: [getRestrictedOptionsApiPath()],
    queryFn: () => getRestrictedOptions(),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 60 * 60 * 1000,
    enabled: enabled,
  });
};

// Sponsor options list.
export const getSponsorOptionsApiPath = () => `/v1/sponsor-option`;

export interface ISponsorSponsors {
  id: number;
  option: string;
}

const getSponsorOptions = () => {
  return apiGet(getSponsorOptionsApiPath()).then(
    (response: AxiosResponse<IApiDataResponse<ISponsorSponsors[]>>) => {
      return response.data.data;
    },
  );
};

export const useRqSponsorOptions = (enabled: boolean = true) => {
  return useQuery({
    queryKey: [getSponsorOptionsApiPath()],
    queryFn: () => getSponsorOptions(),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 60 * 60 * 1000,
    enabled: enabled,
  });
};

// Participation task

export const getParticipationFormByTaskIdApiPath = (taskId: number) =>
  `/v1/task/${taskId}/participation-form`;

const getParticipationFormByTaskId = (taskId: number) => {
  return apiGet(getParticipationFormByTaskIdApiPath(taskId)).then(
    (response: AxiosResponse<IApiDataResponse<IParticipationForms>>) => {
      return response.data.data;
    },
  );
};

export const useRqParticipationFormByTaskId = (
  taskId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getParticipationFormByTaskIdApiPath(taskId)],
    queryFn: () => getParticipationFormByTaskId(taskId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};
