import {useState} from 'react';
import {ISponsorByToken} from '../../dto/Sponsor.dto';
import SponsorResubmitMessage from './SponsorResubmitMessage';
import SponsorVerifyVoice from './SponsorVerifyVoice';
import ValidateSponsorToken from './ValidateSponsorToken';

const SponsorRequestDetailUpdate = () => {
  const [isValidated, setIsValidated] = useState(false);
  const [data, setData] = useState<ISponsorByToken>();
  const [token, setToken] = useState('');
  return isValidated && data ? (
    data.type === 'verify-voiceover-file' ||
    data.type === 'reupload-file' ||
    data.type === 'campaign-file-review' ? (
      <SponsorVerifyVoice data={data} token={token} />
    ) : (
      <SponsorResubmitMessage data={data} token={token} />
    )
  ) : (
    <ValidateSponsorToken
      setIsValidated={setIsValidated}
      setData={setData}
      setToken={setToken}
    />
  );
};
export default SponsorRequestDetailUpdate;
