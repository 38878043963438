import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {selectCompany} from '../../store/Slices/companySlice';
import {apiGetCompany} from '../../Service/companyService';
import DialogApiStatus from '../../components/dialogs/DialogApiStatus';

export default function ImpersonateCompany() {
  const {companyId} = useParams();
  const [apiState, setApiState] = useState({status: '', error: null});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const fetchCompany = useCallback(() => {
    setApiState({status: 'pending', error: null});
    if (companyId === undefined) {
      return navigate('/');
    }
    apiGetCompany(+companyId)
      .then(cmpny => {
        dispatch(selectCompany(cmpny));
        navigate(searchParams.get('redirectTo') || '/', {replace: true});
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });
  }, [companyId, dispatch, navigate, searchParams]);

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  return (
    <DialogApiStatus
      apiState={apiState}
      pendingTitle='Fetching company details'
      pendingSubTitle='Please wait...'
      successTitle=''
      errorTitle='Fetching company details is failed!'
      onRetry={fetchCompany}
    />
  );
}
