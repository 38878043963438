import React from 'react';
import {Outlet} from 'react-router-dom';
import {Backdrop, Box, CircularProgress, Typography} from '@mui/material';
import logo from '../../asset/images/neptune-dark-logo.png';
import unAuthBackground from '../../asset/images/unauth-bg.png';

export default function UnAuthUsers() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <BackDropCmpt loader={false} />
      <Box
        className='unauth-sec h-full items-center'
        sx={{
          backgroundImage: `url(${unAuthBackground})`,
          backgroundSize: 'cover',
        }}>
        <Box className='ml-auto h-full items-center flex-column justify-between blue-variant4 w-full max-w-784'>
          <Box className='w-full max-w-492 text-center flex-column justify-center h-full'>
            <img className='max-w-350 mb-20 mx-auto' src={logo} alt='logo' />
            <React.Suspense fallback={<BackDropCmpt />}>
              <Outlet />
            </React.Suspense>
          </Box>
          <Typography className='text-gray-variant11 py-11 text-14'>
            © {currentYear} neptunenow. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </>
  );
}

const BackDropCmpt = ({loader = true}) => {
  return (
    <Backdrop
      sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
      open={loader}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};
