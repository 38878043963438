import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {
  Box,
  Button,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import FlexCardContent from '../../../../../components/styles/FlexCardContent';
import BackCloseStoreBtn from '../../../../../components/styles/BackCloseStoreBtn';
import {useStylesed} from '../../../../../components/styles/commonStyled';
import {GenresFlipCard} from '../../../../../components/genres/genres_flip_card/GenresFlipCard';
import {
  IMusicGenreCust,
  IMusicGenreGrouped,
} from '../../../../../react_query/music_genres/ListGenresGrouped';
import {useMutation} from '@tanstack/react-query';
import {
  IStoreRMMusicStudentSelectionPayload,
  StoreRMMusicStudentSelection,
} from '../../../../../react_query/media_tracks/StoreRadioModeMusics';
import Toast from '../../../../../package/Toaster';
import {loading} from '../../../../../store/Slices/commonSlice';
import {MusicSchlSelectState} from '../../../schoolSelection/SchoolsSelectionReducer';
import {EventSelectState} from '../../EventSelectionReducer';
import {useStoreSelector} from '../../../../../store/configstore';
import {companyIdSelector} from '../../../../../store/Slices/companySlice';

type ISchoolsCust = {
  company_school_id: number;
  facilities?: number[];
};

const GenreSelectionStudentsForm = () => {
  const classes = useStylesed();
  const navigate = useNavigate();
  const gloablDispatch = useDispatch();
  const [genresId, setGenresId] = React.useState<number[]>([]);
  const [genres, setGenres] = React.useState<IMusicGenreGrouped[]>([]);
  const [songLimit, setSongLimit] = React.useState(0);
  const [timeLimit, setTimeLimit] = React.useState('');
  const companyId = useStoreSelector(companyIdSelector);

  const handleGenreSelect = React.useCallback(
    (genreId: number, subGenreId?: number) => {
      const updateSelectedGenreIds = (gId: number, selected: boolean) => {
        setGenresId((prevSelectedIds: number[]) => {
          if (selected) {
            return [...prevSelectedIds, gId];
          } else {
            return prevSelectedIds.filter(id => id !== gId);
          }
        });
      };
      setGenres(prevVal => {
        const newGenres = [...prevVal];

        const genreIndex = newGenres.findIndex(v => v.id === genreId);
        if (genreIndex < 0) return prevVal;
        const genre = {...newGenres[genreIndex]};
        if (subGenreId) {
          const subGenreIndex = genre.sub_genres?.findIndex(
            v => v.id === subGenreId,
          );
          if (subGenreIndex === undefined || subGenreIndex < 0) return prevVal;
          if (genre.sub_genres) {
            const subGenre = {...genre.sub_genres[subGenreIndex]};
            subGenre.selected = !subGenre.selected;

            genre.sub_genres = [...genre.sub_genres];
            genre.sub_genres[subGenreIndex] = subGenre as IMusicGenreCust;
            genre.selected = genre.sub_genres.some(v => v.selected);
            updateSelectedGenreIds(subGenreId, subGenre.selected);
          }
        } else {
          genre.selected = !genre.selected;
          updateSelectedGenreIds(genreId, genre.selected);
        }
        newGenres[genreIndex] = genre as IMusicGenreGrouped;
        return newGenres;
      });
    },
    [setGenres, setGenresId],
  );

  const Store = useMutation({
    mutationFn: (payload: IStoreRMMusicStudentSelectionPayload) => {
      return StoreRMMusicStudentSelection(Number(companyId), payload);
    },
    onSuccess: response => {
      Toast(response?.message, 'success');
      navigate(`email-link/${response.data.music_selection_id}`);
    },
    onSettled() {
      gloablDispatch(loading(false));
    },
  });

  const handleSubmit = React.useCallback(() => {
    gloablDispatch(loading(true));
    const global_store: MusicSchlSelectState = JSON.parse(
      sessionStorage.getItem('global_store')!,
    );
    const schools = global_store.schools
      ?.filter(school => school.selected)
      .map(school => {
        const facilities = school.facilities
          ?.filter(facility => facility.selected)
          .map(facility => facility.id);
        return {company_school_id: school.id, facilities: facilities};
      }) as ISchoolsCust[];

    const events: number[] = [];
    const global_events: EventSelectState = JSON.parse(
      sessionStorage.getItem('global_events')!,
    );
    global_events.events?.forEach(event => {
      if (event.selected) {
        events.push(event.id);
      }
      event.sub_list.forEach(sub_list => {
        if (sub_list.selected) {
          events.push(event.id);
        }
      });
    });
    const event_type_ids = events.filter(
      (value, index, self) => self.indexOf(value) === index,
    );

    Store.mutate({
      schools: schools,
      expiry_days: Number(timeLimit),
      song_count: songLimit,
      event_type_ids: event_type_ids,
      genre_selection: genresId,
    });
  }, [Store, genresId, gloablDispatch, songLimit, timeLimit]);

  return (
    <FlexCardContent>
      <Box>
        <BackCloseStoreBtn
          title='GENRES SELECTION'
          storeType='remove'
          storeName='sess_genresSelect'
          storeData={null}
        />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={8} md={8}>
              <Typography variant='h5' fontWeight={600} mb={2}>
                GENRES
              </Typography>
              <GenresFlipCard
                genres={genres}
                setGenres={setGenres}
                setGenresId={setGenresId}
                handleGenreSelect={handleGenreSelect}
              />
              <SongLimit songLimit={songLimit} setSongLimit={setSongLimit} />
              <TimeLimit timeLimit={timeLimit} setTimeLimit={setTimeLimit} />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Review
                genres={genres}
                genresId={genresId}
                songLimit={songLimit}
                timeLimit={timeLimit}
                handleGenreSelect={handleGenreSelect}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Box>
      <Box className={classes.r_end}>
        <Button
          variant='contained'
          onClick={handleSubmit}
          disabled={genresId.length === 0 || !songLimit || !timeLimit}>
          Submit
        </Button>
      </Box>
    </FlexCardContent>
  );
};

const Review = ({
  genres,
  genresId,
  songLimit,
  timeLimit,
  handleGenreSelect,
}: {
  genres: IMusicGenreGrouped[];
  genresId: number[];
  songLimit: number;
  timeLimit: string;
  handleGenreSelect: (genreId: number, subGenreId?: number) => void;
}) => {
  return (
    <>
      <Typography variant='h5' fontWeight={600}>
        YOUR GENRES
      </Typography>
      <List dense>
        {genres.map(genre => {
          const value = genre.sub_genres.map(subGenre => {
            if (genresId.includes(subGenre.id)) {
              return (
                <SelectedGenreCmpt
                  key={subGenre.id}
                  genre={genre}
                  subGenre={subGenre}
                  handleGenreSelect={handleGenreSelect}
                />
              );
            }
            return null;
          });
          return value;
        })}
      </List>

      {songLimit > 0 ? (
        <SelectedSongAndTime value={songLimit} type='song' />
      ) : null}
      {timeLimit ? (
        <SelectedSongAndTime value={Number(timeLimit)} type='time' />
      ) : null}
    </>
  );
};

const SelectedSongAndTime = ({
  value,
  type,
}: {
  value: number;
  type: 'song' | 'time';
}) => {
  const classes = useStylesed();
  return (
    <>
      <Divider sx={{my: 1}} />
      <Typography variant='h5' fontWeight={600}>
        {type === 'song' ? 'SONG' : 'TIME'} LIMIT
      </Typography>
      <Stack direction='row' spacing={1} ml={2}>
        <input
          type='checkbox'
          className={classes.blue_checkbox}
          defaultChecked
        />
        <Typography>
          {value} {type === 'song' ? 'songs' : 'days'}
        </Typography>
      </Stack>
    </>
  );
};

const SelectedGenreCmpt = ({
  genre,
  subGenre,
  handleGenreSelect,
}: {
  genre: IMusicGenreGrouped;
  subGenre: IMusicGenreCust;
  handleGenreSelect: (genreId: number, subGenreId?: number) => void;
}) => {
  const classes = useStylesed();

  const handleDelete = React.useCallback(() => {
    handleGenreSelect(genre.id, subGenre.id);
  }, [genre.id, handleGenreSelect, subGenre.id]);

  return (
    <ListItem>
      <Stack
        direction='row'
        spacing={1}
        alignItems='center'
        sx={{width: '100%'}}>
        <input
          type='checkbox'
          className={classes.blue_checkbox}
          defaultChecked
        />
        <Typography>{subGenre.name}</Typography>
        <div style={{flexGrow: 1}} />
        <IconButton aria-label='delete' color='error' onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    </ListItem>
  );
};

const SongLimit = ({
  songLimit,
  setSongLimit,
}: {
  songLimit: number;
  setSongLimit: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const classes = useStylesed();
  return (
    <>
      <Typography variant='h5' fontWeight={600} mt={2}>
        SONG LIMIT
      </Typography>
      <Box className={classes.r_start} mt={1}>
        <IconButton
          color='primary'
          onClick={() => setSongLimit(prev => prev - 1)}>
          <RemoveIcon />
        </IconButton>
        <TextField
          label='Count'
          variant='outlined'
          size='small'
          type='number'
          value={songLimit === 0 ? '' : songLimit}
          sx={{width: 100}}
          onChange={e => setSongLimit(Number(e.target.value))}
        />
        <IconButton
          color='primary'
          onClick={() => setSongLimit(prev => prev + 1)}>
          <AddIcon />
        </IconButton>
      </Box>
    </>
  );
};

const TimeLimit = ({
  timeLimit,
  setTimeLimit,
}: {
  timeLimit: string;
  setTimeLimit: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <>
      <Typography variant='h5' fontWeight={600} mt={2}>
        TIME LIMIT
      </Typography>
      <FormControl fullWidth sx={{mt: 1}}>
        <InputLabel id='time-limit-label'>Time Limit</InputLabel>
        <Select
          labelId='time-limit-label'
          id='time-limit'
          value={timeLimit}
          label='Time Limit'
          sx={{width: 200}}
          onChange={e => setTimeLimit(e.target.value)}>
          <MenuItem value={1}>1 day</MenuItem>
          <MenuItem value={2}>2 days</MenuItem>
          <MenuItem value={3}>3 days</MenuItem>
          <MenuItem value={4}>4 days</MenuItem>
          <MenuItem value={5}>5 days</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default GenreSelectionStudentsForm;
