import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import {Dayjs} from 'dayjs';
import {useCallback} from 'react';
import {DatePicker} from '@mui/x-date-pickers';

const OndemandExpiryDate = ({
  is_expire_date_set,
  expire_date,
  setIsExpireDateSet,
  setExpireDate,
  isToday = false,
}: {
  is_expire_date_set: boolean;
  expire_date: Dayjs | null;
  setIsExpireDateSet: (val: boolean) => void;
  setExpireDate: (val: Dayjs | null) => void;
  isToday?: boolean;
}) => {
  const handleChangeDate = (val: Dayjs | null) => {
    setExpireDate(val);
  };

  return (
    <Box sx={{maxWidth: 'md'}} margin='auto' width='100%'>
      <Grid container mt={2} alignItems='center'>
        <Grid item xs={12} sm={12}>
          <EndDateRequiredOrNot
            is_expire_date_set={is_expire_date_set}
            setIsExpireDateSet={setIsExpireDateSet}
          />
        </Grid>
        <Grid item xs={6} sm={6} className='mt-3 common-date-picker'>
          {is_expire_date_set ? (
            <Box>
              <DatePicker
                label='End Date'
                disablePast
                value={expire_date}
                onChange={handleChangeDate}
                sx={{width: 200}}
              />
            </Box>
          ) : null}
        </Grid>
      </Grid>
      {isToday && expire_date && (
        <Alert severity='error' className='mt-3'>
          Please choose a day other than the current day
        </Alert>
      )}
    </Box>
  );
};

export default OndemandExpiryDate;

const EndDateRequiredOrNot = ({
  is_expire_date_set,
  setIsExpireDateSet,
}: {
  is_expire_date_set: boolean;
  setIsExpireDateSet: (val: boolean) => void;
}) => {
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsExpireDateSet(JSON.parse(e.target.value));
    },
    [setIsExpireDateSet],
  );
  return (
    <FormControl>
      <FormLabel id='days_selection_label'>
        <Typography fontWeight={600}>
          Would you like to set an end date for this message?
        </Typography>
      </FormLabel>
      <RadioGroup
        row
        className='mt-3'
        aria-labelledby='days_selection_label'
        name='radio-buttons-group'
        value={is_expire_date_set}
        onChange={handleOnChange}>
        <FormControlLabel value={true} control={<Radio />} label='Yes' />
        <FormControlLabel
          value={false}
          control={<Radio />}
          label='No'
          defaultChecked
        />
      </RadioGroup>
    </FormControl>
  );
};
