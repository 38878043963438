import {AxiosError, AxiosResponse} from 'axios';
import {AxiosErrorToast} from '../general/ReusableFunctions';
import {
  IApiSuccessMsgDataResponse,
  IApiSuccessMsgResponse,
} from '../../dto/API.dto';
import axiosInstance from '../../axiosConfig';

export interface IStoreRadioModeMusicSelectionPayload {
  music_selection_type?: 'G' | 'S' | 'SG';
  schools?: {
    company_school_id: number;
    facilities?: number[];
  }[];
  event_type_ids?: number[];
  song_requests?: {
    media_track_id?: number;
    track_artist: string;
    track_name: string;
    status: number;
  }[];
  genre_selection?: {
    genre_id: number;
    percentage: number;
  }[];
  comment?: string;
}

export const StoreRadioModeMusicSelection = async (
  company_id: number,
  payload: IStoreRadioModeMusicSelectionPayload,
) => {
  return axiosInstance
    .post(`/v1/music-selection/company/${company_id}`, payload)
    .then((response: AxiosResponse<IApiSuccessMsgResponse>) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      AxiosErrorToast(error, 'Error in send message request');
      throw error;
    });
};

export interface IStoreRMMusicStudentSelectionPayload {
  schools?: {
    company_school_id: number;
    facilities?: number[];
  }[];
  expiry_days: number;
  song_count: number;
  event_type_ids?: number[];
  genre_selection?: number[];
}

type IMusicSelectionIdResponse = {
  music_selection_id: number;
};

export const StoreRMMusicStudentSelection = async (
  company_id: number,
  payload: IStoreRMMusicStudentSelectionPayload,
) => {
  return axiosInstance
    .post(`/v1/music-selection/company/${company_id}/student`, payload)
    .then(
      (
        response: AxiosResponse<
          IApiSuccessMsgDataResponse<IMusicSelectionIdResponse>
        >,
      ) => {
        return response.data;
      },
    )
    .catch((error: AxiosError) => {
      AxiosErrorToast(error, 'Error in send message request');
      throw error;
    });
};
