import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {apiGet} from '../../axiosConfig';
import {IApiDataResponse} from '../../dto/API.dto';
import {IDataProps} from '../../dto/Message.dto';
import {ICompany} from '../../dto/Company.dto';

export type ITnnCampaignTypes = {
  id: number;
  type: string;
  start_date: Date;
  end_date: Date;
  number_of_runs: number;
  url: string;
  tnn_campaign: {
    name: string;
  };
  companies: ICompany[];
  message: IDataProps;
  status: string;
};

export const getTnnCampaignTypesUrl = (sponsorId: number) => {
  return `v1/tnn/sponsor/${sponsorId}/campaign/details`;
};

const getTnnCampaignTypes = (
  sponsorId: number,
  params: ITnnCampaignTypesParamProp,
) => {
  return apiGet(getTnnCampaignTypesUrl(sponsorId), {params}).then(
    (response: AxiosResponse<IApiDataResponse<ITnnCampaignTypes[]>>) => {
      return response.data.data;
    },
  );
};

export type ITnnCampaignTypesParamProp = {
  search?: string;
  status?: string;
};
export const defaultTnnCampaignTypesParam = {
  search: '',
  status: '',
};
export const useRqListTnnCampaignTypes = (
  sponsorId: number,
  params: ITnnCampaignTypesParamProp = defaultTnnCampaignTypesParam,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getTnnCampaignTypesUrl(sponsorId), params],
    queryFn: () => getTnnCampaignTypes(sponsorId, params),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};

// Schedule View
export const getTnnCampaignScheduleUrl = (
  sponsorRequestId: number,
  scheduleId: number,
) => {
  return `v1/tnn/sponsor/${sponsorRequestId}/campaign/${scheduleId}`;
};

const getTnnCampaignSchedule = (
  sponsorRequestId: number,
  scheduleId: number,
) => {
  return apiGet(getTnnCampaignScheduleUrl(sponsorRequestId, scheduleId)).then(
    (response: AxiosResponse<IApiDataResponse<ITnnCampaignTypes>>) => {
      return response.data.data;
    },
  );
};
export const useRqListTnnCampaignSchedule = (
  sponsorRequestId: number,
  scheduleId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getTnnCampaignScheduleUrl(sponsorRequestId, scheduleId)],
    queryFn: () => getTnnCampaignSchedule(sponsorRequestId, scheduleId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};

// tnn campaigns list as per company
export type ITnnCampaignsForCompany = {
  id: number;
  type: string;
  start_date: Date;
  end_date: Date;
  number_of_runs: number;
  url: string;
  tnn_campaign: {
    name: string;
  };
  message: IDataProps;
};

export const getTnnCampaignsForCompanyUrl = (companyId: number) => {
  return `v1/company/${companyId}/tnn-campaigns`;
};

const getTnnCampaignsForCompany = (
  companyId: number,
  params: ITnnCampaignsForCompanyParamProp,
) => {
  return apiGet(getTnnCampaignsForCompanyUrl(companyId), {params}).then(
    (response: AxiosResponse<IApiDataResponse<ITnnCampaignsForCompany[]>>) => {
      return response.data.data;
    },
  );
};

export type ITnnCampaignsForCompanyParamProp = {
  search?: string;
};

export const useRqListTnnCampaignsForCompany = (
  companyId: number,
  params: ITnnCampaignsForCompanyParamProp = {},
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getTnnCampaignsForCompanyUrl(companyId), params],
    queryFn: () => getTnnCampaignsForCompany(companyId, params),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};
