import {Dayjs} from 'dayjs';

export function GetDatesArrayBetweenDates(from: Dayjs, to: Dayjs) {
  let currentDate = from;
  const allDates = [];

  while (currentDate.isBefore(to) || currentDate.isSame(to)) {
    allDates.push(currentDate);
    currentDate = currentDate.add(1, 'day');
  }

  return allDates;
}
