import {useState} from 'react';
import {
  Avatar,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {useDispatch} from 'react-redux';
import {
  nameSelector,
  logout,
  profileUrlSelector,
} from '../../store/Slices/userSlice';
import {useStoreSelector} from '../../store/configstore';
import rightArrowFilled from '../../asset/icons/icon_rightarrow_filled.svg';
const HeaderProfileButton = () => {
  const globalDispatch = useDispatch();
  const userName = useStoreSelector(nameSelector);
  const profileUrl = useStoreSelector(profileUrlSelector);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(!isMenuOpen);
  };
  const userMenuClass = isMenuOpen ? 'usermenu-open' : '';
  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };
  const handleLogout = () => {
    globalDispatch(logout());
  };
  return (
    <>
      <IconButton
        edge='end'
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={handleOpenUserMenu}
        color='inherit'
        className={`p-0 mr-2 size-auto ${userMenuClass}`}>
        <Avatar
          alt={userName || 'Default Alt Text'}
          src={profileUrl || '/static/images/avatar/2.jpg'}
          className='avatar mr-2 bg-gray-variant2'
        />
        <Grid className='flex-column w-full max-w-150 mr-6 items-start text-left'>
          <Typography variant='body2' className='text-gray-variant5'>
            Welcome Back!
          </Typography>
          <Typography variant='body1' className='ellipsis text-white w-115'>
            {userName}
          </Typography>
        </Grid>
        <img
          src={rightArrowFilled}
          className='right-down-arrow w-31 h-33'
          alt='icon'
        />
      </IconButton>
      <Menu
        className='mt-15'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id='menu-appbar'
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          className: 'w-190 radius-0 py-2',
        }}>
        <MenuItem
          className='text-16 blue-variant17 h-40'
          disabled
          sx={{borderBottom: `1px solid var(--gray-opacity-variant2)`}}>
          Profile
        </MenuItem>
        <MenuItem
          className='text-16 blue-variant17 h-40'
          onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
export default HeaderProfileButton;
