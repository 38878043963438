import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import axiosInstance from '../../../axiosConfig';
import {ICompanySchool} from '../../../dto/CompanySchool.dto';
import {IEventType} from '../../../dto/EventType.dto';
import {IStation} from '../../../dto/Station.dto';
import {IUser} from '../../../dto/User.dto';
import {IApiDataResponse} from '../../../dto/API.dto';
import {IMessage} from '../../../dto/Message.dto';
import {
  IEsmStatus,
  IEventSpecificMessageRequest,
} from '../../../dto/EventSpecificMessageRequest.dto';
import {IStartEndDateTime} from '../../../dto/DateTime.dto';

export type IEventMessageDataProps = IEventSpecificMessageRequest & {
  event_types: IEventType[];
  messages: IMessage[];
  request_status: string;
  schools: ICompanySchool[];
  stations: IStation[];
  status: IEsmStatus;
  others_event_name: string;
  created_at: string;
  created_by: IUser;
  multiple_dates: IStartEndDateTime[];
  week_days: string[];
};

export const useRqGetEventMessageByRequestId = (
  company_id: number,
  id: number,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: [`v1/company/${company_id}/message/event-message/${id}`],
    queryFn: () => getEventMgsByRequestId(company_id, id),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};

const getEventMgsByRequestId = (company_id: number, id: number) => {
  return axiosInstance
    .get(`v1/company/${company_id}/message/event-message/${id}`)
    .then(
      (response: AxiosResponse<IApiDataResponse<IEventMessageDataProps>>) => {
        return response.data.data;
      },
    );
};
