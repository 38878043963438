import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {DialogClose} from '../dialogs/DialogFrame/DialogClose';

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  body: React.ReactNode;
  actionButtonText: string;
  onActionButtonClick: () => void;
  maxwidth: string;
  customHeight: string;
  customClass?: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  title,
  body,
  maxwidth,
  customHeight,
  customClass = '',
}) => {
  return (
    <Dialog
      className={customClass}
      maxWidth='md'
      fullWidth
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: `${maxwidth}px`,
          height: `${customHeight}px`,
          backgroundColor: `var(--blue-variant17) !important`,
        },
      }}>
      <DialogTitle className='flex justify-between items-center'>
        <Typography className='text-white'>{title}</Typography>
        <DialogClose onClose={onClose} />
      </DialogTitle>

      <DialogContent>{body}</DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default CustomModal;
