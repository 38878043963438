import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {ITaskCount, ITaskItem} from '../../dto/Task.dto';
import {apiGet} from '../../axiosConfig';
import {IApiDataResponse, IApiPaginationResponse} from '../../dto/API.dto';
import {IUserBase} from '../../dto/User.dto';
import {IMessage, IMessage1} from '../../dto/Message.dto';
import {ICompany} from '../../dto/Company.dto';
import {taskFilterSearchProp} from '../../pages/staff-landing-page/StaffLandingTaskList';

export type ITaskRequestStatus = {
  id: number;
  status: string;
  request_status: string;
  created_at: string;
  company: ICompany;
  messages: IMessage & IMessage1[];
  created_by: IUserBase;
};

export interface IMyTaskFilterProp {
  task_slug: string;
  requestable_type: string;
  task_name: string;
  taskCount: number;
}
export const myTaskList = (search: string) => {
  if (search !== '') {
    return `v1/task/my-task?search=${encodeURIComponent(search)}`;
  } else {
    return 'v1/task/my-task';
  }
};
const getTasks = (search: taskFilterSearchProp | undefined) => {
  return apiGet('v1/task/my-task', {params: search}).then(
    (response: AxiosResponse<IApiPaginationResponse<ITaskItem[]>>) => {
      return response.data;
    },
  );
};
export const useRqListTasks = (
  search: taskFilterSearchProp | undefined,
  type: string = '0',
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: ['v1/task/my-task', search, type],
    queryFn: () => getTasks(search),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};
const getTaskInfo = (taskId: number) => {
  return apiGet(`v1/task/${taskId}`).then(
    (response: AxiosResponse<IApiDataResponse<ITaskItem>>) => {
      return response.data.data;
    },
  );
};
export const useRqTaskInfo = (taskId: number, enabled: boolean = true) => {
  return useQuery({
    queryKey: ['v1/task', taskId],
    queryFn: () => getTaskInfo(taskId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: taskId !== undefined && taskId !== null && enabled,
  });
};
const getFilterList = () => {
  return apiGet(`/v1/task/my-task/filter`).then(
    (response: AxiosResponse<IApiDataResponse<IMyTaskFilterProp[]>>) => {
      return response.data.data;
    },
  );
};
export const useRqMyTaskFilterList = (enabled: boolean = true) => {
  return useQuery({
    queryKey: ['v1/task/my-task/filter'],
    queryFn: () => getFilterList(),
    retry: false,
    enabled: enabled,
    refetchOnWindowFocus: false,
  });
};

export const getTaskCountApiPath = () => {
  return `/v1/task/my-task/count`;
};
const getTaskCount = () => {
  return apiGet(getTaskCountApiPath()).then(
    (response: AxiosResponse<IApiDataResponse<ITaskCount>>) => {
      return response.data.data;
    },
  );
};
export const useRqMyTaskFilterCount = (enabled: boolean = true) => {
  return useQuery({
    queryKey: [getTaskCountApiPath()],
    queryFn: () => getTaskCount(),
    retry: false,
    enabled: enabled,
    refetchOnWindowFocus: false,
  });
};
