import {AxiosResponse} from 'axios';
import {useQuery} from '@tanstack/react-query';
import {apiPost} from '../../../axiosConfig';

const getTimedMsgDateTimeExistence = (company_id: number, payload: object) => {
  return apiPost(
    `/v1/company/${company_id}/message/timed-message/check-time-exist`,
    payload,
  ).then(
    (
      response: AxiosResponse<
        {
          start_date: string;
          end_date: string;
          start_times: string;
          week_days: string;
        }[]
      >,
    ) => {
      return response.data;
    },
  );
};

export const useRqTimedMsgDateTimeExistence = (
  company_id: number,
  payload: object,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: [
      `/v1/company/${company_id}/message/timed-message/check-time-exist`,
      payload,
    ],
    queryFn: () => getTimedMsgDateTimeExistence(company_id, payload),
    refetchOnWindowFocus: false,
    retry: false,
    enabled,
  });
};
