import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {ISportInformationProp} from '../ParticipationFormStepper';

export const SportsInformationCmpt = ({
  sportInformation,
  setSportInformation,
}: {
  sportInformation: ISportInformationProp[];
  setSportInformation: React.Dispatch<
    React.SetStateAction<ISportInformationProp[]>
  >;
}) => {
  const handleEventsValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number,
  ) => {
    const value = Number(event.target.value);
    setSportInformation(prev => {
      return prev.map(val => {
        if (val.id === id && value !== 0) {
          return {...val, no_of_events: value};
        }
        return val;
      });
    });
  };
  const handleAttendenceValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number,
  ) => {
    const value = Number(event.target.value);
    setSportInformation(prev => {
      return prev.map(val => {
        if (val.id === id && value !== 0) {
          return {...val, attendence_per_event: value};
        }
        return val;
      });
    });
  };
  return (
    <>
      <Typography variant='h5' className='font-bold mb-4'>
        Enter Event Information
      </Typography>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Sport</TableCell>
            <TableCell sx={{textAlign: 'center'}}>Number of Events</TableCell>
            <TableCell sx={{textAlign: 'center'}}>
              Approx. Attendance Per Event
            </TableCell>
          </TableRow>
          <TableRow className='p-0'>
            <TableCell className='p-0'></TableCell>
            <TableCell colSpan={2} align='center' className='p-0'>
              <Typography className='italic' variant='caption'>
                (Please include ALL Varsity, JV, JRHS, or any Other Levels)
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sportInformation.map(v => (
            <TableRow key={v.id}>
              <TableCell>{v.name}</TableCell>
              <TableCell className='px-5 w-200' align='center'>
                <TextField
                  id={`${v.id}`}
                  variant='outlined'
                  className='w-80'
                  type='number'
                  value={v.no_of_events}
                  onChange={e => handleEventsValue(e, v.id)}
                />
              </TableCell>
              <TableCell className='px-5 w-250' align='center'>
                <TextField
                  id={`${v.id}`}
                  className='w-80'
                  variant='outlined'
                  type='number'
                  value={v.attendence_per_event}
                  onChange={e => handleAttendenceValue(e, v.id)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
