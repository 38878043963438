import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
  Autocomplete,
  Box,
  Checkbox,
  TextField,
  Typography,
} from '@mui/material';
import {IEventType} from '../../../dto/EventType.dto';
import {useRqAllSports} from '../../../react_query/event_types/GetAll';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import EventSportsCusDropDown from '../../../pages/messages/on-demand-msg/EventSportsCusDropDown';

const EventsDropdownGetEvents = ({
  selected,
  setSelected,
  setEvents,
  othersEventName,
  handleOtherEvents,
  isDuplicateOtherEvent,
}: {
  selected: IEventType[];
  setSelected: React.Dispatch<React.SetStateAction<IEventType[]>>;
  setEvents?: (value: IEventType[]) => void;
  othersEventName: string;
  handleOtherEvents: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  isDuplicateOtherEvent?: boolean;
}): JSX.Element | null => {
  const companyId = useSelector(companyIdSelector);
  const {data, isFetching, isError} = useRqAllSports(true, companyId);

  const options = useMemo(() => {
    if (!isFetching && !isError && data) {
      return [
        {
          id: -1,
          event_name: 'All Sports/Events',
          event_short_name: 'ALL SPORTS/EVENTS',
        } as IEventType,
        {
          id: 0,
          event_name: 'Other Events',
          event_short_name: 'OTHER EVENTS',
        } as IEventType,
        ...data,
      ];
    }
    return [];
  }, [data, isError, isFetching]);

  useEffect(() => {
    if (setEvents) {
      setEvents(options.filter(o => o.id > 0));
    }
  }, [options, setEvents]);

  const handleCancelEventSport = (EventSportId: number) => {
    setSelected(prevEvents =>
      prevEvents.filter(prevEvent => prevEvent.id !== EventSportId),
    );
  };

  const deselectAllEventsSports = () => {
    setSelected([]);
  };

  return (
    <>
      <Box className='flex'>
        {!isFetching && !isError && data ? (
          <Autocomplete
            multiple
            fullWidth
            disableCloseOnSelect
            id='events'
            sx={{
              width: 300,
              '.MuiInputBase-root .MuiButtonBase-root': {display: 'none'},
            }}
            onChange={(_, values) => {
              const allEvents = values.some(obj => obj.id === -1);
              setSelected(allEvents ? data || [] : [...values]);
            }}
            value={selected}
            defaultValue={selected}
            options={options}
            className='cusAutoSelect h-55 w-full max-w-300 m-0 mr-10 overflow-hidden custDpIcon'
            getOptionKey={option => option.id}
            getOptionLabel={(option: IEventType) => option.event_name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={params => (
              <TextField
                {...params}
                id='events'
                placeholder='Select Sports/Events'
              />
            )}
            renderOption={(props, option, {selected}) => (
              <li
                {...props}
                className='autoselect-option options-select flex items-center pl-6 cursor-pointer'>
                <Typography component='h6' className='flex items-center'>
                  <Checkbox
                    checked={selected}
                    sx={{'& .MuiSvgIcon-root': {fontSize: 16}}}
                  />
                  {option.event_name}
                </Typography>
              </li>
            )}
          />
        ) : null}

        {selected && selected.find(obj => obj.id === 0) && (
          <TextField
            id='other-event-input'
            label='Specify Your Event Name'
            variant='outlined'
            value={othersEventName}
            onChange={handleOtherEvents}
            className='mb-5'
            helperText={isDuplicateOtherEvent ? 'Duplicate event' : ''}
            error={isDuplicateOtherEvent}
          />
        )}
      </Box>

      {selected && selected.length > 0 && (
        <EventSportsCusDropDown
          showData={selected}
          cancelData={handleCancelEventSport}
          deselectAll={deselectAllEventsSports}
        />
      )}
    </>
  );
};

export default EventsDropdownGetEvents;
