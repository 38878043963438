import {useQuery} from '@tanstack/react-query';
import {
  IGenericStingerItem,
  GenericStingerListQueryParams,
  IGenericStingerTracksQueryParams,
  IGenericStringerTracksItem,
} from './GenericStingers.dto';
import axiosInstance, {apiGet} from '../../../../axiosConfig';

// Stingers List
export const queryKeyGenericsStingerRequestList = () => {
  return `v1/global-content/stingers`;
};

export const useRqGenericStingersList = (
  params: GenericStingerListQueryParams,
) => {
  return useQuery({
    queryKey: [queryKeyGenericsStingerRequestList(), params],
    queryFn: () => apiGetGenericStingers(params),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 3600000,
  });
};

const apiGetGenericStingers = async (parms: GenericStingerListQueryParams) => {
  const response = await axiosInstance.get(
    queryKeyGenericsStingerRequestList(),
    {
      params: parms,
    },
  );
  return response.data.data as IGenericStingerItem[];
};

// Stringers Details
export const queryKeyGenericStingerDetailsRequest = (stinger_id: number) => {
  return `v1/global-content/stingers/${stinger_id}`;
};

export const useRqGenericStingersDetails = (stinger_id: number) => {
  return useQuery({
    queryKey: [queryKeyGenericStingerDetailsRequest(stinger_id)],
    queryFn: () => apiGetGenericDetails(stinger_id),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 3600000,
  });
};

const apiGetGenericDetails = async (stinger_id: number) => {
  return apiGet(`v1/global-content/stingers/${stinger_id}`).then(response => {
    return response.data;
  });
};

// Stingers Tracks List
export const queryKeyGenericStingerTracksRequestList = (stinger_id: number) => {
  return `v1/global-content/stingers/${stinger_id}/list-tracks`;
};

export const useRqGenericStingerTracksList = (
  params: IGenericStingerTracksQueryParams,
) => {
  return useQuery({
    queryKey: [
      queryKeyGenericStingerTracksRequestList(params.stinger_id),
      params,
    ],
    queryFn: () => apiGetGenericStingerTracksList(params),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 3600000,
  });
};

const apiGetGenericStingerTracksList = async (
  params: IGenericStingerTracksQueryParams,
) => {
  const response = await axiosInstance.get(
    queryKeyGenericStingerTracksRequestList(params.stinger_id),
    {
      params: params,
    },
  );
  return response.data.data as IGenericStringerTracksItem[];
};
