import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {IMsgReqEventDateType} from '../../../dto/MessageRequest.dto';
import {ICompanySchool} from '../../../dto/CompanySchool.dto';
import {IStation} from '../../../dto/Station.dto';
import {IEventType} from '../../../dto/EventType.dto';
import {CombinedMessageProps} from '../event-specific/EventSpecificViewMessage';
import MusicPreviewBtn from '../../../components/music/MusicPreviewBtn';
import {useSelector} from 'react-redux';
import {isGTSelector} from '../../../store/Slices/companySlice';
import {ISeason} from '../../../react_query/seasons/ListSeasons';

type Props = {
  schools: ICompanySchool[];
  stations: IStation[];
  events: IEventType[];
  event_date_type?: IMsgReqEventDateType;
  messages: CombinedMessageProps[];
  other_event_name?: string;
  seasons: ISeason[];
  isAllSeason: boolean;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    overflowX: 'auto',
  },
});

const StingerReview = ({
  schools,
  stations,
  events,
  other_event_name,
  messages,
  isAllSeason,
  seasons,
}: Props) => {
  const classes = useStyles();
  const isGT = useSelector(isGTSelector);
  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h5' className='text-white font-bold mt-2 mb-9'>
          Review
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {isGT ? (
          <Box className={`${classes.root} mb-4`}>
            <Box className='w-82'>
              <Typography
                variant='h6'
                gutterBottom
                className='mb-0 text-white-50'>
                Schools
              </Typography>
            </Box>
            <Box>
              <Typography variant='h6' className='text-white'>
                <Box
                  className={`${classes.root} scrollbar flex flex-wrap max-h-84 max-w-650 overflow-auto`}
                  style={{gap: '2px'}}>
                  {schools.map((item, index: number) => {
                    if (item.id !== 0) {
                      return (
                        <Box key={item.id}>
                          <span id='school-name'>{item.name}</span>
                          {index !== schools.length - 1 && <span>, </span>}
                        </Box>
                      );
                    }
                    return null;
                  })}
                </Box>
              </Typography>
            </Box>
          </Box>
        ) : null}
        <Box className={classes.root} style={{marginBottom: '16px'}}>
          <Box className='w-82'>
            <Typography
              variant='h6'
              gutterBottom
              className='mb-0 text-white-50'>
              Stations
            </Typography>
          </Box>
          <Box>
            <Typography variant='h6' className='text-white'>
              <Box
                className={`${classes.root} scrollbar flex flex-wrap max-h-84 max-w-650 overflow-auto`}
                style={{gap: '2px'}}>
                {stations.map((item, index: number) => {
                  if (item.id !== 0) {
                    return (
                      <Box key={item.id}>
                        <span id='Stations-name'>{item.name}</span>
                        {index !== stations.length - 1 && <span>, </span>}
                      </Box>
                    );
                  }
                  return null;
                })}
              </Box>
            </Typography>
          </Box>
        </Box>
        {isGT ? (
          <Box className={classes.root} style={{marginBottom: '16px'}}>
            <Box className='w-82'>
              <Typography
                variant='h6'
                gutterBottom
                className='mb-0 text-white-50'>
                Sports
              </Typography>
            </Box>
            <Box>
              <Typography variant='h6' className='text-white'>
                <Box
                  className={`${classes.root} scrollbar flex flex-wrap max-h-84 max-w-650 overflow-auto`}
                  style={{gap: '2px'}}>
                  {events.map((item, index: number) => {
                    if (item.id !== 0) {
                      return (
                        <Box key={item.id}>
                          <span id='Sports-name' style={{whiteSpace: 'nowrap'}}>
                            {item.event_name}
                          </span>
                          {index !== stations.length - 1 && <span>, </span>}
                        </Box>
                      );
                    }
                    return null;
                  })}
                  {other_event_name &&
                    events.find(x => x.id === 0) &&
                    (events.length > 1
                      ? `, ${other_event_name}`
                      : other_event_name)}
                </Box>
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box className={classes.root} style={{marginBottom: '16px'}}>
            <Box className='w-82'>
              <Typography
                variant='h6'
                gutterBottom
                className='mb-0 text-white-50'>
                Seasons
              </Typography>
            </Box>
            <Box>
              <Typography variant='h6' className='text-white'>
                {isAllSeason
                  ? 'Year Around'
                  : seasons.map(o => o.season_name).join(', ')}
              </Typography>
            </Box>
          </Box>
        )}

        <Box>
          <Typography variant='h6' className='text-white'>
            Message Details
          </Typography>
          <TableContainer
            component={Paper}
            className='indReviewTable'
            elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className='w-50'>Play</TableCell>
                  <TableCell className='min-w-160 text-white-50'>
                    Message Name
                  </TableCell>
                  <TableCell className='text-white-50'>Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messages.map(
                  (row, index) =>
                    (row.message !== '' || Boolean(row.media_track)) &&
                    row.name !== '' && (
                      <TableRow key={row.uuid}>
                        <TableCell align='center'>
                          {row.media_track ? (
                            <MusicPreviewBtn
                              id={row.uuid}
                              url={row.media_track.url}
                            />
                          ) : null}
                        </TableCell>
                        <TableCell
                          id={`message-name-${index + 1}`}
                          className='text-white'>
                          {row.name}
                        </TableCell>
                        <TableCell
                          id={`message-description-${index + 1}`}
                          className='text-white'>
                          <pre>{row.message}</pre>
                        </TableCell>
                      </TableRow>
                    ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </>
  );
};

export default StingerReview;
