import {Button, Divider, Stack, Typography} from '@mui/material';
import {useCallback} from 'react';
import {
  IPricingReivewDetailsProp,
  IPricingReivewProp,
} from '../AddOnDetailsPage';
import {IStationWithIsDelete} from './AddOnCmpt';

export const AddOnRightBillingCmpt = ({
  type,
  pricingReviewDetails,
  onboardingTotal,
  stations,
  handleSubmit,
}: {
  type: string;
  pricingReviewDetails: IPricingReivewDetailsProp;
  onboardingTotal: number;
  stations: IStationWithIsDelete[];
  handleSubmit: () => void;
}) => {
  const isSubmittable = useCallback(() => {
    return stations.some(val => {
      return val.type === 'mirror_station' && !Boolean(val.parent_id);
    });
  }, [stations]);

  return (
    <>
      <Typography className='font-bold'>Review</Typography>
      <AddOnRightBillingRowCmpt billField={pricingReviewDetails.level} />
      <AddOnRightBillingRowCmpt
        billField={pricingReviewDetails.deleted_full_station}
      />
      {type === 'Neptune Radio' ? (
        <AddOnRightBillingRowCmpt
          billField={pricingReviewDetails.deleted_mirror_station}
        />
      ) : null}
      {type === 'GameTime' ? (
        <AddOnRightBillingRowCmpt
          billField={pricingReviewDetails.deleted_odo_station}
        />
      ) : null}
      {type !== 'SchoolZone' ? (
        <AddOnRightBillingRowCmpt
          billField={pricingReviewDetails.updated_full_station}
        />
      ) : null}
      {type === 'GameTime' ? (
        <AddOnRightBillingRowCmpt
          billField={pricingReviewDetails.updated_odo_station}
        />
      ) : null}
      {type === 'Neptune Radio' ? (
        <AddOnRightBillingRowCmpt
          billField={pricingReviewDetails.updated_mirror_station}
        />
      ) : null}
      <AddOnRightBillingRowCmpt billField={pricingReviewDetails.setup} />
      <AddOnRightBillingRowCmpt billField={pricingReviewDetails.full_station} />
      {type === 'GameTime' ? (
        <AddOnRightBillingRowCmpt
          billField={pricingReviewDetails.odo_station}
        />
      ) : null}
      {type === 'Neptune Radio' ? (
        <AddOnRightBillingRowCmpt
          billField={pricingReviewDetails.mirror_station}
        />
      ) : null}
      <AddOnRightBillingRowCmpt billField={pricingReviewDetails.discount} />
      <AddOnRightBillingRowCmpt
        billField={pricingReviewDetails.service_total}
      />
      <Divider className='my-3' />
      <AddOnRightBillingRowCmpt
        billField={pricingReviewDetails.total_invoice}
      />
      <Divider className='mt-3 mb-10' />

      <AddOnRightBillingRowCmpt
        billField={{
          name: 'Onboarding Total',
          count: 0,
          price: onboardingTotal,
        }}
      />
      <AddOnRightBillingRowCmpt
        billField={{
          name: 'Add On Total',
          count: 0,
          price: pricingReviewDetails.total_invoice.price,
        }}
      />
      <Divider className='my-3' />
      <AddOnRightBillingRowCmpt
        billField={{
          name: 'Grand Total',
          count: 0,
          price: onboardingTotal + pricingReviewDetails.total_invoice.price,
        }}
      />
      <Divider className='my-3' />

      <Button
        variant='contained'
        disabled={isSubmittable()}
        onClick={handleSubmit}
        fullWidth>
        Confirm
      </Button>
    </>
  );
};
const AddOnRightBillingRowCmpt = ({
  billField,
}: {
  billField: IPricingReivewProp;
}) => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'} my={1}>
      <Typography>{billField.name}</Typography>
      {billField.name === 'Discount' ? (
        <Typography color={'red'}>-${billField.price || 0}</Typography>
      ) : billField.price < 0 ? (
        <Typography color={'red'}>
          -${Math.abs(billField.price || 0)}
        </Typography>
      ) : (
        <Typography>${billField.price || 0}</Typography>
      )}
    </Stack>
  );
};
