import {Button, ButtonProps} from '@mui/material';

const RestoreButton: React.FC<ButtonProps> = ({...buttonProps}) => {
  return (
    <Button {...buttonProps} variant='outlined' color='success' size='small'>
      Restore
    </Button>
  );
};

export default RestoreButton;
