export const tabsStyleRadioMode = {
  maxWidth: '-webkit-fill-available',
  '& .MuiTabs-scroller': {
    backgroundColor: `var(--black)`,
    padding: '8px',
    paddingBottom: '8px',
    minHeight: '45px',
    borderRadius: '5px',
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-between !important',
    width: '100%',
  },
  '& .Mui-selected': {
    backgroundColor: `var(--blue_variant34)`,
    color: `var(--blue_variant35) !important`,
    borderRadius: '5px',
    fontWeight: '400 !important',
  },
  '& .MuiTabs-indicator': {
    background: 'transparent !important',
    display: 'none',
  },
  '& .MuiButtonBase-root.MuiTab-root': {
    height: '30px !important',
    minHeight: '30px !important',
    padding: '0 25px',
    textTransform: 'capitalize',
    color: `var(--gray-opacity-variant3)`,
  },
};
