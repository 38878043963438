import {makeStyles} from '@mui/styles';

export const animationStyles = makeStyles({
  '@global': {
    '.fade-in': {
      animation: 'fade-in 0.75s cubic-bezier(0.17, 0.67, 0.83, 0.67)',
    },
    '@keyframes fade-in': {from: {opacity: '0'}, to: {opacity: '1'}},
    '@keyframes spin': {
      '0%': {transform: 'rotate(0)'},
      '100%': {transform: 'rotate(360deg)'},
    },

    '.slide-in-bottom': {
      animation: 'slide-in-bottom 0.25s ease-in-out both',
    },
    '@keyframes slide-in-bottom': {
      '0%': {transform: 'translateY(1000px)', opacity: '0'},
      '100%': {transform: 'translateY(0)', opacity: '1'},
    },

    '.slide-out-bottom': {
      animation: 'slide-out-bottom 0.25s ease-in-out both',
    },
    '@keyframes slide-out-bottom': {
      '0%': {transform: 'translateY(0)', opacity: '1'},
      '100%': {transform: 'translateY(1000px)', opacity: '0'},
    },
    '.spin': {animation: 'spin 3s infinite linear'},
  },
});
