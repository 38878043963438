import {IMediaTrack} from '../../../../dto/MediaTrack.dto';
import {ITimedSequenceType} from '../../../../react_query/messages/timed-messages/ViewTimedMsgDetails';
import {iTimedMessageMessageItem} from '../request/contents/TimedMsgRequestContentMessageCard';

export const TimedSequenceToMessageFormat = (
  timed_sequences: ITimedSequenceType,
) => {
  return timed_sequences.map(o => {
    const newMessage = {id: o.id} as iTimedMessageMessageItem;
    if (o.sequenceable_type === 'message') {
      if (o.sequenceable.media_track) {
        newMessage['track'] = o.sequenceable.media_track;
        newMessage['title'] = o.sequenceable.media_track.track_name;
        newMessage['artist'] = o.sequenceable.media_track.track_artist;
        newMessage['type'] = o.sequenceable.message !== '' ? 'message' : 'song';
        newMessage['message'] = o.sequenceable.message ?? '';
      } else if (o.sequenceable.message) {
        newMessage['type'] = 'message';
        newMessage['message'] = o.sequenceable.message;
      } else {
        const fileContent = {
          name: o.sequenceable.prepopulated_file_name,
        };
        newMessage['type'] = 'song';
        newMessage['file'] = fileContent as File;
      }
    } else {
      newMessage['type'] = 'song';
      if (o.sequenceable.media_track) {
        newMessage['track'] = o.sequenceable.media_track;
        newMessage['title'] = o.sequenceable.media_track.track_name;
        newMessage['artist'] = o.sequenceable.media_track.track_artist;
      } else if (o.sequenceable.status === 'new') {
        newMessage['title'] = o.sequenceable.track_name;
        newMessage['artist'] = o.sequenceable.track_artist;
      } else {
        const track = {
          id: o.sequenceable.media_track_id,
          track_name: o.sequenceable.track_name,
          track_artist: o.sequenceable.track_artist,
        } as IMediaTrack;
        newMessage['track'] = track;
      }
    }
    return newMessage;
  });
};
