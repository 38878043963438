import React from 'react';
import {Button, Typography} from '@mui/material';

interface CustomNoRowsOverlayProps {
  imageSrc: string;
  message: string;
  buttonText?: string;
  IconButton?: string;
  onButtonClick?: () => void;
}

const CustomNoRowsOverlay: React.FC<CustomNoRowsOverlayProps> = ({
  imageSrc,
  message,
  buttonText,
  IconButton,
  onButtonClick,
}) => (
  <div className='gridNoDataContainer'>
    <img src={imageSrc} alt='No data' className='noDataImg' />
    <Typography variant='h6' gutterBottom className='mt-5'>
      {message}
    </Typography>
    {buttonText && (
      <Button
        onClick={onButtonClick}
        variant='contained'
        color='secondary'
        className='my-7 mx-11 min-w-250'>
        {IconButton && (
          <img src={IconButton} alt='Create New' className='mr-4' />
        )}
        {buttonText}
      </Button>
    )}
  </div>
);

export default CustomNoRowsOverlay;
