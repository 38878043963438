import {Box, Button, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';

type Props = {
  nextLabel?: string;
  nextDisabled?: boolean;
  isFirstStep: boolean;
  isLastStep: boolean;
  onNext: () => void;
  onBack: () => void;
  addBtn?: {
    label: string;
    onClick: () => void;
  };
  disabled?: boolean;
  loading?: boolean;
  footerLabel?: string;
};
const StepperButtons = ({
  nextLabel,
  nextDisabled,
  onBack,
  onNext,
  isFirstStep,
  isLastStep,
  addBtn,
  disabled,
  loading,
  footerLabel,
}: Props) => {
  return (
    <Box
      className={`w-full flex items-center ${
        footerLabel && footerLabel.length > 0
          ? 'justify-between'
          : 'justify-end'
      }`}>
      {footerLabel && footerLabel.length > 0 ? (
        <Typography className='text-white-50 text-13 mt-1 italic'>
          {footerLabel}
        </Typography>
      ) : null}
      <Box display='flex'>
        {!isFirstStep ? (
          <Button
            onClick={onBack}
            variant={isFirstStep ? 'contained' : 'outlined'}
            color='info'
            disabled={disabled}>
            Previous
          </Button>
        ) : null}
        {addBtn && (
          <Button
            variant='contained'
            onClick={addBtn.onClick}
            disabled={disabled}>
            {addBtn.label}
          </Button>
        )}
        <LoadingButton
          loading={loading}
          variant='contained'
          onClick={onNext}
          disabled={nextDisabled || disabled}>
          {nextLabel || (isLastStep ? 'Submit' : 'Next')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default StepperButtons;
