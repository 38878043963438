import {Box, List, ListItemButton, Typography} from '@mui/material';
import {useCallback} from 'react';
import IconTick from '../../../asset/icons/icon_tick.svg';

export type StepperItemProps = {
  label: string;
  disabled: boolean;
  subText?: string;
  onClick?: (index: number) => void;
  isOptional?: boolean;
  isReviewDisabled?: boolean;
  virtual?: boolean;
};

const StepperLeftNavItem = ({
  step,
  stepIndex,
  showDivider,
  selected,
  isTick,
  disabled,
  onClick,
  stepNumber,
}: {
  stepIndex: number;
  step: StepperItemProps;
  showDivider: boolean;
  selected: boolean;
  isTick: boolean;
  disabled?: boolean;
  onClick: (index: number) => void;
  stepNumber: number;
}) => {
  const handleClick = useCallback(() => {
    (step.onClick || onClick)(stepIndex);
  }, [onClick, step.onClick, stepIndex]);

  const RoundComp = useCallback(() => {
    return (
      <Box
        className='w-34 h-34 min-w-34 flex flex-column justify-center radius-100 ml-7'
        sx={{
          background: isTick ? 'var(--blue-variant1)' : 'var(--blue_variant37)',
          border: selected
            ? '1px solid var(--blue-variant1)'
            : '1px solid var(--gray-variant9)',
        }}
        onClick={handleClick}>
        {isTick ? (
          <img
            src={IconTick}
            alt='Asset is loading...'
            className='w-16 h-12 self-center'
          />
        ) : (
          <Typography className='text-white text-16 self-center'>
            {stepNumber}
          </Typography>
        )}
      </Box>
    );
  }, [isTick, selected, handleClick, stepNumber]);

  return (
    <ListItemButton
      className='w-full flex flex-column justify-start'
      disabled={disabled}
      onClick={handleClick}>
      <Box
        className='w-full h-34 flex flex-row justify-start'
        sx={{
          marginTop: stepNumber === 1 ? '30px' : '0px',
        }}>
        <RoundComp />
        <Typography className='text-white text-16 self-center ml-6'>
          {step.label}
        </Typography>
      </Box>
      {showDivider ? (
        <Box className='w-full h-50 flex flex-row justify-start'>
          <Box className='w-34 h-full ml-7 mt-1 flex flex-row justify-center'>
            <Box
              className='h-full w-1 self-center '
              sx={{border: '1px dashed #707070'}}
            />
          </Box>
        </Box>
      ) : null}
    </ListItemButton>
  );
};

const StepperLeftNavigation = ({
  steps,
  activeStepIndex,
  onStepClick,
  disabled,
}: {
  steps: StepperItemProps[];
  activeStepIndex: number;
  onStepClick: (index: number) => void;
  disabled?: boolean;
}) => {
  let isPreviousStepDisabled = false;

  let stepNumber = 0;
  const stepIsVirtual = steps.some(
    (step, index) => activeStepIndex === index && step.virtual,
  );
  return (
    <List
      className='max-w-312 w-full stepper-sec h-full overflow-auto scrollbar'
      sx={{background: 'var(--blue_variant36)'}}>
      {stepIsVirtual ||
        steps.map((step, index) => {
          if (step.virtual) {
            return null;
          }
          const isDisabled = step.disabled || isPreviousStepDisabled;
          isPreviousStepDisabled = isDisabled;
          stepNumber++;
          return (
            <StepperLeftNavItem
              key={step.label}
              step={step}
              selected={activeStepIndex === index}
              isTick={index < activeStepIndex}
              disabled={isDisabled || disabled}
              stepIndex={index}
              stepNumber={stepNumber}
              showDivider={index !== steps.length - 1}
              onClick={onStepClick}
            />
          );
        })}
    </List>
  );
};

export default StepperLeftNavigation;
