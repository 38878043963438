import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {Dayjs} from 'dayjs';
import {useSelector} from 'react-redux';
import {useCallback, useMemo, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useLocation, useNavigate} from 'react-router-dom';
import {apiPut} from '../../../axiosConfig';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import DialogApiRequestStatus from '../../../components/dialogs/DialogApiRequestStatus';
import OndemandExpiryDate from './OndemandExpiryDate';
import {IOdmStatus} from '../../../dto/OndemandMessageRequest.dto';
import {DialogClose} from '../../../components/dialogs/DialogFrame/DialogClose';

type Props = {
  status: IOdmStatus;
  handleCancel: () => void;
  messageRequestId: number;
};

const defaultApiState = {
  status: '',
  error: null,
};

const OnDemandActiveDialog = ({
  status,
  messageRequestId,
  handleCancel,
}: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const companyId = useSelector(companyIdSelector);
  const [apiState, setApiState] = useState(defaultApiState);
  const [isExpireDateSet, setIsExpireDateSet] = useState(false);
  const [expireDate, setExpireDate] = useState<Dayjs | null>(null);

  const isExpireDateNotValid = useMemo(
    () => isExpireDateSet && expireDate === null,
    [isExpireDateSet, expireDate],
  );

  const handleSubmit = useCallback(() => {
    const url =
      status === 'Active'
        ? `/v1/company/${companyId}/message/ondemand-message/${messageRequestId}/inactive`
        : `/v1/company/${companyId}/message/ondemand-message/${messageRequestId}/active`;

    const end_date = expireDate ? expireDate.format('YYYY-MM-DD') : '';
    const payload = {
      is_expire_date_set: isExpireDateSet,
      expire_date: end_date,
    };
    apiPut(url, payload).then(_res => {
      queryClient.invalidateQueries({
        queryKey: [`v1/company/${companyId}/message/ondemand-message`],
        refetchType: 'active',
      });
      if (location.pathname.includes('/view')) {
        navigate('/messages/ondemand-message');
      }
      setApiState({
        status: 'success',
        error: null,
      });
    });
  }, [
    companyId,
    expireDate,
    isExpireDateSet,
    location.pathname,
    messageRequestId,
    navigate,
    queryClient,
    status,
  ]);

  if (apiState.status !== '') {
    return (
      <DialogApiRequestStatus
        onEdit={() => setApiState(defaultApiState)}
        onRetry={handleSubmit}
        onClose={handleCancel}
        apiState={apiState}
      />
    );
  }

  return (
    <Dialog open onClose={handleCancel} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Typography className='alignCenter'>Change Request Status</Typography>
        <DialogClose onClose={handleCancel} />
      </DialogTitle>

      <DialogContent>
        {status === 'Inactive' && (
          <OndemandExpiryDate
            is_expire_date_set={isExpireDateSet}
            expire_date={expireDate}
            setIsExpireDateSet={setIsExpireDateSet}
            setExpireDate={setExpireDate}
          />
        )}
        {status === 'Active' && (
          <>
            <h5 className='text-white text-center'>
              Are you sure to deactivate this message?
            </h5>
          </>
        )}
      </DialogContent>
      <DialogActions className='flex items-center justify-center'>
        {status === 'Inactive' ? (
          <Button
            color='success'
            variant='contained'
            onClick={handleSubmit}
            disabled={isExpireDateNotValid}>
            Restore
          </Button>
        ) : (
          <Button
            variant='contained'
            className='deactivate-btn'
            color='error'
            onClick={handleSubmit}>
            Deactivate
          </Button>
        )}
        <Button variant='contained' onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OnDemandActiveDialog;
