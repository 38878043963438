import {ButtonProps, IconButton} from '@mui/material';
import iconView from '../../asset/icons/icon_view.svg';

const IconViewButton: React.FC<ButtonProps> = ({...buttonProps}) => {
  return (
    <IconButton {...buttonProps} title='View' aria-label='view' color='primary'>
      <img src={iconView} alt='' />
    </IconButton>
  );
};

export default IconViewButton;
