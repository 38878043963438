import {InputBase, Paper} from '@mui/material';
import IconSearch from '../../asset/icons/icon_search.svg';

const inputStyle = {
  borderRadius: '100px',
  background: `var(--blue-variant6)`,
  border: `1px solid var(--gray-variant8)`,
  padding: '3px 4px 2px 12px',
  boxShadow: `inset 0 3px 6px var(--gray-opacity-variant1)`,
  '.MuiInputBase-root': {
    minHeight: '34px !important',
    margin: '0 !important',
    padding: '0 !important',
  },
};
export default function InputSearch({
  placeholder,
  value,
  onChange,
  className,
}: {
  placeholder: string;
  value: string;
  onChange?: (value: string) => void;
  className?: string;
}) {
  return (
    <Paper
      className={`input-search flex alignCenter w-full h-42 items-center ${className}`}
      sx={inputStyle}>
      <InputBase
        sx={{ml: 1, flex: 1}}
        value={value}
        placeholder={placeholder}
        inputProps={{'aria-label': placeholder}}
        onChange={e => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
      />

      <img src={IconSearch} alt='Search' className='p-2' />
    </Paper>
  );
}
