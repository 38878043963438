import {
  Grid,
  List,
  ListItem,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import {styled} from '@mui/material/styles';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import {useRqViewListMessagesByRequestID} from '../../../../react_query/messages/MessagesByCmpId';
import {IMessage} from '../../../../dto/Message.dto';
import BackCloseStoreBtn from '../../../../components/styles/BackCloseStoreBtn';
import {IStation} from '../../../../dto/Station.dto';
import {ICompanySchool} from '../../../../dto/CompanySchool.dto';
import {
  companyIdSelector,
  isGTSelector,
} from '../../../../store/Slices/companySlice';
import {useStoreSelector} from '../../../../store/configstore';

type ICustLibrary = {
  id: number;
  name: string;
  custom_library_type?: string;
};

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const Demo = styled('div')(({theme}) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(144, 202, 249, 0.24)' : '#fff',
  height: '200px',
  overflow: 'scroll',
  color: theme.palette.mode === 'dark' ? '#ffffff' : 'black',
}));

const ViewOnDemandMgsList = () => {
  const location = useLocation();
  const companyId = useStoreSelector(companyIdSelector);
  const currentPath = location.pathname.split('/');
  const request_from = currentPath[2] ? currentPath[2] : '';
  const requestID = currentPath[3] ? Number(currentPath[3]) : 0;
  const [content, setContent] = React.useState<IMessage[]>([]);
  const [stations, setStations] = React.useState<IStation[]>([]);
  const [schools, setSchools] = React.useState<ICompanySchool[]>([]);
  const [customLib, setCustomLib] = React.useState<ICustLibrary[]>([]);
  const [isExpired, setIExpired] = React.useState<boolean>(false);
  const [isExpiredDate, setIsExpiredDate] = React.useState<string | undefined>(
    undefined,
  );
  const [boxSize, setBoxSize] = React.useState<number>(4);

  const {data, isFetched, isError} = useRqViewListMessagesByRequestID(
    companyId,
    requestID,
    request_from,
    Boolean(requestID),
  );
  const isGT = useSelector(isGTSelector);
  React.useEffect(() => {
    setBoxSize(isGT ? 4 : 6);
    if (isFetched && !isError) {
      const result = data?.data;
      setContent(result?.messages ?? []);
      setStations(result?.stations ?? []);
      setSchools(result?.schools ?? []);
      setCustomLib(result?.custom_library_request ?? []);
      setIExpired(result?.is_expire_date_set ? false : true);
      setIsExpiredDate(result?.expire_date);
    }
  }, [data, isError, isFetched, isGT]);

  return (
    <>
      <BackCloseStoreBtn
        title='VIEW ONDEMAND MESSAGE'
        storeType='remove'
        storeName='sess_CustomLibrary'
        storeData={null}
        redirectTo='/messages/ondemand-message'
      />

      <Grid container spacing={2}>
        <Grid item xs={boxSize}>
          <Item>
            <Typography sx={{fontWeight: '500'}} align='center'>
              STATIONS LIST
            </Typography>
          </Item>
          <Demo>
            <List
              dense={true}
              sx={{maxHeight: '48vh', overflowY: 'auto', p: 2}}>
              {stations.map(v => (
                <ListItem sx={{p: 0}} key={v.id} dense>
                  <Typography key={v.id}>{v.name}</Typography>
                </ListItem>
              ))}
            </List>
          </Demo>
        </Grid>
        {isGT ? (
          <Grid item xs={boxSize}>
            <Item>
              <Typography sx={{fontWeight: '500'}} align='center'>
                SCHOOLS
              </Typography>
            </Item>
            <Demo>
              <List
                dense={true}
                sx={{maxHeight: '48vh', overflowY: 'auto', p: 2}}>
                {schools.map(v => (
                  <ListItem sx={{p: 0}} key={v.id} dense>
                    <Typography key={v.id}>{v.name}</Typography>
                  </ListItem>
                ))}
              </List>
            </Demo>
          </Grid>
        ) : null}
        <Grid item xs={boxSize}>
          <Item>
            <Typography sx={{fontWeight: '500'}} align='center'>
              CUSTOM LIBRARY
            </Typography>
          </Item>
          <Demo>
            <List
              dense={true}
              sx={{maxHeight: '48vh', overflowY: 'auto', p: 2}}>
              {customLib.map(v => (
                <ListItem sx={{p: 0}} key={v.id} dense>
                  <Typography key={v.id}>{v.name}</Typography>
                </ListItem>
              ))}
            </List>
          </Demo>
        </Grid>
        {!isExpired ? (
          <Grid item xs={12}>
            <Item>
              <Typography sx={{fontWeight: '500'}} align='center'>
                END DATE
              </Typography>
              <Typography sx={{fontWeight: '500'}} align='center'>
                {dayjs(isExpiredDate).format('MM/DD/YYYY')}
              </Typography>
            </Item>
          </Grid>
        ) : null}
        <Grid item xs={12} sx={{display: 'flex'}}>
          <TableContainer component={Paper} sx={{mt: 3}}>
            <Table sx={{minWidth: 650}} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>Play</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Messagge</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Approved By</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {content.length ? (
                  content.map((v: IMessage) => (
                    <PrimaryContent key={v.id} value={v} />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>No Data Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

const PrimaryContent = ({value}: {value: IMessage}) => {
  const audioRef = React.useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
    setIsPlaying(!isPlaying);
  };
  const handleAudioEnded = () => {
    setIsPlaying(false);
  };
  return (
    <TableRow>
      <TableCell>
        {value.initial_url ? (
          <IconButton
            aria-label='play'
            onClick={handlePlayPause}
            color='primary'>
            <audio
              ref={audioRef}
              src={value.initial_url}
              onEnded={handleAudioEnded}
            />
            {isPlaying ? <PauseCircleIcon /> : <PlayCircleIcon />}
          </IconButton>
        ) : null}
      </TableCell>
      <TableCell>
        <Typography>
          <pre>{value.message}</pre>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.approved_by ? (value.approved_by.name as string) : ''}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
export default ViewOnDemandMgsList;
