import dayjs from 'dayjs';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {LicenseInfo} from '@mui/x-license-pro';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {CssBaseline, ThemeProvider} from '@mui/material';
import updateLocale from 'dayjs/plugin/updateLocale';
import {SnackbarProvider} from 'notistack';
// Theme Font: Poppins
import '@fontsource/poppins';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/400-italic.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {store} from './store/configstore';
import {darkTheme} from './components/layouts/Theme';
import 'react-toastify/dist/ReactToastify.css';
import GlobalCss from './styles/GlobalCss';
import './styles/app.css';

if (process.env.REACT_APP_MUI_X_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY);
}

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const queryClient = new QueryClient();
ReactDOM.render(
  // @ts-ignore
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={updateLocale as unknown as string}>
        <Provider store={store}>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <GlobalCss />
            <SnackbarProvider autoHideDuration={3000} />
            <BrowserRouter>
              <ToastContainer />
              <App />
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </LocalizationProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
