import dayjs, {Dayjs} from 'dayjs';
import {DatePicker} from '@mui/x-date-pickers';
import CustomCalIcon from '../../../asset/images/calendar.png';

function MuiIcon() {
  return <img src={CustomCalIcon} alt='Date picker opening icon' width={23} />;
}

export const CalendarIconDatePicker = ({
  label,
  selectedDate,
  handleSingleDateChange,
  disabledDate,
}: {
  label?: string;
  selectedDate: Dayjs | null;
  handleSingleDateChange: (date: Dayjs | null) => void;
  disabledDate?: Dayjs | null;
}) => {
  const shouldDisableDate = (date: Dayjs) => {
    const disabledDates = disabledDate ? disabledDate : dayjs();
    return (
      date.isBefore(disabledDates, 'day') || date.isSame(disabledDates, 'day')
    );
  };
  return (
    <DatePicker
      label={label ?? 'Date'}
      slots={{openPickerIcon: MuiIcon}}
      shouldDisableDate={shouldDisableDate}
      disablePast
      value={selectedDate ?? null}
      sx={{width: 200}}
      onChange={handleSingleDateChange}
      className='popupDatePicker'
    />
  );
};
