import {Box, Button, Stack, Typography} from '@mui/material';
import IconBackArrowButton from '../../../../components/Button/IconBackArrowButton';

const DetailViewContainerCard = ({
  header,
  body,
  className,
}: {
  header: React.ReactNode;
  body: React.ReactNode;
  className?: string;
}) => {
  return (
    <Box className={className ? className : 'h-730 mx-10 mt-5'}>
      <CardHeader body={header} />
      <CardBody body={body} />
    </Box>
  );
};

export default DetailViewContainerCard;

const CardHeader = ({body}: {body: React.ReactNode}) => {
  return (
    <Box
      sx={{
        background: `var(--blue-variant17)`,
        borderRadius: '10px 10px 0 0',
      }}>
      {body}
    </Box>
  );
};

const CardBody = ({body}: {body: React.ReactNode}) => {
  return (
    <Box
      height={'100%'}
      className='overflow-auto scrollbar'
      sx={{background: `var(--blue_variant29)`}}>
      {body}
    </Box>
  );
};

export const TNNControlHeaderCmpt = ({
  type,
  handleTypeChange,
}: {
  type: string;
  handleTypeChange: (val: string) => void;
}) => {
  return (
    <Box className='p-5 flex justify-between align-center'>
      <Typography className='text-white-50 text-center font-bold text-18 pt-2'>
        TNN Control - {type}
      </Typography>
      <Stack spacing={2} direction={'row'}>
        <Button
          variant={type === 'Participating Partners' ? 'contained' : 'outlined'}
          onClick={() => handleTypeChange('Participating Partners')}>
          Participating Partners
        </Button>
        <Button
          variant={type === 'Sponsors' ? 'contained' : 'outlined'}
          onClick={() => handleTypeChange('Sponsors')}>
          Sponsors
        </Button>
        <Button
          variant={type === 'Reports' ? 'contained' : 'outlined'}
          onClick={() => handleTypeChange('Reports')}>
          Reports
        </Button>
      </Stack>
    </Box>
  );
};

export const HeaderWithBackButtonAndCenterTitle = ({
  title,
  handleBack,
}: {
  title: string;
  handleBack: () => void;
}) => {
  return (
    <Box
      className='p-5 flex justify-between align-center'
      sx={{background: `var(--blue-variant17)`, borderRadius: '20px 20px 0 0'}}>
      <IconBackArrowButton onClick={handleBack} />
      <Typography
        variant='h4'
        className='text-white-50 text-center font-bold w-full'>
        {title}
      </Typography>
    </Box>
  );
};

export const HeaderWithCenterTitle = ({
  title,
  className = 'p-3 text-white-50 text-20 text-center font-bold',
}: {
  title: string;
  className?: string;
}) => {
  return <Typography className={className}>{title}</Typography>;
};
