import {Dayjs} from 'dayjs';
import {daysOfWeek} from '../../../dto/DateTime.dto';

export function GetDaysFromDateRange(from: Dayjs, to: Dayjs) {
  const customDaysOfWeek = [];

  const length = to.diff(from, 'day') + 1;

  if (length > 6) {
    return daysOfWeek;
  }

  while (from.isBefore(to) || from.isSame(to)) {
    customDaysOfWeek.push(from.format('dddd'));
    from = from.add(1, 'day');
  }

  return customDaysOfWeek;
}

export function GetDaysFromMultipleDateRange(
  datesArray: {start_date: Dayjs | null; end_date: Dayjs | null}[],
): string[] {
  const customDaysOfWeek: Set<string> = new Set();

  for (const dates of datesArray) {
    if (dates.start_date && dates.end_date) {
      const daysInRange = GetDaysFromDateRange(
        dates.start_date,
        dates.end_date,
      );
      daysInRange.forEach(day => customDaysOfWeek.add(day));
    }

    if (customDaysOfWeek.size === 7) {
      break;
    }
  }

  return daysOfWeek.filter(day => customDaysOfWeek.has(day));
}
