import dayjs from 'dayjs';
import {iTimedMsgDateRange} from './TimedMessageRequest';

export const isValidTimedMsgDates = (
  dateRanges: iTimedMsgDateRange[],
  dayType: string,
  isStaff: boolean,
) => {
  const today = isStaff
    ? dayjs().subtract(1, 'day').startOf('day')
    : dayjs().startOf('day');

  if (dateRanges.length === 0) {
    return false;
  }
  return dateRanges.every(dateRange => {
    const startDate = dayjs(dateRange.start_date).startOf('day');
    const endDate = dayjs(dateRange.end_date).startOf('day');
    let isPastDate =
      dayType !== 'multiple'
        ? startDate.isAfter(today)
        : startDate.isAfter(today) && endDate.isAfter(today);
    let dateCheck =
      dateRange.start_date && (dayType !== 'multiple' || dateRange.end_date);
    return dateCheck && isPastDate;
  });
};
