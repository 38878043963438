import dayjs from 'dayjs';
import {useCallback} from 'react';
import {List, ListItem, Stack, Typography} from '@mui/material';
import {iTimedMsgTimesItem} from './TimedMsgRequestTimesCard';
import IconDeleteButton from '../../../../../components/Button/IconDeletebutton';

export type iTimedMsgTimeRange = {
  id: number;
  start_time: dayjs.Dayjs | null;
};

type Props = {
  timeRange: iTimedMsgTimeRange;
  itemId: number;
  disableDelete: boolean;
  setDaysTimes: React.Dispatch<React.SetStateAction<iTimedMsgTimesItem[]>>;
};

const TimedMsgRequestTimeRangePicker = ({
  timeRange,
  itemId,
  disableDelete,
  setDaysTimes,
}: Props) => {
  const handleDelete = useCallback(() => {
    setDaysTimes(prevItems => {
      const index = prevItems.findIndex(o => o.id === itemId);
      if (index === -1) return prevItems;
      const newItems = [...prevItems];
      const newItem = {
        ...newItems[index],
      } as iTimedMsgTimesItem;
      newItem.timeRanges = newItem.timeRanges.filter(
        o => o.id !== timeRange.id,
      );
      newItems.splice(index, 1, newItem);
      return newItems;
    });
  }, [itemId, setDaysTimes, timeRange.id]);

  return (
    <List className='p-0 pr-2 flex-column list-items-sec'>
      <ListItem className='py-2 px-0'>
        <Stack
          direction='row'
          spacing={1}
          className='flex items-center justify-between w-full'>
          <Typography>{timeRange.start_time?.format('hh:mm a')}</Typography>
          <IconDeleteButton onClick={handleDelete} disabled={disableDelete} />
        </Stack>
      </ListItem>
    </List>
  );
};

export default TimedMsgRequestTimeRangePicker;
