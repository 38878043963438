import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {apiGet} from '../../../axiosConfig';
import {IApiDataResponse} from '../../../dto/API.dto';

export const getTimedMsgExistenceApiPath = (company_id: number) => {
  return `v1/company/${company_id}/message/timed-message/check-exist`;
};

const getTimedMsgExistence = (company_id: number) => {
  return apiGet(getTimedMsgExistenceApiPath(company_id)).then(
    (response: AxiosResponse<IApiDataResponse<boolean>>) => {
      return response.data.data;
    },
  );
};

export const useRqTimedMsgExistence = (company_id: number) => {
  const resultVal = useQuery({
    queryKey: [getTimedMsgExistenceApiPath(company_id)],
    queryFn: () => getTimedMsgExistence(company_id),
    refetchOnWindowFocus: false,
    retry: false,
  });
  return resultVal;
};
