import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import axiosInstance from '../../../axiosConfig';
import {IApiDataResponse} from '../../../dto/API.dto';
import {ITaskMessage} from '../../../dto/Task.dto';

export const useRqGetRecordingTaskMessages = (taskId: number) => {
  return useQuery({
    queryKey: [`v1/task/${taskId}/recording`],
    queryFn: () => getRecordingTaskMessages(taskId),
    refetchOnWindowFocus: false,
    retry: false,
  });
};

const getRecordingTaskMessages = (taskId: number) => {
  return axiosInstance
    .get(`v1/task/${taskId}/recording`)
    .then((response: AxiosResponse<IApiDataResponse<ITaskMessage>>) => {
      return response.data.data;
    });
};
