import {ButtonProps, IconButton} from '@mui/material';
import IconEdit from '../../asset/icons/icon_editbtn.svg';

const IconEditButton: React.FC<ButtonProps> = ({...buttonProps}) => {
  return (
    <IconButton {...buttonProps} color='primary'>
      {IconEdit && <img src={IconEdit} alt='' />}
    </IconButton>
  );
};

export default IconEditButton;
