import {AxiosResponse} from 'axios';
import {useQuery} from '@tanstack/react-query';
import axiosInstance from '../../../axiosConfig';
import {IApiPaginationResponse} from '../../../dto/API.dto';
import {
  IMsgReqEventDateType,
  IMsgRequestProgress,
  IMsgRequestStatus,
} from '../../../dto/MessageRequest.dto';
import {IUser} from '../../../dto/User.dto';
import {ICompany} from '../../../dto/Company.dto';
import {IDataGridSearchSort} from '../../../dto/Html.dto';

export interface IEventMessageRequest {
  id: number;
  company: ICompany;
  status: IMsgRequestProgress;
  request_status: IMsgRequestStatus;
  event_date_type: IMsgReqEventDateType;
  start_date: Date;
  end_date: Date;
  start_time: string;
  end_time: string;
  created_by: IUser;
}

export const onDemandGridApiPath = (company_id: number) =>
  `v1/company/${company_id}/message/ondemand-message`;

export const useRqGetOnDemandMessageList = (
  company_id: number,
  parms: IDataGridSearchSort,
) => {
  const params = {...parms, page: parms.page + 1};
  return useQuery({
    queryKey: [onDemandGridApiPath(company_id), params],
    queryFn: () => getOndemandMgsList(company_id, params),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

const getOndemandMgsList = (
  company_id: number,
  params: IDataGridSearchSort,
) => {
  return axiosInstance
    .get(onDemandGridApiPath(company_id), {
      params,
    })
    .then(
      (
        response: AxiosResponse<IApiPaginationResponse<IEventMessageRequest[]>>,
      ) => {
        return response.data;
      },
    );
};
