import axios, {AxiosError, AxiosResponse} from 'axios';
import axiosInstance from '../axiosConfig';
import {IUser} from '../dto/User.dto';
import {ICompanyDetails} from '../dto/Company.dto';
import Toast from '../package/Toaster';
import {AxiosErrorToast} from '../react_query/general/ReusableFunctions';
import {IApiDataResponse} from '../dto/API.dto';
import {IRole} from '../dto/Role.dto';

const apiBase = process.env.REACT_APP_API_END_POINT;

interface ILoginUserDetails extends IUser {
  email: string;
  profile: string;
  user_type: number;
  roles: IRole[];
  company: ICompanyDetails;
  tawk_hash: string;
}

export interface ILoginResponse {
  isImpersonatedUser?: boolean;
  isForcePassword?: boolean;
  user: ILoginUserDetails;
  token: string;
  scopes: string[];
  companies: ICompanyDetails[];
}

export const login = (email: string, password: string) => {
  return axios
    .post(apiBase + '/v1/login', {
      email,
      password,
    })
    .then((response: AxiosResponse<ILoginResponse>) => {
      return response;
    })
    .catch((error: AxiosError) => {
      AxiosErrorToast(error, 'Login error');
      throw error;
    });
};

export const userLogout = () => {
  return axiosInstance
    .post('/v1/user/logout')
    .then(() => {
      localStorage.clear();
      sessionStorage.clear();
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        Toast(error.response.data?.message, 'error');
      }
      throw error;
    });
};

export const viewUser = async (id: number) => {
  return axiosInstance
    .get(`/v1/user/${id}`)
    .then((response: AxiosResponse<IApiDataResponse<ILoginUserDetails>>) => {
      return response;
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        Toast(error.response.data?.message ?? 'Something went wrong', 'error');
      }
      throw error;
    });
};
