import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IApiDataResponse} from '../../dto/API.dto';
import {apiGet} from '../../axiosConfig';

export type IEventSub = {
  id: number;
  name: string;
  selected: boolean;
  parent?: string;
  special_name?: string;
};

export const getGroupedEventsApiPath = (companyId: number) =>
  `/v1/music-selection/company/${companyId}/event-group`;

export interface IEventGrouped {
  id: number;
  name: string;
  selected: boolean;
  sub_list: IEventSub[];
}
const getGroupedEvents = (companyId: number) => {
  return apiGet(getGroupedEventsApiPath(companyId)).then(
    (response: AxiosResponse<IApiDataResponse<IEventGrouped[]>>) => {
      return response.data?.data;
    },
  );
};

export const useRqGroupedEvents = (
  companyId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getGroupedEventsApiPath(companyId)],
    queryFn: () => getGroupedEvents(companyId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};

export interface IEventGroupedBlackList {
  id: number;
  event_name: string;
  selected: boolean;
}

export const getBlackListGroupedEventsApiPath = (companyId: number) =>
  `/v1/music-selection/company/${companyId}/events`;

const getBlackListGroupedEvents = (companyId: number) => {
  return apiGet(getBlackListGroupedEventsApiPath(companyId)).then(
    (response: AxiosResponse<IApiDataResponse<IEventGroupedBlackList[]>>) => {
      return response.data?.data;
    },
  );
};

export const useRqBlackListGroupEvents = (
  companyId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getBlackListGroupedEventsApiPath(companyId)],
    queryFn: () => getBlackListGroupedEvents(companyId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};
