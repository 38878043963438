import IconButton from '@mui/material/IconButton';
import IconClose from '../../../asset/icons/icon_close.svg';

export const DialogClose = ({onClose}: {onClose: () => void}) => {
  return (
    <IconButton className='w-34' onClick={onClose}>
      <img src={IconClose} alt='close' />
    </IconButton>
  );
};
