import React from 'react';
import {useSelector} from 'react-redux';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {Backdrop, CircularProgress} from '@mui/material';
import {RootState} from '../../store/configstore';
import {isAuthenticatedSelector} from '../../store/Slices/userSlice';

export default function AuthUsersWithoutHeader() {
  const navigate = useNavigate();

  const {loader} = useSelector((state: RootState) => state.common);
  const isLogin = useSelector(isAuthenticatedSelector);
  const location = useLocation();

  React.useEffect(() => {
    if (!isLogin) {
      navigate('/sign-in', {state: {redirectTo: location.pathname}});
    }
  }, [navigate, isLogin, location.pathname]);

  return (
    <>
      <BackDropCmpt loader={loader} />
      <React.Suspense fallback={<BackDropCmpt />}>
        <Outlet />
      </React.Suspense>
    </>
  );
}

const BackDropCmpt = ({loader = true}) => {
  return (
    <Backdrop
      sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
      open={loader}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};
