import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_END_POINT,
});

axiosInstance.interceptors.request.use(
  config => {
    let accessToken = ``;
    if (sessionStorage.hasOwnProperty('access_token')) {
      accessToken = sessionStorage.getItem('access_token') as string;
    } else {
      accessToken = localStorage.getItem('access_token') as string;
    }
    if (accessToken) {
      config.headers = {
        authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
      };
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('user_id');
      localStorage.removeItem('access_token');
      localStorage.removeItem('company_id');
      sessionStorage.clear();
      window.location.href = '/sign-in';
    }
    return Promise.reject(error);
  },
);
export const apiGet = axiosInstance.get;
export const apiPost = axiosInstance.post;
export const apiPut = axiosInstance.put;
export const apiDelete = axiosInstance.delete;
export const apiPatch = axiosInstance.patch;

export default axiosInstance;
