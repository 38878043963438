import {useMemo, useState} from 'react';
import {apiPost} from '../../axiosConfig';
import DialogApiRequestStatus from '../../components/dialogs/DialogApiRequestStatus';
import {
  StepperLayout,
  useStepperState,
} from '../../components/layouts/stepper/StepperLayout';
import {
  ISponsorByToken,
  ISponsorReactiveProp,
  ISponsorshipRequestProp,
} from '../../dto/Sponsor.dto';
import SponsorReactiveReview from './SponsorReactiveReview';
import SponsorReactiveType from './SponsorReactiveType';
import SponsorshipRequest from './SponsorshipRequest';
import DialogSuccess from '../../components/dialogs/DialogSuccess';

type Props = {
  data: ISponsorByToken;
  token: string;
};
const SponsorReactiveBySponsor = ({data, token}: Props) => {
  const {activeStepIndex, onNextStep, onPreviousStep, onStepClick} =
    useStepperState(0);
  const [apiState, setApiState] = useState({status: '', error: null});
  const [close, setClose] = useState(false);
  const [sponsorReactiveType, setSponsorReactiveType] =
    useState<ISponsorReactiveProp>({
      reactive_type: '',
      files: undefined,
    });
  const [sponsorshipRequest, setSponsorshipRequest] =
    useState<ISponsorshipRequestProp>({
      request_format: '',
      files: undefined,
      message: '',
    });
  const steps = useMemo(() => {
    const isValidated =
      sponsorReactiveType.reactive_type === '2' ||
      (sponsorReactiveType.reactive_type === '1' &&
        sponsorReactiveType.files !== undefined) ||
      sponsorReactiveType.reactive_type === '0';
    const sponsorshipRequestNotEmpty =
      (String(sponsorshipRequest.request_format) === '0' &&
        sponsorshipRequest.files !== undefined) ||
      (String(sponsorshipRequest.request_format) === '1' &&
        sponsorshipRequest.message !== '');
    const sponsorDetailstep =
      sponsorReactiveType.reactive_type !== '2'
        ? isValidated
        : sponsorshipRequestNotEmpty;

    const stepList = [
      {
        label: 'Sponsor Reactive',
        disabled: false,
      },
      {
        label: 'Review',
        disabled: !sponsorDetailstep,
      },
    ];
    if (sponsorReactiveType.reactive_type === '2') {
      stepList.splice(1, 0, {
        label: 'Sponsorship Details',
        disabled: !isValidated,
      });
    }
    return stepList;
  }, [sponsorReactiveType, sponsorshipRequest]);

  const handleSubmit = () => {
    setApiState({
      status: 'pending',
      error: null,
    });
    const payload = new FormData();

    payload.append(
      `sponsor_reactive[reactive_type]`,
      sponsorReactiveType.reactive_type,
    );
    payload.append(
      `sponsor_reactive[files]`,
      sponsorReactiveType.files as Blob,
    );
    if (sponsorReactiveType.reactive_type === '2') {
      payload.append(
        `sponsorship_info[request_format]`,
        sponsorshipRequest['request_format'],
      );
      payload.append(
        `sponsorship_info[files]`,
        sponsorshipRequest['files'] as Blob,
      );
      payload.append(
        `sponsorship_info[message]`,
        sponsorshipRequest['message'] as string,
      );
    }
    apiPost(`/v1/sponsor/${token}/reactive`, payload)
      .then(() => {
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({
          status: 'error',
          error,
        });
      });
  };
  const handleEdit = () => {
    setApiState({
      status: '',
      error: null,
    });
  };
  const handleClose = () => {
    setApiState({
      status: '',
      error: null,
    });
    setClose(true);
  };
  if (close) {
    return <DialogSuccess message='Request has been updated sucessfully!!' />;
  }
  if (!close) {
    return (
      <StepperLayout
        steps={steps}
        activeStepIndex={activeStepIndex}
        onBack={onPreviousStep}
        onNext={onNextStep}
        onCancel={handleClose}
        onSubmit={handleSubmit}
        onStepClick={onStepClick}
        isSubmitted={apiState.status !== ''}>
        {activeStepIndex === 0 && (
          <SponsorReactiveType
            sponsorReactiveType={sponsorReactiveType}
            setSponsorReactiveType={setSponsorReactiveType}
          />
        )}
        {activeStepIndex === 1 && sponsorReactiveType.reactive_type === '2' && (
          <SponsorshipRequest
            sponsorshipRequest={sponsorshipRequest}
            setSponsorshipRequest={setSponsorshipRequest}
            type={1}
            product={data.company.product}
            companyName={data.company.name}
          />
        )}
        {(activeStepIndex === 2 ||
          (sponsorReactiveType.reactive_type !== '2' &&
            activeStepIndex === 1)) && (
          <SponsorReactiveReview
            sponsorReactiveType={sponsorReactiveType}
            sponsorshipRequest={sponsorshipRequest}
          />
        )}

        {apiState.status !== '' && (
          <DialogApiRequestStatus
            apiState={apiState}
            onRetry={handleSubmit}
            onEdit={handleEdit}
            onClose={handleClose}
          />
        )}
      </StepperLayout>
    );
  }
  return null;
};
export default SponsorReactiveBySponsor;
