import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {ICompany} from '../../dto/Company.dto';
import {ICompanySchool} from '../../dto/CompanySchool.dto';

export interface IOnboardCmySchools extends ICompany {
  hubspot_id: number;
  product_type: 1 | 2; //1-Gametime, 2-Neptune Radio
  discount: number;
  branding_type: 1 | 2 | null; //1-DistrictBranding , 2-SchoolBranding
  schools: ICompanySchool[];
}

const initialState: IOnboardCmySchools = {
  id: 0,
  name: '',
  hubspot_id: 0,
  branding_type: null,
  product_type: 1,
  discount: 0,
  schools: [],
};
export const OnboardCmySchoolsSlice = createSlice({
  name: 'onboardCmySchools',
  initialState,
  reducers: {
    init: (
      state: IOnboardCmySchools,
      action: PayloadAction<IOnboardCmySchools>,
    ) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.hubspot_id = action.payload.hubspot_id;
      state.branding_type = action.payload.branding_type;
      state.product_type = action.payload.product_type;
      state.discount = action.payload.discount;
      state.schools = action.payload.schools;
    },
  },
});

export const {init} = OnboardCmySchoolsSlice.actions;

export default OnboardCmySchoolsSlice.reducer;
