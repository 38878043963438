import {AxiosError} from 'axios';
import Toast from '../../package/Toaster';

type ErrorResponse = {[key: string]: string[]};

export const AxiosErrorToast = (
  error: AxiosError,
  content: string = 'Something went wrong',
) => {
  if (error.response && error.response.status === 422) {
    const errors: ErrorResponse = error?.response?.data?.errors;

    // Loop through validation errors and display individual toasts
    Object.entries(errors).forEach(([field, msgObj]) => {
      if (msgObj.length) {
        // Display individual error messages
        Toast(`${field}: ${msgObj[0]}`, 'error');
      }
    });
  } else if (error.response) {
    // Display the error message from the response data
    Toast(error.response.data.message ?? content, 'error');
  } else {
    // Display a generic error message
    Toast(content, 'error');
  }
};
