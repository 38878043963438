import {useSelector} from 'react-redux';
import {Dispatch, SetStateAction, useCallback} from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  isGTSelector,
  isNRorSZSelector,
} from '../../../../store/Slices/companySlice';
import {IScripts} from './BaseMessagesReducer';
import MusicPreviewBtn from '../../../../components/music/MusicPreviewBtn';
import IconDeleteButton from '../../../../components/Button/IconDeletebutton';
import {isInternalSelector} from '../../../../store/Slices/userSlice';
import {TooltipText} from '../../../../components/styles/typography/TooltipText';
import {IEventType} from '../../../../dto/EventType.dto';
import {ICompanySchool} from '../../../../dto/CompanySchool.dto';
import {IStation} from '../../../../dto/Station.dto';
import {ISeasonMessage, seasonsDetails} from '../../../../dto/EditMessage.dto';

type Props = {
  messages: IScripts[];
  voiceName: string[];
  setMessages?: Dispatch<SetStateAction<IScripts[]>>;
  events: IEventType[];
  schools: ICompanySchool[];
  stations: IStation[];
  seasons: ISeasonMessage;
  formStatus: string | null;
};
const BaseMessageReview = ({
  messages,
  voiceName,
  setMessages,
  events,
  schools,
  stations,
  seasons,
  formStatus,
}: Props) => {
  const isNRSZ = useSelector(isNRorSZSelector);
  const isStaff = useSelector(isInternalSelector);
  const isGT = useSelector(isGTSelector);
  const handleDelete = useCallback(
    (id: string) => {
      if (setMessages) {
        setMessages(prevMessages => {
          return prevMessages.filter(message => message.uuid !== id);
        });
      }
    },
    [setMessages],
  );
  return (
    <>
      <Box className='h-68 w-full flex flex-row justify-between'>
        <Typography className='text-white text-18'>Review</Typography>
      </Box>
      {isNRSZ && voiceName.length > 0 ? (
        <Box className='flex flex-row' sx={{width: 'calc(100% - 52px)'}}>
          <Typography className='text-white opacity-50 text-16 mr-1'>
            Voice Artist
          </Typography>
          <Typography className='text-white text-16'>
            {voiceName.map(name => name).join(', ')}
          </Typography>
        </Box>
      ) : null}
      <Box sx={{width: 'calc(100% - 52px)'}}>
        <Typography className='text-white opacity-50 text-16 mr-1 mt-2'>
          Station(s)
        </Typography>
        <Typography className='text-white text-16 '>
          {stations.map(val => val.name).join(', ')}
        </Typography>
        {isGT && (
          <>
            <Typography className='text-white opacity-50 text-16 mr-1 mt-2'>
              School(s)
            </Typography>
            <Typography className='text-white text-16 '>
              {schools.map(val => val.name).join(', ')}
            </Typography>
          </>
        )}
        {isGT && (
          <>
            <Typography className='text-white opacity-50 text-16 mr-1 mt-2'>
              Sport/Event(s)
            </Typography>
            <Typography className='text-white text-16 '>
              {events.map(val => val.event_name).join(', ')}
            </Typography>
          </>
        )}
        {isNRSZ && (
          <>
            <Typography className='text-white opacity-50 text-16 mr-1 mt-2'>
              Seasons(s)
            </Typography>

            <Typography className='text-white text-16 '>
              {seasons.isAllSeason
                ? 'Year Around'
                : seasons.seasons
                    .map((val: seasonsDetails) => val.season_name)
                    .join(', ')}
            </Typography>
          </>
        )}
      </Box>
      <TableContainer
        className='h-360 overflow-auto scrollbar'
        sx={{width: 'calc(100% - 52px)'}}>
        <Table>
          <TableHead className='blue-variant17'>
            <TableRow>
              {isStaff ? (
                <TableCell width={80} align='left'>
                  Play
                </TableCell>
              ) : null}
              <TableCell width={180} align='left'>
                <Typography className='text-white text-16 opacity-50'>
                  Message Name
                </Typography>
              </TableCell>

              <TableCell align='left'>
                <Typography
                  className='text-white text-16 opacity-50'
                  id='Message'>
                  Message
                </Typography>
              </TableCell>
              {!formStatus && (
                <TableCell align='center'>
                  <Typography className='text-white text-16 opacity-50 px-1'>
                    Action
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {messages
              .filter(o => {
                if (formStatus) {
                  return o;
                }
                return o.message !== '' && o.name !== '';
              })
              .map((val, index) => (
                <TableRow key={val.id}>
                  {isStaff ? (
                    <TableCell>
                      {val.media_track ? (
                        <MusicPreviewBtn
                          id={val.media_track.id}
                          url={val.media_track.url}
                        />
                      ) : null}
                    </TableCell>
                  ) : null}
                  <TableCell>
                    <Typography
                      className='text-white text-16 px-2'
                      id={`message-name-${index}`}
                      title={val.name}>
                      <TooltipText text={`${val.name}`} />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className='text-white text-16 px-1'
                      id={`message-description-${index}`}
                      title={val.message}>
                      <TooltipText text={`${val.message}`} maxWidth={200} />
                    </Typography>
                  </TableCell>
                  {!formStatus && (
                    <TableCell align='center'>
                      <IconDeleteButton
                        onClick={() => {
                          handleDelete(val.uuid);
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BaseMessageReview;

export const SeasonEventTableCell = ({val}: {val: IScripts}) => {
  if (val.seasons.length > 0) {
    return <>{val.seasons.map(season => season.season_name).join(', ')}</>;
  }
  if (val.events.length > 0) {
    return <>{val.events.map(event => event.event_name).join(', ')}</>;
  }
  if (val.isAllSeason) {
    return <>Year Around</>;
  }
  return null;
};

type IEditProps = {
  messages: IScripts[];
};
export const BaseMessageEditReview = ({messages}: IEditProps) => {
  return (
    <>
      <Card>
        <CardHeader title='Messages'></CardHeader>
        <CardContent>
          {messages.map((val, index) => (
            <List key={index}>
              <ListItem>
                <Typography
                  variant='body1'
                  component='div'
                  style={{width: '60%'}}>
                  Name
                </Typography>
                <Typography
                  variant='h6'
                  component='div'
                  id={`message-name-${index}`}>
                  {val.name}
                </Typography>
              </ListItem>
              {val.isAllSeason && (
                <ListItem>
                  <Typography
                    variant='body1'
                    component='div'
                    style={{width: '60%'}}>
                    Season(s)
                  </Typography>
                  <Typography variant='subtitle1' component='div'>
                    Year Around
                  </Typography>
                </ListItem>
              )}
              {val.seasons.length > 0 && (
                <ListItem>
                  <Typography
                    variant='body1'
                    component='div'
                    style={{width: '60%'}}>
                    Season(s)
                  </Typography>
                  <Typography variant='subtitle1' component='div'>
                    {val.seasons.map(season => season.season_name).join(', ')}
                  </Typography>
                </ListItem>
              )}
              {val.events.length > 0 && (
                <ListItem>
                  <Typography
                    variant='body1'
                    component='div'
                    style={{width: '60%'}}>
                    Event/Sport(s)
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='div'
                    id={`events-${index}`}>
                    {val.events.map(event => event.event_name).join(', ')}
                  </Typography>
                </ListItem>
              )}
              <ListItem>
                <TextField
                  multiline
                  rows={4}
                  sx={{width: '100%'}}
                  aria-readonly
                  value={val.message}
                  id={`message-description-${index}`}></TextField>
              </ListItem>
            </List>
          ))}
        </CardContent>
      </Card>
    </>
  );
};
