import {Dayjs} from 'dayjs';

export type IStartEndTime = {
  id: number;
  uuid?: string;
  startTime: Dayjs | null;
  endTime: Dayjs | null;
};

export interface IStartEndDate {
  id: number;
  uuid?: string;
  start_date: Dayjs | null;
  end_date: Dayjs | null;
}

export interface IStartEndDateTime {
  id: number;
  start_date: Dayjs | null;
  end_date: Dayjs | null;
  start_time: Dayjs | null;
  end_time: Dayjs | null;
  uuid?: string;
  is_whole_day?: boolean;
}

export const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const;

export type IDaysOfWeek = FlattenArray<typeof daysOfWeek>;

type FlattenArray<T> = T extends ReadonlyArray<infer U> ? U : T;
