import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {
  ICompaniesQueryParmas,
  useRqPaginateCompanies,
} from '../../../../Service/companyService';
import RetryPage from '../../../../components/styles/RetryButton';
import useDebounce from '../../../../components/utlis/useDebounce';
import {ICompanyDetails} from '../../../../dto/Company.dto';
import {IErrorResponse} from '../../../../dto/Html.dto';
import IconBackArrowButton from '../../../../components/Button/IconBackArrowButton';
import {IStation} from '../../../../dto/Station.dto';
import {IService} from '../../../../dto/Service.dto';
import {IconDeleteDialog} from './IconDeleteDialog';
import {AddNewStationDialog} from './AddNewStationDialog';
import {IconResetDialog} from './IconResetDialog';

export const removeUnwantedCharacters = (value: string) => {
  let result = value.replace(/[\[\]\(\)\{\}\-\|]/g, '').replace(/\s{2,}/g, ' ');
  return result.trim();
};

const queryDefault = {
  page: 1,
  per_page: 100,
  search: '',
};

const AddOnSearchCmpt = ({
  currentSchool,
  setCurrentSchool,
}: {
  currentSchool: ICompanyDetails | null;
  setCurrentSchool: React.Dispatch<
    React.SetStateAction<ICompanyDetails | null>
  >;
}) => {
  const [query, setQuery] = useState<ICompaniesQueryParmas>(queryDefault);
  const [companies, setCompanies] = useState<ICompanyDetails[]>([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState<string | null>('');
  const [, setRetryTime] = React.useState(Date.now());

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedSearch) {
      setQuery(prev => {
        return {...prev, search: debouncedSearch};
      });
    }
    if (debouncedSearch?.length === 0) {
      setCompanies([]);
    }
  }, [debouncedSearch]);

  const selectOnchange = useCallback(
    (Values: ICompanyDetails | null) => {
      setCurrentSchool(Values);
    },
    [setCurrentSchool],
  );

  const {data, isFetching, isError, error} = useRqPaginateCompanies(
    query,
    Boolean(debouncedSearch?.length) && !!query.search,
  );

  useEffect(() => {
    if (data && !isFetching && !isError) {
      setCompanies(data.data);
    }
  }, [data, isError, isFetching]);

  if (isError) {
    const errResp = error as IErrorResponse;
    return (
      <RetryPage
        pageTitle='List hubspot companies'
        errResp={errResp?.response}
        setRetryTime={setRetryTime}
        queryKey={['api/v1/companies', `${query}`]}
      />
    );
  }

  return (
    <Autocomplete
      sx={{width: 500, mx: 'auto'}}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={currentSchool}
      getOptionLabel={option =>
        option.name !== undefined
          ? `${option.name}${
              option.product.cmc_product ? '-' + option.product.cmc_product : ''
            }`
          : ''
      }
      getOptionDisabled={option => !option.product.cmc_product}
      options={companies ?? []}
      onChange={(_: React.SyntheticEvent<Element, Event>, newValue) => {
        setSearch(newValue?.name ?? '');
        selectOnchange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        if (event && event.type === 'change') {
          setSearch(newInputValue ?? '');
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          label='School / Organization'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isFetching ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AddOnSearchCmpt;

// New Design Addon Components.

export type IStationWithIsDelete = IStation & {
  previous_type: string;
  is_deleted: boolean;
  deleted_at: string;
};

export type ILevelStationDetails = {
  level: {
    previous_level: string;
    selected_level: string;
  };
  stations: IStationWithIsDelete[];
  setup: number;
  discount: number;
  laptop: number;
};
export type IStationCountProp = {
  full_station: number | null;
  odo_station: number | null;
  mirror_station: number | null;
  discount: number | null;
  setup: number | null;
  laptop: number | null;
};

export const AddOnHeader = ({
  title,
  handleBack,
}: {
  title: string;
  handleBack: () => void;
}) => {
  return (
    <Box
      className='p-5 flex justify-between align-center'
      sx={{background: `var(--blue-variant17)`, borderRadius: '20px 20px 0 0'}}>
      <IconBackArrowButton onClick={handleBack} />
      <Typography variant='h4' className='text-white-50 text-center font-bold'>
        {title}
      </Typography>
      <Typography></Typography>
    </Box>
  );
};

export const AddOnBody = ({body}: {body: React.ReactNode}) => {
  return (
    <Box
      height={'100%'}
      sx={{background: `var(--blue_variant29)`, borderRadius: '0 0 20px 20px'}}>
      {body}
    </Box>
  );
};

export const AddOnLevelSelectionCmpt = ({
  productType,
  levels,
  levelStationDetails,
  handleLevelChange,
}: {
  productType: string;
  levels: IService[];
  levelStationDetails: ILevelStationDetails;
  handleLevelChange: (val: string) => void;
}) => {
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      sx={{background: 'black'}}
      className='w-500 mx-auto px-7 py-3 my-5'>
      {levels.map(service => (
        <CheckBoxWithBlueSelectedLabel
          key={service.id}
          productType={productType}
          levelStationDetails={levelStationDetails}
          service={service}
          handleLevelChange={handleLevelChange}
        />
      ))}
    </Stack>
  );
};

const CheckBoxWithBlueSelectedLabel = ({
  levelStationDetails,
  service,
  handleLevelChange,
  productType,
}: {
  levelStationDetails: ILevelStationDetails;
  service: IService;
  handleLevelChange: (val: string) => void;
  productType: string;
}): JSX.Element => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={
            productType === 'SchoolZone' &&
            levelStationDetails.level.previous_level === 'Level 2'
          }
          checked={levelStationDetails.level.selected_level === service.name}
          onChange={() => handleLevelChange(service.name ?? '')}
        />
      }
      label={
        <Typography
          className={
            levelStationDetails.level.selected_level === service.name
              ? `text-blue-variant1`
              : 'text-white-50'
          }>
          {service.name}
        </Typography>
      }
    />
  );
};

export const AddOnStationCreateAndViewCmpt = ({
  type,
  isStationDeletable,
  isAddDisabled,
  company,
  stationLevels,
  levelStationDetails,
  setLevelStationDetails,
}: {
  type: string;
  isStationDeletable: boolean;
  isAddDisabled: boolean;
  company: ICompanyDetails;
  stationLevels: IService[];
  levelStationDetails: ILevelStationDetails;
  setLevelStationDetails: React.Dispatch<
    React.SetStateAction<ILevelStationDetails>
  >;
}) => {
  const handleStationLevelChecked = useCallback(
    (id: number, selected: string) => {
      setLevelStationDetails(prev => {
        return {
          ...prev,
          stations: prev.stations.map(val => {
            if (val.id === id) {
              return {...val, type: selected} as IStationWithIsDelete;
            }
            return val;
          }),
        };
      });
    },
    [setLevelStationDetails],
  );

  const handleDelete = useCallback(
    (id: number) => {
      setLevelStationDetails(prev => {
        if (id > 0) {
          return {
            ...prev,
            stations: prev.stations.map(val => {
              if (val.id === id) {
                return {...val, is_deleted: true} as IStationWithIsDelete;
              }
              return val;
            }),
          };
        } else {
          let stationCount = prev.stations.filter(val => val.id > 0).length + 1;
          const filteredStations = prev.stations.filter(val => val.id !== id);
          const newStations = filteredStations.map(val => {
            if (val.id < 0) {
              const count = stationCount++;
              return {
                ...val,
                name: `${removeUnwantedCharacters(company.name)} ${count}`,
              };
            }
            return val;
          });

          return {...prev, stations: newStations};
        }
      });
    },
    [company.name, setLevelStationDetails],
  );

  const handleReset = useCallback(
    (id: number) => {
      setLevelStationDetails(prev => {
        if (id > 0) {
          return {
            ...prev,
            stations: prev.stations.map(val => {
              if (val.id === id) {
                return {...val, is_deleted: false} as IStationWithIsDelete;
              }
              return val;
            }),
          };
        }
        return prev;
      });
    },
    [setLevelStationDetails],
  );

  return (
    <Box
      sx={{
        background:
          type === 'current'
            ? 'var(--blue_variant36)'
            : 'var(--blue-variant17)',
      }}
      p={3}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Typography className='h-50 flex items-center'>
          {type === 'current' ? 'Current Stations' : 'Revised Stations'}
        </Typography>
        {type === 'revised' ? (
          <AddNewStationDialog
            company={company}
            isAddDisabled={isAddDisabled}
            stationLevels={stationLevels}
            levelStationDetails={levelStationDetails}
            setLevelStationDetails={setLevelStationDetails}
          />
        ) : null}
      </Stack>
      <TableContainer
        className={`h-full max-h-350 overflow-auto scrollbar ${
          type === 'current' ? 'w-400' : 'w-600'
        }`}>
        <Table>
          <TableHead sx={{background: 'var(--blue-variant17)'}}>
            <TableRow>
              <TableCell align='left'>
                <Typography className='text-16 opacity-50 text-white'>
                  Stations
                </Typography>
              </TableCell>
              {type === 'current' ? (
                <TableCell align='center'>
                  <Typography className='text-16 opacity-50 text-white'>
                    Current Level
                  </Typography>
                </TableCell>
              ) : (
                <>
                  {company.product.cmc_product !== 'SchoolZone' ? (
                    <TableCell align='center'>
                      <Typography className='text-16 opacity-50 text-white'>
                        Level Upgrade
                      </Typography>
                    </TableCell>
                  ) : null}
                  <TableCell align='center'>
                    <Typography className='text-16 opacity-50 text-white'>
                      Action
                    </Typography>
                  </TableCell>
                </>
              )}

              <TableCell width={72}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='max-h-500 overflow-auto scrollbar'>
            {type === 'current' &&
              levelStationDetails.stations &&
              levelStationDetails.stations.map(item => {
                if (!item.deleted_at && item.id > 0) {
                  return <CurrentStationRow key={item.id} item={item} />;
                }
                return null;
              })}
            {type === 'revised' &&
              levelStationDetails.stations &&
              levelStationDetails.stations.map(station => (
                <RevisedStationRow
                  key={station.id}
                  type={company.product.cmc_product}
                  station={station}
                  isStationDeletable={isStationDeletable}
                  stationLevels={stationLevels}
                  handleStationLevelChecked={handleStationLevelChecked}
                  handleDelete={handleDelete}
                  handleReset={handleReset}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const RevisedStationRow = ({
  station,
  type,
  stationLevels,
  isStationDeletable,
  handleStationLevelChecked,
  handleDelete,
  handleReset,
}: {
  type: string;
  station: IStationWithIsDelete;
  stationLevels: IService[];
  isStationDeletable: boolean;
  handleStationLevelChecked: (id: number, selected: string) => void;
  handleDelete: (id: number) => void;
  handleReset: (id: number) => void;
}): JSX.Element => {
  return (
    <TableRow>
      <TableCell align='left'>
        <Typography
          variant='caption'
          className='text-16 text-white'
          title={station.name}>
          {station.name}
        </Typography>
      </TableCell>
      {type !== 'SchoolZone' ? (
        <TableCell align='center'>
          <Stack direction={'row'} justifyContent={'center'} spacing={1}>
            {stationLevels.map(val => (
              <FormControlLabel
                key={val.id}
                className='mr-0'
                control={
                  <Checkbox
                    checked={station.type === val.name}
                    onChange={() =>
                      handleStationLevelChecked(station.id, val.name ?? '')
                    }
                  />
                }
                label={
                  <Typography>
                    {val.name === 'full_station'
                      ? 'FULL'
                      : val.name === 'odo_station'
                      ? 'ODO'
                      : 'MIRROR'}
                  </Typography>
                }
              />
            ))}
          </Stack>
        </TableCell>
      ) : null}
      <TableCell align='center'>
        {(station.is_deleted && station.deleted_at) ||
        (station.is_deleted && station.id > 0) ? (
          <IconResetDialog id={station.id} handleReset={handleReset} />
        ) : (
          <IconDeleteDialog
            id={station.id}
            handleDelete={handleDelete}
            isDisabled={type === 'SchoolZone' && isStationDeletable}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

const CurrentStationRow = ({
  item,
}: {
  item: IStationWithIsDelete;
}): JSX.Element => {
  return (
    <TableRow>
      <TableCell align='left'>
        <Typography
          variant='caption'
          className='text-16 text-white'
          title={item.name}>
          {item.name}
        </Typography>
      </TableCell>
      <TableCell align='center'>
        <Typography
          variant='caption'
          className='text-16 text-white'
          title={item.previous_type}>
          {item.previous_type === 'full_station'
            ? 'FULL'
            : item.type === 'odo_station'
            ? 'ODO'
            : 'MIRROR'}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
