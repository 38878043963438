import {ChangeEvent, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {useStoreSelector} from '../../../store/configstore';
import {
  companyIdSelector,
  isNRorSZSelector,
} from '../../../store/Slices/companySlice';
import CommonCusDropDown from '../../../pages/messages/on-demand-msg/CommonCusDropDown';
import {
  ISeason,
  useRqListSeasons,
} from '../../../react_query/seasons/ListSeasons';
import BaseCreateSeasonDialog from '../../../pages/contacts/messages/base/BaseCreateSeasonDialog';

const AutoCompleteGetSeasons = ({
  seasons,
  isAllSeason,
  handleYearAround,
  removeSeason,
  removeAllSeasons,
  onChange,
}: {
  seasons: ISeason[];
  isAllSeason: boolean;
  handleYearAround: (e: ChangeEvent<HTMLInputElement>) => void;
  removeSeason: (SeasonId: number) => void;
  removeAllSeasons: () => void;
  onChange: (
    e: React.SyntheticEvent<Element, Event>,
    seasons: ISeason[],
  ) => void;
}) => {
  const isNRSZ = useSelector(isNRorSZSelector);
  const companyId = useStoreSelector(companyIdSelector);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleSeason = () => {
    setOpen(true);
  };
  const {data} = useRqListSeasons(
    Number(companyId),
    Boolean(companyId) && isNRSZ,
  );

  return (
    <>
      <Stack direction='row' className='my-3' alignItems='center' spacing={2}>
        <FormControlLabel
          className='w-200 ml-0 flex-nowrap h-50'
          control={
            <Checkbox
              disableRipple
              checked={isAllSeason}
              onChange={handleYearAround}
            />
          }
          label='Year Around'
        />
        {isAllSeason ? null : (
          <Autocomplete
            multiple
            disableCloseOnSelect
            id='season'
            sx={{
              width: 300,
              '.MuiInputBase-root .MuiButtonBase-root': {
                display: 'none',
              },
            }}
            className='cusAutoSelect w-full max-300 overflow-hidden custDpIcon'
            onChange={onChange}
            disabled={isAllSeason}
            value={seasons}
            options={data ?? []}
            getOptionLabel={option => option.season_name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={params => (
              <TextField {...params} placeholder='Select seasons' />
            )}
            renderOption={(props, option, {selected}) => (
              <li
                {...props}
                className='autoselect-option options-select flex items-center pl-6 cursor-pointer'>
                <Typography component='h6' className='flex items-center'>
                  <Checkbox
                    checked={selected}
                    sx={{'& .MuiSvgIcon-root': {fontSize: 16}}}
                  />
                  {option.season_name}
                </Typography>
              </li>
            )}
          />
        )}
        {isNRSZ && (
          <Button variant='outlined' className='mr-3' onClick={handleSeason}>
            Create New Season
          </Button>
        )}
      </Stack>

      {seasons.length > 0 && (
        <CommonCusDropDown
          showData={seasons}
          cancelData={removeSeason}
          deselectAll={removeAllSeasons}
        />
      )}
      <BaseCreateSeasonDialog
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
      />
    </>
  );
};

export default AutoCompleteGetSeasons;
