import {Alert, AlertTitle, Box, Button, Container, Stack} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import {useStylesed} from './commonStyled';
import {QueryKey, useQueryClient} from '@tanstack/react-query';
import {IErrorData} from '../../dto/Html.dto';

const RetryPage = ({
  setRetryTime,
  queryKey,
  pageTitle = null,
  errResp = null,
}: {
  setRetryTime: React.Dispatch<React.SetStateAction<number>>;
  queryKey: QueryKey;
  errResp?: IErrorData | null;
  pageTitle?: string | null;
}) => {
  const classes = useStylesed();
  const queryClient = useQueryClient();

  const refreshQuery = () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
      refetchType: 'active',
    });
  };

  return (
    <Container maxWidth='sm'>
      <Box className={classes.c_center}>
        <Button
          variant='contained'
          size='large'
          startIcon={<ReplayIcon />}
          onClick={() => {
            refreshQuery();
            setRetryTime(Date.now());
          }}>
          Retry
        </Button>
      </Box>
      {errResp ? (
        <Stack mt={5}>
          <Alert severity='error'>
            {pageTitle ? <AlertTitle>{pageTitle}</AlertTitle> : null}
            {errResp?.data?.message}
          </Alert>
        </Stack>
      ) : null}
    </Container>
  );
};

export default RetryPage;
