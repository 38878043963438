import {ButtonProps, IconButton} from '@mui/material';
import IconDelete from '../../asset/icons/icon_delete.svg';

const IconDeleteButton: React.FC<ButtonProps> = ({...buttonProps}) => {
  return (
    <IconButton
      title='Delete'
      variant='outlined'
      color='error'
      {...buttonProps}>
      {IconDelete && <img src={IconDelete} alt='' />}
    </IconButton>
  );
};

export default IconDeleteButton;
