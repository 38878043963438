import {Box, Button} from '@mui/material';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {UserCanAny} from '../UserCan';
import {
  isGTSelector,
  productLevelSelector,
} from '../../store/Slices/companySlice';

const MusicPlaylistNavButtons = ({type}: {type: string}) => {
  const isGT = useSelector(isGTSelector);
  const productLevel = useSelector(productLevelSelector);

  const rejectedSongs = 'is-admin,partner,rejected-songs:list';
  const walkupScopes =
    'is-admin,partner,music-walkup:list,music-walkup:create,music-walkup:update';
  const templatePattern =
    'is-admin,template-pattern:list,template-pattern:create,template-pattern:delete';
  const musicSchedule =
    'is-admin,partner,music-schedules:list,music-schedules:create,music-schedules:update';
  const onDemandSchedule =
    'is-admin,partner,on-demand-playlist:list,on-demand-playlist:create,on-demand-playlist:view,on-demand-playlist:update,on-demand-playlist:delete';
  const blackListScopes =
    'is-admin,partner,blacklisted-artist:list,blacklisted-artist:create,blacklisted-artist:delete,blacklisted-song:list,blacklisted-song:create,blacklisted-song:delete,blacklisted-tag:list,blacklisted-tag:create,blacklisted-tag:delete,blacklisted-bpm:view,blacklisted-bpm:update';
  const partnerGenreSelectionScopes =
    'is-admin,partner-genre-management:list,partner-genre-management:create,partner-genre-management:update,partner-genre-management:delete';

  return (
    <>
      <Box className='flex gap-3'>
        {isGT && productLevel !== 'Level 0 (ODO Only)' ? (
          <Button
            color='primary'
            variant={type === 'radio-mode' ? 'contained' : 'outlined'}
            component={Link}
            to='/music/radio-mode'>
            Radio Mode
          </Button>
        ) : null}
        {!isGT && (
          <UserCanAny scope={musicSchedule}>
            <Button
              color='primary'
              variant={type === 'music-schedules' ? 'contained' : 'outlined'}
              component={Link}
              to='/music/music-schedules'>
              Music Schedules
            </Button>
          </UserCanAny>
        )}
        <UserCanAny scope={onDemandSchedule}>
          <Button
            color='primary'
            variant={type === 'on-demand' ? 'contained' : 'outlined'}
            component={Link}
            to='/music/on-demand'>
            On Demand
          </Button>
        </UserCanAny>

        {isGT ? (
          <UserCanAny scope={walkupScopes}>
            <Button
              color='primary'
              variant={type === 'walkup' ? 'contained' : 'outlined'}
              component={Link}
              to='/music/walkup'>
              Walk Ups
            </Button>
          </UserCanAny>
        ) : null}

        <UserCanAny scope={rejectedSongs}>
          <Button
            color='primary'
            sx={{whiteSpace: 'nowrap'}}
            variant={type === 'rejected-songs' ? 'contained' : 'outlined'}
            component={Link}
            to='/music/rejected-songs'>
            Rejected Songs/Clips
          </Button>
        </UserCanAny>

        <UserCanAny scope={blackListScopes}>
          <Button
            color='primary'
            variant={
              type === 'blacklist-songs-artists' ? 'contained' : 'outlined'
            }
            component={Link}
            to='/music/blacklist-songs-artists'>
            Blacklist
          </Button>
        </UserCanAny>

        <UserCanAny scope={partnerGenreSelectionScopes}>
          <Button
            color='primary'
            sx={{whiteSpace: 'nowrap'}}
            variant={type === 'manage-genre' ? 'contained' : 'outlined'}
            component={Link}
            to='/music/manage-genre'>
            Manage Genres
          </Button>
        </UserCanAny>
        <UserCanAny scope={templatePattern}>
          <Button
            color='primary'
            variant={type === 'template-pattern' ? 'contained' : 'outlined'}
            component={Link}
            to='/music/template-pattern'>
            Template
          </Button>
        </UserCanAny>
      </Box>
    </>
  );
};

export default MusicPlaylistNavButtons;
