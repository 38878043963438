import {useCallback, useEffect} from 'react';
import {
  Alert,
  Grid,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import {useSelector} from 'react-redux';
import {DatePicker} from '@mui/x-date-pickers';
import OutlinedRadioControlLabel from '../../../../../components/OutlinedRadioControlLabel';
import {ITimedMessageDayType} from '../../../../../dto/timed-message/TimedMessageRequest.dto';
import TimedMsgDateRange from './TimedMsgDateRange';
import IconDeleteButton from '../../../../../components/Button/IconDeletebutton';
import {isInternalSelector} from '../../../../../store/Slices/userSlice';
import CustomCalIcon from '../../../../../asset/icons/icon_calendar_gray.svg';
import {iTimedMsgDateRange} from '../TimedMessageRequest';

type Props = {
  dayType: string;
  onChange: (val: ITimedMessageDayType) => void;
  dates: iTimedMsgDateRange[];
  setDateRanges: React.Dispatch<React.SetStateAction<iTimedMsgDateRange[]>>;
  setFooterText?: React.Dispatch<React.SetStateAction<string>>;
  isToday?: boolean;
};

const MAX_ITEMS = 10;

const options = [
  {
    value: 'no-end',
    label: 'Choose a start date only',
    caption: 'No end date for this message',
  },
  {
    value: 'one-day',
    label: 'One Day',
    caption: 'This message will play only during the selected dates',
  },
  {
    value: 'multiple',
    label: 'Multiple Days',
    caption: 'Multiple combinations of start date & end date',
  },
];

function MuiIcon() {
  return <img src={CustomCalIcon} alt='Date picker opening icon' width={23} />;
}

const TimedMsgRequestDays = ({
  dayType,
  onChange,
  dates,
  setDateRanges,
  setFooterText,
  isToday = false,
}: Props) => {
  const isStaff = useSelector(isInternalSelector);
  const minDate = isStaff ? dayjs() : dayjs().add(1, 'day');

  const handleTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newDayType = event.target.value as ITimedMessageDayType;
      onChange(newDayType);
    },
    [onChange],
  );

  const handleRemove = (id: number) => {
    setDateRanges(prevDateRanges => {
      const newDateRanges = [...prevDateRanges];
      const index = newDateRanges.findIndex(o => o.id === id);
      if (index !== -1) {
        newDateRanges.splice(index, 1);
      }
      return newDateRanges;
    });
  };

  const handeNoEndDate = useCallback(
    (date: Dayjs | null) => {
      setDateRanges([
        {
          id: Date.now(),
          start_date: date,
          end_date: dayjs('2099-12-31'),
        },
      ]);
    },
    [setDateRanges],
  );

  useEffect(() => {
    if (dayType === 'multiple') {
      setFooterText?.(
        ` Up to ${MAX_ITEMS} date range can be added for each request`,
      );
    }
    return () => {
      setFooterText?.('');
    };
  }, [setFooterText, dayType]);

  return (
    <>
      <Typography variant='h5' className='mb-2'>
        Select Date
      </Typography>
      <Typography variant='subtitle1' className='text-white-50 text-14 mb-8'>
        Please choose the day(s) for this Timed activity
      </Typography>
      <RadioGroup
        className='pl-3'
        name='message_type'
        value={dayType}
        onChange={handleTypeChange}>
        <Grid container spacing={1}>
          {options.map(option => (
            <Grid item xs={4} key={option.value}>
              <OutlinedRadioControlLabel
                value={option.value}
                label={option.label}
                caption={option.caption}
              />
            </Grid>
          ))}
          <Grid item xs={4}>
            {dayType === 'no-end' ? (
              <DatePicker
                disablePast
                minDate={minDate}
                label='Start Date'
                value={dates[0]?.start_date ?? null}
                slots={{openPickerIcon: MuiIcon}}
                onChange={handeNoEndDate}
                slotProps={{
                  textField: {size: 'small'},
                  calendarHeader: {className: 'month-year'},
                }}
              />
            ) : null}
          </Grid>
          <Grid item xs={4}>
            {dayType === 'one-day' ? (
              <TimedMsgDateRange
                onChangeDates={setDateRanges}
                isOneDay
                dates={dates}
              />
            ) : null}
          </Grid>
          <Grid item xs={4}>
            {dayType === 'multiple' ? (
              <TimedMsgDateRange
                onChangeDates={setDateRanges}
                isOneDay={false}
                dates={dates}
              />
            ) : null}
          </Grid>
        </Grid>
      </RadioGroup>
      {dayType !== 'no-end' && dates[0] && dates[0].start_date !== null ? (
        <TableContainer className='my-5 h-150 overflow-auto scrollbar'>
          <Table sx={{minWidth: 650}}>
            <TableHead className='blue-variant17'>
              <TableRow>
                {dayType === 'multiple' ? (
                  <>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                  </>
                ) : (
                  <TableCell>Date</TableCell>
                )}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dates.map(range => {
                if (
                  range.start_date &&
                  range.start_date.isValid() &&
                  range.end_date &&
                  range.end_date.isValid()
                ) {
                  return (
                    <TableRow key={range.id}>
                      <TableCell>
                        {range.start_date?.format('MM/DD/YYYY')}
                      </TableCell>
                      {dayType === 'multiple' ? (
                        <TableCell>
                          {range.end_date?.format('MM/DD/YYYY')}
                        </TableCell>
                      ) : null}
                      <TableCell className='text-end'>
                        <IconDeleteButton
                          onClick={() => handleRemove(range.id)}
                          disabled={dates.length === MAX_ITEMS}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}

      {isToday &&
        dates &&
        dates.find(x => x.end_date !== null || x.start_date !== null) && (
          <Alert severity='error' className='mt-3'>
            Please choose a day other than the current day
          </Alert>
        )}
    </>
  );
};

export default TimedMsgRequestDays;
