export default function SecondsNumberToTimeFormat(time: number) {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.round(time % 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${
    hours ? formattedHours + ':' : ''
  }${formattedMinutes}:${formattedSeconds}`;
}

// Shorten text for display
export const manipulateText = (inputString: string, limit: number) => {
  if (inputString && inputString.length <= limit) {
    return inputString;
  }
  if (inputString) {
    let firstTenLetters = inputString.slice(0, limit);
    let modifiedString = firstTenLetters + '...';
    return modifiedString;
  }
  return inputString;
};
