import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IApiDataResponse} from '../../dto/API.dto';
import {IStation} from '../../dto/Station.dto';
import {apiGet} from '../../axiosConfig';

export type IListQueryParams = {
  search?: string;
  page?: number;
  per_page: number;
  sort_field?: string;
  sort_order?: string;
  mirror?: string;
};

const getStations = (company_id: number, mirror: string) => {
  return apiGet(`/v1/company/${company_id}/stations`, {params: {mirror}}).then(
    (response: AxiosResponse<IApiDataResponse<IStation[]>>) => {
      return response.data.data;
    },
  );
};
export const useRqListCompanyStations = (
  company_id: number,
  enabled: boolean = true,
  mirror: string = '',
) => {
  return useQuery({
    queryKey: ['api/v1/company', company_id, 'stations', mirror],
    queryFn: () => getStations(company_id, mirror),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};
