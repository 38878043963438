import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {ICustomLibrary, IMessage, IMessageType} from '../../dto/Message.dto';
import {apiGet} from '../../axiosConfig';
import {IApiDataResponse, IApiPaginationResponse} from '../../dto/API.dto';
import {IMessageRequest} from '../../dto/MessageRequest.dto';
import {IStation} from '../../dto/Station.dto';
import {ICompanySchool} from '../../dto/CompanySchool.dto';
import {ISeason} from '../../pages/contacts/messages/base/BaseMessagesReducer';
import {IEventType} from '../../dto/EventType.dto';
import {IMediaTrack} from '../../dto/MediaTrack.dto';

export type IListQueryParms = {
  search?: string;
  starts_with?: string;
  page?: number;
  selected_day?: string;
  per_page: number;
  company_id?: number;
  id?: number;
  status?: string;
  event_id?: number;
  season_id?: number;
};

export const queryKeyMessageRequestList = (
  company_id: number,
  message_type: IMessageType,
) => {
  return `v1/company/${company_id}/message/${message_type}`;
};

export const useRqGetMessageList = (
  company_id: number,
  parms: IListQueryParms = {
    per_page: 10,
  },
  message_type: IMessageType,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: [queryKeyMessageRequestList(company_id, message_type), parms],
    queryFn: () => getMgsList(company_id, parms, message_type),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};
const getMgsList = (
  company_id: number,
  params: IListQueryParms,
  message_type: IMessageType,
) => {
  return apiGet(queryKeyMessageRequestList(company_id, message_type), {
    params,
  }).then(
    (response: AxiosResponse<IApiPaginationResponse<IMsgRequestCust[]>>) => {
      return response.data;
    },
  );
};

const getViewMessages = (
  companyId: number,
  request_id: number,
  message_type: string,
) => {
  return apiGet(
    `v1/company/${companyId}/message/${message_type}/${request_id}`,
  ).then(
    (
      response: AxiosResponse<IApiDataResponse<IGetMessageRequestResponace>>,
    ) => {
      return response.data;
    },
  );
};

export const useRqViewListMessagesByRequestID = (
  companyId: number,
  request_id: number,
  message_type: string,
  enabled: boolean = true,
) => {
  const resultVal = useQuery({
    queryKey: [
      `api/v1/company/${companyId}/message/${message_type}`,
      request_id,
    ],
    queryFn: () => getViewMessages(companyId, request_id, message_type),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
  return resultVal;
};

export type IGetMessageRequestResponace = {
  custom_library_request: ICustomLibrary[];
  expire_date: string;
  name: string;
  id: number;
  events?: string[];
  seasons?: string[];
  is_expire_date_set: number;
  messages?: IMessage[];
  schools?: ICompanySchool[];
  stations?: IStation[];
  voice_artists?: string[];
  created_at: string;
  created_by: string;
  is_all_seasons: number;
  status: string;
};

export type IGetBaseMessageRequestResponace = {
  custom_library_request: ICustomLibrary[];
  expire_date: string;
  name: string;
  id: number;
  events?: IEventType[];
  seasons?: ISeason[];
  is_expire_date_set: number;
  message?: IMessage;
  schools?: ICompanySchool[];
  stations?: IStation[];
  voice_artists?: string[];
  created_at: string;
  created_by: string;
  is_all_seasons: number;
  status: string;
};

export interface IMsgRequestCust extends IMessageRequest {
  company_name: string;
  start_times?: string;
  media_track?: IMediaTrack;
  completed_base_script_tasks_count: number;
}

export const getMessagesByMgsId = async (request_id: number) => {
  return apiGet(`/v1/message-request/ondemand-message/${request_id}`).then(
    (
      response: AxiosResponse<IApiDataResponse<IGetMessageRequestResponace>>,
    ) => {
      return response.data.data;
    },
  );
};

export const viewBaseMessagesApi = (companyId: number, requestId: number) => {
  return `/v1/company/${companyId}/message/base-message/${requestId}`;
};

const getViewBaseMessages = (companyId: number, requestId: number) => {
  return apiGet(viewBaseMessagesApi(companyId, requestId)).then(
    (
      response: AxiosResponse<
        IApiDataResponse<IGetBaseMessageRequestResponace>
      >,
    ) => {
      return response.data.data;
    },
  );
};
export const useRqViewBaseListMessagesByRequestID = (
  companyId: number,
  requestId: number,
  enabled: boolean = true,
) => {
  const enableRender = companyId > 0 && requestId > 0 ? enabled : false;
  return useQuery({
    queryKey: [viewBaseMessagesApi(companyId, requestId)],
    queryFn: () => getViewBaseMessages(companyId, requestId),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: enableRender,
  });
};
