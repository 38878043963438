import {
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {IStationWithIsDelete} from './AddOnCmpt';
import {IOSSwitch} from '../../../../components/styles/ToggleButton';
import {IPrimaryStations} from '../../../admin/CMS_OPS/StationMgmt/OnboardingStationsRequest';

export const AddOnStationNamingCmpt = ({
  stations,
  setstations,
  type,
}: {
  stations: IStationWithIsDelete[];
  setstations: React.Dispatch<React.SetStateAction<IStationWithIsDelete[]>>;
  type: string;
}) => {
  const [primaryStations, setPrimaryStations] = useState<IPrimaryStations>({
    full_station:
      stations.find(
        val => val.type === 'full_station' && val.is_primary_station,
      )?.id || 0,
    odo_station:
      stations.find(val => val.type === 'odo_station' && val.is_primary_station)
        ?.id || 0,
    mirror_station: 0,
  });
  const handleLabelChange = (id: number, value: string) => {
    setstations(prev => {
      const updatedStations = prev.map(val => {
        if (val.id === id) {
          return {...val, label: value.trimLeft()};
        }
        return val;
      });

      return updatedStations;
    });
  };
  const handlePrimaryStationChange = (id: number, type: string) => {
    setPrimaryStations(prev => {
      return {...prev, [type]: id};
    });
  };

  useEffect(() => {
    setstations(prev => {
      const updatedStations = prev.map(val => {
        if (
          val.type === 'full_station' &&
          val.id === primaryStations.full_station
        ) {
          return {...val, is_primary_station: true};
        } else if (
          val.type === 'odo_station' &&
          val.id === primaryStations.odo_station
        ) {
          return {...val, is_primary_station: true};
        }
        return {...val, is_primary_station: false};
      });

      return updatedStations;
    });
  }, [primaryStations, setstations]);

  const handleParentStationChange = useCallback(
    (id: number, parentId: number) => {
      setstations(prev => {
        const updatedStations = prev.map(val => {
          if (val.id === id) {
            return {...val, parent_id: parentId};
          }
          return val;
        });

        return updatedStations;
      });
    },
    [setstations],
  );

  return (
    <Box className='h-full overflow-auto scrollbar p-3 pt-0'>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align='center'>Label</TableCell>
            <TableCell align='center'>Type</TableCell>
            <TableCell align='center'>Primary</TableCell>
            {type === 'Neptune Radio' ? (
              <TableCell align='center'>Parent Station Selection</TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {stations.map(station => {
            if (!station.is_deleted) {
              return (
                <AddOnStationNamingTableRow
                  key={station.id}
                  type={type}
                  station={station}
                  stations={stations}
                  handleLabelChange={handleLabelChange}
                  handlePrimaryStationChange={handlePrimaryStationChange}
                  handleParentStationChange={handleParentStationChange}
                />
              );
            }
            return null;
          })}
        </TableBody>
      </Table>
    </Box>
  );
};
const AddOnStationNamingTableRow = ({
  type,
  station,
  stations,
  handleLabelChange,
  handlePrimaryStationChange,
  handleParentStationChange,
}: {
  type: string;
  station: IStationWithIsDelete;
  stations: IStationWithIsDelete[];
  handleLabelChange: (id: number, value: string) => void;
  handlePrimaryStationChange: (id: number, type: string) => void;
  handleParentStationChange: (id: number, parentId: number) => void;
}) => {
  return (
    <TableRow>
      <TableCell className='max-w-100 ellipsis'>{station.name}</TableCell>
      <TableCell align='center'>
        {station.id > 0 ? (
          station.label
        ) : (
          <TextField
            variant='outlined'
            value={station.label}
            onChange={e => handleLabelChange(station.id, e.target.value)}
            sx={{
              width: '65px',
              height: '40px !important',
              '& .MuiInputBase-root': {
                height: '40px !important',
              },
            }}
          />
        )}
      </TableCell>
      <TableCell align='center'>{station.type}</TableCell>
      <TableCell align='center'>
        {station.type !== 'mirror_station' ? (
          <FormControlLabel
            className='ml-2'
            label=''
            onChange={() =>
              handlePrimaryStationChange(station.id, station.type)
            }
            control={<IOSSwitch defaultChecked />}
            checked={Boolean(station.is_primary_station)}
          />
        ) : (
          '-'
        )}
      </TableCell>
      {type === 'Neptune Radio' ? (
        <TableCell align='center'>
          {station.type === 'mirror_station' ? (
            <FormControl fullWidth>
              <Select
                className='w-150 mx-auto'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={station.parent_id}
                onChange={e =>
                  handleParentStationChange(station.id, Number(e.target.value))
                }>
                {stations.map(val => {
                  if (val.type === 'full_station' && !val.is_deleted) {
                    return (
                      <MenuItem key={val.id} value={val.id}>
                        {val.name}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </Select>
            </FormControl>
          ) : (
            '-'
          )}
        </TableCell>
      ) : null}
    </TableRow>
  );
};
