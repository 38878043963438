import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IApiPaginationResponse} from '../../dto/API.dto';
import axiosInstance from '../../axiosConfig';
import {IMediaPlaylistWithStation} from '../../dto/MediaPlaylist.dto';

const getPlaylist = (companyId: number, query: ICustomLibraryQueryParms) => {
  return axiosInstance
    .get(`v1/company/${companyId}/custom-library`, {params: query})
    .then(
      (
        response: AxiosResponse<
          IApiPaginationResponse<IMediaPlaylistWithStation[]>
        >,
      ) => {
        return response.data;
      },
    );
};

export type ICustomLibraryQueryParms = {
  station_ids: number[];
};

export const useRqCustomLibrary = (
  companyId: number = 0,
  query: ICustomLibraryQueryParms,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [`v1/company/${companyId}/custom-library`, query],
    queryFn: () => getPlaylist(companyId, query),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};
