import {Box, Typography} from '@mui/material';
import {
  ISponsorReactiveProp,
  ISponsorshipRequestProp,
} from '../../dto/Sponsor.dto';
type Props = {
  sponsorReactiveType: ISponsorReactiveProp;
  sponsorshipRequest: ISponsorshipRequestProp;
};
const SponsorReactiveReview = ({
  sponsorReactiveType,
  sponsorshipRequest,
}: Props) => {
  return (
    <>
      <Typography variant='h5' className='text-white font-bold mb-8'>
        Reactivate Sponsor
      </Typography>
      <Box>
        <Typography
          variant='h6'
          className='mb-7 text-white-50'
          aria-label='sponsor-response'>
          {sponsorReactiveType.reactive_type === '0'
            ? 'Use My Last Year Commercial'
            : 'Use My New Attach Commercial'}
        </Typography>
        {sponsorReactiveType.reactive_type === '1' &&
          sponsorReactiveType.files && (
            <Typography variant='h6' className='mb-7 text-white-75'>
              {sponsorReactiveType.files.name}
            </Typography>
          )}
      </Box>

      {sponsorReactiveType.reactive_type === '2' && (
        <>
          <Typography variant='h5' className='text-white font-bold my-8'>
            Sponsorship Details
          </Typography>
          <Typography variant='h6' className='mb-7 text-white-50'>
            {sponsorshipRequest.request_format === '0'
              ? 'I am providing a pre-recorded commercial.'
              : 'I am providing all of the information needed to produce the commercial for my sponsor'}
          </Typography>
          {String(sponsorshipRequest.request_format) === '1' && (
            <Typography variant='h6' className='mb-7 text-white-75'>
              <pre>{sponsorshipRequest.message}</pre>
            </Typography>
          )}
          {String(sponsorshipRequest.request_format) === '0' &&
            sponsorshipRequest.files?.name && (
              <Typography variant='h6' className='mb-7 text-white-75'>
                {sponsorshipRequest.files?.name}
              </Typography>
            )}
        </>
      )}
    </>
  );
};

export default SponsorReactiveReview;
