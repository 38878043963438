import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Typography} from '@mui/material';
import {v4 as uuidv4} from 'uuid';
import {apiPost} from '../../axiosConfig';
import {
  StepperLayout,
  useStepperState,
} from '../../components/layouts/stepper/StepperLayout';
import {ISponsorshipRequestProp, ISponsorByToken} from '../../dto/Sponsor.dto';
import SponsorshipRequest from './SponsorshipRequest';
import {IMultipleFileUploadProp} from './MultipleFileUploadCmpt';
import SponsorshipRequestReview from './SponsorshipRequestReview';
import DialogApiRequestStatus from '../../components/dialogs/DialogApiRequestStatus';
import IconTick from '../../asset/icons/icon_tick.svg';
import SponsorReactiveBySponsor from './SponsorshipReactiveBySponsor';
import ValidateSponsorToken from './ValidateSponsorToken';

type SponserProps = {
  data: ISponsorByToken;
  token: string;
};

const SponsorUpdate = ({data, token}: SponserProps) => {
  const navigate = useNavigate();
  const {activeStepIndex, onNextStep, onPreviousStep, onStepClick} =
    useStepperState(0);
  const [close, setClose] = useState(false);
  const [apiState, setApiState] = useState({status: '', error: null});
  const [multipleFileUpload, setMultipleFileUpload] = useState<
    IMultipleFileUploadProp[]
  >([]);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [sponsorshipRequest, setSponsorshipRequest] =
    useState<ISponsorshipRequestProp>({
      request_format: '',
      files: undefined,
      message: '',
    });

  const handleSubmit = useCallback(() => {
    setApiState({
      status: 'pending',
      error: null,
    });
    const payload = new FormData();
    payload.append(
      `sponsorship_info[request_format]`,
      sponsorshipRequest['request_format'],
    );
    payload.append(
      `sponsorship_info[files]`,
      sponsorshipRequest['files'] as Blob,
    );
    payload.append(
      `sponsorship_info[message]`,
      sponsorshipRequest['message'] as string,
    );

    if (multipleFileUpload && multipleFileUpload.length > 0) {
      multipleFileUpload.forEach((val, key) => {
        payload.append(
          `sponsorship_info[multiple_files][${key}][file_name]`,
          val.fileName,
        );
        payload.append(
          `sponsorship_info[multiple_files][${key}][file]`,
          val.file as Blob,
        );
      });
    }

    apiPost(`/v1/sponsor/${token}/update`, payload)
      .then(() => {
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({
          status: 'error',
          error,
        });
      });
  }, [multipleFileUpload, sponsorshipRequest, token]);

  useEffect(() => {
    if (selectedOption && setMultipleFileUpload) {
      setSponsorshipRequest({
        request_format: '2',
        files: undefined,
        message: '',
      });
      let values: IMultipleFileUploadProp[] = [];
      for (let i = 1; i <= selectedOption; i++) {
        values.push({
          id: uuidv4(),
          fileName: '',
        });
      }
      setMultipleFileUpload(values);
    } else {
      setMultipleFileUpload([]);
    }
  }, [selectedOption, setMultipleFileUpload]);

  const ThankyouMessage = () => {
    return (
      <Box className='h-full w-full flex justify-center items-center'>
        <Box
          className='w-full max-w-600 min-h-180 flex flex-column justify-center p-5 radius-10'
          sx={{background: `var(--green-variant14)`}}>
          <Box
            className='w-61 h-61 flex flex-column justify-center rounded self-center'
            sx={{background: '#06A018'}}>
            <img src={IconTick} alt='icon' className='w-22 h-15 self-center' />
          </Box>

          <Typography
            aria-label='success-message'
            className='text-white text-16 self-center mt-3 text-center'>
            Thank you for your submission. We will be back in touch soon. You
            can now close this browser window
          </Typography>
        </Box>
      </Box>
    );
  };
  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  const handleEdit = useCallback(() => {
    setApiState({
      status: '',
      error: null,
    });
  }, []);

  const handleClose = () => {
    setApiState({
      status: '',
      error: null,
    });
    setClose(true);
  };
  const steps = useMemo(() => {
    const sponsorshipRequestNotEmpty =
      (String(sponsorshipRequest.request_format) === '0' &&
        sponsorshipRequest.files !== undefined) ||
      (String(sponsorshipRequest.request_format) === '1' &&
        sponsorshipRequest.message !== '') ||
      (multipleFileUpload.length > 0 &&
        multipleFileUpload.every(val => val.fileName.length > 0 && val.file));
    const stepList = [
      {
        label: 'Sponsorship Details',
        disabled: false,
      },
      {
        label: 'Review',
        disabled: !sponsorshipRequestNotEmpty,
      },
    ];
    return stepList;
  }, [sponsorshipRequest, multipleFileUpload]);
  if (close) {
    return <ThankyouMessage />;
  }

  if (!close) {
    return (
      <StepperLayout
        steps={steps}
        activeStepIndex={activeStepIndex}
        onBack={onPreviousStep}
        onNext={onNextStep}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        onStepClick={onStepClick}
        title='Sponsorship Request Details'
        isSubmitted={apiState.status !== ''}
        hideCloseButton={true}>
        {activeStepIndex === 0 && (
          <SponsorshipRequest
            sponsorshipRequest={sponsorshipRequest}
            setSponsorshipRequest={setSponsorshipRequest}
            product={data.company?.product}
            companyName={data.company?.name}
            requestType={data.type}
            type={1}
            token={token}
            multipleFileUpload={multipleFileUpload}
            setMultipleFileUpload={setMultipleFileUpload}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        )}
        {activeStepIndex === 1 && (
          <SponsorshipRequestReview
            sponsorshipRequest={sponsorshipRequest}
            onStepClick={onStepClick}
            requestType={data.type}
            multipleFileUpload={multipleFileUpload}
            from='by_sponsor'
          />
        )}
        {apiState.status !== '' && (
          <DialogApiRequestStatus
            apiState={apiState}
            onRetry={handleSubmit}
            onEdit={handleEdit}
            onClose={handleClose}
          />
        )}
      </StepperLayout>
    );
  }
  return null;
};
const SponsorshipUpdateBySponsor = () => {
  const [isValidated, setIsValidated] = useState(false);
  const [data, setData] = useState<ISponsorByToken>();
  const [token, setToken] = useState('');
  return isValidated && data ? (
    data.type === 'request-update' || data.type === 'campaign-created' ? (
      <SponsorUpdate data={data} token={token} />
    ) : (
      <SponsorReactiveBySponsor data={data} token={token} />
    )
  ) : (
    <ValidateSponsorToken
      setIsValidated={setIsValidated}
      setData={setData}
      setToken={setToken}
    />
  );
};
export default SponsorshipUpdateBySponsor;
