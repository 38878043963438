import {Box, Skeleton, Stack, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useRqGetCompany} from '../../Service/companyService';
import {IUserWithPivot} from '../../dto/Company.dto';

export const CompanyDetailsList = ({
  company_id,
  taskSlug = '',
  className = '',
}: {
  company_id: number;
  taskSlug: string;
  className?: string;
}) => {
  const {data, isFetching} = useRqGetCompany(company_id, Boolean(company_id));
  const [isPrimaryContact, setIsPrimaryContact] = useState<IUserWithPivot[]>();
  const [isReceivesInvoice, setIsReceivesInvoice] =
    useState<IUserWithPivot[]>();
  const [fullAddress, setFullAddress] = useState('NA');

  useEffect(() => {
    if (!isFetching && data) {
      if (data.company_detail) {
        const address = data.company_detail;
        const addressDetails =
          address.address1 +
          (address.address2 && address.address2.length > 0
            ? `, ${address.address2}`
            : '') +
          (address.city && address.city.length > 0 ? `, ${address.city}` : '') +
          (address.zip && address.zip.length > 0 ? `, ${address.zip}` : '');
        setFullAddress(addressDetails);
        setIsPrimaryContact(
          data.users?.filter(val => val.pivot.is_primary_contact),
        );
        setIsReceivesInvoice(
          data.users?.filter(val => val.pivot.is_receives_invoice),
        );
      }
    }
  }, [data, isFetching]);

  if (isFetching) {
    return (
      <Box className={`${className} w-400`}>
        <Skeleton className='h-32' />
        <Skeleton className='h-32' />
        <Skeleton className='h-32' />
      </Box>
    );
  }

  return (
    <>
      {!isFetching ? (
        <Box
          className={
            className.length > 0
              ? className
              : 'h-350 overflow-auto scrollbar p-4'
          }>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Typography className='w-150 font-bold'>Partner</Typography>
            <Typography>: {data?.name}</Typography>
          </Stack>
          {['business_management'].includes(taskSlug) ? (
            <Stack direction='row' spacing={2} alignItems='center'>
              <Typography className='w-150 font-bold'>Address</Typography>
              <Typography>: {fullAddress}</Typography>
            </Stack>
          ) : null}

          {['business_management'].includes(taskSlug) ? (
            isPrimaryContact ? (
              <Box className='my-4'>
                <Typography className='font-bold'>Primary Contact</Typography>
                {isPrimaryContact
                  .filter(val => val.pivot.is_primary_contact)
                  .map(primary => (
                    <Box className='mb-3'>
                      <Stack direction='row' spacing={2} alignItems='center'>
                        <Typography className='w-150 font-bold'>
                          Name
                        </Typography>
                        <Typography>: {primary.name ?? 'NA'}</Typography>
                      </Stack>
                      <Stack direction='row' spacing={2} alignItems='center'>
                        <Typography className='w-150 font-bold'>
                          Email
                        </Typography>
                        <Typography>: {primary.email ?? 'NA'}</Typography>
                      </Stack>
                    </Box>
                  ))}
              </Box>
            ) : (
              <Box className='my-4'>
                <Typography className='font-bold'>Primary Contact</Typography>
                <Typography className='font-bold'>NA</Typography>
              </Box>
            )
          ) : null}

          {['business_management'].includes(taskSlug) ? (
            isReceivesInvoice && isReceivesInvoice.length ? (
              <Box className='my-4'>
                <Typography className='font-bold'>
                  Receives Invoice Contact
                </Typography>
                {isReceivesInvoice.map(receivesInvoice => (
                  <Box className='mb-3'>
                    <Stack direction='row' spacing={2} alignItems='center'>
                      <Typography className='w-150 font-bold'>Name</Typography>
                      <Typography>: {receivesInvoice.name ?? 'NA'}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={2} alignItems='center'>
                      <Typography className='w-150 font-bold'>Email</Typography>
                      <Typography>: {receivesInvoice.email ?? 'NA'}</Typography>
                    </Stack>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box className='my-4'>
                <Typography className='font-bold'>
                  Receives Invoice Contact
                </Typography>
                <Typography className='font-bold'>NA</Typography>
              </Box>
            )
          ) : null}

          {['partner_success_manager'].includes(taskSlug) ? (
            <Stack direction='row' spacing={2} alignItems='center'>
              <Typography className='w-150 font-bold'>Phone</Typography>
              <Typography>
                :{' '}
                {data && data?.company_detail && data?.company_detail?.phone
                  ? data?.company_detail?.phone
                  : 'NA'}
              </Typography>
            </Stack>
          ) : null}
          {['create_dj_stinger_message'].includes(taskSlug) ? (
            <Stack direction='row' spacing={2} alignItems='center'>
              <Typography className='w-150 font-bold'>
                Account Branding
              </Typography>
              <Typography>
                :{' '}
                {data && data?.company_detail && data?.company_detail?.branding
                  ? data?.company_detail?.branding
                  : 'NA'}
              </Typography>
            </Stack>
          ) : null}
          {['create_dj_stinger_message'].includes(taskSlug) ? (
            <Stack direction='row' spacing={2} alignItems='center'>
              <Typography className='w-150 font-bold'>PSM</Typography>
              <Typography>
                :{' '}
                {data && data?.psm && data?.psm?.name ? data?.psm?.name : 'NA'}
              </Typography>
            </Stack>
          ) : null}
          {['create_dj_stinger_message'].includes(taskSlug) ? (
            <Stack direction='row' spacing={2} alignItems='center'>
              <Typography className='w-150 font-bold'>LM Code</Typography>
              <Typography>
                : {data && data?.company_code ? data?.company_code : 'NA'}
              </Typography>
            </Stack>
          ) : null}
          {['create_dj_stinger_message'].includes(taskSlug) &&
          data &&
          data.product &&
          data.schools &&
          data.product.cmc_product &&
          data.product.cmc_product === 'GameTime' ? (
            <Box className='flex-grow'>
              {data.schools.map((val, index) => (
                <Box key={index} className='w-550 mb-4'>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography className='w-150 font-bold'>
                      School Name
                    </Typography>
                    <Typography>
                      : {val.name && val.name.length ? val.name : 'NA'}
                    </Typography>
                  </Stack>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography className='w-150 font-bold'>
                      Boy's Mascot
                    </Typography>
                    <Typography>
                      :{' '}
                      {val.boys_mascot && val.boys_mascot.length
                        ? val.boys_mascot
                        : 'NA'}
                    </Typography>
                  </Stack>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography className='w-150 font-bold'>
                      Girl's Mascot
                    </Typography>
                    <Typography>
                      :{' '}
                      {val.girls_mascot && val.girls_mascot.length
                        ? val.girls_mascot
                        : 'NA'}
                    </Typography>
                  </Stack>
                </Box>
              ))}
            </Box>
          ) : null}
        </Box>
      ) : null}
    </>
  );
};
