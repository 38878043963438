import {ButtonProps, CircularProgress, IconButton} from '@mui/material';
import {Howl} from 'howler';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useMusicPlayAssignCtx} from '../../pages/music-section/MusicSectionContexts';
import IconPlay from '../../asset/icons/icon_play.svg';
import IconPause from '../../asset/icons/icon_pause.svg';

type Props = {
  id: string | number | null;
  url: string;
  size?: boolean;
  buttonProps?: ButtonProps;
};

const MusicPreviewBtn = ({id, url, size, buttonProps}: Props) => {
  const {trackId, setTrackId} = useMusicPlayAssignCtx();
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const soundRef = useRef<Howl | null>(null);

  const sizeSet = size ? {width: '29px', height: '29px'} : {};

  const togglePlay = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      if (soundRef.current?.state() === 'unloaded') {
        setLoading(true);
        soundRef.current?.load();
      }
      setTrackId(prevId => {
        if (prevId && prevId === id) {
          return null;
        }
        return id;
      });
    },
    [setTrackId, id],
  );

  useEffect(() => {
    soundRef.current = new Howl({
      src: [`${url}`],
      html5: true,
      preload: false,
      onplay() {
        setIsPlaying(true);
      },
      onend() {
        setIsPlaying(false);
      },
      onpause() {
        setIsPlaying(false);
      },
      onload() {
        setLoading(false);
      },
      onloaderror() {
        setLoading(false);
      },
    });
  }, [url]);

  useEffect(() => {
    if (trackId === id) {
      soundRef.current?.play();
    } else if (soundRef.current?.playing()) {
      soundRef.current?.pause();
    }
  }, [id, trackId]);

  const cleanup = useCallback(() => {
    setTrackId(prev => {
      if (prev && prev === id) {
        return null;
      }
      return prev;
    });
    soundRef.current?.stop();
  }, [id, setTrackId]);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  return (
    <IconButton
      className='p-0'
      aria-label='play'
      onClick={togglePlay}
      {...buttonProps}
      disabled={url ? url.trim() === '' : true}>
      {loading ? (
        <CircularProgress style={{width: '20px', height: '20px'}} />
      ) : (
        <>
          {isPlaying ? (
            <img src={IconPause} alt='' style={sizeSet} />
          ) : (
            <img src={IconPlay} alt='' style={sizeSet} />
          )}
        </>
      )}
    </IconButton>
  );
};

export default MusicPreviewBtn;

export const MusicPreviewBtnDisable = () => (
  <IconButton aria-label='play' disabled={true}>
    <img src={IconPlay} alt='' />
  </IconButton>
);
