import {FormControlLabel, Radio, Typography} from '@mui/material';

type Props = {
  value: string;
  label: string;
  caption?: string;
  checked?: boolean;
};

const OutlinedRadioControlLabel = ({value, label, caption, checked}: Props) => {
  return (
    <FormControlLabel
      sx={{display: 'flex', alignItems: 'flex-start !important'}}
      value={value}
      control={<Radio className='py-1 pr-3 pl-1' checked={checked} />}
      label={
        <>
          {label}
          {caption && (
            <Typography variant='caption' className='text-white-50 block'>
              {caption}
            </Typography>
          )}
        </>
      }
    />
  );
};

export default OutlinedRadioControlLabel;
