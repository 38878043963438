import {ButtonProps, IconButton, Typography} from '@mui/material';
import IconBack from '../../asset/icons/icon_back.svg';

const IconBackArrowButton: React.FC<ButtonProps> = ({...buttonProps}) => {
  return (
    <IconButton
      {...buttonProps}
      className='w-81 h-30 flex flex-row justify-center radius-5 cursor-pointer'>
      <img src={IconBack} className='mr-3' alt='' />
      <Typography component='h6' className='text-white-50 font-bold'>
        Back
      </Typography>
    </IconButton>
  );
};

export default IconBackArrowButton;
