import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useQueryClient} from '@tanstack/react-query';
import {DialogClose} from '../../../components/dialogs/DialogFrame/DialogClose';
import SubmitButton from '../../../components/styles/buttons/SubmitButton';
import {queryKeyMessageRequestList} from '../../../react_query/messages/MessagesByCmpId';
import {companyIdSelector} from '../../../store/Slices/companySlice';
type Props = {
  request_id: number;
  handleCancel: () => void;
};

export const TimedMsgDeActivate = ({request_id, handleCancel}: Props) => {
  const queryClient = useQueryClient();
  const companyId = useSelector(companyIdSelector);

  const successOnApiCall = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [queryKeyMessageRequestList(companyId, 'timed-message')],
      refetchType: 'active',
    });
    handleCancel();
  }, [companyId, handleCancel, queryClient]);

  return (
    <Dialog
      open
      maxWidth='md'
      fullWidth
      onClose={handleCancel}
      aria-labelledby='de-activate-title'
      aria-describedby='de-activate-description'>
      <DialogTitle>
        <Typography className='alignCenter'>
          Deactivate the timed message
        </Typography>
        <DialogClose onClose={handleCancel} />
      </DialogTitle>

      <DialogContent>
        <Typography>Are you sure to deactivate this message?</Typography>
      </DialogContent>
      <DialogActions className='flex items-center justify-center'>
        <SubmitButton
          payload={{type: 'Inactive'}}
          title='OK'
          url={`v1/company/${companyId}/message/timed-message/${request_id}?_method=PUT`}
          successAction={successOnApiCall}
          buttonProps={{
            variant: 'contained',
          }}
        />
        <Button variant='outlined' color='info' onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
