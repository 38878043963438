import IconButton, {IconButtonProps} from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

interface SongSelectionAddButtonProps extends IconButtonProps {
  onClick: (() => void) | undefined;
}

export const SongSelectionAddButton = ({
  onClick,
  ...props
}: SongSelectionAddButtonProps) => {
  return (
    <IconButton
      {...props}
      onClick={onClick}
      aria-label='delete'
      color='secondary'>
      <AddIcon />
    </IconButton>
  );
};
