import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../store/configstore';
import {IToastType} from '../../package/Toaster';

export type IMode = 'dark' | 'light';

export interface CommonState {
  toastMsg: string;
  toastType: IToastType;
  loader: boolean;
  mode: IMode;
}
const mode_LS = localStorage.getItem('theme_mode') as IMode;

const initialState: CommonState = {
  toastMsg: '',
  toastType: 'error',
  loader: false,
  mode: mode_LS === 'dark' || mode_LS === 'light' ? mode_LS : 'dark',
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    toast_msg: (
      state: CommonState,
      action: PayloadAction<{toastMsg: string; toastType: IToastType}>,
    ) => {
      state.toastMsg = action.payload.toastMsg;
      state.toastType = action.payload.toastType;
    },
    loading: (state: CommonState, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    theme_mode: (state: CommonState) => {
      const new_mode = state.mode === 'dark' ? 'light' : 'dark';
      state.mode = new_mode;
      localStorage.setItem('theme_mode', new_mode);
    },
  },
});

export const {toast_msg, loading, theme_mode} = commonSlice.actions;
export default commonSlice.reducer;

export const commonSelector: (state: RootState) => CommonState = (
  state: RootState,
) => state.common;
