import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useQueryClient} from '@tanstack/react-query';
import {useSelector} from 'react-redux';
import {useRqUsersByTask} from '../../react_query/tasks/UsersByTask';
import {useRqTaskInfo} from '../../react_query/tasks/ListTasks';
import {apiPost} from '../../axiosConfig';
import {
  queryKeyTaskIsAssigned,
  useRqGetTaskIsAssigned,
  useRqGetTaskIsPreview,
} from '../../react_query/messages/task/CheckTaskIsPreview';
import {DialogClose} from '../dialogs/DialogFrame/DialogClose';
import DialogApiRequestStatus from '../dialogs/DialogApiRequestStatus';
import {defaultApiState} from '../../dto/Html.dto';
import {isAdminSelector} from '../../store/Slices/userSlice';

const AssignTaskButton = ({
  isPreview,
  setIsPreview,
  setCompanyId,
  setIsGT,
  task_id,
  buttonClassName,
  setTaskName,
}: {
  isPreview: boolean;
  setIsPreview: (value: boolean) => void;
  setCompanyId?: (value: number) => void;
  setIsGT?: (value: boolean) => void;
  task_id?: number;
  buttonClassName?: string;
  setTaskName?: (val: string) => void;
}) => {
  const taskParam = useParams();
  const isAdmin = useSelector(isAdminSelector);
  const taskId = task_id ?? Number(taskParam.task_id);
  const {data, isFetching, isError} = useRqTaskInfo(taskId);
  const location = useLocation();
  const navigate = useNavigate();
  const companyIdData = location.state ? location.state.company_id : 0;
  const {data: taskPreviewData, isFetching: taskIsFetching} =
    useRqGetTaskIsPreview(taskId, Boolean(data?.id && !isFetching));
  useEffect(() => {
    if (!taskIsFetching && taskPreviewData) {
      setIsPreview(taskPreviewData.is_preview);
    }
  }, [taskIsFetching, setIsPreview, taskPreviewData]);

  useEffect(() => {
    if (setCompanyId && Boolean(companyIdData)) {
      setCompanyId(companyIdData);
    } else if (setCompanyId && !isFetching && data && data.company) {
      setCompanyId(data.company.id);
    }
  }, [companyIdData, data, isFetching, setCompanyId]);

  const {
    data: userData,
    isFetching: userIsFetching,
    isError: userIsError,
  } = useRqUsersByTask(taskId, isPreview || isAdmin);

  const [open, setOpen] = useState<boolean>(false);
  const [assignee, setAssignee] = useState(0);
  const [apiState, setApiState] = useState(defaultApiState);
  const handleChange = useCallback((event: SelectChangeEvent) => {
    setAssignee(Number(event.target.value));
  }, []);
  const handleDialogCancel = () => {
    setOpen(false);
    setApiState({status: '', error: null});
  };
  const handleSubmit = useCallback(() => {
    setApiState({status: 'pending', error: null});
    const fd = new FormData();
    fd.append('assigned_to', assignee.toString());
    apiPost(`/v1/task/${taskId}/assign?_method=PATCH`, fd)
      .then(() => {
        setApiState({status: 'success', error: null});
        setTimeout(() => {
          navigate(0);
        }, 2000);
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });
  }, [assignee, taskId, navigate]);

  useEffect(() => {
    if (data && !isFetching && !isError) {
      setAssignee(data.assigned_to?.id ?? 0);
      if (setTaskName) {
        setTaskName(data.task_name);
      }
      if (
        data.company &&
        data.company.product &&
        data.company.product.cmc_product === 'GameTime' &&
        setIsGT
      ) {
        setIsGT(true);
      }
    }
  }, [data, isError, isFetching, setIsGT, setTaskName]);

  return (
    <>
      {!isFetching &&
      data &&
      data?.last_active &&
      data.assigned_by?.id === data.assigned_to?.id ? (
        <CheckActive taskId={taskId} />
      ) : null}
      {isPreview || isAdmin ? (
        <>
          <Button
            variant='contained'
            color='warning'
            size='medium'
            onClick={() => setOpen(true)}
            className={`${buttonClassName ?? ''} ml-2`}
            sx={{
              float: 'right',
              zIndex: 10000,
            }}>
            Assign Task
          </Button>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            aria-labelledby='Assign Task'
            aria-describedby='alert-dialog-descaription'>
            <DialogTitle>
              <Typography className='alignCenter'>Assign User Task</Typography>
              <DialogClose onClose={handleDialogCancel} />
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth>
                <InputLabel id='task-assignee-label'>Assignee</InputLabel>
                <Select
                  labelId='task-assignee-label'
                  id='task-assignee'
                  label='Assignee'
                  value={assignee.toString()}
                  onChange={handleChange}>
                  <MenuItem value={0} disabled>
                    Choose Assignee
                  </MenuItem>
                  {!userIsFetching &&
                    !userIsError &&
                    userData &&
                    userData.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </DialogContent>
            {apiState.status !== '' && (
              <DialogApiRequestStatus
                onEdit={() => setApiState(defaultApiState)}
                onRetry={handleSubmit}
                onClose={handleDialogCancel}
                apiState={apiState}
              />
            )}
            <DialogActions>
              {apiState.status !== 'success' ? (
                <Button
                  disabled={assignee === 0}
                  onClick={handleSubmit}
                  variant='contained'>
                  Assign
                </Button>
              ) : null}
              <Button
                onClick={handleDialogCancel}
                variant='outlined'
                disabled={apiState.status === 'pending'}>
                {apiState.status === 'success' ? 'Close' : 'Cancel'}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}
    </>
  );
};

const CheckActive = ({taskId}: {taskId: number}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const {data} = useRqGetTaskIsAssigned(taskId, isActive);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data && !data.is_assigned) {
      setOpen(true);
    }
  }, [data]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => setIsActive(false), 1 * 60 * 1000); // 1 minute of inactivity
    };

    const handleUserActivity = () => {
      setIsActive(true);
      resetTimeout();
    };

    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    resetTimeout();

    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (isActive) {
      const fetchData = () => {
        queryClient.invalidateQueries({
          queryKey: [queryKeyTaskIsAssigned(taskId)],
        });
      };
      // Fetch data every 5 minutes
      const interval = setInterval(fetchData, 5 * 60 * 1000); // triggers each 5 minutes.
      return () => clearInterval(interval);
    }
    return;
    // Clean up the interval on component unmount
  }, [isActive, queryClient, taskId]);

  return <TaskPageRefreshDialog open={open} setOpen={setOpen} />;
};

const TaskPageRefreshDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) => {
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate('/dashboard');
  };

  return (
    <>
      {open ? (
        <Dialog
          open={open}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          maxWidth='sm'
          fullWidth>
          <DialogTitle className='border-none'>
            <Typography className='alignCenter'>
              Kindly refresh to continue
            </Typography>{' '}
            <DialogClose onClose={handleClose} />
          </DialogTitle>
          <Button
            variant='contained'
            className='py-3 px-4 w-150 mx-auto my-5'
            onClick={handleClose}
            autoFocus>
            OK
          </Button>
        </Dialog>
      ) : null}
    </>
  );
};

export default AssignTaskButton;
