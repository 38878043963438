import {useDispatch} from 'react-redux';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import DialogApiStatus from '../../components/dialogs/DialogApiStatus';
import {apiGet} from '../../axiosConfig';
import {loginSuccess} from '../../store/Slices/userSlice';

export default function ImpersonateUser() {
  const {userId} = useParams();
  const [apiState, setApiState] = useState({status: '', error: null});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchUser = useCallback(() => {
    setApiState({status: 'pending', error: null});
    if (userId) {
      apiGet(`/v1/impersonate-user/${userId}`)
        .then(res => {
          sessionStorage.setItem('impersonated_user', `${res.data.user.id}`);
          sessionStorage.setItem('access_token', res.data.token);
          dispatch(
            loginSuccess(Object.assign(res.data, {isImpersonatedUser: true})),
          );
          navigate('/');
        })
        .catch(error => {
          setApiState({status: 'error', error});
        });
    }
  }, [dispatch, navigate, userId]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <DialogApiStatus
      apiState={apiState}
      pendingTitle='Fetching user details'
      pendingSubTitle='Please wait...'
      successTitle=''
      errorTitle='Fetching user details is failed!'
      onRetry={fetchUser}
    />
  );
}
