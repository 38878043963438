import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Stack,
  Typography,
  InputAdornment,
} from '@mui/material';
import {useCallback} from 'react';
import {IPricingReivewDetailsProp} from '../AddOnDetailsPage';

type IPricingReivewDetailsKeyProp = keyof IPricingReivewDetailsProp;
export const AddOnReviewLeftCmpt = ({
  level,
  pricingReviewDetails,
  setpricingReviewDetails,
  type,
}: {
  level: string;
  pricingReviewDetails: IPricingReivewDetailsProp;
  setpricingReviewDetails: React.Dispatch<
    React.SetStateAction<IPricingReivewDetailsProp>
  >;
  type: string;
}) => {
  const fullStationLablePrice =
    type === 'SchoolZone' ? (level === 'Level 1' ? 1000 : 1250) : 1200;

  const handleChangeValue = useCallback(
    (type: IPricingReivewDetailsKeyProp, value: number) => {
      setpricingReviewDetails(prev => {
        const newPricingDetails: IPricingReivewDetailsProp = {
          ...prev,
          [type]: {...prev[type], count: Number(value)},
        };
        // service total without discount and laptop fee
        const serviceTotalLevel =
          Math.sign(newPricingDetails.level.price) === -1
            ? 0
            : newPricingDetails.level.price;
        const negativeServiceTotalLevel =
          Math.sign(newPricingDetails.level.price) !== -1
            ? 0
            : newPricingDetails.level.price;
        const serviceTotal =
          (serviceTotalLevel || 0) +
          (newPricingDetails.full_station.price || 0) +
          (newPricingDetails.mirror_station.price || 0) +
          (newPricingDetails.odo_station.price || 0) +
          (newPricingDetails.updated_full_station.price || 0);

        const negativeServiceTotal =
          (negativeServiceTotalLevel || 0) +
          (newPricingDetails.deleted_full_station.price || 0) +
          (newPricingDetails.deleted_mirror_station.price || 0) +
          (newPricingDetails.deleted_odo_station.price || 0) +
          (newPricingDetails.updated_mirror_station.price || 0) +
          (newPricingDetails.updated_odo_station.price || 0);

        // Discount pricing
        newPricingDetails.discount.price = Math.floor(
          (serviceTotal * newPricingDetails.discount.count) / 100,
        );

        // service total calculation
        newPricingDetails.service_total.price =
          serviceTotal +
          negativeServiceTotal +
          (newPricingDetails.setup.price || 0) -
          (newPricingDetails.discount.price || 0);

        // Laptop pricing
        newPricingDetails.laptop.price = newPricingDetails.laptop.count * 500;

        // Total voice calculation.
        newPricingDetails.total_invoice.price =
          (newPricingDetails.service_total.price || 0) +
          newPricingDetails.laptop.price;

        return newPricingDetails;
      });
    },
    [setpricingReviewDetails],
  );

  const handlePriceValueChange = useCallback(
    (type: IPricingReivewDetailsKeyProp, value: number) => {
      setpricingReviewDetails(prev => {
        const newPricingDetails: IPricingReivewDetailsProp = {
          ...prev,
          [type]: {...prev[type], price: Number(value)},
        };
        // service total without discount and laptop fee
        const serviceTotalLevel =
          Math.sign(newPricingDetails.level.price) === -1
            ? 0
            : newPricingDetails.level.price;
        const negativeServiceTotalLevel =
          Math.sign(newPricingDetails.level.price) !== -1
            ? 0
            : newPricingDetails.level.price;
        const serviceTotal =
          (serviceTotalLevel || 0) +
          (newPricingDetails.full_station.price || 0) +
          (newPricingDetails.mirror_station.price || 0) +
          (newPricingDetails.odo_station.price || 0) +
          (newPricingDetails.updated_full_station.price || 0);

        const negativeServiceTotal =
          (negativeServiceTotalLevel || 0) +
          (newPricingDetails.deleted_full_station.price || 0) +
          (newPricingDetails.deleted_mirror_station.price || 0) +
          (newPricingDetails.deleted_odo_station.price || 0) +
          (newPricingDetails.updated_mirror_station.price || 0) +
          (newPricingDetails.updated_odo_station.price || 0);

        // Discount pricing
        if (newPricingDetails.discount.count) {
          newPricingDetails.discount.price = Math.floor(
            (serviceTotal * newPricingDetails.discount.count) / 100,
          );
        }

        // service total calculation
        newPricingDetails.service_total.price =
          serviceTotal +
          negativeServiceTotal +
          (newPricingDetails.setup.price || 0) -
          (newPricingDetails.discount.price || 0);

        // Total voice calculation.
        newPricingDetails.total_invoice.price =
          (newPricingDetails.service_total.price || 0) +
          newPricingDetails.laptop.price;

        return newPricingDetails;
      });
    },
    [setpricingReviewDetails],
  );

  return (
    <Box className='h-full overflow-auto scrollbar px-2'>
      <Typography>{type}</Typography>
      <Table aria-label='simple table'>
        <TableHead
          sx={{background: 'var(--blue-variant17)', zIndex: '3 !important'}}>
          <TableRow>
            <TableCell>Primary Service</TableCell>
            <TableCell align='center'></TableCell>
            <TableCell align='right'></TableCell>
            <TableCell align='right'>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <AddOnReviewLeftTableRow
            type={'level'}
            handlePriceValueChange={handlePriceValueChange}
            col1={<>Level Change</>}
            col3={pricingReviewDetails.level.count}
            col4={pricingReviewDetails.level.price}
          />
          <AddOnReviewLeftTableRow
            type={'deleted_full_station'}
            handlePriceValueChange={handlePriceValueChange}
            col1={<>Deleted Full Station</>}
            col3={pricingReviewDetails.deleted_full_station.count ?? 0}
            col4={pricingReviewDetails.deleted_full_station.price}
          />
          {type === 'Neptune Radio' ? (
            <AddOnReviewLeftTableRow
              type={'deleted_mirror_station'}
              handlePriceValueChange={handlePriceValueChange}
              col1={<>Deleted Mirror Station</>}
              col3={pricingReviewDetails.deleted_mirror_station.count ?? 0}
              col4={pricingReviewDetails.deleted_mirror_station.price}
            />
          ) : null}
          {type === 'GameTime' ? (
            <AddOnReviewLeftTableRow
              type={'deleted_odo_station'}
              handlePriceValueChange={handlePriceValueChange}
              col1={<>Deleted ODO Station</>}
              col3={pricingReviewDetails.deleted_odo_station.count ?? 0}
              col4={pricingReviewDetails.deleted_odo_station.price}
            />
          ) : null}
          {type !== 'SchoolZone' ? (
            <AddOnReviewLeftTableRow
              type={'updated_full_station'}
              handlePriceValueChange={handlePriceValueChange}
              col1={<>Converted Full Station</>}
              col3={pricingReviewDetails.updated_full_station.count ?? 0}
              col4={pricingReviewDetails.updated_full_station.price}
            />
          ) : null}
          {type === 'Neptune Radio' ? (
            <AddOnReviewLeftTableRow
              type={'updated_mirror_station'}
              handlePriceValueChange={handlePriceValueChange}
              col1={<>Converted Mirror Station</>}
              col3={pricingReviewDetails.updated_mirror_station.count ?? 0}
              col4={pricingReviewDetails.updated_mirror_station.price}
            />
          ) : null}
          {type === 'GameTime' ? (
            <AddOnReviewLeftTableRow
              type={'updated_odo_station'}
              handlePriceValueChange={handlePriceValueChange}
              col1={<>Converted ODO Station</>}
              col3={pricingReviewDetails.updated_odo_station.count ?? 0}
              col4={pricingReviewDetails.updated_odo_station.price}
            />
          ) : null}
          {pricingReviewDetails.setup.price > 0 ? (
            <AddOnReviewLeftTableRow
              type={'setup'}
              handlePriceValueChange={handlePriceValueChange}
              col1={
                <>
                  Set-up Fee from <br /> Level 0 to Level 1
                </>
              }
              col2={375}
              col3={1}
              col4={pricingReviewDetails.setup.price}
            />
          ) : null}
        </TableBody>
        <TableHead>
          <TableRow>
            <TableCell>Additional Machines</TableCell>
            <TableCell align='center'></TableCell>
            <TableCell align='right'></TableCell>
            <TableCell align='right'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <AddOnReviewLeftTableRow
            type={'full_station'}
            handlePriceValueChange={handlePriceValueChange}
            col1={<>Full Station</>}
            col2={fullStationLablePrice}
            col3={pricingReviewDetails.full_station.count ?? 0}
            col4={pricingReviewDetails.full_station.price}
          />
          {type === 'GameTime' ? (
            <AddOnReviewLeftTableRow
              type={'odo_station'}
              handlePriceValueChange={handlePriceValueChange}
              col1={<>ODO Station</>}
              col2={500}
              col3={pricingReviewDetails.odo_station.count ?? 0}
              col4={pricingReviewDetails.odo_station.price}
            />
          ) : null}
          {type === 'Neptune Radio' ? (
            <AddOnReviewLeftTableRow
              type={'mirror_station'}
              handlePriceValueChange={handlePriceValueChange}
              col1={<>Mirror Station</>}
              col2={300}
              col3={pricingReviewDetails.mirror_station.count ?? 0}
              col4={pricingReviewDetails.mirror_station.price}
            />
          ) : null}
          <AddOnReviewLeftTableRow
            type='discount'
            handleChangeValue={handleChangeValue}
            col1={<Typography className='text-white-50'>Discount</Typography>}
            col3={pricingReviewDetails.discount.count}
            col4={pricingReviewDetails.discount.price}
          />
          <AddOnReviewLeftTableRow
            type='service_total'
            col1={
              <Typography className='text-white-50'>Service Total</Typography>
            }
            col4={pricingReviewDetails.service_total.price}
          />
        </TableBody>
        <TableHead>
          <TableRow>
            <TableCell>Laptop(S)</TableCell>
            <TableCell align='center'></TableCell>
            <TableCell align='right'></TableCell>
            <TableCell align='right'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <AddOnReviewLeftTableRow
            type={'laptop'}
            handlePriceValueChange={handlePriceValueChange}
            handleChangeValue={handleChangeValue}
            col1={<>Laptops</>}
            col2={500}
            col3={pricingReviewDetails.laptop.count ?? 0}
            col4={pricingReviewDetails.laptop.price}
          />
        </TableBody>
        <TableHead
          sx={{
            position: 'sticky',
            bottom: '0',
            background: '#131929',
            zIndex: '2 !important',
          }}>
          <TableRow>
            <TableCell>
              <Typography className='font-bold'>Total InVoice</Typography>
            </TableCell>
            <TableCell align='center'></TableCell>
            <TableCell align='right'></TableCell>
            <TableCell align='right'>
              $ {pricingReviewDetails.total_invoice.price || 0}
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </Box>
  );
};
export const AddOnReviewLeftTableRow = ({
  type,
  handlePriceValueChange,
  handleChangeValue,
  col1,
  col2,
  col3,
  col4,
}: {
  type?: IPricingReivewDetailsKeyProp;
  handlePriceValueChange?: (
    type: IPricingReivewDetailsKeyProp,
    value: number,
  ) => void;
  handleChangeValue?: (
    type: IPricingReivewDetailsKeyProp,
    value: number,
  ) => void;
  col1?: JSX.Element;
  col2?: number;
  col3?: number;
  col4: number;
}) => {
  return (
    <TableRow>
      <TableCell align='left'>{col1 ? col1 : null}</TableCell>
      <TableCell align='left'>{col2 ? `$ ${col2}` : null}</TableCell>
      <TableCell align='left'>
        {col3 || type === 'discount' || type === 'laptop' ? (
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={1}>
            {type !== 'discount' ? 'Qty ' : ''}
            <TextField
              variant='outlined'
              type='number'
              value={col3}
              disabled={
                type && (type === 'laptop' || type === 'discount')
                  ? false
                  : true
              }
              onChange={e => {
                if (Number(e.target.value) > -1 && handleChangeValue && type) {
                  if (type === 'discount') {
                    if (Number(e.target.value) < 100) {
                      handleChangeValue(type, Number(e.target.value));
                    }
                  } else {
                    handleChangeValue(type, Number(e.target.value));
                  }
                }
              }}
              className='mx-2'
              sx={{
                width: '60px',
                height: '40px !important',
                '& .MuiOutlinedInput-root': {
                  minHeight: '40px !important',
                },
              }}
            />
            {type === 'discount' ? '%' : ''}
          </Stack>
        ) : null}
      </TableCell>
      {type === 'discount' ? (
        <TableCell align='right' sx={{color: 'red'}}>
          -${Math.abs(col4)}
        </TableCell>
      ) : type === 'service_total' ? (
        col4 > -1 ? (
          <TableCell align='right'>{col4}</TableCell>
        ) : (
          <TableCell align='right' sx={{color: 'red'}}>
            -${Math.abs(col4)}
          </TableCell>
        )
      ) : col4 < 0 ? (
        <TableCell align='right' sx={{color: 'red !important'}}>
          <TextField
            hiddenLabel
            disabled={col3 === 0}
            className='w-100'
            type='number'
            variant='filled'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start' className='mr-1 text-error'>
                  -$
                </InputAdornment>
              ),
              sx: {
                '& .MuiInputBase-input': {
                  color: 'red',
                },
              },
            }}
            onChange={e => {
              if (
                Number(e.target.value) > 0 &&
                handlePriceValueChange &&
                type
              ) {
                handlePriceValueChange(type, Number(e.target.value) * -1);
              }
            }}
            value={Math.abs(col4)}
            size='small'
          />
        </TableCell>
      ) : (
        <TableCell align='right'>
          <TextField
            hiddenLabel
            disabled={col3 === 0}
            className='w-100'
            type='number'
            variant='filled'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start' className='mr-1'>
                  $
                </InputAdornment>
              ),
            }}
            onChange={e => {
              if (
                Number(e.target.value) > -1 &&
                handlePriceValueChange &&
                type
              ) {
                handlePriceValueChange(type, Number(e.target.value));
              }
            }}
            value={col4 || 0}
            size='small'
          />
        </TableCell>
      )}
    </TableRow>
  );
};
