import {Stack, TextField, Typography} from '@mui/material';

export const OtherNamingCmpt = ({
  label,
  placeholder,
  other,
  setOther,
}: {
  label: string;
  placeholder: string;
  other: string;
  setOther: (val: string) => void;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = String(event.target.value);
    if (!(value.length === 1 && value === ' ')) {
      setOther(value);
    }
  };
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      sx={{position: 'absolute', bottom: '25px'}}>
      <Typography className='mr-2'>{label}</Typography>
      <TextField
        placeholder={placeholder}
        variant='outlined'
        value={other}
        onChange={handleChange}
      />
    </Stack>
  );
};
