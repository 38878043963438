import {configureStore} from '@reduxjs/toolkit';
import {useSelector, TypedUseSelectorHook} from 'react-redux';
import {commonSlice} from './Slices/commonSlice';
import {authSlice} from './Slices/userSlice';
import {onboardingRequestSlice} from './Slices/OnboardingRequestSlice';
import {OnboardCmySchoolsSlice} from './Slices/OnboardCmySchoolsSlice';
import {companySlice} from './Slices/companySlice';
import MusicStationSlice from './Slices/MusicStationSlice';

export const store = configureStore({
  reducer: {
    onboardingRequest: onboardingRequestSlice.reducer,
    onboardCmpSchools: OnboardCmySchoolsSlice.reducer,
    user: authSlice.reducer,
    company: companySlice.reducer,
    common: commonSlice.reducer,
    station: MusicStationSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useStoreSelector: TypedUseSelectorHook<RootState> = useSelector;
