import {useCallback, useState} from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {v4 as uuidv4} from 'uuid';
import IconDeleteButton from '../../../components/Button/IconDeletebutton';
import IconPlusButton from '../../../components/Button/PlusButton';
import {ISponsorShipInformationProp} from '../ParticipationFormStepper';

export const SponsorShipInformationCmpt = ({
  sporsorShipInformation,
  setSporsorShipInformation,
}: {
  sporsorShipInformation: ISponsorShipInformationProp[];
  setSporsorShipInformation: React.Dispatch<
    React.SetStateAction<ISponsorShipInformationProp[]>
  >;
}) => {
  const [businessName, setBusinessName] = useState<string>('');
  const [contactName, setContactName] = useState<string>('');

  const handleBusinessName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = String(event.target.value);
      if (!(value.length === 1 && value === ' ')) {
        setBusinessName(value);
      }
    },
    [],
  );

  const handleContactName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = String(event.target.value);
      if (!(value.length === 1 && value === ' ')) {
        setContactName(value);
      }
    },
    [],
  );

  const handleAddDetails = useCallback(() => {
    setSporsorShipInformation(prev => {
      return [
        ...prev,
        {
          uuid: uuidv4(),
          business_name: businessName,
          contact_name: contactName,
        },
      ];
    });
    setBusinessName('');
    setContactName('');
  }, [
    setSporsorShipInformation,
    setBusinessName,
    setContactName,
    businessName,
    contactName,
  ]);

  const handleDelete = useCallback(
    (id: string) => {
      setSporsorShipInformation(prev => {
        return prev.filter(val => val.uuid !== id);
      });
    },
    [setSporsorShipInformation],
  );

  return (
    <>
      <Typography variant='h5' className='font-bold'>
        Enter Sponsorship Details
      </Typography>
      <Typography className='text-white-50 py-2 my-2'>
        Do you know of any local businesses that would be a good fit as a
        sponsor? <br />
        If so, please list the business name and contact name (if known) below:
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            placeholder='Enter Business Name'
            variant='outlined'
            value={businessName}
            className='mt-0'
            onChange={handleBusinessName}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            placeholder='Enter Contact Name'
            variant='outlined'
            value={contactName}
            className='mt-0'
            onChange={handleContactName}
          />
        </Grid>
        <Grid item xs={2}>
          <IconPlusButton
            onClick={handleAddDetails}
            disabled={
              businessName.trim().length === 0 ||
              contactName.trim().length === 0
            }
          />
        </Grid>
      </Grid>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Business Name</TableCell>
            <TableCell>Contact Name</TableCell>
            <TableCell sx={{textAlign: 'center'}}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sporsorShipInformation.map(v => (
            <TableRow key={v.uuid}>
              <TableCell>{v.business_name}</TableCell>
              <TableCell>{v.contact_name}</TableCell>
              <TableCell align='center'>
                <IconDeleteButton onClick={() => handleDelete(v.uuid)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
