import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {useStoreSelector} from '../../../store/configstore';
import {
  companyIdSelector,
  companyMusicTypeSelector,
} from '../../../store/Slices/companySlice';
import {useCallback, useState} from 'react';
import DialogConfirmSave from '../../../components/dialogs/DialogConfirmSave';
import DialogApiRequestStatus from '../../../components/dialogs/DialogApiRequestStatus';
import {apiPost} from '../../../axiosConfig';
import {useNavigate} from 'react-router-dom';

const MusicTypePane = () => {
  const companyId: number = useStoreSelector(companyIdSelector);
  const companyMusicType = useStoreSelector(companyMusicTypeSelector);
  const [updateMusicType, setUpdateMusicType] = useState<string>('');
  const [apiState, setApiState] = useState({status: '', error: null});
  const navigate = useNavigate();

  const handleMusicChange = (event: SelectChangeEvent) => {
    setUpdateMusicType(event.target.value);
  };

  const handleEdit = useCallback(() => {
    setApiState({status: '', error: null});
  }, []);

  const saveMusicChange = () => {
    setApiState({status: 'pending', error: null});
    apiPost(`/v1/company/${companyId}/change-music-type?_method=PUT`, {
      music_type: Number(updateMusicType),
    })
      .then(() => {
        setApiState({status: 'success', error: null});
        setUpdateMusicType('');
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });
  };

  return (
    <>
      <Box className='blue-variant16 p-5 mt-3 radius-10'>
        <Typography className='text-center'>Music Type</Typography>
        <FormControl fullWidth>
          <Select
            displayEmpty
            value={companyMusicType}
            onChange={handleMusicChange}>
            <MenuItem value={1}>Music Selection - GT / On Demand - GT</MenuItem>
            <MenuItem value={2}>
              Music Selection - FULL / On Demand - GT
            </MenuItem>
            <MenuItem value={3}>
              Music Selection - GT / On Demand - FULL
            </MenuItem>
            <MenuItem value={0}>
              Music Selection - FULL / On Demand - FULL
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      {updateMusicType !== '' &&
        updateMusicType !== null &&
        updateMusicType !== undefined && (
          <DialogConfirmSave
            name='Are you sure want to change Music Type ?'
            onSave={saveMusicChange}
            onCancel={() => setUpdateMusicType('')}
          />
        )}
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={saveMusicChange}
          onClose={() => navigate(0)}
          onEdit={handleEdit}
        />
      )}
    </>
  );
};

export default MusicTypePane;
