import dayjs from 'dayjs';
import {v4 as uuidv4} from 'uuid';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import OndemandStepper from './OndemandStepper';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import {useRqOndemandMsgRequestView} from '../../../react_query/on_demand_messages/OndemandMsgView';
import {IScripts} from '../event-specific/EsmMessages';
import {IMediaTrack} from '../../../dto/MediaTrack.dto';

const OnDemandMsgCreate = ({
  onClose,
  update_id,
}: {
  onClose: () => void;
  update_id?: number;
}) => {
  const companyId = useSelector(companyIdSelector);
  const {data, isFetching} = useRqOndemandMsgRequestView(
    companyId,
    Number(update_id),
    Boolean(update_id),
  );

  const messages = useCallback(() => {
    if (!isFetching && data?.messages) {
      return data.messages.map(o => ({
        id: o.id,
        uuid: uuidv4(),
        message: o.message,
        name: data.name || '',
        requestable_type: 'ondemand-message',
        media_track: o.media_track ?? null,
      }));
    }
    return [
      {
        id: Date.now(),
        uuid: uuidv4(),
        message: '',
        name: '',
        requestable_type: 'ondemand-message',
        media_track: null,
      },
    ];
  }, [data, isFetching]);

  const tracks = useCallback(() => {
    if (!isFetching && data?.messages) {
      const track = data.messages[0]?.media_track;
      if (track) {
        return [track];
      }
      return [];
    }
    return [];
  }, [data, isFetching]);

  const stationDetails = useCallback(() => {
    if (!isFetching && data?.stations) {
      return data.stations?.map(station => ({
        ...station,
        custom_libraries: data.custom_library_request.filter(
          o => o.station_id === station.id,
        ),
      }));
    }
    return [];
  }, [data, isFetching]);

  return !isFetching ? (
    <OndemandStepper
      defaultStationsList={stationDetails()}
      handleClose={onClose}
      defaultMessages={messages() as IScripts[]}
      defaultExpireDate={data?.expire_date ? dayjs(data?.expire_date) : null}
      defaultIsExpireDateSet={Boolean(data?.is_expire_date_set) ?? false}
      messageStatus={data?.status ?? null}
      defaultTracks={tracks() as IMediaTrack[]}
      update_id={update_id}
    />
  ) : null;
};
export default OnDemandMsgCreate;
