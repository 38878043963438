import {v4 as uuidv4} from 'uuid';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IEventType} from '../../dto/EventType.dto';
import {IMsgOptionMsgType} from '../../dto/MessageOption.dto';
import {IMsgOptionOnBoard} from '../../pages/admin/CMS_OPS/Creative/OnboardingMessageTask/OnboardingMessageRequest';

export type ISchools = {
  id: number;
  name: string;
  boys_mascot: string;
  girls_mascot: string;
};

export type IUsers = {
  id: number;
  name: string;
  email: string;
  is_admin: boolean;
  is_primary_contact: boolean;
  is_user: boolean;
};

export interface IOnBoardReqState {
  contact_id: number | null;
  dj_event_id: number | null;
  stinger_event_id: number | null;
  initiated: boolean;
  messages: IMsgOptionOnBoard[];
  sports: IEventType[];
  users: IUsers[];
}

const initialState: IOnBoardReqState = {
  initiated: false,
  messages: [],
  sports: [],
  dj_event_id: 1,
  stinger_event_id: 1,
  contact_id: null,
  users: [],
};

export const onboardingRequestSlice = createSlice({
  name: 'onboardingRequest',
  initialState,
  reducers: {
    initMsg: (
      state: IOnBoardReqState,
      action: PayloadAction<IMsgOptionOnBoard[]>,
    ) => {
      state.messages = action.payload;
    },
    initUsers: (state: IOnBoardReqState, action: PayloadAction<IUsers[]>) => {
      state.users = action.payload;
    },
    initSports: (
      state: IOnBoardReqState,
      action: PayloadAction<IEventType[]>,
    ) => {
      state.sports = action.payload;
    },
    initState: (state: IOnBoardReqState, action: PayloadAction<boolean>) => {
      state.initiated = action.payload;
    },
    addDj: (
      state: IOnBoardReqState,
      action: PayloadAction<{type: IMsgOptionMsgType}>,
    ) => {
      state.messages = [
        {
          message_option_id: uuidv4(),
          message: '',
          message_type: action.payload.type,
          selected: false,
          error: null,
        } as unknown as IMsgOptionOnBoard,
        ...state.messages,
      ];
    },
    toggleSelectDj: (
      state: IOnBoardReqState,
      action: PayloadAction<number | string>,
    ) => {
      const messages = state.messages.map(item => {
        if (item.message_option_id === action.payload) {
          return {...item, selected: !item.selected};
        }
        return item;
      });
      state.messages = [...messages];
    },
    editMsg: (
      state: IOnBoardReqState,
      action: PayloadAction<IMsgOptionOnBoard>,
    ) => {
      const messages = state.messages.map(item => {
        if (item.message_option_id === action.payload.message_option_id) {
          return {...action.payload};
        }
        return item;
      });
      state.messages = [...messages];
    },
    selectedUser: (state: IOnBoardReqState, action: PayloadAction<number>) => {
      state.contact_id = action.payload;
    },
    resetOnboard: _ => {
      return initialState;
    },
  },
});

export const {
  initMsg,
  initUsers,
  initSports,
  initState,
  addDj,
  toggleSelectDj,
  editMsg,
  selectedUser,
  resetOnboard,
} = onboardingRequestSlice.actions;

export default onboardingRequestSlice.reducer;
