import * as React from 'react';
import {
  useGridApiContext,
  GridPagination,
  GridRenderPaginationProps,
} from '@mui/x-data-grid';
import {TablePaginationProps} from '@mui/material/TablePagination';
import MuiPagination from '@mui/material/Pagination';

function Pagination({
  page,
  onPageChange,
  className,
}: Readonly<
  Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>
>) {
  const apiRef = useGridApiContext();
  const rowsCount = apiRef.current.getRowsCount();
  const pageCount = apiRef.current.state.pagination.paginationModel.pageSize;

  return (
    <MuiPagination
      color='primary'
      className={className}
      count={Math.ceil(rowsCount / pageCount)}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(
          event as React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
          newPage - 1,
        );
      }}
    />
  );
}

export function DataGridCustPagination(
  props: Readonly<GridRenderPaginationProps>,
) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}
