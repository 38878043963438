import {Dispatch, SetStateAction} from 'react';
import {FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';
import {iTimedMessageMessageItem} from './TimedMsgRequestContentMessageCard';
import {ITimedMessageMsgType} from '../../../../../dto/timed-message/TimedMessageRequest.dto';

type Props = {
  isUpdate: boolean;
  type: string;
  isCopyForm?: boolean;
  defaultContentType: ITimedMessageMsgType;
  onChange: Dispatch<SetStateAction<ITimedMessageMsgType>>;
  setMessages: Dispatch<SetStateAction<iTimedMessageMessageItem[]>>;
  messageStatus: string | null;
};

const TimedMsgRequestContentTypeSwitchForm = ({
  type,
  onChange,
  setMessages,
  isCopyForm,
  isUpdate,
  defaultContentType,
  messageStatus,
}: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMessageType = e.target.value;
    if (!isUpdate) {
      let defaultMessages: iTimedMessageMessageItem[] = [
        {
          id: -1 * Date.now(),
          isNew: true,
          type: newMessageType as 'message' | 'song',
          message: '',
          artist: '',
          title: '',
          label: newMessageType === 'message' ? 'Message' : 'Song',
        },
      ];
      if (newMessageType === 'sequence') {
        defaultMessages = [];
      }
      setMessages(defaultMessages);
    }
    onChange(e.target.value as ITimedMessageMsgType);
  };

  const disableMessage = () => {
    if (messageStatus === 'Active' || messageStatus === 'Inactive') {
      return true;
    }
    if (isCopyForm) {
      return true;
    } else if (isUpdate && defaultContentType === 'sequence') {
      return true;
    } else if (isUpdate && defaultContentType === 'song') {
      return true;
    }
    return false;
  };

  const disableSong = () => {
    if (messageStatus === 'Active' || messageStatus === 'Inactive') {
      return true;
    }
    if (isCopyForm) {
      return true;
    } else if (isUpdate && defaultContentType === 'sequence') {
      return true;
    } else if (isUpdate && defaultContentType === 'message') {
      return true;
    }
    return false;
  };

  const disableSequence = () => {
    if (messageStatus === 'Active' || messageStatus === 'Inactive') {
      return true;
    }
    if (isCopyForm) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Typography variant='h5' className='my-5 text-white-50'>
        Select Message Type
      </Typography>
      <RadioGroup
        name='message_type'
        value={type}
        onChange={handleChange}
        className='flex-row'>
        <FormControlLabel
          value='message'
          control={
            <Radio
              disabled={disableMessage()}
              sx={{opacity: disableMessage() ? 0.5 : 1}}
            />
          }
          label='Timed Message'
        />
        <FormControlLabel
          value='song'
          control={
            <Radio
              disabled={disableSong()}
              sx={{opacity: disableSong() ? 0.5 : 1}}
            />
          }
          label='Timed Song'
        />
        <FormControlLabel
          value='sequence'
          control={
            <Radio
              disabled={disableSequence()}
              sx={{opacity: disableSequence() ? 0.5 : 1}}
            />
          }
          disabled={disableSequence()}
          label='Timed Sequence'
          checked={type === 'sequence'}
        />
      </RadioGroup>
    </>
  );
};

export default TimedMsgRequestContentTypeSwitchForm;
