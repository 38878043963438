import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {useCallback, useState} from 'react';
import IconReinstate from '../../../../asset/icons/reinstate.svg';

export const IconResetDialog = ({
  id,
  handleReset,
}: {
  id: number;
  handleReset: (id: number) => void;
}) => {
  const [deleteDialog, setDeleteDialog] = useState(false);

  const onClose = useCallback(() => {
    setDeleteDialog(false);
  }, [setDeleteDialog]);

  return (
    <>
      <IconButton
        title='Reinstate'
        onClick={() => setDeleteDialog(true)}
        className='radius-5 p-1 w-30 h-30 cursor-pointer'
        sx={{
          background: `var(--green-variant14) !important`,
          border: `1px solid var(--green-variant18)`,
          '&:hover': {
            background: `var(--green-variant18) !important`,
          },
        }}>
        <img src={IconReinstate} alt='Reinstate' />
      </IconButton>
      <Dialog maxWidth='sm' open={deleteDialog} onClose={onClose}>
        <DialogContent
          className='p-8 flex flex-column justify-center'
          sx={{background: 'var(--green-variant17)'}}>
          <Typography className='text-white text-16 text-center mb-4'>
            Are you sure that you want to <br />
            Reinstate the removed station?
          </Typography>
          <Typography className='text-white text-16 text-center mb-4'>
            Note: This will bring all your playlist as well.
            <br />
            And you will be changed in adding the station
            <br />
            in your account.
          </Typography>
          <DialogActions className='flex direction-row justify-center pb-0'>
            <Stack direction={'row'} justifyContent={'center'} spacing={3}>
              <Button
                variant='outlined'
                className='btn-outlined-gray'
                onClick={onClose}>
                No, Cancel
              </Button>
              <Button
                sx={{
                  background: `var(--green-variant14) !important`,
                  border: `1px solid var(--green-variant18)`,
                  '&:hover': {
                    background: `var(--green-variant18) !important`,
                    border: `1px solid var(--green-variant18)`,
                  },
                }}
                variant='contained'
                onClick={() => handleReset(id)}>
                Yes, Reinstate
              </Button>
            </Stack>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
