import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {
  AddOnBody,
  AddOnHeader,
  AddOnLevelSelectionCmpt,
  AddOnStationCreateAndViewCmpt,
  ILevelStationDetails,
  IStationWithIsDelete,
} from './AddOnCmpt/AddOnCmpt';
import {useRqGetCompany} from '../../../Service/companyService';
import {ICompanyDetails} from '../../../dto/Company.dto';
import RetryPage from '../../../components/styles/RetryButton';
import {IErrorResponse} from '../../../dto/Html.dto';
import {useRqServiceList} from '../../../react_query/services/Services';
import {IService} from '../../../dto/Service.dto';
import {apiGet, apiPost} from '../../../axiosConfig';
import DialogApiRequestStatus from '../../../components/dialogs/DialogApiRequestStatus';
import {AddOnReviewLeftCmpt} from './AddOnCmpt/AddOnReviewLeftCmpt';
import {AddOnStationNamingCmpt} from './AddOnCmpt/AddOnStationNamingCmpt';
import {AddOnRightBillingCmpt} from './AddOnCmpt/AddOnRightBillingCmpt';

type PricingDetail = {
  name: string;
  count: number;
  price: number;
};
type NewPricingDetails = {
  product: {
    cmc_product: string;
    primary: string;
  };
  level: PricingDetail;
  deleted_full_station: PricingDetail;
  deleted_mirror_station: PricingDetail;
  deleted_odo_station: PricingDetail;
  updated_full_station: PricingDetail;
  updated_mirror_station: PricingDetail;
  updated_odo_station: PricingDetail;
  full_station: PricingDetail;
  odo_station: PricingDetail;
  mirror_station: PricingDetail;
  setup: PricingDetail;
  discount: PricingDetail;
  service_total: PricingDetail;
  laptop: PricingDetail;
  total_invoice: PricingDetail;
  previous_total: PricingDetail;
  grand_total: PricingDetail;
};

const AddOnDetails = () => {
  const companyParam = useParams();
  const navigate = useNavigate();
  const [currentPage, setcurrentPage] = useState(1);
  const companyId = Number(companyParam.company_id);
  const [company, setCompany] = useState<ICompanyDetails | null>(null);
  const [, setRetryTime] = useState(Date.now());
  const [levelStationDetails, setLevelStationDetails] =
    useState<ILevelStationDetails>({} as ILevelStationDetails);
  const {data, isFetching, isError, error} = useRqGetCompany(
    companyId,
    Boolean(companyId),
  );
  const {
    data: servicesData,
    isFetching: serviceFetching,
    isError: serviceIsError,
  } = useRqServiceList(
    company ? company?.product.cmc_product : '',
    Boolean(company),
  );
  const {data: stations, isFetching: stationsIsFetching} = useQuery({
    queryKey: [`/v1/company/${company ? company.id : 0}/stations?is_all=true`],
    queryFn: () =>
      apiGet(
        `/v1/company/${company ? company.id : 0}/stations?is_all=true`,
      ).then(response => {
        return response.data.data as IStationWithIsDelete[];
      }),
    refetchOnWindowFocus: false,
    enabled: Boolean(company),
  });
  const {data: previousBill, isFetching: previousBillIsFetching} = useQuery({
    queryKey: [`/v1/company/${company ? company.id : 0}/add-on`],
    queryFn: () =>
      apiGet(`/v1/company/${company ? company.id : 0}/add-on`).then(
        response => {
          return response.data.data as NewPricingDetails;
        },
      ),
    refetchOnWindowFocus: false,
    enabled: Boolean(company),
  });

  const onboardingTotal = useMemo(() => {
    if (previousBill && !previousBillIsFetching) {
      return previousBill.grand_total.price || 0;
    }
    return 0;
  }, [previousBill, previousBillIsFetching]);

  const levels = useMemo(() => {
    if (servicesData && !serviceFetching && !serviceIsError) {
      return servicesData.filter(val => val.service_type === 'station');
    }
    return [];
  }, [serviceFetching, serviceIsError, servicesData]);
  const stationLevels = useMemo(() => {
    if (servicesData && !serviceFetching && !serviceIsError) {
      return servicesData.filter(val => val.service_type === 'additional');
    }
    return [];
  }, [serviceFetching, serviceIsError, servicesData]);

  useEffect(() => {
    if (data && !isFetching && !isError) {
      setCompany(data);
    }
  }, [data, isError, isFetching]);

  useEffect(() => {
    if (company) {
      setLevelStationDetails(prev => {
        return {
          ...prev,
          level: {
            previous_level: company.product_level
              ? company.product_level.level
              : '',
            selected_level: company.product_level
              ? company.product_level.level
              : '',
          },
        };
      });
    }
  }, [company]);

  useEffect(() => {
    if (stations && !stationsIsFetching) {
      const stationsDetails: IStationWithIsDelete[] = stations.map(val => {
        return {
          ...val,
          is_primary_station: Boolean(val.is_primary_station),
          is_deleted: val.deleted_at ? true : false,
          previous_type: val.type,
        };
      });

      setLevelStationDetails(prev => {
        return {...prev, stations: stationsDetails};
      });
    }
  }, [stations, stationsIsFetching]);

  const handleReset = useCallback(() => {
    if (stations && !stationsIsFetching) {
      const stationsDetails: IStationWithIsDelete[] = stations.map(val => {
        return {
          ...val,
          is_deleted: val.deleted_at ? true : false,
          previous_type: val.type,
        };
      });
      if (company) {
        const productLevel = {
          level: {
            previous_level: company.product_level
              ? company.product_level.level
              : '',
            selected_level: company.product_level
              ? company.product_level.level
              : '',
          },
        };
        setLevelStationDetails({
          level: productLevel.level,
          stations: stationsDetails,
        } as ILevelStationDetails);
      } else {
        setLevelStationDetails(prev => {
          return {...prev, stations: stationsDetails};
        });
      }
    }
  }, [company, stations, stationsIsFetching]);

  const handleNextPage = useCallback(() => {
    setcurrentPage(prev => prev + 1);
  }, [setcurrentPage]);

  const handleBackButton = useCallback(() => {
    if (currentPage === 1) {
      navigate('/partner');
    } else {
      setcurrentPage(prev => prev - 1);
    }
  }, [currentPage, navigate]);

  if (isError) {
    const errResp = error as IErrorResponse;
    return (
      <RetryPage
        pageTitle='Selected Company'
        errResp={errResp?.response}
        setRetryTime={setRetryTime}
        queryKey={[`/v1/company/${companyId}`]}
      />
    );
  }

  return (
    <>
      {currentPage === 1 ? (
        <Box className='h-750 mx-10 mt-5'>
          <AddOnHeader title='Account Change' handleBack={handleBackButton} />
          <AddOnBody
            body={
              <AddOnStationLevelModifyingCmpt
                company={company}
                levels={levels}
                stationLevels={stationLevels}
                levelStationDetails={levelStationDetails}
                setLevelStationDetails={setLevelStationDetails}
                handleReset={handleReset}
                handleNextPage={handleNextPage}
              />
            }
          />
        </Box>
      ) : null}
      {currentPage === 2 ? (
        <Box className='h-750 mx-10 mt-5'>
          <AddOnHeader
            title={company ? company?.name : ''}
            handleBack={handleBackButton}
          />
          <AddOnBody
            body={
              <AddOnReviewCmpt
                company={company}
                onboardingTotal={onboardingTotal}
                levelStationDetails={levelStationDetails}
              />
            }
          />
        </Box>
      ) : null}
    </>
  );
};

export default AddOnDetails;

const AddOnStationLevelModifyingCmpt = ({
  company,
  levels,
  stationLevels,
  levelStationDetails,
  setLevelStationDetails,
  handleReset,
  handleNextPage,
}: {
  company: ICompanyDetails | null;
  levels: IService[];
  stationLevels: IService[];
  levelStationDetails: ILevelStationDetails;
  setLevelStationDetails: React.Dispatch<
    React.SetStateAction<ILevelStationDetails>
  >;
  handleReset: () => void;
  handleNextPage: () => void;
}) => {
  const handleLevelChange = useCallback(
    (level: string) => {
      setLevelStationDetails(prev => {
        return {...prev, level: {...prev.level, selected_level: level}};
      });
    },
    [setLevelStationDetails],
  );
  const [isStationDeletable, setIsStationDeletable] = useState(false);
  const [isAddDisabled, setIsAddDisabled] = useState(false);

  useEffect(() => {
    if (company?.product.cmc_product === 'SchoolZone') {
      const nonDeletedStationsCount = levelStationDetails.stations?.filter(
        val => !val.is_deleted,
      ).length;
      const newAddedStationsCount = levelStationDetails.stations?.filter(
        val => val.id < 0,
      ).length;
      setIsStationDeletable(
        nonDeletedStationsCount < 5 || newAddedStationsCount > 0,
      );
      setIsAddDisabled(newAddedStationsCount === 3);
    }
  }, [levelStationDetails, company]);

  const isSubmittable = useCallback(() => {
    if (company?.product.cmc_product === 'SchoolZone') {
      if (
        levelStationDetails.stations?.filter(val => val.id < 0).length === 3 ||
        levelStationDetails.stations?.filter(
          val => val.is_deleted && !val.deleted_at,
        ).length === 3
      ) {
        return false;
      } else if (
        levelStationDetails.level?.previous_level !==
          levelStationDetails.level?.selected_level &&
        levelStationDetails.stations?.filter(val => val.id < 0).length === 0 &&
        levelStationDetails.stations?.filter(
          val => val.is_deleted && !val.deleted_at,
        ).length === 0
      ) {
        return false;
      }
      return true;
    } else if (company?.product.cmc_product !== 'SchoolZone') {
      if (
        levelStationDetails.stations?.filter(val => val.id < 0).length > 0 ||
        levelStationDetails.level?.previous_level !==
          levelStationDetails.level?.selected_level ||
        levelStationDetails.stations?.filter(
          val => val.is_deleted && !val.deleted_at,
        ).length > 0 ||
        levelStationDetails.stations?.filter(
          val => !val.is_deleted && val.deleted_at,
        ).length > 0 ||
        levelStationDetails.stations?.filter(
          val => val.previous_type !== val.type,
        ).length > 0
      ) {
        return false;
      }
      return true;
    }

    return false;
  }, [
    company?.product.cmc_product,
    levelStationDetails.level?.previous_level,
    levelStationDetails.level?.selected_level,
    levelStationDetails.stations,
  ]);

  return (
    <>
      {company ? (
        <Box>
          {/* Second Header */}
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            p={4}
            alignContent={'center'}>
            <Typography
              variant='h5'
              pt={1}
              className='font-bold'
              textAlign={
                'center'
              }>{`${company.product.cmc_product}-${company.product.primary}-Account Level`}</Typography>
            <Stack direction={'row'}>
              <FormControlLabel
                control={<Checkbox checked={true} />}
                label={
                  <Typography variant='h5' className='font-bold'>
                    Account Level
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={true} />}
                label={
                  <Typography variant='h5' className='text-bold'>
                    Station Level
                  </Typography>
                }
              />
            </Stack>
          </Stack>
          <Divider />
          {/* Level Selection */}
          <AddOnLevelSelectionCmpt
            productType={company.product.cmc_product}
            levels={levels}
            levelStationDetails={levelStationDetails}
            handleLevelChange={handleLevelChange}
          />
          {/* Alert for schoolzone */}
          {company.product.cmc_product === 'SchoolZone' ? (
            <Alert severity='info' className='w-600 mx-auto my-1'>
              Note: Add or delete exactly 3 stations to enable submission.
            </Alert>
          ) : null}
          {/* Stations List and Modification */}
          <Stack
            direction={'row'}
            justifyContent={'center'}
            className='min-w-500 mx-auto'>
            <AddOnStationCreateAndViewCmpt
              type='current'
              isStationDeletable={isStationDeletable}
              isAddDisabled={isAddDisabled}
              company={company}
              stationLevels={stationLevels}
              levelStationDetails={levelStationDetails}
              setLevelStationDetails={setLevelStationDetails}
            />
            <AddOnStationCreateAndViewCmpt
              type='revised'
              isStationDeletable={isStationDeletable}
              isAddDisabled={isAddDisabled}
              company={company}
              stationLevels={stationLevels}
              levelStationDetails={levelStationDetails}
              setLevelStationDetails={setLevelStationDetails}
            />
          </Stack>
          {/* Submit or Reset Button */}
          <Stack direction={'row'} justifyContent={'center'} my={4}>
            <Button
              variant='contained'
              className='px-6 py-4'
              onClick={handleReset}>
              Reset
            </Button>
            <Button
              variant='contained'
              className='px-6 py-4 ml-3'
              onClick={handleNextPage}
              disabled={isSubmittable()}>
              Review
            </Button>
          </Stack>
        </Box>
      ) : null}
    </>
  );
};
export type IPricingReivewProp = {
  name: string;
  count: number;
  price: number;
};
export type IPricingReivewDetailsProp = {
  level: IPricingReivewProp;
  deleted_full_station: IPricingReivewProp;
  deleted_mirror_station: IPricingReivewProp;
  deleted_odo_station: IPricingReivewProp;
  updated_full_station: IPricingReivewProp;
  updated_mirror_station: IPricingReivewProp;
  updated_odo_station: IPricingReivewProp;
  full_station: IPricingReivewProp;
  odo_station: IPricingReivewProp;
  mirror_station: IPricingReivewProp;
  setup: IPricingReivewProp;
  discount: IPricingReivewProp;
  laptop: IPricingReivewProp;
  service_total: IPricingReivewProp;
  total_invoice: IPricingReivewProp;
};

const defaultPricingDetails = {
  level: {name: 'Level Change', count: 0, price: 0},
  deleted_full_station: {name: 'Deleted Full Station', count: 0, price: 0},
  deleted_mirror_station: {
    name: 'Deleted to Mirror Station',
    count: 0,
    price: 0,
  },
  deleted_odo_station: {name: 'Deleted to ODO Station', count: 0, price: 0},
  updated_full_station: {name: 'Updated to Full Station', count: 0, price: 0},
  updated_mirror_station: {
    name: 'Updated to Mirror Station',
    count: 0,
    price: 0,
  },
  updated_odo_station: {name: 'Updated to ODO Station', count: 0, price: 0},
  full_station: {name: 'Full Station', count: 0, price: 0},
  odo_station: {name: 'ODO Station', count: 0, price: 0},
  mirror_station: {name: 'Mirror Station', count: 0, price: 0},
  setup: {name: 'Setup Fee', count: 0, price: 0},
  discount: {name: 'Discount', count: 0, price: 0},
  service_total: {name: 'Service Total', count: 0, price: 0},
  laptop: {name: 'Laptops', count: 0, price: 0},
  total_invoice: {name: 'Total Invoice', count: 0, price: 0},
};

const levelChangesPricing = {
  GameTime: {
    'Level 0 (ODO Only) - Level 1': 800,
    'Level 0 (ODO Only) - Level 2': 1400,
    'Level 1 - Level 2': 600,
    'Level 1 - Level 0 (ODO Only)': -800,
    'Level 2 - Level 0 (ODO Only)': -1400,
    'Level 2 - Level 1': -600,
  },
  'Neptune Radio': {
    'Level 1 - Level 2': 600,
    'Level 1 - Level 3': 1200,
    'Level 2 - Level 3': 600,
    'Level 2 - Level 1': -600,
    'Level 3 - Level 1': -1200,
    'Level 3 - Level 2': -600,
  },
};

type ILevelPricingProp = {
  'Level 0 (ODO Only) - Level 1': number;
  'Level 0 (ODO Only) - Level 2': number;
  'Level 1 - Level 2': number;
  'Level 1 - Level 0 (ODO Only)': number;
  'Level 2 - Level 0 (ODO Only)': number;
  'Level 2 - Level 1': number;
};
type ILevelPricingNRProp = {
  'Level 1 - Level 2': number;
  'Level 1 - Level 3': number;
  'Level 2 - Level 3': number;
  'Level 2 - Level 1': number;
  'Level 3 - Level 1': number;
  'Level 3 - Level 2': number;
};

type ILevelPricingKeyProp = keyof ILevelPricingProp;
type ILevelPricingNRKeyProp = keyof ILevelPricingNRProp;

const AddOnReviewCmpt = ({
  company,
  onboardingTotal,
  levelStationDetails,
}: {
  company: ICompanyDetails | null;
  onboardingTotal: number;
  levelStationDetails: ILevelStationDetails;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [stations, setstations] = useState<IStationWithIsDelete[]>(
    levelStationDetails.stations,
  );
  const [pricingReviewDetails, setpricingReviewDetails] =
    useState<IPricingReivewDetailsProp>(defaultPricingDetails);
  const [apiState, setApiState] = useState({status: '', error: null});

  useEffect(() => {
    const newPricingDetails = {
      level: {name: 'Level Change', count: 0, price: 0},
      deleted_full_station: {name: 'Deleted Full Station', count: 0, price: 0},
      deleted_mirror_station: {
        name: 'Deleted to Mirror Station',
        count: 0,
        price: 0,
      },
      deleted_odo_station: {name: 'Deleted to ODO Station', count: 0, price: 0},
      updated_full_station: {
        name: 'Updated to Full Station',
        count: 0,
        price: 0,
      },
      updated_mirror_station: {
        name: 'Updated to Mirror Station',
        count: 0,
        price: 0,
      },
      updated_odo_station: {name: 'Updated to ODO Station', count: 0, price: 0},
      full_station: {name: 'Full Station', count: 0, price: 0},
      odo_station: {name: 'ODO Station', count: 0, price: 0},
      mirror_station: {name: 'Mirror Station', count: 0, price: 0},
      setup: {name: 'Setup Fee', count: 0, price: 0},
      discount: {name: 'Discount', count: 0, price: 0},
      service_total: {name: 'Service Total', count: 0, price: 0},
      laptop: {name: 'Laptops', count: 0, price: 0},
      total_invoice: {name: 'Total Invoice', count: 0, price: 0},
    };

    // Station level change pricing
    levelStationDetails.stations.map(val => {
      if (val.previous_type !== val.type && !val.deleted_at && val.id > 0) {
        if (company?.product.cmc_product === 'GameTime') {
          if (val.type === 'full_station') {
            newPricingDetails.updated_full_station.count += 1;
            newPricingDetails.updated_full_station.price += 700;
          } else {
            newPricingDetails.updated_odo_station.count += 1;
            newPricingDetails.updated_odo_station.price -= 700;
          }
        } else if (company?.product.cmc_product === 'Neptune Radio') {
          if (val.type === 'full_station') {
            newPricingDetails.updated_full_station.count += 1;
            newPricingDetails.updated_full_station.price += 900;
          } else {
            newPricingDetails.updated_mirror_station.count += 1;
            newPricingDetails.updated_mirror_station.price -= 900;
          }
        }
      }
    });

    // Level change pricing
    if (company?.product.cmc_product === 'GameTime') {
      const levelPricingKey =
        `${levelStationDetails.level.previous_level} - ${levelStationDetails.level.selected_level}` as ILevelPricingKeyProp;
      newPricingDetails.level.price =
        levelChangesPricing['GameTime'][levelPricingKey] ?? 0;
      newPricingDetails.level.count = newPricingDetails.level.price > 0 ? 1 : 0;
    } else if (company?.product.cmc_product === 'Neptune Radio') {
      const levelPricingKey =
        `${levelStationDetails.level.previous_level} - ${levelStationDetails.level.selected_level}` as ILevelPricingNRKeyProp;
      newPricingDetails.level.price =
        levelChangesPricing['Neptune Radio'][levelPricingKey] ?? 0;
      newPricingDetails.level.count = newPricingDetails.level.price > 0 ? 1 : 0;
    } else if (
      company?.product.cmc_product === 'SchoolZone' &&
      levelStationDetails.level.previous_level === 'Level 1' &&
      levelStationDetails.level.selected_level === 'Level 2'
    ) {
      newPricingDetails.level.price = 250;
      newPricingDetails.level.count = 1;
    }

    // setup fee
    if (
      company?.product.cmc_product === 'GameTime' &&
      levelStationDetails.level.previous_level === 'Level 0 (ODO Only)' &&
      levelStationDetails.level.selected_level !== 'Level 0 (ODO Only)'
    ) {
      newPricingDetails.setup.price = 375;
    }

    // Additional Station pricing
    levelStationDetails.stations.map(val => {
      if (val.id < 0 || (val.id > 0 && val.deleted_at && !val.is_deleted)) {
        if (
          company?.product.cmc_product === 'GameTime' ||
          company?.product.cmc_product === 'Neptune Radio'
        ) {
          if (val.type === 'full_station') {
            newPricingDetails.full_station.count += 1;
            newPricingDetails.full_station.price += 1200;
          } else if (val.type === 'odo_station') {
            newPricingDetails.odo_station.count += 1;
            newPricingDetails.odo_station.price += 500;
          } else if (val.type === 'mirror_station') {
            newPricingDetails.mirror_station.count += 1;
            newPricingDetails.mirror_station.price += 300;
          }
        }
      }
    });
    if (
      levelStationDetails.stations.filter(
        val => val.id < 0 || (val.id > 0 && val.deleted_at && !val.is_deleted),
      ).length === 3
    ) {
      if (levelStationDetails.level.selected_level === 'Level 1') {
        newPricingDetails.full_station.count = 3;
        newPricingDetails.full_station.price = 1000;
      } else {
        newPricingDetails.full_station.count = 3;
        newPricingDetails.full_station.price = 1250;
      }
    }

    // Deleted Stations
    levelStationDetails.stations.map(val => {
      if (!val.deleted_at && val.is_deleted && val.id > 0) {
        if (company?.product.cmc_product === 'GameTime') {
          if (val.type === 'full_station') {
            newPricingDetails.deleted_full_station.count += 1;
            newPricingDetails.deleted_full_station.price -= 1200;
          } else {
            newPricingDetails.deleted_odo_station.count += 1;
            newPricingDetails.deleted_odo_station.price -= 500;
          }
        } else if (company?.product.cmc_product === 'Neptune Radio') {
          if (val.type === 'full_station') {
            newPricingDetails.deleted_full_station.count += 1;
            newPricingDetails.deleted_full_station.price -= 1200;
          } else {
            newPricingDetails.deleted_mirror_station.count += 1;
            newPricingDetails.deleted_mirror_station.price -= 300;
          }
        } else if (company?.product.cmc_product === 'SchoolZone') {
          if (levelStationDetails.level.selected_level === 'Level 1') {
            newPricingDetails.deleted_full_station.count += 1;
            newPricingDetails.deleted_full_station.price -= 1000;
          } else {
            newPricingDetails.deleted_full_station.count += 1;
            newPricingDetails.deleted_full_station.price -= 1250;
          }
        }
      }
    });

    if (
      levelStationDetails.stations.filter(
        val => !val.deleted_at && val.is_deleted && val.id > 0,
      ).length === 3
    ) {
      if (levelStationDetails.level.selected_level === 'Level 1') {
        newPricingDetails.deleted_full_station.count += 1;
        newPricingDetails.deleted_full_station.price -= 1000;
      } else {
        newPricingDetails.deleted_full_station.count += 1;
        newPricingDetails.deleted_full_station.price -= 1250;
      }
    }

    // service total without discount and laptop fee
    const serviceTotalLevel =
      Math.sign(newPricingDetails.level.price) === -1
        ? 0
        : newPricingDetails.level.price;
    const negativeServiceTotalLevel =
      Math.sign(newPricingDetails.level.price) !== -1
        ? 0
        : newPricingDetails.level.price;
    const serviceTotal =
      (serviceTotalLevel || 0) +
      (newPricingDetails.full_station.price || 0) +
      (newPricingDetails.mirror_station.price || 0) +
      (newPricingDetails.odo_station.price || 0) +
      (newPricingDetails.updated_full_station.price || 0);

    const negativeServiceTotal =
      (negativeServiceTotalLevel || 0) +
      (newPricingDetails.deleted_full_station.price || 0) +
      (newPricingDetails.deleted_mirror_station.price || 0) +
      (newPricingDetails.deleted_odo_station.price || 0) +
      (newPricingDetails.updated_mirror_station.price || 0) +
      (newPricingDetails.updated_odo_station.price || 0);

    // Discount pricing
    if (levelStationDetails.discount) {
      const discount = levelStationDetails.discount ?? 0;
      newPricingDetails.discount.count = discount;
      newPricingDetails.discount.price = Math.floor(
        (serviceTotal * discount) / 100,
      );
    }

    // service total calculation
    newPricingDetails.service_total.price =
      serviceTotal +
      negativeServiceTotal +
      (newPricingDetails.setup.price || 0) -
      (newPricingDetails.discount.price || 0);

    // Laptop pricing
    const laptop = levelStationDetails.laptop || 0;
    newPricingDetails.laptop.count = laptop;
    newPricingDetails.laptop.price = laptop * 500;

    // Total voice calculation.
    newPricingDetails.total_invoice.price =
      (newPricingDetails.service_total.price || 0) +
      newPricingDetails.laptop.price;

    setpricingReviewDetails(newPricingDetails);
  }, [
    company,
    levelStationDetails.discount,
    levelStationDetails.laptop,
    levelStationDetails.level.previous_level,
    levelStationDetails.level.selected_level,
    levelStationDetails.stations,
  ]);

  const handleSubmit = useCallback(() => {
    setApiState({status: 'pending', error: null});
    apiPost(`/v1/company/${company?.id}/add-on`, {
      level: levelStationDetails.level,
      stations: stations,
      bill: pricingReviewDetails,
    })
      .then(() => {
        setApiState({status: '', error: null});
        queryClient.invalidateQueries({
          queryKey: [`/v1/company/${company?.id}`],
          refetchType: 'active',
        });
        navigate('/partner');
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });
  }, [
    navigate,
    company,
    levelStationDetails,
    queryClient,
    pricingReviewDetails,
    stations,
  ]);

  const handleEdit = useCallback(() => {
    setApiState({status: '', error: null});
  }, [setApiState]);

  const handleClose = useCallback(() => {
    setApiState({status: '', error: null});
  }, [setApiState]);

  return (
    <>
      <Grid container className='h-full'>
        <Grid item xs={4.5} p={3} className='h-full'>
          <AddOnReviewLeftCmpt
            level={levelStationDetails.level.selected_level}
            pricingReviewDetails={pricingReviewDetails}
            setpricingReviewDetails={setpricingReviewDetails}
            type={company?.product.cmc_product ?? ''}
          />
        </Grid>
        <Grid
          item
          xs={4.5}
          sx={{background: `var(--blue-variant17)`}}
          p={3}
          className='h-full'>
          <AddOnStationNamingCmpt
            stations={stations}
            setstations={setstations}
            type={company?.product.cmc_product ?? ''}
          />
        </Grid>
        <Grid item xs={3} p={3} sx={{background: `var(--blue_variant47)`}}>
          <AddOnRightBillingCmpt
            pricingReviewDetails={pricingReviewDetails}
            onboardingTotal={onboardingTotal}
            type={company?.product.cmc_product ?? ''}
            stations={stations}
            handleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={handleSubmit}
          onEdit={handleEdit}
          onClose={handleClose}
        />
      )}
    </>
  );
};
