import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import {useCallback, useState} from 'react';
import IconDeleteButton from '../../../../components/Button/IconDeletebutton';

export const IconDeleteDialog = ({
  id,
  handleDelete,
  isDisabled,
}: {
  id: number;
  handleDelete: (id: number) => void;
  isDisabled: boolean;
}) => {
  const [deleteDialog, setDeleteDialog] = useState(false);

  const onClose = useCallback(() => {
    setDeleteDialog(false);
  }, [setDeleteDialog]);

  return (
    <>
      <IconDeleteButton
        onClick={() => setDeleteDialog(true)}
        disabled={isDisabled}
      />
      <Dialog maxWidth='sm' open={deleteDialog} onClose={onClose}>
        <DialogContent
          className='p-8 flex flex-column justify-center'
          sx={{background: 'var(--blue-variant17)'}}>
          <Typography className='text-white text-16 text-center mb-4'>
            Are you sure that you want to <br />
            Do this action?
          </Typography>
          <DialogActions className='flex direction-row justify-center pb-0'>
            <Stack direction={'row'} justifyContent={'center'} spacing={3}>
              <Button
                onClick={onClose}
                variant='outlined'
                className='btn-outlined-gray'>
                No, Cancel
              </Button>
              <Button
                sx={{background: 'red !important'}}
                variant='contained'
                onClick={() => handleDelete(id)}>
                Yes, Delete
              </Button>
            </Stack>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
