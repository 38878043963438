import {Box, TextField} from '@mui/material';

type Props = {
  value: string;
  id: number;
  onChange: (text: string) => void;
  label?: string;
};

const TimedMsgRequestContentMessageForm = ({
  value,
  id,
  onChange,
  label,
}: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  return (
    <Box className='w-full'>
      <TextField
        name={`message-${id}`}
        fullWidth
        multiline
        rows='4'
        maxRows='15'
        placeholder={`Enter ${label ?? 'Message'} here`}
        required
        value={value}
        onChange={handleChange}
        variant='outlined'
        sx={{
          backgroundColor: 'var(--blue-variant15)',
          '& fieldset': {
            borderColor: '#bdbdbd',
          },
        }}
      />
      <div className='text-white-50 italic mt-3 text-13'>
        The message must be atleast 10 characters long
      </div>
    </Box>
  );
};

export default TimedMsgRequestContentMessageForm;
