import {useCallback} from 'react';
import {Box, Button, Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TimedMsgRequestTimesCard, {
  iTimedMsgTimesItem,
} from './TimedMsgRequestTimesCard';
import {ITimedDuplicateTimes} from '../TimedMessageRequest';

type Props = {
  isAddSlot: boolean;
  items: iTimedMsgTimesItem[];
  selectedWeekdays: string[];
  enableWeekdays: boolean;
  setDaysTimes: React.Dispatch<React.SetStateAction<iTimedMsgTimesItem[]>>;
  availableDays: string[];
  duplicateTimes?: ITimedDuplicateTimes[];
};

const TimedMsgRequestTimes = ({
  isAddSlot,
  items,
  selectedWeekdays,
  enableWeekdays,
  setDaysTimes,
  availableDays,
  duplicateTimes,
}: Props) => {
  const handleRemove = useCallback(
    (id: number) => {
      setDaysTimes(prevItems => {
        const index = prevItems.findIndex(o => o.id === id);
        if (index === -1) return prevItems;
        const newItems = [...prevItems];
        newItems.splice(index, 1);
        return newItems;
      });
    },
    [setDaysTimes],
  );

  const handleAddSlot = useCallback(() => {
    setDaysTimes(prevItems => [
      ...prevItems,
      {
        id: Date.now(),
        selectedDays: [],
        timeRanges: [],
      },
    ]);
  }, [setDaysTimes]);

  return (
    <>
      <Box className='flex justify-between items-start'>
        <Box>
          <Typography variant='h5' className='mb-2'>
            Select Time
          </Typography>
          <Typography
            variant='subtitle1'
            className='text-white-50 text-14 mb-8 italic'>
            Please choose the time when the message needs to play
          </Typography>
        </Box>
        {isAddSlot && (
          <Button
            startIcon={<AddIcon />}
            variant='outlined'
            size='small'
            onClick={handleAddSlot}>
            Add Slot
          </Button>
        )}
      </Box>

      <Box className='pr-2 mt-6 h-340 overflow-auto scrollbar'>
        {items.map((item, index) => (
          <TimedMsgRequestTimesCard
            key={item.id}
            title={enableWeekdays ? `Slot ${index + 1}` : ``}
            item={item}
            enableWeekdays={enableWeekdays}
            selectedWeekdays={selectedWeekdays}
            removeBtnDisabled={items.length === 1}
            setDaysTimes={setDaysTimes}
            onRemove={handleRemove}
            availableDays={availableDays}
            duplicateTimes={duplicateTimes}
          />
        ))}
      </Box>
    </>
  );
};

export default TimedMsgRequestTimes;
