import {ChangeEvent, useCallback, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Typography,
  styled,
  TextField,
  ListItemText,
  ListItemIcon,
  Box,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MusicPreviewBtn from '../../../../../components/music/MusicPreviewBtn';
import {useRqGetRecordingTaskMessages} from '../../../../../react_query/messages/task/ListRecordingMessages';
import {apiPost} from '../../../../../axiosConfig';
import AssignTaskButton from '../../../../../components/my-task/AssignTaskButton';
import DialogApiRequestStatus from '../../../../../components/dialogs/DialogApiRequestStatus';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FileReUploadProcess = () => {
  const {task_id} = useParams();
  const [file, setFile] = useState<File | null>(null);
  const [text, setText] = useState('');
  const [apiState, setApiState] = useState({status: '', error: null});
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const navigate = useNavigate();

  const {data} = useRqGetRecordingTaskMessages(Number(task_id));

  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;
      if (fileList && fileList.length > 0) {
        const selectedFile = fileList[0];
        if (selectedFile) {
          setFile(selectedFile);
        }
      }
    },
    [setFile],
  );
  const handleRemoveFile = useCallback(() => {
    setFile(null);
  }, []);
  const handleText = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };
  const handleSubmit = () => {
    const fd = new FormData();
    if (file) {
      fd.append('file', file as Blob);
    }
    fd.append('notes', String(text));
    setApiState({
      status: 'pending',
      error: null,
    });
    apiPost(`/v1/task/${task_id}/reupload`, fd)
      .then(() => {
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({
          status: 'error',
          error,
        });
      });
  };
  const handleEdit = useCallback(() => {
    setApiState({
      status: '',
      error: null,
    });
  }, []);

  const handleClose = useCallback(() => {
    setApiState({
      status: '',
      error: null,
    });
    navigate('/');
  }, [navigate]);

  return (
    <Card className='w-1000 mt-5 mx-auto p-5'>
      <AssignTaskButton isPreview={isPreview} setIsPreview={setIsPreview} />
      <CardHeader title='Quality issue' className='text-bold'></CardHeader>
      <CardContent>
        {data && (
          <List>
            <ListItem>
              <Typography className='w-200' variant='subtitle1'>
                Description
              </Typography>
              :{' '}
              {data?.requestable?.message?.processing_center_detail.processing_center_notes
                .map(val => val.notes)
                ?.join(', ')}
            </ListItem>
            <ListItem>
              <Typography className='w-200' variant='subtitle1'>
                Requestable Type
              </Typography>
              <Typography variant='subtitle1'>
                : {data.requestable_type}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography className='w-200' variant='subtitle1'>
                File
              </Typography>
              <Typography variant='subtitle1'>
                : {data?.requestable.message?.prepopulated_file_name}
                <MusicPreviewBtn
                  id={data.id}
                  url={String(data.requestable.message?.initial_url)}
                />
              </Typography>
            </ListItem>
          </List>
        )}
        <Typography variant='h4' className='my-2'>
          {' '}
          Upload File{' '}
        </Typography>
        <Alert severity='info' className='mb-2'>
          Please upload a file that is smaller than 20 MB.
        </Alert>
        <Button
          size='medium'
          component='label'
          variant='outlined'
          startIcon={<CloudUploadIcon />}>
          Click to attach file(s)
          <VisuallyHiddenInput
            type='file'
            accept='audio/*'
            onChange={handleFileChange}
          />
        </Button>
        {file && (
          <List sx={{mt: 2}}>
            <ListItem sx={{p: 2, background: '#0d1525'}}>
              <ListItemText primary={file.name} />
              <ListItemIcon
                onClick={handleRemoveFile}
                style={{cursor: 'pointer'}}>
                <DeleteOutlineIcon />
              </ListItemIcon>
            </ListItem>
          </List>
        )}
        <Typography variant='h4' className='my-2'>
          {' '}
          Comment
        </Typography>
        <TextField
          fullWidth
          className='mb-4'
          multiline
          rows={4}
          onChange={handleText}></TextField>
        <Box className='d-block w-100 mx-auto'>
          <Button
            variant='outlined'
            disabled={text === '' || file === null || isPreview}
            onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </CardContent>
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={handleSubmit}
          onEdit={handleEdit}
          onClose={handleClose}
        />
      )}
    </Card>
  );
};

export default FileReUploadProcess;
