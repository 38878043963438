import {Button, ButtonProps} from '@mui/material';
import {useCallback, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {apiDelete} from '../../../axiosConfig';
import DialogApiRequestStatus from '../../dialogs/DialogApiRequestStatus';
import DialogConfirmDeletion from '../../dialogs/DialogConfirmDeletion';
import IconDeleteButton from '../../Button/IconDeletebutton';

const DeleteRow = ({
  dialog_content,
  url,
  refreshQueryKey,
  isIcon = true,
  title = 'Delete',
  buttonProps,
  onSuccessAction,
}: {
  dialog_content: JSX.Element;
  url: string;
  refreshQueryKey: string;
  isIcon?: boolean;
  title?: string;
  buttonProps?: ButtonProps;
  onSuccessAction?: () => void;
}) => {
  const queryClient = useQueryClient();
  const [isDelete, setIsDelete] = useState(false);
  const [apiState, setApiState] = useState({
    status: '',
    error: null,
    successMsg: '',
  });

  const handleCloseDialog = useCallback(() => {
    setApiState({status: '', error: null, successMsg: ''});
  }, []);

  const handleCancel = useCallback(() => {
    setApiState({
      status: '',
      error: null,
      successMsg: '',
    });
    setIsDelete(false);
  }, []);

  const handleSetDelete = useCallback(() => {
    setIsDelete(true);
  }, []);

  const handleDelete = useCallback(() => {
    setApiState({status: 'pending', error: null, successMsg: ''});
    apiDelete(url)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [refreshQueryKey],
          refetchType: 'active',
        });
        setApiState({status: 'success', error: null, successMsg: ''});
        onSuccessAction?.();
        setIsDelete(false);
      })
      .catch(error => {
        setApiState({
          status: 'error',
          error,
          successMsg: '',
        });
      });
  }, [onSuccessAction, queryClient, refreshQueryKey, url]);

  return (
    <>
      {isIcon ? (
        <IconDeleteButton
          {...buttonProps}
          title={title}
          onClick={handleSetDelete}
        />
      ) : (
        <Button variant='outlined' {...buttonProps} onClick={handleSetDelete}>
          {title}
        </Button>
      )}

      {isDelete ? (
        <DialogConfirmDeletion
          name={dialog_content}
          onDelete={handleDelete}
          onCancel={handleCancel}
          isPermanent
        />
      ) : null}
      {apiState.status !== '' ? (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={handleDelete}
          onEdit={handleCloseDialog}
          onClose={handleCancel}
        />
      ) : null}
    </>
  );
};

export default DeleteRow;
